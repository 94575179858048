/* tslint:disable */
import {
  UserAccount,
  Group
} from '../index';

declare var Object: any;
export interface UserAccountGroupInterface {
  "userAccountId"?: number;
  "groupId"?: number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  userAccount?: UserAccount;
  group?: Group;
}

export class UserAccountGroup implements UserAccountGroupInterface {
  "userAccountId": number;
  "groupId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  userAccount: UserAccount;
  group: Group;
  constructor(data?: UserAccountGroupInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountGroup`.
   */
  public static getModelName() {
    return "UserAccountGroup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountGroup for dynamic purposes.
  **/
  public static factory(data: UserAccountGroupInterface): UserAccountGroup{
    return new UserAccountGroup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountGroup',
      plural: 'UserAccountGroups',
      path: 'UserAccountGroups',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "groupId": {
          name: 'groupId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        userAccount: {
          name: 'userAccount',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'groupId',
          keyTo: 'id'
        },
      }
    }
  }
}
