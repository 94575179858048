/* tslint:disable */

declare var Object: any;
export interface TopicCommentInterface {
  "commentTypeId": number;
  "topicId": number;
  "content": string;
  "languageCode": string;
  "replyToId"?: number;
  "data"?: string;
  "guid": string;
  "reported"?: boolean;
  "requireModeration"?: boolean;
  "active"?: boolean;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class TopicComment implements TopicCommentInterface {
  "commentTypeId": number;
  "topicId": number;
  "content": string;
  "languageCode": string;
  "replyToId": number;
  "data": string;
  "guid": string;
  "reported": boolean;
  "requireModeration": boolean;
  "active": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: TopicCommentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TopicComment`.
   */
  public static getModelName() {
    return "TopicComment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TopicComment for dynamic purposes.
  **/
  public static factory(data: TopicCommentInterface): TopicComment{
    return new TopicComment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TopicComment',
      plural: 'TopicComments',
      path: 'TopicComments',
      idName: 'id',
      properties: {
        "commentTypeId": {
          name: 'commentTypeId',
          type: 'number'
        },
        "topicId": {
          name: 'topicId',
          type: 'number'
        },
        "content": {
          name: 'content',
          type: 'string'
        },
        "languageCode": {
          name: 'languageCode',
          type: 'string'
        },
        "replyToId": {
          name: 'replyToId',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "guid": {
          name: 'guid',
          type: 'string'
        },
        "reported": {
          name: 'reported',
          type: 'boolean',
          default: false
        },
        "requireModeration": {
          name: 'requireModeration',
          type: 'boolean',
          default: false
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
