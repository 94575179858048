<div class="relative pb-5 border-b border-mulberry-550 p-7">
  <div class="relative text-4xl font-medium text-center sm:text-5xl sm:font-medium sm:text-center">
    {{ "newAccount" | transloco }}
  </div>
  <div class="flex items-center justify-center mt-3 space-x-2">
    <ng-container *ngFor="let _stage of validSteps">
      <div
        *ngIf="(teamInviteString && _stage > stepsEnum.ACCOUNT_TYPE) || !teamInviteString"
        [ngClass]="{
          'bg-razberry-500': _stage <= stage,
          'hover:bg-razberry-450 cursor-pointer': _stage < stage,
          'bg-mulberry-600': _stage > stage
        }"
        class="w-3 h-3 rounded-full sm:h-4 sm:w-4"
        (click)="_stage < stage ? (stage = _stage) : null"
      ></div>
    </ng-container>
  </div>
</div>

<div
  *ngIf="teamInviteCode && teamInviteString"
  class="py-2 text-2xl font-medium text-center bg-mulberry-100"
  [innerHTML]="teamInviteString"
></div>

<div class="signup-back" *ngIf="showBackButton()" (click)="prevStep()">
  <i class="fas fa-arrow-left"></i>
</div>

<div *ngIf="stage === stepsEnum.ACCOUNT_TYPE" class="flex flex-col justify-center p-7">
  <div class="mb-4 text-center">
    {{ "signup.userTypeQuestion" | transloco }}
  </div>

  <div class="flex items-center flex-wrap justify-center -mx-4">
    <button
      (click)="processType('student')"
      class="flex flex-col items-center justify-center m-2 w-28 md:w-40 p-3 px-3 py-4 font-medium transition duration-150 ease-in transform rounded-lg ring-0 ring-inset hover:-translate-y-2 focus:outline-none active:translate-y-0 text-mulberry-600 bg-mulberry-450 shadow-mulberry-light hover:ring hover:ring-blueberry-300 hover:ring-inset hover:shadow-blueberry active:shadow-none"
    >
      <img class="w-12 h-12 md:w-16 md:h-16" src="assets/img/color/user-player.svg" />
      {{ "player" | transloco }}
    </button>

    <button
      (click)="processType('teacher')"
      class="flex flex-col items-center justify-center m-2 w-28 md:w-40 p-3 px-3 py-4 font-medium transition duration-150 ease-in transform rounded-lg ring-0 ring-inset hover:-translate-y-2 focus:outline-none active:translate-y-0 text-mulberry-600 bg-mulberry-450 shadow-mulberry-light hover:ring hover:ring-blueberry-300 hover:ring-inset hover:shadow-blueberry active:shadow-none"
    >
      <img class="w-12 h-12 md:w-16 md:h-16" src="assets/img/color/teacher.svg" />
      {{ "teacher" | transloco }}
    </button>

    <button
      (click)="processType('parent')"
      class="flex flex-col items-center justify-center m-2 w-28 md:w-40 p-3 px-3 py-4 font-medium transition duration-150 ease-in transform rounded-lg ring-0 ring-inset hover:-translate-y-2 focus:outline-none active:translate-y-0 text-mulberry-600 bg-mulberry-450 shadow-mulberry-light hover:ring hover:ring-blueberry-300 hover:ring-inset hover:shadow-blueberry active:shadow-none"
    >
      <img class="w-10 h-10 h-14 w-14" src="assets/img/color/parent.svg" />
      {{ "parent" | transloco }}
    </button>
  </div>
</div>

<div *ngIf="stage === stepsEnum.COUNTRY" class="relative flex flex-col justify-center p-7 sm:px-20">
  <div
    *ngIf="!activePolyChallenges"
    class="absolute inset-0 z-50 flex justify-center bg-white bg-opacity-50 align-items"
  >
    <svg class="w-10 animate-spin text-mulberry-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
  <div class="mb-4">
    <div class="mb-1 text-lg">{{ "chooseYourCountry" | transloco }}</div>
    <div *ngIf="!activePolyChallenges" class="w-full h-10 rounded-lg bg-mulberry-300"></div>
    <ui-iso-picker
      *ngIf="activePolyChallenges"
      [countries]="activePolyChallenges"
      [value]="country"
      [showDialCode]="false"
      (selectionChanged)="setCountry($event.iso2)"
      placeholder=""
    ></ui-iso-picker>
  </div>

  <div class="mb-4" *ngIf="isStudent">
    <div *ngIf="requiresStep('DOB'); else ageBlock">
      <div class="mb-1 text-lg">{{ "signup.birthday" | transloco }}</div>
      <div class="flex-row space-x-2">
        <ui-select
          class="flex-grow"
          [useTransloco]="true"
          [value]="birth.month"
          [placeholder]="'monthPlaceholder' | transloco"
          [options]="months"
          (selectionChange)="birth.month = $event"
        ></ui-select>
        <ui-select
          [useTransloco]="false"
          [value]="birth.day"
          [placeholder]="'dayPlaceholder' | transloco"
          [options]="days"
          (selectionChange)="birth.day = $event"
        ></ui-select>
        <ui-select
          [useTransloco]="false"
          [value]="birth.year"
          [placeholder]="'yearPlaceholder' | transloco"
          [options]="years"
          (selectionChange)="birth.year = $event"
        ></ui-select>
      </div>
    </div>
    <ng-template #ageBlock>
      <div class="mb-1 text-lg">{{ "age" | transloco }}</div>
      <ui-select
        [inline]="true"
        [placeholder]="'selectAge' | transloco"
        (selectionChange)="setAge($event)"
        [value]="age"
        [options]="ages"
      ></ui-select>
    </ng-template>
  </div>

  <div class="mb-4" *ngIf="isStudent">
    <div class="mb-1 text-lg">{{ "myGrade" | transloco }}</div>
    <ui-select
      [inline]="true"
      [placeholder]="'selectGrade' | transloco"
      (selectionChange)="setGrade($event)"
      [value]="grade"
      [options]="grades || defaultGrades"
      class="w-fit-content"
    ></ui-select>
  </div>

  <div class="mb-4" *ngIf="isUserTypeFromQuery">
    <div class="mb-1 text-lg">Account type</div>
    <div class="flex items-center text-lg font-light text-mulberry-800">
      <img class="flex-shrink-0 w-8 h-8 mr-2" [src]="userTypeIcon" />
      {{ userTypeLabel | transloco }}
    </div>
  </div>

  <ui-button size="lg" classes="mt-4" [disabled]="!validCountryAge()" (click)="processAge($event)">
    {{ "next" | transloco }}
  </ui-button>
</div>

<div class="flex flex-col justify-center p-7 sm:px-20" *ngIf="stage == stepsEnum.TEAM">
  <ng-container *ngIf="isStudent">
    <div class="mb-4 text-center">{{ "signup.askTeamCode" | transloco }}</div>

    <ui-text-input
      name="username"
      id="username"
      maxLength="20"
      autocomplete="off"
      [placeholder]="'enterCodePlaceholder' | transloco"
      (keydown.space)="$event.preventDefault()"
      [value]="teamInviteCode"
      [error]="errors.teamInviteCode | transloco"
      [success]="teamInviteCodeValid"
      [loading]="isCheckingTeamCode"
      (textChanged)="setTeamCode($event)"
    ></ui-text-input>

    <ui-button
      [disabled]="this.teamInviteCode && this.teamInviteCode.length > 0 && !this.teamInviteCodeValid"
      size="lg"
      classes="mt-4"
      (click)="nextStep()"
      >{{ this.teamInviteCode?.length > 0 ? ("next" | transloco) : ("skip" | transloco) }}</ui-button
    >
  </ng-container>

  <ng-container *ngIf="userType == 'teacher'">
    <div>
      <div
        class="mb-4 text-center"
        [innerHTML]="'signup.createTeamPrompt' | transloco: { challenge: countryName }"
      ></div>
      <ui-text-input
        [error]="errors.teamName | transloco"
        classes="mb-2"
        maxlength="24"
        [placeholder]="'classroomName' | transloco"
        [blacklist]="[' ']"
        [value]="teamName"
        (textChanged)="teamName = $event"
      ></ui-text-input>

      <div [innerHTML]="'signup.teamNameRestrictions' | transloco" class="mb-4 text-xs text-mulberry-650"></div>

      <div class="mb-2 text-xs text-mulberry-650">
        {{ "signup.dontWorryTeamName" | transloco }}
      </div>

      <ui-button size="lg" classes="mt-4" [disabled]="!teamName || !validTeamName()" (click)="nextStep()">
        {{ "next" | transloco }}
      </ui-button>
    </div>
  </ng-container>
</div>

<form class="flex flex-col justify-center p-7 sm:px-20" *ngIf="stage == stepsEnum.PERSONAL_INFO">
  <div *ngIf="requiresStep('name')" class="mb-2">
    <div class="mb-1 text-lg">{{ "enterYourName" | transloco }}</div>

    <ui-text-input
      classes="mb-2"
      type="name"
      [placeholder]="'firstNamePlaceholder' | transloco"
      autocomplete="off"
      [value]="firstName"
      [maxLength]="36"
      [success]="firstName?.length > 0"
      (textChanged)="firstName = $event"
    ></ui-text-input>

    <ui-text-input
      type="name"
      classes="mb-2"
      [placeholder]="'middleNamePlaceholder' | transloco"
      autocomplete="off"
      [value]="middleName"
      [maxLength]="36"
      [success]="middleName?.length > 0"
      (textChanged)="middleName = $event"
    ></ui-text-input>

    <ui-text-input
      type="name"
      classes="mb-2"
      [placeholder]="'lastNamePlaceholder' | transloco"
      autocomplete="off"
      [value]="lastName"
      [maxLength]="36"
      [success]="lastName?.length > 0"
      (textChanged)="lastName = $event"
    ></ui-text-input>
  </div>

  <div *ngIf="requiresStep('studentId')" class="mb-2">
    <div *ngIf="getHelpText('studentId')" class="mb-1 flex-apart">
      <div class="text-lg">{{ "studentId" | transloco }}</div>
      <div
        popover
        [content]="getHelpText('studentId') | transloco"
        [setSVGAttributes]="{ class: 'w-6 h-6' }"
        class="cursor-pointer text-grape-450 hover:text-grape-500 leading-0"
        inlineSVG="assets/img/black/help-circle.svg"
      ></div>
    </div>

    <div>
      <ui-text-input
        type="name"
        classes="mb-2"
        [placeholder]="'studentId' | transloco"
        autocomplete="off"
        [maxLength]="36"
        [blacklist]="[' ']"
        [value]="alternativeId"
        [success]="alternativeId?.length > 0"
        (textChanged)="alternativeId = $event"
      ></ui-text-input>
    </div>
  </div>

  <ui-button size="lg" classes="mt-4" [disabled]="!validStudentInfo()" (click)="nextStep()">
    {{ "next" | transloco }}
  </ui-button>
</form>

<app-signup-school-picker
  *ngIf="stage == stepsEnum.SCHOOL_PICKER"
  [countryId]="countryId"
  [defaultLanguage]="countryDefaultLanguage"
  [otherSchool]="otherSchool"
  [school]="school"
  (submit)="nextStep()"
  (selectOtherSchool)="selectOtherSchool($event)"
  (onSelect)="onSchoolSelect($event)"
></app-signup-school-picker>

<form class="flex flex-col justify-center p-7 sm:px-20" *ngIf="stage == stepsEnum.ACCOUNT_INFO">
  <div class="mb-4 text-center">
    {{ "signup.createUserPass" | transloco }}
  </div>

  <div class="mb-4" *ngIf="userType === 'student-minor'">
    <div class="mb-1 text-lg">
      {{ "enterUsername" | transloco }}
    </div>

    <ui-text-input
      name="username"
      id="username"
      maxLength="20"
      autocomplete="off"
      [blacklist]="[' ']"
      [placeholder]="'usernamePlaceholder' | transloco"
      [value]="minorUsername"
      [error]="errors.signupUsername | transloco"
      [success]="usernameNotTaken && !errors.signupUsername"
      [loading]="isCheckingUsername"
      (textChanged)="setMinorUsername($event)"
    ></ui-text-input>

    <div class="my-2 text-xs text-mulberry-650">
      {{ "usernameRestrictions" | transloco }}
    </div>
  </div>

  <div class="mb-4" *ngIf="userType === 'student-major' || userType === 'teacher' || userType === 'parent'">
    <div class="mb-1 text-lg">{{ "yourEmailAddress" | transloco }}</div>
    <ui-text-input
      type="email"
      [placeholder]="'emailPlaceholder' | transloco"
      autocomplete="off"
      [value]="signupEmail"
      [error]="errors.signupEmail | transloco"
      [loading]="isCheckingEmail"
      [success]="emailNotTaken"
      (textChanged)="setSignupEmail($event)"
    ></ui-text-input>
  </div>

  <div class="mb-4" *ngIf="requiresStep('phoneMobile')">
    <div class="mb-1 text-lg">{{ "phoneNumber" | transloco }}</div>

    <ui-text-input
      type="text"
      [placeholder]="'phoneNumberPlaceholder' | transloco"
      autocomplete="off"
      [value]="phoneMobile"
      [maxLength]="36"
      (textChanged)="setPhoneNumber($event)"
      [error]="phoneError | transloco"
      [warning]="phoneWarning | transloco"
      [success]="phoneMobile && !phoneWarning && !phoneError"
    ></ui-text-input>
  </div>

  <div class="mb-4">
    <div class="mb-1 text-lg">{{ "setAStrongPassword" | transloco }}</div>

    <ui-text-input
      classes="mb-2"
      type="password"
      [placeholder]="'passwordPlaceholder' | transloco"
      [value]="signupPassword"
      [error]="errors.signupPassword"
      autocomplete="new-password"
      (textChanged)="validatePassword($event)"
    ></ui-text-input>

    <ui-text-input
      type="password"
      [placeholder]="'confirmPasswordPlaceholder' | transloco"
      [value]="confirmPassword"
      [error]="errors.confirmPassword | transloco"
      autocomplete="new-password"
      (textChanged)="validateConfirm($event)"
    ></ui-text-input>
  </div>

  <div class="mb-4" *ngIf="userType === 'student-minor' && !teamInviteCode">
    <div class="mb-1 text-lg">{{ "parentEmail" | transloco }}</div>

    <ui-text-input
      type="email"
      [placeholder]="'parentEmailPlaceholder' | transloco"
      [value]="parentEmail"
      autocomplete="off"
      (blur)="validateParentEmail()"
      (textChanged)="parentEmail = $event"
      [error]="errors.parentEmail | transloco"
    ></ui-text-input>
  </div>

  <ui-alert type="error" *ngIf="errorText" classes="my-2">{{ errorText | transloco }}</ui-alert>

  <ui-button
    size="lg"
    [loading]="isCreatingAccount"
    classes="mt-4"
    [disabled]="!validSignup() || signupDisabled"
    (click)="createClicked()"
  >
    {{ (challengeCode ? "next" : "createAccount") | transloco }}
  </ui-button>
</form>

<div
  *ngIf="stage == stepsEnum.ACCOUNT_INFO && (teamInviteCode || userType !== 'student-minor')"
  class="flex flex-col items-center justify-center py-10 border-t border-mulberry-550 sm:px-20"
>
  <div class="mb-2 text-xs text-mulberry-650">{{ "signUpExternal" | transloco }}</div>
  <button
    class="flex items-center py-1.5 px-3 font-medium rounded-lg transition duration-150 ease-in ring-0 ring-inset hover:-translate-y-2 transform focus:outline-none active:translate-y-0 bg-mulberry-450 text-mulberry-800 hover:shadow-mulberry focus:ring-2 focus:ring-mulberry-600 active:shadow-none shadow-mulberry-light"
    (click)="swgClicked()"
  >
    <div *ngIf="googleLoading" class="icon sm mar-sm-right">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <img *ngIf="!googleLoading" class="icon sm mar-sm-right" src="assets/img/icons8-google.svg" />
    <div class="subtle yeswrap">
      {{ "signUpWithGoogle" | transloco }}
    </div>
  </button>
  <div *ngIf="googleError" class="error mar-sm-top">
    {{ googleError | transloco }}
  </div>
</div>

<!-- <ng-container *ngIf="stage === 9">
  <div class="flex-center pad-md">
    <div class="mar-sm">In order to use LetsMod at home, register for a Poly Challenge.</div>
    <button [routerLink]="SITEMAP.CHALLENGES_LANDING" class="orange mar-sm">Find My Poly Challenge</button>
  </div>
</ng-container> -->

<div class="my-4 mb-8 text-center" *ngIf="stage == stepsEnum.ACCOUNT_TYPE">
  {{ "signup.alreadyHaveAccount" | transloco }}
  <a (click)="goToLogin()" class="font-medium text-mango-500">{{ "logIn" | transloco }}</a>
</div>

<app-modal size="small" *ngIf="affirmationPopupActive" (close)="affirmationPopupActive = false">
  <div class="pad-xl">{{ "signup.teacherConfirmation" | transloco }}</div>
  <div class="flex-row-reverse gray-button-container">
    <button class="button button-lg mar-xs orange mar-md-right" (click)="affirmationClicked()">
      {{ "accept" | transloco }}
    </button>
    <button (click)="affirmationPopupActive = false" class="button button-lg mar-xs">
      {{ "cancel" | transloco }}
    </button>
  </div>
</app-modal>

<app-modal size="small" *ngIf="googleAffirmationPopupActive" (close)="googleAffirmationPopupActive = false">
  <div class="pad-xl">{{ "signup.teacherConfirmation" | transloco }}</div>
  <div class="flex-row-reverse gray-button-container">
    <button class="button button-lg mar-xs orange mar-md-right" (click)="signUpWithGoogle()">
      {{ "accept" | transloco }}
    </button>
    <button (click)="googleAffirmationPopupActive = false" class="button button-lg mar-xs">
      {{ "cancel" | transloco }}
    </button>
  </div>
</app-modal>
