/* tslint:disable */

declare var Object: any;
export interface TutorialStepTranslationInterface {
  "tutorialStepId": number;
  "languageCode": string;
  "name"?: string;
  "description"?: string;
  "data": any;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class TutorialStepTranslation implements TutorialStepTranslationInterface {
  "tutorialStepId": number;
  "languageCode": string;
  "name": string;
  "description": string;
  "data": any;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: TutorialStepTranslationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TutorialStepTranslation`.
   */
  public static getModelName() {
    return "TutorialStepTranslation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TutorialStepTranslation for dynamic purposes.
  **/
  public static factory(data: TutorialStepTranslationInterface): TutorialStepTranslation{
    return new TutorialStepTranslation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TutorialStepTranslation',
      plural: 'TutorialStepTranslations',
      path: 'TutorialStepTranslations',
      idName: 'id',
      properties: {
        "tutorialStepId": {
          name: 'tutorialStepId',
          type: 'number'
        },
        "languageCode": {
          name: 'languageCode',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
