/* tslint:disable */
import {
  Entity,
  UserAccount,
  ClassroomTranslation,
  Challenge
} from '../index';

declare var Object: any;
export interface ClassroomInterface {
  "name"?: string;
  "displayName"?: string;
  "description"?: string;
  "code"?: string;
  "ownerUserAccountId": number;
  "active": number;
  "entityId": number;
  "joiningEnabled"?: number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "grades"?: Array<any>;
  "enableComments"?: boolean;
  "id"?: number;
  entity?: Entity;
  owner?: UserAccount;
  translations?: ClassroomTranslation[];
  teachers?: UserAccount[];
  students?: UserAccount[];
  challenges?: Challenge[];
}

export class Classroom implements ClassroomInterface {
  "name": string;
  "displayName": string;
  "description": string;
  "code": string;
  "ownerUserAccountId": number;
  "active": number;
  "entityId": number;
  "joiningEnabled": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "grades": Array<any>;
  "enableComments": boolean;
  "id": number;
  entity: Entity;
  owner: UserAccount;
  translations: ClassroomTranslation[];
  teachers: UserAccount[];
  students: UserAccount[];
  challenges: Challenge[];
  constructor(data?: ClassroomInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Classroom`.
   */
  public static getModelName() {
    return "Classroom";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Classroom for dynamic purposes.
  **/
  public static factory(data: ClassroomInterface): Classroom{
    return new Classroom(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Classroom',
      plural: 'Classrooms',
      path: 'Classrooms',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "ownerUserAccountId": {
          name: 'ownerUserAccountId',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'number',
          default: 1
        },
        "entityId": {
          name: 'entityId',
          type: 'number'
        },
        "joiningEnabled": {
          name: 'joiningEnabled',
          type: 'number',
          default: 1
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "grades": {
          name: 'grades',
          type: 'Array&lt;any&gt;'
        },
        "enableComments": {
          name: 'enableComments',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        entity: {
          name: 'entity',
          type: 'Entity',
          model: 'Entity',
          relationType: 'belongsTo',
                  keyFrom: 'entityId',
          keyTo: 'id'
        },
        owner: {
          name: 'owner',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'ownerUserAccountId',
          keyTo: 'id'
        },
        translations: {
          name: 'translations',
          type: 'ClassroomTranslation[]',
          model: 'ClassroomTranslation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'classroomId'
        },
        teachers: {
          name: 'teachers',
          type: 'UserAccount[]',
          model: 'UserAccount',
          relationType: 'hasMany',
          modelThrough: 'ClassroomTeacher',
          keyThrough: 'userAccountId',
          keyFrom: 'id',
          keyTo: 'classroomId'
        },
        students: {
          name: 'students',
          type: 'UserAccount[]',
          model: 'UserAccount',
          relationType: 'hasMany',
          modelThrough: 'ClassroomStudent',
          keyThrough: 'userAccountId',
          keyFrom: 'id',
          keyTo: 'classroomId'
        },
        challenges: {
          name: 'challenges',
          type: 'Challenge[]',
          model: 'Challenge',
          relationType: 'hasMany',
          modelThrough: 'ClassroomChallenge',
          keyThrough: 'challengeId',
          keyFrom: 'id',
          keyTo: 'classroomId'
        },
      }
    }
  }
}
