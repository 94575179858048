import { Injectable } from '@angular/core';
import { UserAccountApi, Classroom, ClassroomApi } from 'src/app/shared/sdk';

import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClassroomService {
  constructor(private $userAccount: UserAccountApi, private $classroom: ClassroomApi) {}
  classrooms: BehaviorSubject<Classroom[]> = new BehaviorSubject([]);
  isLoading = false;
  fetch(userId: number) {
    this.isLoading = true;
    this.$userAccount.findById(userId, { include: 'classrooms' }).subscribe((res: any) => {
      this.isLoading = false;
      this.classrooms.next(res.classrooms);
    });
  }

  leaveClass(id: number) {
    this.$classroom.deleteStudentSelfFromClassroom(id).subscribe((res) => {
      this.classrooms.next([]);
    });
  }
}
