/* tslint:disable */

declare var Object: any;
export interface PaymentInterface {
  "userAccountId": number;
  "entityId": number;
  "providerId"?: number;
  "tokenTypeId"?: number;
  "quantity"?: number;
  "mobile"?: string;
  "paymentStatus"?: number;
  "finalPrice"?: number;
  "finalCurrency"?: string;
  "checkoutData"?: any;
  "webhookData"?: any;
  "paymentUpdatedAt"?: Date;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class Payment implements PaymentInterface {
  "userAccountId": number;
  "entityId": number;
  "providerId": number;
  "tokenTypeId": number;
  "quantity": number;
  "mobile": string;
  "paymentStatus": number;
  "finalPrice": number;
  "finalCurrency": string;
  "checkoutData": any;
  "webhookData": any;
  "paymentUpdatedAt": Date;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: PaymentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Payment`.
   */
  public static getModelName() {
    return "Payment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Payment for dynamic purposes.
  **/
  public static factory(data: PaymentInterface): Payment{
    return new Payment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Payment',
      plural: 'Payments',
      path: 'Payments',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "entityId": {
          name: 'entityId',
          type: 'number'
        },
        "providerId": {
          name: 'providerId',
          type: 'number'
        },
        "tokenTypeId": {
          name: 'tokenTypeId',
          type: 'number'
        },
        "quantity": {
          name: 'quantity',
          type: 'number'
        },
        "mobile": {
          name: 'mobile',
          type: 'string'
        },
        "paymentStatus": {
          name: 'paymentStatus',
          type: 'number',
          default: 0
        },
        "finalPrice": {
          name: 'finalPrice',
          type: 'number'
        },
        "finalCurrency": {
          name: 'finalCurrency',
          type: 'string'
        },
        "checkoutData": {
          name: 'checkoutData',
          type: 'any'
        },
        "webhookData": {
          name: 'webhookData',
          type: 'any'
        },
        "paymentUpdatedAt": {
          name: 'paymentUpdatedAt',
          type: 'Date'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
