/* tslint:disable */
import { Injectable } from '@angular/core';
import { ActivityLog } from '../../models/ActivityLog';
import { ActivityType } from '../../models/ActivityType';
import { BoardChip } from '../../models/BoardChip';
import { BoardHistory } from '../../models/BoardHistory';
import { BoardPortal } from '../../models/BoardPortal';
import { BoardProgress } from '../../models/BoardProgress';
import { Board } from '../../models/Board';
import { Challenge } from '../../models/Challenge';
import { ChallengeLesson } from '../../models/ChallengeLesson';
import { ChallengeTimeline } from '../../models/ChallengeTimeline';
import { ChipHistory } from '../../models/ChipHistory';
import { ChipProgress } from '../../models/ChipProgress';
import { Chip } from '../../models/Chip';
import { Classroom } from '../../models/Classroom';
import { Container } from '../../models/Container';
import { Currency } from '../../models/Currency';
import { CurrencyType } from '../../models/CurrencyType';
import { Diagnostic } from '../../models/Diagnostic';
import { DiagnosticLog } from '../../models/DiagnosticLog';
import { Email } from '../../models/Email';
import { EntityChallenge } from '../../models/EntityChallenge';
import { Entity } from '../../models/Entity';
import { EntityTranslation } from '../../models/EntityTranslation';
import { EntityType } from '../../models/EntityType';
import { Export } from '../../models/Export';
import { GlobalSettings } from '../../models/GlobalSettings';
import { GoogleClassroom } from '../../models/GoogleClassroom';
import { GoogleClassroomAssignment } from '../../models/GoogleClassroomAssignment';
import { Group } from '../../models/Group';
import { GroupPermission } from '../../models/GroupPermission';
import { GroupRole } from '../../models/GroupRole';
import { Integration } from '../../models/Integration';
import { Lesson } from '../../models/Lesson';
import { LessonChallengeTimeline } from '../../models/LessonChallengeTimeline';
import { LessonMachine } from '../../models/LessonMachine';
import { MachineBoard } from '../../models/MachineBoard';
import { MachineHistory } from '../../models/MachineHistory';
import { MachinePresentation } from '../../models/MachinePresentation';
import { MachinePrize } from '../../models/MachinePrize';
import { MachineProgress } from '../../models/MachineProgress';
import { MachineRecommendation } from '../../models/MachineRecommendation';
import { Machine } from '../../models/Machine';
import { ObjectType } from '../../models/ObjectType';
import { Permission } from '../../models/Permission';
import { PromoCode } from '../../models/PromoCode';
import { Report } from '../../models/Report';
import { Tag } from '../../models/Tag';
import { TagType } from '../../models/TagType';
import { TimeZone } from '../../models/TimeZone';
import { Token } from '../../models/Token';
import { UIDiagnostic } from '../../models/UIDiagnostic';
import { UIApp } from '../../models/UIApp';
import { UICache } from '../../models/UICache';
import { UIPortalClient } from '../../models/UIPortalClient';
import { UIPublic } from '../../models/UIPublic';
import { UIUserAccount } from '../../models/UIUserAccount';
import { UserAccount } from '../../models/UserAccount';
import { UserAccountGroup } from '../../models/UserAccountGroup';
import { UserAccountChallenge } from '../../models/UserAccountChallenge';
import { UserEntity } from '../../models/UserEntity';
import { UserMigration } from '../../models/UserMigration';
import { UserMachineEntry } from '../../models/UserMachineEntry';
import { UserMachineEntryScore } from '../../models/UserMachineEntryScore';
import { V3 } from '../../models/V3';
import { Payment } from '../../models/Payment';
import { Announcement } from '../../models/Announcement';
import { AnnouncementGroup } from '../../models/AnnouncementGroup';
import { AnnouncementChallenge } from '../../models/AnnouncementChallenge';
import { AnnouncementTranslation } from '../../models/AnnouncementTranslation';
import { ClassroomTranslation } from '../../models/ClassroomTranslation';
import { ClassroomTeacher } from '../../models/ClassroomTeacher';
import { ClassroomStudent } from '../../models/ClassroomStudent';
import { ClassroomChallenge } from '../../models/ClassroomChallenge';
import { UserAccountEntity } from '../../models/UserAccountEntity';
import { Milestone } from '../../models/Milestone';
import { MilestoneType } from '../../models/MilestoneType';
import { UserAccountMilestone } from '../../models/UserAccountMilestone';
import { UserAccountItem } from '../../models/UserAccountItem';
import { Item } from '../../models/Item';
import { ItemType } from '../../models/ItemType';
import { Assessment } from '../../models/Assessment';
import { AssessmentTemplate } from '../../models/AssessmentTemplate';
import { UserAccountAssessment } from '../../models/UserAccountAssessment';
import { UserAccountAssessmentAnswer } from '../../models/UserAccountAssessmentAnswer';
import { ItemRelation } from '../../models/ItemRelation';
import { LessonProgress } from '../../models/LessonProgress';
import { Topic } from '../../models/Topic';
import { TopicCategory } from '../../models/TopicCategory';
import { TopicComment } from '../../models/TopicComment';
import { TopicCommentType } from '../../models/TopicCommentType';
import { TopicCommentReport } from '../../models/TopicCommentReport';
import { TopicCommentReportType } from '../../models/TopicCommentReportType';
import { HelpCardType } from '../../models/HelpCardType';
import { ObjectHelpCard } from '../../models/ObjectHelpCard';
import { ObjectTypeHelpCard } from '../../models/ObjectTypeHelpCard';
import { Tutorial } from '../../models/Tutorial';
import { TutorialStep } from '../../models/TutorialStep';
import { TutorialStepTranslation } from '../../models/TutorialStepTranslation';
import { TutorialProgress } from '../../models/TutorialProgress';
import { ClassroomMachineSet } from '../../models/ClassroomMachineSet';
import { Campaign } from '../../models/Campaign';
import { CampaignSetting } from '../../models/CampaignSetting';
import { Library } from '../../models/Library';
import { LibraryObject } from '../../models/LibraryObject';
import { Tray } from '../../models/Tray';
import { TrayObject } from '../../models/TrayObject';
import { UserAccountObjectHistory } from '../../models/UserAccountObjectHistory';
import { UserAccountTrayFavorite } from '../../models/UserAccountTrayFavorite';
import { UserAccountNotification } from '../../models/UserAccountNotification';
import { Notification } from '../../models/Notification';
import { NotificationRule } from '../../models/NotificationRule';
import { NotificationType } from '../../models/NotificationType';
import { NotificationTranslation } from '../../models/NotificationTranslation';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    ActivityLog: ActivityLog,
    ActivityType: ActivityType,
    BoardChip: BoardChip,
    BoardHistory: BoardHistory,
    BoardPortal: BoardPortal,
    BoardProgress: BoardProgress,
    Board: Board,
    Challenge: Challenge,
    ChallengeLesson: ChallengeLesson,
    ChallengeTimeline: ChallengeTimeline,
    ChipHistory: ChipHistory,
    ChipProgress: ChipProgress,
    Chip: Chip,
    Classroom: Classroom,
    Container: Container,
    Currency: Currency,
    CurrencyType: CurrencyType,
    Diagnostic: Diagnostic,
    DiagnosticLog: DiagnosticLog,
    Email: Email,
    EntityChallenge: EntityChallenge,
    Entity: Entity,
    EntityTranslation: EntityTranslation,
    EntityType: EntityType,
    Export: Export,
    GlobalSettings: GlobalSettings,
    GoogleClassroom: GoogleClassroom,
    GoogleClassroomAssignment: GoogleClassroomAssignment,
    Group: Group,
    GroupPermission: GroupPermission,
    GroupRole: GroupRole,
    Integration: Integration,
    Lesson: Lesson,
    LessonChallengeTimeline: LessonChallengeTimeline,
    LessonMachine: LessonMachine,
    MachineBoard: MachineBoard,
    MachineHistory: MachineHistory,
    MachinePresentation: MachinePresentation,
    MachinePrize: MachinePrize,
    MachineProgress: MachineProgress,
    MachineRecommendation: MachineRecommendation,
    Machine: Machine,
    ObjectType: ObjectType,
    Permission: Permission,
    PromoCode: PromoCode,
    Report: Report,
    Tag: Tag,
    TagType: TagType,
    TimeZone: TimeZone,
    Token: Token,
    UIDiagnostic: UIDiagnostic,
    UIApp: UIApp,
    UICache: UICache,
    UIPortalClient: UIPortalClient,
    UIPublic: UIPublic,
    UIUserAccount: UIUserAccount,
    UserAccount: UserAccount,
    UserAccountGroup: UserAccountGroup,
    UserAccountChallenge: UserAccountChallenge,
    UserEntity: UserEntity,
    UserMigration: UserMigration,
    UserMachineEntry: UserMachineEntry,
    UserMachineEntryScore: UserMachineEntryScore,
    V3: V3,
    Payment: Payment,
    Announcement: Announcement,
    AnnouncementGroup: AnnouncementGroup,
    AnnouncementChallenge: AnnouncementChallenge,
    AnnouncementTranslation: AnnouncementTranslation,
    ClassroomTranslation: ClassroomTranslation,
    ClassroomTeacher: ClassroomTeacher,
    ClassroomStudent: ClassroomStudent,
    ClassroomChallenge: ClassroomChallenge,
    UserAccountEntity: UserAccountEntity,
    Milestone: Milestone,
    MilestoneType: MilestoneType,
    UserAccountMilestone: UserAccountMilestone,
    UserAccountItem: UserAccountItem,
    Item: Item,
    ItemType: ItemType,
    Assessment: Assessment,
    AssessmentTemplate: AssessmentTemplate,
    UserAccountAssessment: UserAccountAssessment,
    UserAccountAssessmentAnswer: UserAccountAssessmentAnswer,
    ItemRelation: ItemRelation,
    LessonProgress: LessonProgress,
    Topic: Topic,
    TopicCategory: TopicCategory,
    TopicComment: TopicComment,
    TopicCommentType: TopicCommentType,
    TopicCommentReport: TopicCommentReport,
    TopicCommentReportType: TopicCommentReportType,
    HelpCardType: HelpCardType,
    ObjectHelpCard: ObjectHelpCard,
    ObjectTypeHelpCard: ObjectTypeHelpCard,
    Tutorial: Tutorial,
    TutorialStep: TutorialStep,
    TutorialStepTranslation: TutorialStepTranslation,
    TutorialProgress: TutorialProgress,
    ClassroomMachineSet: ClassroomMachineSet,
    Campaign: Campaign,
    CampaignSetting: CampaignSetting,
    Library: Library,
    LibraryObject: LibraryObject,
    Tray: Tray,
    TrayObject: TrayObject,
    UserAccountObjectHistory: UserAccountObjectHistory,
    UserAccountTrayFavorite: UserAccountTrayFavorite,
    UserAccountNotification: UserAccountNotification,
    Notification: Notification,
    NotificationRule: NotificationRule,
    NotificationType: NotificationType,
    NotificationTranslation: NotificationTranslation,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
