import { Component, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { isEmpty } from 'rxjs/operators';
import { Option, OptionValue, GroupOption } from './types';

@Component({
  selector: 'ui-select',
  templateUrl: './select.component.html',
})
export class SelectComponent implements OnInit, OnChanges {
  @Input() options: Option[] | GroupOption[];
  @Input() placeholder: string;
  @Input() value: OptionValue | OptionValue[];
  @Input() classes: string;
  @Input() disabled: boolean;
  @Input() inline: boolean = false;
  @Input() multiple: boolean = false;
  @Input() loading: boolean = false;

  @Input() error: string;

  @Input() useTransloco: boolean = false;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('select') select;

  constructor() {}

  ngOnInit(): void {}

  get hasSelection(): boolean {
    const hasSelection = typeof this.value !== 'undefined' && this.value !== null && this.value !== '';
    if (this.multiple) {
      return hasSelection && Array.isArray(this.value) && this.value.length > 0;
    }
    return hasSelection;
  }

  isSelected(value) {
    if (!this.hasSelection) {
      return false;
    }

    if (this.multiple) {
      const thisValue = this.value as OptionValue[];
      return thisValue.includes(value);
    } else {
      return this.value === value;
    }
  }

  onSelectionChange(selectedOptions: HTMLOptionsCollection) {
    const selections = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      selections.push(selectedOptions[i].value);
    }

    if (this.multiple) {
      this.selectionChange.emit(selections);
    } else {
      this.selectionChange.emit(selections[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      if (
        changes.value.currentValue === '' ||
        changes.value.currentValue === undefined ||
        changes.value.currentValue === null
      ) {
        if (this.select?.nativeElement) {
          this.select.nativeElement.selectedIndex = 0;
        }
      }
    }
  }

  get multipleKey() {
    let key;
    if (navigator.appVersion.indexOf('Win') != -1 || navigator.appVersion.indexOf('Linux') != -1) {
      key = 'ctrl';
    } else if (navigator.appVersion.indexOf('Mac') != -1) {
      key = 'cmd';
    }

    if (!key) {
      return;
    }
    return `<strong>${key}</strong>`;
  }
}
