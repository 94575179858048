import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { UserAccountApi, LoopBackAuth, V3Api, ClassroomApi, Challenge, ChallengeApi } from 'src/app/shared/sdk';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { SITEMAP } from 'src/utilities/sitemap';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthUtils } from 'src/app/shared/services/auth-utils.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';
import { Location } from '@angular/common';
import { Option } from 'src/app/shared/modules/ui/select/types';

import { debounceTime, distinctUntilChanged, switchMap, tap, takeUntil } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { defaultGrades } from 'src/app/shared/modules/ui/iso-picker/countries';

import { HttpClient } from '@angular/common/http';

import { LanguageService } from 'src/app/shared/services/language.service';

import { parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { StringService } from 'src/app/shared/services/string.service';

const DEFAULT_COUNTRY_CODE = undefined;

enum Steps {
  ACCOUNT_TYPE = 1,
  COUNTRY = 2,
  TEAM = 3,
  PERSONAL_INFO = 4,
  SCHOOL_PICKER = 5,
  ACCOUNT_INFO = 6,
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  @Output() login: EventEmitter<any> = new EventEmitter();
  @ViewChild('affirmation') affirmation: ElementRef;
  countries: Option[];
  countryId: number;
  countryDefaultLanguage: string;
  minorUsernameSubject: Subject<any> = new Subject();
  signupEmailSubject: Subject<any> = new Subject();
  teamCodeSubject: Subject<any> = new Subject();

  grades: any;
  defaultGrades: any = defaultGrades;
  userSource: string;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  errors: { [key: string]: string } = {};
  hasValidUsername: boolean;

  inviteCodeInput: string;

  googleError: string;

  onboardingFlow: any;

  birth: any = { day: null, month: null, year: null };

  days = Array.from(new Array(31), (x, i) => ({ label: i + 1, value: i + 1 }));
  months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ].map((x, i) => ({ label: x, value: i + 1 }));
  years = Array.from(new Array(100), (x, i) => ({ label: 2020 - i, value: 2020 - i }));

  validSteps: number[];
  phoneError: string;
  phoneWarning: string;

  constructor(
    private $lbauth: LoopBackAuth,
    private $router: Router,
    private $route: ActivatedRoute,
    private $v3: V3Api,
    private $translocoService: TranslocoService,
    private $authUtils: AuthUtils,
    private $authService: SocialAuthService,
    private $userAccount: UserAccountApi,
    private $classroom: ClassroomApi,
    private $utils: UtilsService,
    private $challenge: ChallengeApi,
    private http: HttpClient,
    private $languageService: LanguageService,
    private $localStorage: LocalStorageService,
    private $myuser: UserService,
    private $stringService: StringService
  ) {
    this.refreshFlow();
  }

  SITEMAP = SITEMAP;
  countryExplanationPopupActive: boolean = false;
  signupEmail: string;
  parentEmail: string;
  signupPassword: string = '';
  confirmPassword: string = '';
  signupDisabled: boolean;
  userType: string;
  errorText: string;
  studentGrade: number;
  stage: number = 1;
  teacherAffirmed: boolean = false;
  teamInviteCode: string;
  challengeCode: string;
  challengeInviteString: string;
  isUserTypeFromQuery = false;
  teamName: string;

  googleIdToken: string;

  teamInviteString: string;
  authStateListener: Subscription;

  firstName: string = null;
  middleName: string = null;
  lastName: string = null;

  affirmationPopupActive: boolean;
  googleAffirmationPopupActive: boolean;

  googleLoading: boolean;

  swgPopupOpened: boolean = false;

  activePolyChallenges: any[];

  teamInviteError: boolean = false;

  alternativeId: string = null;

  otherSchool: string;

  school: any;

  phoneMobile: string;

  referralCode: string;

  get challenge() {
    return this.activePolyChallenges.find((challenge) => challenge.code === this.country);
  }

  challengeModel: Challenge;

  age: number;
  ages: Option[] = Array(18)
    .fill(1)
    .map((x, i, array) => {
      const value = i + 1;
      let label = value === array.length ? `${value}+` : String(value);
      if (value <= 4) {
        label = "4 or younger";
      }
      return {
        label,
        value,
      };
    }).filter( value => value.value > 3);

  usernameTaken: boolean;
  usernameNotTaken: boolean;
  usernameChecker: Subscription;

  emailTaken: boolean;
  emailNotTaken: boolean;
  emailChecker: Subscription;

  minorUsername: string;

  country: string = DEFAULT_COUNTRY_CODE;

  grade: any;

  isCheckingTeamCode: boolean;
  teamInviteCodeError: any;
  teamInviteCodeValid: boolean;

  isCheckingEmail: boolean = false;
  isCheckingUsername: boolean = false;
  isCreatingAccount: boolean = false;

  @Output() switchAuthMode: EventEmitter<any> = new EventEmitter();

  goToLogin() {
    this.login.emit();
  }

  processAge() {
    if (String(this.userType).startsWith('student')) {
      if (this.isMinorAge(this.age)) {
        this.userType = 'student-minor';
      } else {
        this.userType = 'student-major';
      }
    }
    this.nextStep();
  }

  processType(type: string) {
    this.isUserTypeFromQuery = false;
    this.userType = type;
    this.refreshFlow();
    this.nextStep();
  }

  validCountryAge() {
    if (!this.country) return false;
    if (!this.isStudent) return true;
    return this.grade && (this.requiresStep('DOB') ? this.birth.year && this.birth.month && this.birth.day : this.age);
  }

  validStudentInfo() {
    return (
      (!this.requiresStep('name') ||
        (this.firstName && this.firstName.length > 0 && this.lastName && this.lastName.length > 0)) &&
      (!this.requiresStep('studentId') || (this.alternativeId && this.alternativeId.length > 0))
    );
  }

  validSignup() {
    return (
      !this.isCheckingUsername &&
      !this.isCheckingEmail &&
      (this.userType === 'student-minor'
        ? this.teamInviteCode || this.$authUtils.validEmail(this.parentEmail)
        : this.$authUtils.validEmail(this.signupEmail) && this.emailNotTaken) &&
      this.validPassword() &&
      (this.userType !== 'student-minor' || (this.hasValidUsername && this.usernameNotTaken)) &&
      this.signupPassword === this.confirmPassword &&
      this.validatePhone()
    );
  }

  resetLoading() {
    this.isCheckingEmail = false;
    this.isCheckingUsername = false;
    this.isCreatingAccount = false;
  }

  validateParentEmail() {
    this.errorText = undefined;
    if (!this.$authUtils.validEmail(this.parentEmail)) {
      this.errors.parentEmail = 'signup.invalidEmail';
    } else if (this.errors.signupUsername != 'signup.invalidEmail') {
      this.errors.parentEmail = undefined;
    }
  }

  validPassword() {
    return this.$authUtils.validPassword(this.signupPassword);
  }

  validatePassword(password: string): void {
    this.signupPassword = password;

    this.errorText = undefined;
    // called with errorOnly true on each keypress, meaning we should only change the error state if there is an error
    // called with errorOnly false on unfocus from field
    if (this.confirmPassword.length > 0) {
      this.validateConfirm();
    }

    this.errors.signupPassword = this.$authUtils.passwordErrors(this.signupPassword);
  }

  validateConfirm(confirmedPassword?: string) {
    if (confirmedPassword) {
      this.confirmPassword = confirmedPassword;
    }
    this.errorText = undefined;

    if (this.confirmPassword !== this.signupPassword) {
      this.errors.confirmPassword = 'signup.invalidPasswordConfirm';
    } else {
      this.errors.confirmPassword = undefined;
    }
  }

  prevStep() {
    this.stage = this.validSteps[this.validSteps.findIndex((step) => step == this.stage) - 1];
  }

  nextStep() {
    this.stage = this.validSteps[this.validSteps.findIndex((step) => step == this.stage) + 1];
  }

  showBackButton() {
    return this.validSteps.findIndex((step) => step == this.stage) > 0;
  }

  swgClicked() {
    if (this.userType === 'teacher') {
      this.googleAffirmationPopupActive = true;
    } else {
      this.signUpWithGoogle();
    }
  }

  signUpWithGoogle() {
    this.googleAffirmationPopupActive = false;
    this.swgPopupOpened = true;
    this.googleError = undefined;
    this.$authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  createClicked() {
    if (this.userType === 'teacher') {
      this.affirmationPopupActive = true;
    } else {
      this.createAccount();
    }
  }

  affirmationClicked() {
    this.googleIdToken = undefined;
    if (this.challengeCode) {
      this.affirmationPopupActive = false;
      this.stage = Steps.ACCOUNT_INFO;
    } else {
      this.createAccount();
    }
  }

  dobToString() {
    if (!this.requiresStep('DOB') || !this.birth.month || !this.birth.year || !this.birth.day) return null;

    return [this.birth.year, this.$utils.leadingZero(this.birth.month), this.$utils.leadingZero(this.birth.day)].join(
      '-'
    );
  }

  createAccount() {
    if (this.googleIdToken) {
      this.completeGoogleSignup();
      return;
    }
    this.affirmationPopupActive = false;
    this.errorText = undefined;
    this.errors = {};
    this.signupDisabled = true;
    this.isCreatingAccount = true;
    if (this.userType === 'teacher') {
      this.$v3
        .postSignupTeacher({
          username: this.signupEmail,
          password: this.signupPassword,
          country: this.country,
          challengeCode: this.challengeCode,
          classroomName: this.teamName,
          firstName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.firstName),
          middleName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.middleName),
          lastName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.lastName),
          alternativeId: this.requiresStep('studentId') && this.$stringService.trimNonPrintable(this.alternativeId),
          entityId: this.requiresStep('schoolPicker') ? this.school?.id : undefined,
          entityOther: this.requiresStep('schoolPicker') ? this.otherSchool : undefined,
          phoneMobile: this.requiresStep('phoneMobile') ? this.phoneMobile : undefined,
          referredByCode: this.referralCode,
          source: this.userSource,
        })
        .subscribe(
          (user) => {
            this.clearLocalStorage();
            this.$lbauth.setToken({
              ...user.__data,
              user: { ...user.userAccount, roles: user.roles, permissions: user.permissions },
            });
            window.location.href = '/';
          },
          (error) => {
            if (error.name && error.name == 'Forbidden') {
              this.stage = Steps.ACCOUNT_INFO;
              this.errorText = 'signup.userExists';
            } else if (error.message == 'Duplicate team name') {
              this.stage = Steps.TEAM;
              this.errors.teamName = 'createTeam.failed';
            } else {
              this.errorText = 'signup.failed';
            }
            this.signupDisabled = false;
            this.isCreatingAccount = false;
          }
        );
    } else if (this.userType === 'parent') {
      this.$v3
        .postSignupParent({
          username: this.signupEmail,
          password: this.signupPassword,
          country: this.country,
          firstName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.firstName),
          middleName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.middleName),
          lastName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.lastName),
          referredByCode: this.referralCode,
          source: this.userSource,
        })
        .subscribe((user) => {
          this.clearLocalStorage();
          this.$lbauth.setToken({
            ...user.__data,
            user: { ...user.userAccount, roles: user.roles, permissions: user.permissions },
          });
          window.location.href = '/';
        });
    } else {
      this.$v3
        .postSignupStudent({
          username: this.userType === 'student-minor' ? this.minorUsername : this.signupEmail,
          password: this.signupPassword,
          parentEmail: this.parentEmail,
          grade: this.grade,
          country: this.country,
          isMinor: this.userType === 'student-minor',
          classroomCode: this.teamInviteCode && this.teamInviteCode.length > 0 ? this.teamInviteCode : undefined,
          firstName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.firstName),
          middleName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.middleName),
          lastName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.lastName),
          alternativeId: this.requiresStep('studentId') && this.$stringService.trimNonPrintable(this.alternativeId),
          dateOfBirth: this.dobToString(),
          entityId: this.requiresStep('schoolPicker') ? this.school?.id : undefined,
          entityOther: this.requiresStep('schoolPicker') ? this.otherSchool : undefined,
          phoneMobile: this.requiresStep('phoneMobile') ? this.phoneMobile : undefined,
          referredByCode: this.referralCode,
          source: this.userSource,
        })
        .subscribe(
          (user) => {
            this.clearLocalStorage();
            if (user.userAccount?.active) {
              console.log('setting user to' + JSON.stringify(user));

              this.$lbauth.setToken({
                ...user,
                user: { ...user.userAccount, roles: user.roles, permissions: user.permissions },
              });
              window.location.href = '/';
            } else {
              this.stage = Steps.ACCOUNT_TYPE;
              this.switchAuthMode.emit('signup-complete');
            }
          },
          (error) => {
            console.log(error);
            if (error.name && error.name == 'Forbidden') {
              if (this.userType === 'student-minor') {
                this.errors.signupUsername = 'signup.usernameTaken';
              } else {
                this.errorText = 'signup.userExists';
              }
            } else if (error.message === 'CLASS_JOIN_DISABLED') {
              this.errorText = 'classroom.joinDisabled';
            } else {
              this.errorText = 'signup.failed';
            }
            this.signupDisabled = false;
            this.isCreatingAccount = false;
          }
        );
    }
  }

  completeGoogleSignup() {
    this.$v3
      .postSignupGoogle({
        idToken: this.googleIdToken,
        userType: this.userTypeForGoogle,
        classroomName: this.teamName,
        challengeCode: this.challengeCode,
        grade: this.grade,
        country: this.country,
        firstName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.firstName),
        middleName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.middleName),
        lastName: this.requiresStep('name') && this.$stringService.trimNonPrintable(this.lastName),
        alternativeId: this.requiresStep('studentId') && this.$stringService.trimNonPrintable(this.alternativeId),
        dateOfBirth: this.dobToString(),
        entityId: this.requiresStep('schoolPicker') ? this.school?.id : undefined,
        entityOther: this.requiresStep('schoolPicker') ? this.otherSchool : undefined,
        phoneMobile: this.requiresStep('phoneMobile') ? this.phoneMobile : undefined,
        referredByCode: this.referralCode,
        classroomCode: this.teamInviteCode && this.teamInviteCode.length > 0 ? this.teamInviteCode : undefined,
        source: this.userSource,
      })
      .subscribe(
        (user) => {
          this.clearLocalStorage();

          this.$lbauth.setToken({
            ...user,
            user: { ...user.userAccount, roles: user.roles, permissions: user.permissions },
          });
          if (this.teamInviteCode) {
            window.location.href = '/';
          } else if (this.challengeCode) {
            window.location.href = '/teams' + window.location.search;
          } else {
            window.location.href = '/';
          }
        },
        (error) => {
          this.googleLoading = false;
          this.googleError = error.message === 'NATIVE_ACCOUNT_EXISTS' ? 'swg.nativeExists' : 'swg.failed';
        }
      );
  }

  ngOnInit(): void {
    if (this.$myuser.loggedIn()) {
      this.$router.navigate(['', SITEMAP.ROOT]);
    }

    const countries = this.getCountries();
    this.referralCode = this.$localStorage.get('referralCode');

    const userSource = this.$localStorage.get('userSource');
    this.userSource = userSource && typeof userSource === 'object' ? JSON.stringify(userSource) : null;

    switch (this.$route.snapshot.queryParams.u) {
      case 's':
        this.processType('student');
        this.isUserTypeFromQuery = true;
        break;

      case 't':
        this.processType('teacher');
        this.isUserTypeFromQuery = true;
        break;

      case 'p':
        this.processType('parent');
        this.isUserTypeFromQuery = true;
        break;
    }

    this.authStateListener = this.$authService.authState.subscribe((user) => {
      if (!this.swgPopupOpened) return;
      console.log('GOT GOOGLE USER:', user);
      this.googleLoading = true;
      this.googleIdToken = user.idToken;
      this.signupEmail = undefined;
      this.signupPassword = undefined;
      this.minorUsername = undefined;
      this.confirmPassword = undefined;
      this.errors = {};
      if (this.challengeCode) {
        this.googleLoading = false;
        this.stage = Steps.ACCOUNT_INFO;
      } else {
        this.completeGoogleSignup();
      }
    });

    this.challengeCode = this.$route.snapshot.queryParams.challengeInviteCode;

    this.watchMinorUsernameUpdates();
    this.watchSignupEmailUpdates();
    this.watchTeamCodeChanges();

    const teamInviteCode = this.$route.snapshot.queryParams.teamInviteCode;
    if (teamInviteCode) {
      this.processType('student');

      countries.subscribe(() => {
        this.$classroom.getIsClassValidByCode(teamInviteCode).subscribe((res) => {
          if (res.countryCode) {
            this.setCountry(res.countryCode);
          }
          this.setTeamCode(teamInviteCode);
        });
      });
    }

    if (this.$route.snapshot.queryParams.type) {
      this.stage = Steps.COUNTRY;
      this.userType = this.$route.snapshot.queryParams.type;
    }
  }

  setTeamCode(teamCode: string) {
    this.teamInviteCode = teamCode;
    this.teamCodeSubject.next(teamCode);
  }
  setMinorUsername(username: string) {
    this.minorUsername = username;
    this.minorUsernameSubject.next(username);
  }

  watchMinorUsernameUpdates() {
    this.minorUsernameSubject
      .pipe(
        tap(() => {
          this.errors.signupUsername = undefined;
          this.isCheckingUsername = false;
          this.usernameTaken = false;
          this.usernameNotTaken = false;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((username) => {
          this.hasValidUsername = this.$authUtils.validUsername(this.minorUsername);
          if (!this.hasValidUsername) {
            this.errors.signupUsername = this.$authUtils.usernameErrors(this.minorUsername);
            return new Observable();
          } else {
            this.isCheckingUsername = true;
            return this.$v3.getUsernameExists(username).pipe(takeUntil(this.minorUsernameSubject));
          }
        })
      )
      .subscribe((response) => {
        this.isCheckingUsername = false;
        this.usernameTaken = response.count > 0;
        this.usernameNotTaken = !this.usernameTaken;
        if (this.usernameTaken) {
          this.errors.signupUsername = 'signup.usernameTaken';
        } else {
          this.errors.signupUsername = undefined;
        }
      });
  }

  setSignupEmail(email: string) {
    this.signupEmail = email;
    this.signupEmailSubject.next(email);
  }

  setCountry(country: any) {
    this.clearSchools();
    this.country = country;
    this.grade = null;
    this.grades = this.$utils.tryParse(
      this.activePolyChallenges.find((country) => country.iso2 == this.country)?.branding,
      []
    )?.customGrades;

    this.refreshFlow();

    if (this.teamInviteCode) {
      this.setTeamCode(this.teamInviteCode); // check if the team code is valid for this new country
    }

    this.$languageService.setActiveLanguage(
      this.activePolyChallenges.find((country) => country.iso2 == this.country)?.defaultLanguage
    );
  }

  refreshFlow() {
    if (!this.userType || !this.activePolyChallenges || !this.country) {
      if (this.userType === 'parent') {
        this.validSteps = [Steps.ACCOUNT_TYPE, Steps.COUNTRY, Steps.ACCOUNT_INFO];
      } else {
        this.validSteps = this.$route.snapshot.queryParams.teamInviteCode
          ? [Steps.COUNTRY, Steps.TEAM, Steps.ACCOUNT_INFO]
          : [Steps.ACCOUNT_TYPE, Steps.COUNTRY, Steps.TEAM, Steps.ACCOUNT_INFO];
      }
      return;
    }

    let key = this.capitalize(this.userType || 'Student');

    const country = this.activePolyChallenges.find((country) => country.iso2 == this.country);

    this.onboardingFlow = this.$utils.tryParse(country?.onboarding, {})?.flow[key];

    this.countryId = country?.id;

    this.countryDefaultLanguage = country?.defaultLanguage;

    let steps = [];
    if (!this.teamInviteCode) {
      steps.push(Steps.ACCOUNT_TYPE); // choose student or teacher
    }
    steps.push(Steps.COUNTRY); // choose country
    if (!this.teamInviteCode && !this.skipStep('teamCode') && this.userType !== 'parent') {
      steps.push(Steps.TEAM); // enter team code / team name
    }
    if (this.requiresStep('name') || this.requiresStep('dob')) {
      steps.push(Steps.PERSONAL_INFO); // enter addl info such as name and dob
    }

    if (this.requiresStep('schoolPicker')) {
      steps.push(Steps.SCHOOL_PICKER);
    }

    steps.push(Steps.ACCOUNT_INFO); // account info; always a required step

    this.validSteps = steps;
  }

  watchSignupEmailUpdates() {
    this.signupEmailSubject
      .pipe(
        tap(() => {
          this.errors.signupEmail = undefined;
          this.isCheckingEmail = false;
          this.emailTaken = false;
          this.emailNotTaken = false;
        }),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((email: string) => {
          if (!this.$authUtils.validEmail(email)) {
            this.errors.signupEmail = 'signup.invalidEmail';
            return new Observable();
          } else {
            this.isCheckingEmail = true;
            return this.$v3.getUsernameExists(email).pipe(takeUntil(this.signupEmailSubject));
          }
        })
      )
      .subscribe((response) => {
        this.isCheckingEmail = false;
        this.emailTaken = response.count > 0;
        this.emailNotTaken = !this.emailTaken;
        if (this.emailTaken) {
          this.errors.signupEmail = 'signup.usernameTaken';
        } else {
          this.errors.signupEmail = undefined;
        }
      });
  }

  watchTeamCodeChanges() {
    this.teamCodeSubject
      .pipe(
        tap(() => {
          this.errors.teamInviteCode = undefined;
          this.isCheckingTeamCode = false;
          this.teamInviteCodeValid = false;
        }),
        debounceTime(500),
        switchMap((teamCode) => {
          if (!teamCode || String(teamCode) === '' || !this.validTeamName(teamCode)) {
            return new Observable();
          } else {
            this.isCheckingTeamCode = true;
            return this.$classroom.getIsClassValidByCode(teamCode).pipe(takeUntil(this.minorUsernameSubject));
          }
        })
      )
      .subscribe((response) => {
        this.isCheckingTeamCode = false;
        if (!response.isValid) {
          this.errors.teamInviteCode = 'signup.invalidTeamCode';
          this.teamInviteCodeValid = false;
        } else if (response.countryCode && response.countryCode != this.country) {
          this.errors.teamInviteCode = 'signup.enrollDifferentCountryError';
          this.teamInviteCodeValid = false;
        } else {
          this.errors.teamInviteCode = undefined;
          this.teamInviteCodeValid = true;
        }
      });
  }

  getCountries() {
    const countries = this.$userAccount.getSignupCountries();

    countries.subscribe((res) => {
      this.activePolyChallenges = Object.values(res)
        .map((country: any[]) => {
          let info = country.filter(
            (countryLanguage) => countryLanguage.languageCode == this.$translocoService.getActiveLang()
          )[0];
          if (info) {
            info['iso2'] = info['code']; // morph key
            delete info['code'];
          }

          return info;
        })
        .filter((item) => item);

      const countryCode = this.$route.snapshot.queryParams.cc;
      if (countryCode) {
        this.setCountry(countryCode);
      } else {
        this.setCountry(this.country);
      }
    });

    return countries;
  }

  ngOnDestroy() {
    this.authStateListener.unsubscribe();
  }

  validTeamName(validTeamName?: string) {
    return /^\#{0,1}([a-zA-Z0-9_.-]{4,})$/.test(validTeamName || this.teamName.toLowerCase());
  }

  goTo(url: any) {
    window.open(url);
  }

  setAge(value: string) {
    this.age = parseInt(value);
  }
  setGrade(value: string) {
    this.grade = parseInt(value);
  }

  get countryName() {
    return this.activePolyChallenges.find((country) => country.iso2 == this.country)?.name;
  }

  requiresStep(stepName: string) {
    return this.onboardingFlow?.some((step) => step.step == stepName && step.enabled);
  }

  skipStep(stepName: string) {
    return this.onboardingFlow?.some((step) => step.step == stepName && step.enabled == false);
  }

  getHelpText(stepName: string) {
    return this.onboardingFlow?.find((step) => step.step == stepName && step.enabled)?.helpText;
  }

  isMinorAge(age: number) {
    const country = this.activePolyChallenges.find((c) => c.iso2 === this.country);
    let minorAge = country?.minorAge || 13;

    if (this.requiresStep('DOB')) {
      return (Date.now() - Date.parse(this.dobToString())) / (1000 * 60 * 60 * 24 * 365) < minorAge;
    } else {
      return age < country.minorAge;
    }
  }

  get stepsEnum() {
    return Steps;
  }

  capitalize(str: string) {
    str = str.toLowerCase();
    const words = str.split(' ');

    const capitalized = words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1, word.length);
    });
    return capitalized.join(' ');
  }

  get isStudent() {
    return this.userType !== 'teacher' && this.userType !== 'parent';
  }

  setPhoneNumber(phoneNumber: string) {
    this.phoneMobile = phoneNumber;

    this.validatePhone();
  }

  validatePhone() {
    if (!this.requiresStep('phoneMobile')) {
      return true;
    }

    this.phoneError = undefined;
    this.phoneWarning = undefined;

    if (!this.phoneMobile) {
      this.phoneError = 'validation.required';

      return false;
    }

    try {
      const parsedPhone = parsePhoneNumberWithError(this.phoneMobile, String(this.country).toUpperCase() as any);

      if (!parsedPhone) {
        this.phoneError = 'validation.invalidPhoneNumber';
        return false;
      } else if (!parsedPhone.isPossible() && !parsedPhone.isValid()) {
        this.phoneWarning = 'validation.possiblePhoneNumber';
      } else if (!parsedPhone.isValid()) {
        this.phoneError = 'validation.invalidPhoneNumber';
        return false;
      }
    } catch (error) {
      switch (error.message) {
        case 'NOT_A_NUMBER':
        default:
          this.phoneError = 'validation.invalidPhoneNumber';
          break;
        case 'TOO_LONG':
          this.phoneError = 'validation.tooLonghoneNumber';
          break;
        case 'TOO_SHORT':
          this.phoneError = 'validation.tooShortPhoneNumber';
          break;
      }

      return false;
    }

    return true;
  }

  get userTypeForGoogle() {
    const userType = String(this.userType).toLowerCase();
    switch (userType) {
      case 'teacher':
      case 'parent':
        return userType;

      default:
        return 'student';
    }
  }
  selectOtherSchool(otherSchool: string) {
    this.otherSchool = otherSchool;
    this.school = null;
    this.nextStep();
  }
  onSchoolSelect(school) {
    this.school = school;
    this.otherSchool = null;
  }
  clearSchools() {
    this.school = null;
    this.otherSchool = null;
  }

  clearLocalStorage() {
    if (!this.referralCode) {
      this.referralCode = null;
      this.$localStorage.remove('referralCode');
    }

    if (this.userSource) {
      this.$localStorage.remove('userSource');
    }
  }

  get userTypeIcon() {
    switch (this.userType) {
      case 'student':
        return 'assets/img/color/user-player.svg';
      case 'teacher':
        return 'assets/img/color/teacher.svg';
      case 'parent':
        return 'assets/img/color/parent.svg';
    }
  }

  get userTypeLabel() {
    switch (this.userType) {
      case 'student':
        return 'player';
      case 'teacher':
        return 'teacher';
      case 'parent':
        return 'parent';
    }
  }
}
