import { Action, Size, Align, Direction } from './types';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-empty-state',
  templateUrl: './empty-state.component.html',
  styles: [],
})
export class EmptyStateComponent implements OnInit {
  constructor() {}

  @Input() img?: string;
  @Input() message: string;
  @Input() subMessage: string;

  @Input() actions: Action[];

  @Input() size: Size = Size.md;
  @Input() contentAlign: Align = Align.center;
  @Input() contentDirection: Direction = Direction.column;

  sizeEnum = Size;
  alignEnum = Align;
  directionEnum = Direction;

  ngOnInit() {}

  get isColumn(){
    return this.contentDirection === Direction.column
  }
}
