import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../services/user.service';

type ID = number | string;

interface OpenData {
  objectType: number | string;
  objectId: ID;
  accessToken: string;
  isGuest: boolean;
  userId: number;
}
@Injectable({
  providedIn: 'root',
})
export class HelpService {
  openSubject = new BehaviorSubject<OpenData>(null);

  constructor(private $user: UserService) {}

  openPage() {
    this.open(4, 1);
  }

  openMachine(machineId: ID) {}

  open(objectType: ID, objectId: ID) {
    this.openSubject.next({
      objectType,
      objectId,
      accessToken: this.$user.token(),
      isGuest: false,
      userId: this.$user.id(),
    });
  }
  close() {
    this.openSubject.next(null);
  }
}
