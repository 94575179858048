<div class="full-height">
  <div class="absolute top-right"></div>
  <div class="relative pad-md-top">
    <app-sign-in *ngIf="mode === 'login'" (signup)="signupInstead()"></app-sign-in>
    <app-sign-up
      *ngIf="mode === 'signup'"
      (login)="loginInstead()"
      (switchAuthMode)="swithMode($event)"
      (close)="close.emit()"
    ></app-sign-up>

    <app-signup-complete *ngIf="mode === 'signup-complete'" (switchAuthMode)="swithMode($event)"></app-signup-complete>
  </div>
  <div
    *ngIf="mode === 'signup'"
    class="flex items-center justify-center px-6 py-2 lg:py-4 border-t border-mulberry-550"
  >
    <div class="flex-row mt-2 lg:mt-0">
      <i class="mr-1 fas fa-globe"></i>
      <select [value]="$language.getActiveLanguage()" (change)="$language.setActiveLanguage($event.target.value)">
        <option
          *ngFor="let option of languages"
          [value]="option.value"
          [selected]="$language.getActiveLanguage() == option.value"
        >
          {{ option.label | transloco }}
        </option>
      </select>
    </div>
  </div>
</div>
