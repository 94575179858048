/* tslint:disable */

declare var Object: any;
export interface V3Interface {
  "id"?: number;
}

export class V3 implements V3Interface {
  "id": number;
  constructor(data?: V3Interface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `V3`.
   */
  public static getModelName() {
    return "V3";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of V3 for dynamic purposes.
  **/
  public static factory(data: V3Interface): V3{
    return new V3(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'V3',
      plural: 'v3',
      path: 'v3',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
