/* tslint:disable */
import {
  UserAccount,
  Assessment,
  AssessmentTemplate,
  UserAccountAssessmentAnswer
} from '../index';

declare var Object: any;
export interface UserAccountAssessmentInterface {
  "userAccountId": number;
  "assessmentId": number;
  "version": number;
  "takenStartTs"?: number;
  "takenEndTs"?: number;
  "completed"?: boolean;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  user?: UserAccount;
  assessment?: Assessment;
  template?: AssessmentTemplate;
  answers?: UserAccountAssessmentAnswer[];
}

export class UserAccountAssessment implements UserAccountAssessmentInterface {
  "userAccountId": number;
  "assessmentId": number;
  "version": number;
  "takenStartTs": number;
  "takenEndTs": number;
  "completed": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  user: UserAccount;
  assessment: Assessment;
  template: AssessmentTemplate;
  answers: UserAccountAssessmentAnswer[];
  constructor(data?: UserAccountAssessmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountAssessment`.
   */
  public static getModelName() {
    return "UserAccountAssessment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountAssessment for dynamic purposes.
  **/
  public static factory(data: UserAccountAssessmentInterface): UserAccountAssessment{
    return new UserAccountAssessment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountAssessment',
      plural: 'UserAccountAssessments',
      path: 'UserAccountAssessments',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "assessmentId": {
          name: 'assessmentId',
          type: 'number'
        },
        "version": {
          name: 'version',
          type: 'number'
        },
        "takenStartTs": {
          name: 'takenStartTs',
          type: 'number'
        },
        "takenEndTs": {
          name: 'takenEndTs',
          type: 'number'
        },
        "completed": {
          name: 'completed',
          type: 'boolean',
          default: false
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
        assessment: {
          name: 'assessment',
          type: 'Assessment',
          model: 'Assessment',
          relationType: 'belongsTo',
                  keyFrom: 'assessmentId',
          keyTo: 'id'
        },
        template: {
          name: 'template',
          type: 'AssessmentTemplate',
          model: 'AssessmentTemplate',
          relationType: 'belongsTo',
                  keyFrom: 'assessmentId',
          keyTo: 'id'
        },
        answers: {
          name: 'answers',
          type: 'UserAccountAssessmentAnswer[]',
          model: 'UserAccountAssessmentAnswer',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountAssessmentId'
        },
      }
    }
  }
}
