import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {
  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() on: boolean = true;
  // added for backward compatability and will be removed in the future
  // so make sure to pass on always
  @Input() switchInternally: boolean = true;
  constructor() {}

  flip() {
    const on = !this.on;

    // @todo remove this
    if (this.switchInternally) {
      this.on = on;
    }

    this.toggled.emit(on);
  }

  ngOnInit(): void {}
}
