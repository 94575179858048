/* tslint:disable */
import {
  Entity,
  Machine,
  MachineProgress,
  Board,
  BoardProgress,
  Chip,
  ChipProgress,
  GoogleClassroom,
  Group,
  UserAccountChallenge,
  Challenge,
  Classroom,
  UserAccountTrayFavorite
} from '../index';

declare var Object: any;
export interface UserAccountInterface {
  "displayName"?: string;
  "firstName"?: string;
  "middleName"?: string;
  "lastName"?: string;
  "active"?: boolean;
  "timeZoneId"?: string;
  "observeDst"?: boolean;
  "phoneWork"?: string;
  "phoneMobile"?: string;
  "mobileCarrier"?: string;
  "title"?: string;
  "company"?: string;
  "addressLine1"?: string;
  "addressLine2"?: string;
  "city"?: string;
  "state"?: string;
  "postalCode"?: string;
  "communicationPreferences"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "guest"?: boolean;
  "deviceId"?: string;
  "atLeast13"?: boolean;
  "parent"?: boolean;
  "teacher"?: boolean;
  "data"?: string;
  "continueCode"?: string;
  "tags"?: any;
  "oauth"?: boolean;
  "grade"?: string;
  "authenticationProviderId"?: number;
  "isMinor"?: boolean;
  "parentEmail"?: string;
  "targetId"?: number;
  "alternativeId"?: string;
  "dateOfBirth"?: Date;
  "entityOther"?: string;
  "certificateCode"?: string;
  "certificatePublicName"?: string;
  "certificatePrivateName"?: string;
  "certificateShowPrivateNameToTeacher"?: boolean;
  "commentsAllowed"?: number;
  "commentsAcceptTerms"?: boolean;
  "commentsAcceptTermsAt"?: Date;
  "referenceCode"?: string;
  "referredByCode"?: string;
  "source"?: string;
  "action"?: number;
  "actionData"?: string;
  "cryptoWalletId"?: string;
  "lastSeen"?: any;
  "realm"?: string;
  "username"?: string;
  "email": string;
  "emailVerified"?: boolean;
  "id"?: number;
  "password"?: string;
  accessTokens?: any[];
  roles?: any[];
  entitiesCreated?: Entity[];
  entitiesUpdated?: Entity[];
  machines?: Machine[];
  machineProgress?: MachineProgress[];
  boards?: Board[];
  boardProgress?: BoardProgress[];
  chips?: Chip[];
  chipProgress?: ChipProgress[];
  googleClassrooms?: GoogleClassroom[];
  roleMapping?: any[];
  groups?: Group[];
  settings?: any[];
  userAccountChallenge?: UserAccountChallenge[];
  challenge?: Challenge[];
  userChallenge?: UserAccountChallenge[];
  entities?: Entity[];
  teacherClassrooms?: Classroom[];
  classrooms?: Classroom[];
  trayFav?: UserAccountTrayFavorite[];
}

export class UserAccount implements UserAccountInterface {
  "displayName": string;
  "firstName": string;
  "middleName": string;
  "lastName": string;
  "active": boolean;
  "timeZoneId": string;
  "observeDst": boolean;
  "phoneWork": string;
  "phoneMobile": string;
  "mobileCarrier": string;
  "title": string;
  "company": string;
  "addressLine1": string;
  "addressLine2": string;
  "city": string;
  "state": string;
  "postalCode": string;
  "communicationPreferences": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "guest": boolean;
  "deviceId": string;
  "atLeast13": boolean;
  "parent": boolean;
  "teacher": boolean;
  "data": string;
  "continueCode": string;
  "tags": any;
  "oauth": boolean;
  "grade": string;
  "authenticationProviderId": number;
  "isMinor": boolean;
  "parentEmail": string;
  "targetId": number;
  "alternativeId": string;
  "dateOfBirth": Date;
  "entityOther": string;
  "certificateCode": string;
  "certificatePublicName": string;
  "certificatePrivateName": string;
  "certificateShowPrivateNameToTeacher": boolean;
  "commentsAllowed": number;
  "commentsAcceptTerms": boolean;
  "commentsAcceptTermsAt": Date;
  "referenceCode": string;
  "referredByCode": string;
  "source": string;
  "action": number;
  "actionData": string;
  "cryptoWalletId": string;
  "lastSeen": any;
  "realm": string;
  "username": string;
  "email": string;
  "emailVerified": boolean;
  "id": number;
  "password": string;
  accessTokens: any[];
  roles: any[];
  entitiesCreated: Entity[];
  entitiesUpdated: Entity[];
  machines: Machine[];
  machineProgress: MachineProgress[];
  boards: Board[];
  boardProgress: BoardProgress[];
  chips: Chip[];
  chipProgress: ChipProgress[];
  googleClassrooms: GoogleClassroom[];
  roleMapping: any[];
  groups: Group[];
  settings: any[];
  userAccountChallenge: UserAccountChallenge[];
  challenge: Challenge[];
  userChallenge: UserAccountChallenge[];
  entities: Entity[];
  teacherClassrooms: Classroom[];
  classrooms: Classroom[];
  trayFav: UserAccountTrayFavorite[];
  constructor(data?: UserAccountInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccount`.
   */
  public static getModelName() {
    return "UserAccount";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccount for dynamic purposes.
  **/
  public static factory(data: UserAccountInterface): UserAccount{
    return new UserAccount(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccount',
      plural: 'UserAccounts',
      path: 'UserAccounts',
      idName: 'id',
      properties: {
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "firstName": {
          name: 'firstName',
          type: 'string'
        },
        "middleName": {
          name: 'middleName',
          type: 'string'
        },
        "lastName": {
          name: 'lastName',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "timeZoneId": {
          name: 'timeZoneId',
          type: 'string'
        },
        "observeDst": {
          name: 'observeDst',
          type: 'boolean'
        },
        "phoneWork": {
          name: 'phoneWork',
          type: 'string'
        },
        "phoneMobile": {
          name: 'phoneMobile',
          type: 'string'
        },
        "mobileCarrier": {
          name: 'mobileCarrier',
          type: 'string'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "company": {
          name: 'company',
          type: 'string'
        },
        "addressLine1": {
          name: 'addressLine1',
          type: 'string'
        },
        "addressLine2": {
          name: 'addressLine2',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "postalCode": {
          name: 'postalCode',
          type: 'string'
        },
        "communicationPreferences": {
          name: 'communicationPreferences',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "guest": {
          name: 'guest',
          type: 'boolean',
          default: false
        },
        "deviceId": {
          name: 'deviceId',
          type: 'string'
        },
        "atLeast13": {
          name: 'atLeast13',
          type: 'boolean',
          default: false
        },
        "parent": {
          name: 'parent',
          type: 'boolean',
          default: false
        },
        "teacher": {
          name: 'teacher',
          type: 'boolean',
          default: false
        },
        "data": {
          name: 'data',
          type: 'string',
          default: '{}'
        },
        "continueCode": {
          name: 'continueCode',
          type: 'string'
        },
        "tags": {
          name: 'tags',
          type: 'any'
        },
        "oauth": {
          name: 'oauth',
          type: 'boolean',
          default: false
        },
        "grade": {
          name: 'grade',
          type: 'string'
        },
        "authenticationProviderId": {
          name: 'authenticationProviderId',
          type: 'number',
          default: 1
        },
        "isMinor": {
          name: 'isMinor',
          type: 'boolean',
          default: true
        },
        "parentEmail": {
          name: 'parentEmail',
          type: 'string'
        },
        "targetId": {
          name: 'targetId',
          type: 'number'
        },
        "alternativeId": {
          name: 'alternativeId',
          type: 'string'
        },
        "dateOfBirth": {
          name: 'dateOfBirth',
          type: 'Date'
        },
        "entityOther": {
          name: 'entityOther',
          type: 'string'
        },
        "certificateCode": {
          name: 'certificateCode',
          type: 'string'
        },
        "certificatePublicName": {
          name: 'certificatePublicName',
          type: 'string'
        },
        "certificatePrivateName": {
          name: 'certificatePrivateName',
          type: 'string'
        },
        "certificateShowPrivateNameToTeacher": {
          name: 'certificateShowPrivateNameToTeacher',
          type: 'boolean',
          default: true
        },
        "commentsAllowed": {
          name: 'commentsAllowed',
          type: 'number',
          default: 1
        },
        "commentsAcceptTerms": {
          name: 'commentsAcceptTerms',
          type: 'boolean',
          default: false
        },
        "commentsAcceptTermsAt": {
          name: 'commentsAcceptTermsAt',
          type: 'Date'
        },
        "referenceCode": {
          name: 'referenceCode',
          type: 'string'
        },
        "referredByCode": {
          name: 'referredByCode',
          type: 'string'
        },
        "source": {
          name: 'source',
          type: 'string'
        },
        "action": {
          name: 'action',
          type: 'number',
          default: 0
        },
        "actionData": {
          name: 'actionData',
          type: 'string'
        },
        "cryptoWalletId": {
          name: 'cryptoWalletId',
          type: 'string'
        },
        "lastSeen": {
          name: 'lastSeen',
          type: 'any'
        },
        "realm": {
          name: 'realm',
          type: 'string'
        },
        "username": {
          name: 'username',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "emailVerified": {
          name: 'emailVerified',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "password": {
          name: 'password',
          type: 'string'
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userId'
        },
        roles: {
          name: 'roles',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          modelThrough: 'RoleMapping',
          keyThrough: 'roleId',
          keyFrom: 'id',
          keyTo: 'principalId'
        },
        entitiesCreated: {
          name: 'entitiesCreated',
          type: 'Entity[]',
          model: 'Entity',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'createdBy'
        },
        entitiesUpdated: {
          name: 'entitiesUpdated',
          type: 'Entity[]',
          model: 'Entity',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'updatedBy'
        },
        machines: {
          name: 'machines',
          type: 'Machine[]',
          model: 'Machine',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        machineProgress: {
          name: 'machineProgress',
          type: 'MachineProgress[]',
          model: 'MachineProgress',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        boards: {
          name: 'boards',
          type: 'Board[]',
          model: 'Board',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        boardProgress: {
          name: 'boardProgress',
          type: 'BoardProgress[]',
          model: 'BoardProgress',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        chips: {
          name: 'chips',
          type: 'Chip[]',
          model: 'Chip',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        chipProgress: {
          name: 'chipProgress',
          type: 'ChipProgress[]',
          model: 'ChipProgress',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        googleClassrooms: {
          name: 'googleClassrooms',
          type: 'GoogleClassroom[]',
          model: 'GoogleClassroom',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        roleMapping: {
          name: 'roleMapping',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'principalId'
        },
        groups: {
          name: 'groups',
          type: 'Group[]',
          model: 'Group',
          relationType: 'hasMany',
          modelThrough: 'UserAccountGroup',
          keyThrough: 'groupId',
          keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        settings: {
          name: 'settings',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        userAccountChallenge: {
          name: 'userAccountChallenge',
          type: 'UserAccountChallenge[]',
          model: 'UserAccountChallenge',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        challenge: {
          name: 'challenge',
          type: 'Challenge[]',
          model: 'Challenge',
          relationType: 'hasMany',
          modelThrough: 'UserAccountChallenge',
          keyThrough: 'challengeId',
          keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        userChallenge: {
          name: 'userChallenge',
          type: 'UserAccountChallenge[]',
          model: 'UserAccountChallenge',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        entities: {
          name: 'entities',
          type: 'Entity[]',
          model: 'Entity',
          relationType: 'hasMany',
          modelThrough: 'UserEntity',
          keyThrough: 'entityId',
          keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        teacherClassrooms: {
          name: 'teacherClassrooms',
          type: 'Classroom[]',
          model: 'Classroom',
          relationType: 'hasMany',
          modelThrough: 'ClassroomTeacher',
          keyThrough: 'classroomId',
          keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        classrooms: {
          name: 'classrooms',
          type: 'Classroom[]',
          model: 'Classroom',
          relationType: 'hasMany',
          modelThrough: 'ClassroomStudent',
          keyThrough: 'classroomId',
          keyFrom: 'id',
          keyTo: 'userAccountId'
        },
        trayFav: {
          name: 'trayFav',
          type: 'UserAccountTrayFavorite[]',
          model: 'UserAccountTrayFavorite',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userAccountId'
        },
      }
    }
  }
}
