import { Injectable } from '@angular/core';
import { of, Subject, Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { tap, startWith } from 'rxjs/operators';
import { Machine, CurrencyApi, LoopBackAuth, V3Api } from '../../shared/sdk';
import { UserService } from 'src/app/shared/services/user.service';
import { MachineService } from 'src/app/shared/services/machine.service';

import { MachineComponent } from 'src/app/shared/modules/machine/components/machine/machine.component';

type MachineCache = {
  [page: number]: Machine[];
};

type MachinesStreams = { [key: string]: BehaviorSubject<Machine[]> };

@Injectable({
  providedIn: 'root',
})
export class LabService {
  // @todo remove this historyStream & myStream in future versions and use historyCache & myMachineCacheInstead.
  historyStream = new ReplaySubject(1);
  myStream = new ReplaySubject(1);

  page = 0;
  myMachinePage = 0;

  skipMyMachines: number = 0;
  skipHistory: number = 0;

  currentHistory: any[];
  currentMyMachines: any[];

  myMachinesCache: MachineCache = {};
  myMachinesStreams: MachinesStreams = {};

  historyCache: MachineCache = {};
  historyStreams: MachinesStreams = {};

  constructor(private $v3: V3Api, private $myuser: UserService, private $machineService: MachineService) {}

  getStats() {
    return this.$v3.getUserCoins();
  }

  deleteMachine(id: number) {
    this.$v3.deleteMachine(id).subscribe((_) => {
      this.removeMachineFromStreams(id, this.myMachinesStreams);
      this.removeMachineFromStreams(id, this.historyStreams);
    });
  }

  cacheKey(limit: number, skip: number) {
    return `${limit}-${skip}`;
  }

  removeMachineFromStreams(id: number, streams: MachinesStreams) {
    Object.values(streams).forEach((stream) => {
      const machines = stream.getValue();
      const filtered = machines.filter((machine) => machine.id !== id);
      if (filtered.length !== machines.length) {
        stream.next(filtered);
      }
    });
  }

  getHistory(limit = 10, skip = 0): Observable<any> {
    const cacheKey = this.cacheKey(limit, skip);

    if (!this.historyStreams[cacheKey]) {
      this.historyStreams[cacheKey] = new BehaviorSubject([]);
    }

    const historyStream = this.historyStreams[cacheKey];

    if (!this.historyCache[cacheKey]) {
      this.$v3.getUserMachines(null, false, 0, limit, skip).subscribe((data) => {
        this.historyCache[cacheKey] = data;
        historyStream.next(data);
      });
    } else {
      historyStream.next(this.historyCache[cacheKey]);
    }

    return historyStream;
  }

  getMyMachines(limit = 10, skip = 0, initial = []): Observable<any> {
    const cacheKey = this.cacheKey(limit, skip);
    if (!this.myMachinesStreams[cacheKey]) {
      this.myMachinesStreams[cacheKey] = new BehaviorSubject(initial);
    }

    const stream = this.myMachinesStreams[cacheKey];
    if (!this.myMachinesCache[cacheKey]) {
      this.$v3.getUserMachines(null, false, 1, limit, skip).subscribe((data) => {
        this.myMachinesCache[cacheKey] = data;
        stream.next(data);
      });
    } else {
      stream.next(this.myMachinesCache[cacheKey]);
    }

    return stream;
  }

  addLatestMachine() {
    if (!this.currentHistory) return;
    setTimeout(
      // wait to give thumbnail time to be updated
      () =>
        this.$v3.getUserMachines(null, false, 1, 1, 0).subscribe((res) => {
          if (res.length == 0) return;
          let data = res[0];
          console.log('new machine to be added is ', data);
          this.currentHistory = this.currentHistory.filter((machine) => machine.shareId == data.shareId); // if we already have the machine somehow
          this.currentHistory.unshift(data);
          if ((res.userAccountId = this.$myuser.id())) {
            this.currentMyMachines.unshift(data);
          }
          this.skipMyMachines++;
          this.skipHistory++;
          this.historyStream.next(this.currentHistory);
          this.myStream.next(this.currentMyMachines);
        }),
      1000
    );
  }

  promoteMachine(machineToPromote: any, myMachine: boolean) {
    if (!this.currentHistory) return;
    //if (shareId == 'new') return;
    let oldLen = this.currentHistory.length;
    this.currentHistory = [machineToPromote].concat(
      this.currentHistory.filter((machine) => machine.shareId != machineToPromote.shareId)
    );
    if (this.currentHistory.length > oldLen) this.skipHistory++;
    this.historyStream.next(this.currentHistory);
    if (myMachine) {
      this.currentMyMachines = [machineToPromote].concat(
        this.currentMyMachines.filter((machine) => machine.shareId != machineToPromote.shareId)
      );
      if (this.currentHistory.length > oldLen) this.skipMyMachines++;
      this.myStream.next(this.currentMyMachines);
    }
  }

  addPage() {
    this.page++;
    this.$v3.getUserMachines(null, false, 0, 10, 10 * this.page + this.skipHistory).subscribe((res) => {
      this.currentHistory = this.currentHistory.concat(res);
      this.historyStream.next(this.currentHistory);
    });
  }

  addMyMachinePage() {
    console.log('adding machinepage');
    this.myMachinePage++;
    this.$v3.getUserMachines(null, false, 1, 10, 10 * this.myMachinePage + this.skipMyMachines).subscribe((res) => {
      this.currentMyMachines = this.currentMyMachines.concat(res);
      this.myStream.next(this.currentMyMachines);
    });
  }
}
