<div
  *ngIf="banners.length"
  [ngClass]="{
    'container mt-6': contained
  }"
>
  <swiper
    *ngIf="swiperEnabled && banners.length > 1"
    [preventClicks]="false"
    [preventClicksPropagation]="false"
    #swiper
    [slidesPerView]="1"
    [initialSlide]="activeIndex"
    (slideChange)="onSlideChange($event.activeIndex)"
  >
    <div
      slot="container-end"
      *ngIf="swiper && swiper.swiperRef && banners.length > 1"
      class="container relative z-50 py-1 -mt-7 print:hidden pointer-events-none"
    >
      <div class="flex justify-end w-full space-x-2 pointer-events-none">
        <ng-container>
          <button
            class="text-white focus:outline-none pointer-events-auto"
            [ngClass]="{
              'opacity-30': swiper.swiperRef.isBeginning,
              'hover:text-opacity-50': !swiper.swiperRef.isBeginning
            }"
            (click)="swiper.swiperRef.slidePrev()"
            [disabled]="swiper.swiperRef.isBeginning"
            inlineSVG="assets/img/black/caret-left.svg"
            [setSVGAttributes]="{ class: 'w-5 h-5 rtl:flip-x' }"
          ></button>
        </ng-container>

        <div class="flex items-center space-x-2">
          <button
            *ngFor="let banner of banners; let i = index"
            (click)="swiper.swiperRef.slideTo(i)"
            class="w-3 h-3 border-2 border-white rounded-full border-opacity-30 focus:outline-none pointer-events-auto"
            [class.hover:border-white]="i != activeIndex"
            [class.hover:border-opacity-50]="i != activeIndex"
            [class.bg-white]="i == activeIndex"
            [class.bg-opacity-50]="i == activeIndex"
          ></button>
        </div>
        <button
          class="text-white focus:outline-none pointer-events-auto"
          [ngClass]="{
            'opacity-30': swiper.swiperRef.isEnd,
            'hover:text-opacity-50': !swiper.swiperRef.isEnd
          }"
          (click)="swiper.swiperRef.slideNext()"
          [disabled]="swiper.swiperRef.isEnd"
          inlineSVG="assets/img/black/caret-right.svg"
          [setSVGAttributes]="{ class: 'w-5 h-5 rtl:flip-x' }"
        ></button>
      </div>
    </div>

    <ng-container *ngFor="let banner of banners">
      <ng-template swiperSlide>
        <app-banner-item [rounded]="contained" [banner]="banner"></app-banner-item>
      </ng-template>
    </ng-container>
  </swiper>

  <div class="space-y-4 flex flex-col" *ngIf="!swiperEnabled || banners.length == 1">
    <app-banner-item [rounded]="contained" *ngFor="let banner of banners" [banner]="banner"></app-banner-item>
  </div>

  <div *ngIf="banners.length > 1" class="print:hidden">
    <div class="container flex justify-end">
      <button
        (click)="toggleSwiper()"
        class="px-2 py-1 text-sm font-light transition focus:outline-none rounded-b-md"
        [class]="toggleButtonColor"
      >
        <span *ngIf="swiperEnabled">{{ "showAll" | transloco }}</span>
        <span *ngIf="!swiperEnabled">{{ "showLess" | transloco }}</span>
      </button>
    </div>
  </div>
</div>
