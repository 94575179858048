import { Component, Input, OnInit, ElementRef } from '@angular/core';

type Color = 'mango' | 'mulberry' | 'kiwi' | 'white' | 'plum' | 'plain' | 'mulberry-dark' | 'mango-plain' | 'cherry';
type Size = 'sm' | 'md' | 'lg';

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  host: {
    '[attr.disabled]': 'isDisabled || null',
  },
  styleUrls: ['./button.css'],
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() classes: string;
  @Input() loading: boolean;
  @Input() color: Color = 'mango';
  @Input() size: Size = 'md';
  @Input() success;
  @Input() externalLink;
  @Input() elevate = false;

  constructor(public _elementRef: ElementRef) {}

  ngOnInit(): void {}

  openLink(externalLink) {
    if (externalLink) {
      window.open(externalLink);
    }
  }

  get isDisabled() {
    return this.disabled || this.loading;
  }
}
