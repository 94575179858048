import { Injectable } from '@angular/core';
import {
  Entity,
  UserAccount,
  V3Api,
  UserAccountApi,
  LoopBackAuth,
  UIUserAccountApi,
  UserAccountEntityApi,
  UserAccountEntity,
  EntityApi,
} from '../sdk';
import { NgxPermissionsService } from 'ngx-permissions';
import { flatMap, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { AuthUtils } from './auth-utils.service';
import { BannerService } from '../modules/banner/banner.service';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest } from 'rxjs';
import differenceDateInDays from 'date-fns/differenceInDays';
import { ClassroomService } from 'src/app/dashboard/team/classrooms.service';
import { Thumbs } from 'swiper';
import { LocalStorageService } from './local-storage.service';
interface UserAccountSettings {
  displayName: {
    showToPublic: boolean;
    showToLoggedIn: boolean;
    showToTeacher: boolean;
  };
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: any;
  public roles: string[] = [];
  private autoFillUsername: any;

  userAccountSettings = new BehaviorSubject<UserAccountSettings>(null);

  country = new BehaviorSubject(null);
  school = new BehaviorSubject(null);

  userSubj: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private $permissionsService: NgxPermissionsService,
    private $v3: V3Api,
    private $user: UserAccountApi,
    private $router: Router,
    private $lbauth: LoopBackAuth,
    private $userAccount: UserAccountApi,
    private $authUtils: AuthUtils,
    private $uiUser: UIUserAccountApi,
    private $banner: BannerService,
    private $userAccountEntity: UserAccountEntityApi,
    private $translocoService: TranslocoService,
    private $entity: EntityApi,
    private $classroom: ClassroomService,
    private $localStorage: LocalStorageService
  ) {
    if (this.$lbauth.getCurrentUserData()) {
      console.log('SETTING USER FROM CACHE');
      this.setUser(this.$lbauth.getCurrentUserData());
      console.log(this.$lbauth.getCurrentUserData());
    }

    if (this.loggedIn()) {
      this.refreshUser();
    }
  }

  getUserSubscription() {
    return this.userSubj.asObservable();
  }

  setUser(u: UserAccount) {
    this.user = u;
    this.userSubj.next(u);
    if (u.roles) {
      this.roles = u.roles;
    }
    this.$permissionsService.loadPermissions(u['permissions']);
  }

  logout(doNotReload?: boolean) {
    this.$user.logout().subscribe(
      (result) => {
        this.logoutCallback(doNotReload);
      },
      (error) => {
        this.logoutCallback(doNotReload);
      }
    );
  }

  logoutCallback(doNotReload: boolean) {
    this.$permissionsService.flushPermissions();
    this.$localStorage.remove('userSource');
    this.$localStorage.remove('onboardingMod');

    if (doNotReload != true) {
      window.location.href = '/about?mode=login';
    }
    console.log('logged out successfully');
  }

  isTeacher() {
    return this.roles.includes('Teacher') || this.roles.includes('Global Admin');
  }

  isParent() {
    return this.roles.includes('Parent');
  }
  isStudent() {
    return !this.isTeacher() && !this.isParent();
  }
  isGA() {
    return this.roles.includes('Global Admin');
  }

  isGuest(): boolean {
    return this.roles.includes('Guest');
  }

  getName(): string {
    return this.user ? this.user.firstName || this.user.username : undefined;
  }

  getOwnerEntities(): Entity[] {
    return this.user.ownerEntities;
  }

  /**
   * @deprecated use getMemberClassrooms instead
   */
  getMemberEntities(): Entity[] {
    return this.user.memberEntities;
  }

  getMemberClassrooms() {
    return this.user.memberClassrooms;
  }

  getUser() {
    return this.user;
  }

  countryCode() {
    return this.user?.countryCode;
  }

  username(): string {
    return this.user ? this.user.username : '';
  }

  usernameIsPhone(): boolean {
    return this.user?.username?.substring(0, 1) == '+';
  }

  contact(): string {
    if (this.isMinor()) {
      return this.user.parentEmail;
    }
    return this.$authUtils.validEmail(this.user.username) ? this.user.username : this.user.email;
  }

  email() {
    return this.user?.email;
  }

  id(): number {
    return this.user?.id;
  }

  isMinor() {
    return this.user.isMinor;
  }

  parentEmail() {
    return this.user.parentEmail;
  }

  isVerified() {
    return this.user.emailVerified !== false;
  }

  isVerificationPopupActive = new BehaviorSubject(false);

  loggedIn(): boolean {
    let lbData = this.$lbauth.getCurrentUserData();
    return (
      this.$lbauth.getAccessTokenId() && lbData?.roles && lbData.roles.length > 0 && !lbData.roles.includes('Guest')
    );
  }

  token(): string {
    return this.$lbauth.getToken().id;
  }

  setAutoFillUsername(name: string) {
    this.autoFillUsername = name;
  }
  getAutoFillUsername() {
    return this.autoFillUsername;
  }

  isGoogle() {
    return this.user?.authenticationProviderId == 5;
  }

  getGrade(): number {
    return this.user?.grade ? parseInt(this.user?.grade) : undefined;
  }

  setGrade(i: number): Observable<any> {
    return this.$userAccount.patchAttributes(this.user.id, { grade: i.toString() }).pipe(
      tap((res) => {
        this.user.grade = res.grade;
        this.$lbauth.setUser({ ...this.$lbauth.getCurrentUserData(), grade: res.grade });
      })
    );
  }

  setEmail(str: string): Observable<any> {
    return this.$userAccount.patchAttributes(this.user.id, { email: str }).pipe(
      tap((res) => {
        this.user.email = str;
        this.$lbauth.setUser({ ...this.$lbauth.getCurrentUserData(), email: str });
      })
    );
  }

  setParentEmail(parentEmail: string): Observable<any> {
    return this.updateUserFields({
      parentEmail,
    });
  }

  setUsername(str: string): Observable<any> {
    return this.updateUserFields({ username: str });
  }

  updateUserFields(fields: { [key: string]: any }, refreshUser = true): Observable<any> {
    return this.$userAccount.patchAttributes(this.user.id, fields).pipe(
      tap((res) => {
        this.updateClientUserField(fields);
        if (refreshUser) {
          this.refreshUser();
        }
      })
    );
  }

  updateClientUserField(fields) {
    this.setUser({
      ...this.user,
      ...fields,
    });
    this.$lbauth.setUser({ ...this.$lbauth.getCurrentUserData(), ...fields });
  }

  setDisplayName(displayName: string): Observable<any> {
    return this.updateUserFields({ displayName });
  }

  hasCompletedTutorial(str: string): Observable<any> {
    return this.$uiUser
      .getUserAccountSettings()
      .pipe(map((res: any) => res.tutorialPreferences && res.tutorialPreferences[str] == true));
  }

  completeTutorial(str: string): Observable<any> {
    return this.$uiUser.getUserAccountSettings().pipe(
      flatMap((res: any) =>
        this.$uiUser.patchUserAccountSettings({
          ...res,
          tutorialPreferences: { ...res.tutorialPreferences, [str]: true },
        })
      )
    );
  }

  //// FAKE METHOD, RETURN WITH USERACCOUNTCHALLENGE REPONSE LATER

  activeChallenge(): number {
    return 102;
  }

  premium(): boolean {
    return true;
  }

  setSounds() {}

  updateAccountSettings(settings: Partial<UserAccountSettings>) {
    const accountSettings = {
      ...this.userAccountSettings.getValue(),
      ...settings,
    };

    this.userAccountSettings.next(accountSettings);

    return this.$uiUser.patchUserAccountSettings(settings);
  }

  resendVerification() {
    this.$banner.update('EMAIL_VERIFICATION', (banner) => ({
      ...banner,
      action: {
        ...banner.action,
        loading: true,
      },
    }));
    this.$v3.postResendVerify({ username: this.username() }).subscribe(
      (res) => {
        this.$banner.update('EMAIL_VERIFICATION', (banner) => ({
          ...banner,
          action: {
            ...banner.action,
            loading: false,
            success: true,
          },
        }));
      },
      (err) => {
        this.$banner.update('EMAIL_VERIFICATION', (banner) => ({
          ...banner,
          action: {
            ...banner.action,
            loading: false,
            success: false,
          },
        }));
      }
    );
  }

  getSchool() {
    this.$userAccountEntity
      .find({
        where: {
          userAccountId: this.user.id,
        },
        include: {
          relation: 'entity',
          scope: {
            include: [
              {
                relation: 'translations',
                scope: {
                  where: { languageCode: this.$translocoService.getActiveLang() },
                },
              },
            ],
          },
        },
        order: 'createdAt DESC',
        limit: 5,
      })
      .subscribe((res: UserAccountEntity[]) => {
        const country = res.find(
          (entity) => !entity.entity.topEntityId || entity.entity.topEntityId === entity.entity.id
        );

        if (country) {
          this.country.next(country?.entity || null);
        } else if (res.length) {
          this.$entity.findById(res[0].entity.topEntityId).subscribe((country) => {
            this.country.next(country);
          });
        }

        this.school.next(
          res.find((entity) => entity.entity.id !== entity.entity.topEntityId && entity.entity.topEntityId) || null
        );
      });
  }

  addEmailVerificationBanner() {
    if (this.$banner.has('EMAIL_VERIFICATION')) {
      this.$banner.update('EMAIL_VERIFICATION', (banner) => ({
        ...banner,
        translocoParams: { email: this.contact() },
      }));
      return;
    }

    this.$banner.addOnce(
      {
        key: 'EMAIL_VERIFICATION',
        title: this.isMinor() ? 'emailVerificationBanner.parentTitle' : 'emailVerificationBanner.title',
        content: this.isMinor() ? 'parentEmailNotVerified' : 'emailNotVerified',
        translocoParams: { email: this.contact() },
        action: {
          label: 'login.resendVerification',
          onClick: this.resendVerification.bind(this),
        },
        secondaryAction: {
          label: this.isMinor()
            ? 'emailVerificationBanner.parentSecondaryAction'
            : 'emailVerificationBanner.secondaryAction',
          onClick: () =>
            this.$router.navigate(['/settings'], {
              queryParams: {
                parentEmail: this.isMinor() ? true : undefined,
                email: !this.isMinor() ? true : undefined,
              },
            }),
        },
        image:
          differenceDateInDays(Date.now(), new Date(this.user.createdAt)) >= 7
            ? '/assets/img/color/poly-dizzy.svg'
            : '/assets/img/color/poly-smile.svg',

        priority: true,
      },
      false
    );
  }

  addParentEmailBanner() {
    if (this.$banner.has('SET_PARENT_EMAIL')) {
      return;
    }
    this.$banner.addOnce(
      {
        key: 'SET_PARENT_EMAIL',
        content: 'parentEmailBanner.content',
        color: 'pineapple',
        action: {
          label: 'parentEmailBanner.action',
          onClick: () => {
            this.$router.navigate(['/settings'], {
              queryParams: { parentEmail: true },
            });
          },
        },
      },
      false
    );
  }

  removeParentEmailBanner() {
    this.$banner.remove('SET_PARENT_EMAIL');
  }

  showSchoolBanner() {
    combineLatest([this.country, this.school]).subscribe(([country, school]) => {
      const schoolPickerEnabled = country?.onboarding?.flow?.Student.find(
        (step) => step.step === 'schoolPicker'
      )?.enabled;

      if (schoolPickerEnabled && !school && !this.user.entityOther && !this.$banner.has('SCHOOL_PICKER')) {
        this.$banner.addOnce({
          key: 'SCHOOL_PICKER',
          content: 'schoolPickerBanner.content',
          color: 'blueberry',
          action: {
            label: 'schoolPickerBanner.action',
            onClick: () => {
              this.$router.navigate(['/settings'], {
                queryParams: { schoolPicker: true },
              });
            },
          },
        });
      }
    });

    this.getSchool();
  }

  handeUserActionBanner() {
    const bannerKey = 'USER_ACTION';

    const removeBanner = () => {
      if (this.$banner.has(bannerKey)) {
        this.$banner.remove(bannerKey);
      }
    };

    if (!this.user.action) {
      removeBanner();
      return;
    }

    const actionData =
      this.user.actionData && typeof this.user.actionData === 'string' ? JSON.parse(this.user.actionData) : null;

    if (!actionData) {
      removeBanner();
      return;
    }

    let action;

    if (/* actionData.actionMethod &&  */ actionData.actionMethodTitle && actionData.actionMethodTarget) {
      action = {
        label: actionData.actionMethodTitle,
        onClick: () => {
          this.$router.navigateByUrl(actionData.actionMethodTarget);
        },
      };
    }

    this.$banner.addOnce({
      key: bannerKey,
      title: actionData.actionTitle,
      content: actionData.actionDescription,
      color: actionData.actionColor || 'cherry',
      action,
      priority: 2,
    });
  }

  refreshUser() {
    this.$v3.getUserDataRefresh().subscribe((user) => {
      this.$lbauth.setToken({
        ...this.$lbauth.getToken(),
        user: user,
      });

      if (this.isStudent) {
        this.$classroom.fetch(user.id);
      }
      this.setUser(user);

      combineLatest([this.$uiUser.getUserAccountSettings(), this.$classroom.classrooms]).subscribe(([settings]) => {
        this.userAccountSettings.next({
          ...settings,
          displayName: settings.displayName || {
            showToPublic: false,
            showToLoggedIn: true,
            showToTeacher: true,
          },
        });

        if (this.contact() && !this.isVerified()) {
          this.addEmailVerificationBanner();
        } else if (this.$banner.has('EMAIL_VERIFICATION')) {
          this.$banner.remove('EMAIL_VERIFICATION');
        }

        this.handeUserActionBanner();

        if (
          !this.user.parentEmail &&
          this.isMinor() &&
          !this.$classroom.isLoading &&
          !this.$classroom.classrooms.getValue().length
        ) {
          this.addParentEmailBanner();
        }

        if (!this.user.displayName && !this.isParent()) {
          this.$banner.addOnce({
            key: 'SET_DISPLAY_NAME',
            content: 'displayNameBanner.content',
            color: 'kiwi',
            action: {
              label: 'displayNameBanner.action',
              onClick: () => {
                this.$router.navigate(['/settings'], {
                  queryParams: { displayName: true },
                });
              },
            },
          });
        }
      });
    });
  }
}
