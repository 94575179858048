import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UserAccountChallenge } from './shared/sdk';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from './shared/services/user.service';
import { EventData, PlayerCommunicationsService, PlayerEvents } from './shared/services/player-communications.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';

import { Meta } from '@angular/platform-browser';
import { LabService } from './lab/services/lab.service';

import { AudioService } from 'src/app/shared/services/audio.service';

import { ChallengeService } from './dashboard/services/challenge.service';

import { environment } from 'src/environments/environment';

import * as FullStory from '@fullstory/browser';
import { LanguageService } from './shared/services/language.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { PopupComponent } from './shared/modules/ui/popup/popup.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('playerIframe') playerIframe: ElementRef;
  @ViewChild('iframeContainer') iframeContainer: ElementRef;
  title = 'dashboard';

  iframeLoaded: boolean = false;
  machineHidden: boolean = true;
  machineTransparent: boolean = false;
  iframeOpen: boolean = false;
  initialQueryParam: boolean;

  lastUrl: string;

  loadWrapper: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.iframeContainer.nativeElement.style.height = window.innerHeight + 'px';
    this.iframeContainer.nativeElement.style.width = window.innerWidth + 'px';
  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   if (this.iframeOpen) {
  //     this.hideMachine();
  //   }
  // }

  constructor(
    private $myUser: UserService,
    public $route: ActivatedRoute,
    private $router: Router,
    private $iframeCommunicationsService: PlayerCommunicationsService,
    private $labService: LabService,
    private $meta: Meta,
    private $audio: AudioService,
    private $challengeService: ChallengeService,
    private $languageService: LanguageService,
    private $translocoService: TranslocoService,
    private $localStorage: LocalStorageService
  ) {
    if (window.location.href.includes('m=')) {
      document.body.style.background = '#323650';
      this.initialQueryParam = true;
    } else {
      this.updateAppBackground();
    }
    FullStory.init({ orgId: '114749', devMode: !environment.production });
  }

  updateAppBackground() {
    const classNames = document.documentElement.className || '';

    if (this.$myUser.loggedIn() && !window.location.href.includes('/about')) {
      document.body.style.background = '#e8eced';
      document.documentElement.className = classNames.replace('logged-out-page', '');
    } else {
      document.body.style.background = 'none';

      // document.documentElement.className = `${classNames} logged-out-page`;
    }
  }

  iframeSrc: string;
  avoidIframeRedirect: boolean;

  historyNeedsReload: boolean;

  ngOnInit() {
    // setInterval(() => {
    //   let localCookie = document.cookie.split('$LoopBackSDK$id=')[1]
    //     ? document.cookie.split('$LoopBackSDK$id=')[1].split(';')[0]
    //     : null;
    //   let token = this.$myUser.token();
    //   if (token != localCookie) {
    //     window.location.reload();
    //   }
    // }, 1000);

    // this.$location.onPopState(() => {
    //   console.log('state popped');

    //   if (this.iframeOpen) {
    //     this.hideMachine();
    //   }
    // });

    this.$iframeCommunicationsService.subscribe((event) => {
      if (event.type === PlayerEvents.MACHINE_CLOSE) {
        this.hideMachine(event);
      }
      if (event.type === PlayerEvents.MACHINE_COMPLETED) {
        this.$iframeCommunicationsService.setActiveMachineCompleted();
      }
      if (event.type === PlayerEvents.MACHINE_SWITCHED) {
        this.$labService.addLatestMachine();
        this.historyNeedsReload = false;

        if (!this.iframeOpen || this.$route.snapshot.queryParams.m === event.shareId) return;
        console.log('ROUTE M', this.$route.snapshot.queryParams.m, event);
        if (this.$route.snapshot.queryParams.m && this.$route.snapshot.queryParams.m !== 'new') {
          console.log('ROUTE REPLACE');
          const url = this.$router
            .createUrlTree([...this.$router.url.split('?')[0].split('/')], {
              queryParams: { ...this.$route.snapshot.queryParams, m: event.shareId, name: null },
            })
            .toString();

          window.history.replaceState(null, null, url);
        } else {
          console.log('ROUTE NAVIGATE');
          this.$router.navigate([], {
            relativeTo: this.$route,
            queryParamsHandling: 'merge',
            queryParams: { m: event.shareId, name: null },
          });
        }
      }
    });

    this.$route.queryParams.subscribe((res) => {
      if (res.urc) {
        this.$localStorage.set('referralCode', res.urc);
      }

      this.updateUserSourceFromQuery(res);

      console.log('this.avoidIframeRedirect', this.avoidIframeRedirect);
      if (this.avoidIframeRedirect) {
        this.avoidIframeRedirect = false;
        return;
      }

      if (!res['m'] && this.iframeOpen) {
        // if user clicks browser back button
        this.hideMachine();
        this.$iframeCommunicationsService.notifyObservers({
          type: PlayerEvents.MACHINE_CLOSE,
        });
        return;
      }

      if (res['m'] == 'new') {
        this.historyNeedsReload = true;
        this.avoidIframeRedirect = true;
      }
      if (res['m'] && res['m'] != 'new') {
        this.avoidIframeRedirect = false;
      }

      if (res['m']) {
        if (this.playerIframe?.nativeElement) {
          setTimeout(() => {
            this.playerIframe.nativeElement.focus();
          }, 150);
        }
        PopupComponent.activeInstances++;
        document.body.style.overflow = 'hidden';
        this.$meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' });
        this.iframeLoaded = true;
        this.iframeOpen = true;
        this.machineHidden = false;
        setTimeout(() => {
          this.onResize(null);
          //this.playerIframe.nativeElement.focus();
        }, 400);
        setTimeout(() => {
          this.machineTransparent = false;
        }, 1000);
        if (this.playerIframe && this.playerIframe.nativeElement) {
          if (res['m'] == 'new') {
            this.playerIframe.nativeElement.contentWindow.postMessage(
              {
                type: 'player:machine.new',
                machineName: res['name'],
              },
              '*'
            );
          } else {
            this.playerIframe.nativeElement.contentWindow.postMessage(
              {
                type: 'player:machine.switch',
                machineId: res['m'],
                playlistId: res['p'],
              },
              '*'
            );
          }
        } else {
          console.log('ROUTE SET IFRAME URL');
          let path = res['m'];
          if (res['m'] == 'new' && res['name']) {
            path += '?name=' + res['name'];
          }
          if (res['p']) {
            path += '?playlist=' + res['p'];
          }
          this.iframeSrc = '/machine/' + path;
          console.log('iframe src is ' + this.iframeSrc);
        }
      }

      if (res['upgrade']) {
        this.$challengeService.getActiveUserChallenge().subscribe((userChallenge: UserAccountChallenge) => {
          if (!userChallenge || !this.$challengeService.isUserChallengeLocked(userChallenge)) {
            return;
          }

          this.$challengeService.getMetadata(userChallenge.challengeId).subscribe((challenge) => {
            const deadline = this.$challengeService.getChallengeBrandingDeadline(challenge);

            this.$router.navigate(
              [
                '',
                {
                  outlets: {
                    'modal-router': ['payment', 'challenge', challenge.id],
                  },
                },
              ],
              {
                queryParams: { scholarshipCode: deadline?.defaultScholarshipCode },
                replaceUrl: true,
              }
            );
          });
        });
      }
    });
    this.$router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.lastUrl = window.location.href;
      }
      if (event instanceof NavigationEnd) {
        this.lastUrl = event.urlAfterRedirects;
        this.updateAppBackground();
      }
    });

    this.$translocoService.langChanges$.subscribe((lang) => {
      const root = document.documentElement;
      const font = this.$languageService.getLanguage(lang).font || this.$languageService.defaultFont;

      const head = document.head || document.getElementsByTagName('head')[0];

      const fontUrlId = 'fontUrl';

      let link: HTMLLinkElement = document.getElementById(fontUrlId) as HTMLLinkElement;

      if (font.url) {
        const exists = Boolean(link);
        if (!exists) {
          link = document.createElement('link');
        }

        link.id = fontUrlId;

        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.media = 'all';
        link.href = font.url;

        if (!exists) {
          head.appendChild(link);
        }
      } else if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }
      if (font.family) {
        root.style.setProperty('--fontFamily', font.family);
      }

      this.$iframeCommunicationsService.sendMessage('languageChanged', {
        language: lang,
      });
    });
  }

  hideMachine(event?: EventData) {
    console.log('ROUTE HIDE MACVHIE', event);
    this.iframeOpen = false;

    this.updateAppBackground();

    this.$audio.play('/assets/sounds/close.mp3');
    this.$iframeCommunicationsService.hideAnimCircle();
    this.$meta.removeTag("name='viewport'");
    document.body.style.overflow = 'visible';
    PopupComponent.activeInstances--;
    this.machineTransparent = true;
    setTimeout(() => {
      this.machineHidden = true;
      this.playerIframe.nativeElement.contentWindow.postMessage({ type: 'player:sleep' }, '*');
      this.loadWrapper = true;
    }, 300);

    const path = [];

    if (event?.path) {
      path.push(event.path);
    }

    const queryParams = { m: null, name: null, p: null, ...(event?.params ? event?.params : {}) };

    this.$router.navigate(path, {
      relativeTo: this.$route,
      queryParams,
      queryParamsHandling: 'merge',
    });
    if (this.playerIframe?.nativeElement) {
      this.playerIframe.nativeElement.blur();
    }
  }
  updateUserSourceFromQuery(params: Params) {
    if (Object.keys(params).length === 0 || this.$localStorage.get('userSource')) {
      return;
    }

    if (this.$myUser.loggedIn() && !this.$myUser.user.source) {
      this.$challengeService.getActiveUserChallenge(this.$myUser.id()).subscribe((userChallenge) => {
        if (userChallenge.accessLevel !== 1) {
        }
        this.$myUser.updateUserFields(
          {
            source: JSON.stringify(params),
          },
          false
        );
      });
    } else if (!this.$myUser.loggedIn()) {
      this.$localStorage.set('userSource', params);
    }
  }

  onIframeLoaded() {
    if (!this.playerIframe) {
      return;
    }
    this.playerIframe.nativeElement.contentWindow.focus();
    this.playerIframe.nativeElement.contentWindow.history.pushState =
      this.playerIframe.nativeElement.contentWindow.history.replaceState;
  }
}
