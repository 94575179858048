/* tslint:disable */

declare var Object: any;
export interface ItemTypeInterface {
  "name": string;
  "displayName"?: string;
  "description"?: string;
  "code"?: string;
  "active"?: number;
  "strings"?: string;
  "order"?: string;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class ItemType implements ItemTypeInterface {
  "name": string;
  "displayName": string;
  "description": string;
  "code": string;
  "active": number;
  "strings": string;
  "order": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: ItemTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ItemType`.
   */
  public static getModelName() {
    return "ItemType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ItemType for dynamic purposes.
  **/
  public static factory(data: ItemTypeInterface): ItemType{
    return new ItemType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ItemType',
      plural: 'ItemTypes',
      path: 'ItemTypes',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'number',
          default: 1
        },
        "strings": {
          name: 'strings',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
