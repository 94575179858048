/* tslint:disable */
import {
  UserAccount,
  Entity
} from '../index';

declare var Object: any;
export interface UserAccountEntityInterface {
  "userAccountId": number;
  "entityId": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  userAccount?: UserAccount;
  entity?: Entity;
}

export class UserAccountEntity implements UserAccountEntityInterface {
  "userAccountId": number;
  "entityId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  userAccount: UserAccount;
  entity: Entity;
  constructor(data?: UserAccountEntityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountEntity`.
   */
  public static getModelName() {
    return "UserAccountEntity";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountEntity for dynamic purposes.
  **/
  public static factory(data: UserAccountEntityInterface): UserAccountEntity{
    return new UserAccountEntity(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountEntity',
      plural: 'UserAccountEntities',
      path: 'UserAccountEntities',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "entityId": {
          name: 'entityId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        userAccount: {
          name: 'userAccount',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
        entity: {
          name: 'entity',
          type: 'Entity',
          model: 'Entity',
          relationType: 'belongsTo',
                  keyFrom: 'entityId',
          keyTo: 'id'
        },
      }
    }
  }
}
