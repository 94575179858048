import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { SITEMAP } from '../../../utilities/sitemap';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { LoopBackAuth } from '../sdk';

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
  constructor(private $transloco: TranslocoService, private $router: Router, private $lbauth: LoopBackAuth) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse, caught) => {
        const authError = response.error.error.statusCode === 401;
        if (authError && !response.url.includes('login') && !response.url.includes('/useraccounts/reset')) {
          // if there is an access token
          if (!this.$lbauth.getAccessTokenId()) return;
          console.log('Received 401 error on ' + response.url + '. Redirecting to login.');
          this.$lbauth.clear();
          window.location.href = SITEMAP.LANDING + '?mode=login';
          return;
        }
        return throwError(response);
      })
    );
  }
}
