import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-signup-school-picker',
  templateUrl: './school-picker.component.html',
  styles: [],
})
export class SchoolPickerComponent implements OnInit {
  constructor(private $translocoService: TranslocoService) {}

  language: string;
  keyword: string;

  @Input() countryId: number;
  @Input() defaultLanguage: string;
  @Input() school: any;
  @Input() otherSchool: any;

  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() selectOtherSchool: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.$translocoService.langChanges$.subscribe((language) => {
      this.setLanguage(language);
    });

    this.setLanguage(this.$translocoService.getActiveLang());
  }

  setLanguage(language: string) {
    let searchLanguage = language;
    if (
      this.defaultLanguage === language ||
      (!this.defaultLanguage && this.$translocoService.getDefaultLang() === language)
    ) {
      searchLanguage = null;
    }

    this.language = searchLanguage;
  }
}
