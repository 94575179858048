import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PopupComponent } from './popup/popup.component';
import { SelectComponent } from './select/select.component';
import { TextInputComponent } from './text-input/text-input.component';
import { ButtonComponent } from './button/button.component';
import { IsoPickerComponent } from './iso-picker/iso-picker.component';
import { CountryFlagComponent } from './country-flag/country-flag.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MatSelectModule } from '@angular/material/select';

import { AlertComponent } from './alert/alert.component';
import { RouterModule } from '@angular/router';
import { AnnouncementCardComponent } from './announcement-card/announcement-card.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { GradesComponent } from './grades/grades.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { TypographyComponent } from './typography/typography.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { FormLabelComponent } from './form-label/form-label.component';
import { TimeProgressComponent } from './time-progress/time-progress.component';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { MultilangauageInputComponent } from './multilangauage-input/multilangauage-input.component';
import { TitleButtonComponent } from './title-button/title-button.component';

import { TranslocoRootModule } from '../../../transloco-root.module';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  declarations: [
    PopupComponent,
    SelectComponent,
    TextInputComponent,
    ButtonComponent,
    IsoPickerComponent,
    CountryFlagComponent,
    AnnouncementCardComponent,
    PaginationComponent,
    BreadcrumbComponent,
    GradesComponent,
    AlertComponent,
    ConfirmComponent,
    TypographyComponent,
    EmptyStateComponent,
    FormLabelComponent,
    TimeProgressComponent,
    SpinnerComponent,
    MultilangauageInputComponent,
    TitleButtonComponent,
  ],
  imports: [CommonModule, RouterModule, MatSelectModule, TranslocoRootModule, ProgressBarModule, InlineSVGModule],
  exports: [
    PopupComponent,
    SelectComponent,
    TextInputComponent,
    ButtonComponent,
    IsoPickerComponent,
    AnnouncementCardComponent,
    PaginationComponent,
    BreadcrumbComponent,
    CountryFlagComponent,
    GradesComponent,
    AlertComponent,
    ConfirmComponent,
    TypographyComponent,
    EmptyStateComponent,
    FormLabelComponent,
    TimeProgressComponent,
    SpinnerComponent,
    MultilangauageInputComponent,
    TitleButtonComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class UiModule {}
