import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Machine } from '../sdk';

type Machines = {
  [id: number]: Machine;
};

@Injectable({
  providedIn: 'root',
})
export class MachineService {
  machines: BehaviorSubject<Machines> = new BehaviorSubject({});

  constructor() {}

  addBulk(machines: Machine[]): number[] {
    type Accumlator = {
      machines: Machines;
      ids: number[];
    };

    const result = machines.reduce(
      (acc: Accumlator, machine: Machine) => {
        acc.machines[machine.id] = machine;
        acc.ids.push(machine.id);
        return acc;
      },
      {
        machines: {},
        ids: [],
      }
    );

    this.machines.next({ ...this.machines.getValue(), ...result.machines });

    return result.ids;
  }

  add(id: number, machine: Machine) {
    this.machines.next({ ...this.machines.getValue(), [id]: machine });
  }

  update(id: number, machine: Machine) {
    this.machines.next({ ...this.machines.getValue(), [id]: machine });
  }

  get(ids: number[]) {
    const value = this.machines.getValue();
    return ids.map((id) => value[id]);
  }

  subscribe(callback: (machines: Machines) => void) {
    return this.machines.subscribe(callback);
  }
}
