/* tslint:disable */
import {
  Item
} from '../index';

declare var Object: any;
export interface ItemRelationInterface {
  "parentItemId"?: number;
  "childItemId"?: number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  parent?: Item;
  child?: Item;
}

export class ItemRelation implements ItemRelationInterface {
  "parentItemId": number;
  "childItemId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  parent: Item;
  child: Item;
  constructor(data?: ItemRelationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ItemRelation`.
   */
  public static getModelName() {
    return "ItemRelation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ItemRelation for dynamic purposes.
  **/
  public static factory(data: ItemRelationInterface): ItemRelation{
    return new ItemRelation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ItemRelation',
      plural: 'ItemRelations',
      path: 'ItemRelations',
      idName: 'id',
      properties: {
        "parentItemId": {
          name: 'parentItemId',
          type: 'number'
        },
        "childItemId": {
          name: 'childItemId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        parent: {
          name: 'parent',
          type: 'Item',
          model: 'Item',
          relationType: 'belongsTo',
                  keyFrom: 'parentItemId',
          keyTo: 'id'
        },
        child: {
          name: 'child',
          type: 'Item',
          model: 'Item',
          relationType: 'belongsTo',
                  keyFrom: 'childItemId',
          keyTo: 'id'
        },
      }
    }
  }
}
