<div class="modal-container" (click)="closeModal()" (scroll)="$event.stopPropagation()">
  <div
    class="modal-inner"
    id="active-modal"
    [class.big-modal]="size == 'big'"
    [class.small-modal]="size == 'small'"
    [class.full-screen]="size == 'fullScreen'"
    (click)="$event.stopPropagation()"
  >
    <button class="z-50 close-button text-mulberry-700 focus:outline-none hover:text-mulberry-800" *ngIf="closeable && !hideX" (click)="closeModal()">
      <i class="fas fa-times"></i>
    </button>
    <ng-content></ng-content>
  </div>
</div>
