<div #root [dir]="'_direction' | transloco">
  <div *ngIf="this.$route.snapshot.queryParams.m && !this.iframeLoaded" class="square-wipe"></div>
  <router-outlet *ngIf="loadWrapper || !initialQueryParam"></router-outlet>
  <router-outlet name="modal-router"></router-outlet>
  <app-first-mod></app-first-mod>
  <div
    #iframeContainer
    class="iframe-container"
    [class.machine-transparent]="machineTransparent"
    [class.hide]="machineHidden"
  >
    <iframe
      tabindex="-1"
      *ngIf="iframeLoaded"
      (load)="onIframeLoaded()"
      #playerIframe
      id="player-iframe"
      [src]="iframeSrc | safe"
    ></iframe>
  </div>

  <app-help></app-help>
</div>

<!-- <div class="screen-height flex-center-2d">
  <img class="icon lg" src="assets/img/color/poly-puzzled.svg" />
  <div class="center maxw mar-md-vert">
    The LetsMod Website is down for scheduled maintenance. We should be back up and running before:
  </div>
  <div class="center large">4:30PM EST</div>

  <div class="center mar-lg-top subtle thin">To stay alerted for scheduled maintenance, follow us on social media:</div>
  <div class="flex-row">
    <a target="_blank" href="https://www.facebook.com/polyupinc" class="social-icon"><i class="fab fa-facebook"></i></a>
    <a target="_blank" href="https://www.twitter.com/polyupinc" class="social-icon"><i class="fab fa-twitter"></i></a>
  </div>
</div> -->
