/* tslint:disable */
import {
  Board
} from '../index';

declare var Object: any;
export interface BoardPortalInterface {
  "parentBoardId"?: number;
  "childBoardId"?: number;
  "data"?: string;
  "position"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "boardPortalCode"?: string;
  "id"?: number;
  portal?: Board;
  parents?: Board;
}

export class BoardPortal implements BoardPortalInterface {
  "parentBoardId": number;
  "childBoardId": number;
  "data": string;
  "position": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "boardPortalCode": string;
  "id": number;
  portal: Board;
  parents: Board;
  constructor(data?: BoardPortalInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BoardPortal`.
   */
  public static getModelName() {
    return "BoardPortal";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BoardPortal for dynamic purposes.
  **/
  public static factory(data: BoardPortalInterface): BoardPortal{
    return new BoardPortal(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BoardPortal',
      plural: 'BoardPortals',
      path: 'BoardPortals',
      idName: 'id',
      properties: {
        "parentBoardId": {
          name: 'parentBoardId',
          type: 'number'
        },
        "childBoardId": {
          name: 'childBoardId',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "position": {
          name: 'position',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "boardPortalCode": {
          name: 'boardPortalCode',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        portal: {
          name: 'portal',
          type: 'Board',
          model: 'Board',
          relationType: 'belongsTo',
                  keyFrom: 'childBoardId',
          keyTo: 'id'
        },
        parents: {
          name: 'parents',
          type: 'Board',
          model: 'Board',
          relationType: 'belongsTo',
                  keyFrom: 'parentBoardId',
          keyTo: 'id'
        },
      }
    }
  }
}
