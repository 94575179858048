/* tslint:disable */
import {
  Group
} from '../index';

declare var Object: any;
export interface PermissionInterface {
  "name"?: string;
  "displayName"?: string;
  "description"?: string;
  "active"?: boolean;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  groups?: Group[];
}

export class Permission implements PermissionInterface {
  "name": string;
  "displayName": string;
  "description": string;
  "active": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  groups: Group[];
  constructor(data?: PermissionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Permission`.
   */
  public static getModelName() {
    return "Permission";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Permission for dynamic purposes.
  **/
  public static factory(data: PermissionInterface): Permission{
    return new Permission(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Permission',
      plural: 'Permissions',
      path: 'Permissions',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        groups: {
          name: 'groups',
          type: 'Group[]',
          model: 'Group',
          relationType: 'hasMany',
          modelThrough: 'GroupPermission',
          keyThrough: 'groupId',
          keyFrom: 'id',
          keyTo: 'permisionId'
        },
      }
    }
  }
}
