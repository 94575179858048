import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { RoutingService } from 'src/app/shared/services/routing-service';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss'],
})
export class LoggedOutComponent implements OnInit {
  loginPopupActive: boolean = false;
  constructor(private $router: Router, private $route: ActivatedRoute, private $transloco: TranslocoService) { }
  whiteNav: boolean = this.isWhite(window.location.pathname);
  paddingTop: boolean = !this.isWhite(window.location.pathname);
  hideNav: boolean = this.hideNavForRoute(window.location.pathname)

  authMode: boolean;

  hideNavForRoute(url: string) {
    const routesToHideNav = ['/about'];

    const hideNav = routesToHideNav.includes(url);
    return hideNav;
  }

  isWhite(url: string) {
    return url.includes('educators') || url.includes('partners') || url.includes('mod-market') || url.includes('challenges') || url == '/about';
  }

  ngOnInit(): void {

    this.$router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.isWhite(window.location.pathname)) {
          this.whiteNav = true;
          this.paddingTop = false;
        } else {
          this.whiteNav = false;
          this.paddingTop = true;
        }

        this.hideNav = this.hideNavForRoute(window.location.pathname)
      }
    });

    this.$route.queryParams.subscribe((res) => {
      if (res.mode == 'login' || res.mode === 'signup') {
        this.loginPopupActive = true;
        this.authMode = res.mode;
      } else {
        this.loginPopupActive = false;
        this.authMode = undefined;
      }
    });
  }

  closeAuth(): void {
    this.$router.navigate([], { queryParams: { mode: null }, relativeTo: this.$route });
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.isWhite(window.location.pathname)) {
      return;
    }
    if (window.scrollY > 10) {
      this.whiteNav = false;
    } else {
      this.whiteNav = true;
    }
  }
}
