/* tslint:disable */

declare var Object: any;
export interface TimeZoneInterface {
  "active"?: boolean;
  "cc"?: string;
  "coordinates"?: string;
  "tz"?: string;
  "comments"?: string;
  "utcOffset"?: string;
  "utcDstOffset"?: string;
  "display"?: string;
  "id"?: string;
  "browserOffsetMinutes"?: number;
}

export class TimeZone implements TimeZoneInterface {
  "active": boolean;
  "cc": string;
  "coordinates": string;
  "tz": string;
  "comments": string;
  "utcOffset": string;
  "utcDstOffset": string;
  "display": string;
  "id": string;
  "browserOffsetMinutes": number;
  constructor(data?: TimeZoneInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TimeZone`.
   */
  public static getModelName() {
    return "TimeZone";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TimeZone for dynamic purposes.
  **/
  public static factory(data: TimeZoneInterface): TimeZone{
    return new TimeZone(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TimeZone',
      plural: 'TimeZones',
      path: 'TimeZones',
      idName: 'id',
      properties: {
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "cc": {
          name: 'cc',
          type: 'string'
        },
        "coordinates": {
          name: 'coordinates',
          type: 'string'
        },
        "tz": {
          name: 'tz',
          type: 'string'
        },
        "comments": {
          name: 'comments',
          type: 'string'
        },
        "utcOffset": {
          name: 'utcOffset',
          type: 'string'
        },
        "utcDstOffset": {
          name: 'utcDstOffset',
          type: 'string'
        },
        "display": {
          name: 'display',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'string'
        },
        "browserOffsetMinutes": {
          name: 'browserOffsetMinutes',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
