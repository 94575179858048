/* tslint:disable */
import {
  Classroom,
  UserAccount
} from '../index';

declare var Object: any;
export interface ClassroomTeacherInterface {
  "classroomId": number;
  "userAccountId": number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  classroom?: Classroom;
  teacher?: UserAccount;
}

export class ClassroomTeacher implements ClassroomTeacherInterface {
  "classroomId": number;
  "userAccountId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  classroom: Classroom;
  teacher: UserAccount;
  constructor(data?: ClassroomTeacherInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ClassroomTeacher`.
   */
  public static getModelName() {
    return "ClassroomTeacher";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ClassroomTeacher for dynamic purposes.
  **/
  public static factory(data: ClassroomTeacherInterface): ClassroomTeacher{
    return new ClassroomTeacher(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ClassroomTeacher',
      plural: 'ClassroomTeachers',
      path: 'ClassroomTeachers',
      idName: 'id',
      properties: {
        "classroomId": {
          name: 'classroomId',
          type: 'number'
        },
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        classroom: {
          name: 'classroom',
          type: 'Classroom',
          model: 'Classroom',
          relationType: 'belongsTo',
                  keyFrom: 'classroomId',
          keyTo: 'id'
        },
        teacher: {
          name: 'teacher',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
      }
    }
  }
}
