/* tslint:disable */
import {
  UserAccount,
  Tutorial
} from '../index';

declare var Object: any;
export interface TutorialProgressInterface {
  "userAccountId": number;
  "tutorialId": number;
  "tutorialStepId": number;
  "completed"?: boolean;
  "skipped"?: boolean;
  "name"?: string;
  "description"?: string;
  "data"?: any;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  user?: UserAccount;
  tutorial?: Tutorial;
}

export class TutorialProgress implements TutorialProgressInterface {
  "userAccountId": number;
  "tutorialId": number;
  "tutorialStepId": number;
  "completed": boolean;
  "skipped": boolean;
  "name": string;
  "description": string;
  "data": any;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  user: UserAccount;
  tutorial: Tutorial;
  constructor(data?: TutorialProgressInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TutorialProgress`.
   */
  public static getModelName() {
    return "TutorialProgress";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TutorialProgress for dynamic purposes.
  **/
  public static factory(data: TutorialProgressInterface): TutorialProgress{
    return new TutorialProgress(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TutorialProgress',
      plural: 'TutorialProgresses',
      path: 'TutorialProgresses',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "tutorialId": {
          name: 'tutorialId',
          type: 'number'
        },
        "tutorialStepId": {
          name: 'tutorialStepId',
          type: 'number',
          default: 0
        },
        "completed": {
          name: 'completed',
          type: 'boolean',
          default: false
        },
        "skipped": {
          name: 'skipped',
          type: 'boolean',
          default: false
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
        tutorial: {
          name: 'tutorial',
          type: 'Tutorial',
          model: 'Tutorial',
          relationType: 'belongsTo',
                  keyFrom: 'tutorialId',
          keyTo: 'id'
        },
      }
    }
  }
}
