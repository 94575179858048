/* tslint:disable */
import {
  Notification
} from '../index';

declare var Object: any;
export interface NotificationRuleInterface {
  "notificationId": number;
  "name"?: string;
  "displayName"?: string;
  "data"?: any;
  "active"?: boolean;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  notification?: Notification;
}

export class NotificationRule implements NotificationRuleInterface {
  "notificationId": number;
  "name": string;
  "displayName": string;
  "data": any;
  "active": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  notification: Notification;
  constructor(data?: NotificationRuleInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `NotificationRule`.
   */
  public static getModelName() {
    return "NotificationRule";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of NotificationRule for dynamic purposes.
  **/
  public static factory(data: NotificationRuleInterface): NotificationRule{
    return new NotificationRule(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'NotificationRule',
      plural: 'NotificationRules',
      path: 'NotificationRules',
      idName: 'id',
      properties: {
        "notificationId": {
          name: 'notificationId',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string',
          default: 'false'
        },
        "displayName": {
          name: 'displayName',
          type: 'string',
          default: 'false'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        notification: {
          name: 'notification',
          type: 'Notification',
          model: 'Notification',
          relationType: 'belongsTo',
                  keyFrom: 'notificationId',
          keyTo: 'id'
        },
      }
    }
  }
}
