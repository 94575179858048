<div>
  <div *ngIf="searchBox" class="mb-4">
    <ui-text-input
      #input
      [loading]="isSearching"
      (textChanged)="search($event)"
      classes="w-full"
      inputClasses="border border-mulberry-600"
      [placeholder]="'schoolPicker.searchPlaceholder' | transloco"
    ></ui-text-input>
  </div>
  <div
    *ngIf="!isSearching && isValidKeyword && schools.length > 0"
    class="flex justify-end mb-2 text-sm font-bold text-mango-500"
    [ngClass]="{
      '-mt-2': searchBox
    }"
  >
    <button (click)="openOtherSchoolPopup()" class="font-medium px-1 py-0.5 rounded-md hover:bg-mulberry-400">
      {{ "schoolPicker.schoolIsNotInTheList" | transloco }}
    </button>
  </div>
  <button
    *ngIf="otherSchool"
    class="flex flex-col w-full px-3 py-3 mb-4 text-left text-white rounded-lg  sm:pr-6 sm:pl-0 sm:flex-row sm:items-center focus:outline-none bg-gradient-to-b from-razberry-550 to-blueberry-500"
  >
    <div
      class="flex w-8 sm:mx-2 sm:items-center sm:justify-center"
      inlineSVG="/assets/img/black/check-circle.svg"
      [setSVGAttributes]="{ class: 'w-5 h-5' }"
    ></div>

    <div>
      <div class="text-lg font-medium">
        {{ otherSchool }}
      </div>
      <div class="font-light">
        {{ "otherSchool" | transloco }}
      </div>
    </div>
  </button>

  <ng-container *ngIf="!isSearching && schools.length">
    <button
      *ngFor="let school of schools; let last = last"
      class="flex flex-col w-full px-3 py-3 text-left rounded-lg  sm:pr-6 sm:pl-0 sm:flex-row sm:items-center focus:outline-none"
      [ngClass]="{
        'mb-2': !last,
        'bg-mulberry-300 bg-opacity-50': selectedSchool != school.id,
        'bg-gradient-to-b from-pineapple-550 to-mango-500 text-white': selectedSchool == school.id
      }"
      (click)="selectSchool(school)"
    >
      <div class="flex w-8 sm:mx-2 sm:items-center sm:justify-center">
        <div
          *ngIf="selectedSchool == school.id"
          class="flex"
          inlineSVG="/assets/img/black/check-circle.svg"
          [setSVGAttributes]="{ class: 'w-5 h-5' }"
        ></div>
      </div>
      <div>
        <div class="text-lg font-medium">
          {{ school.name }}
        </div>
        <div class="font-light">
          {{ school.parent_path }}
        </div>
      </div>
    </button>
  </ng-container>

  <div
    *ngIf="emptyStateMessage === 'noSchoolsFound' && !isSearching"
    class="p-4 md:flex md:justify-between dashed-grape md:items-center"
  >
    <span class="font-medium"> {{ emptyStateMessage | transloco }} </span>

    <ui-button
      color="plain"
      classes="px-2 rounded text-mango-500 hover:text-mango-600 focus:outline-none focus:ring focus:ring-mulberry-450  relative text-left"
      [loading]="isSavingOtherSchool"
      (click)="onCreateOther()"
      size="sm"
    >
      {{ "createOtherSchool" | transloco }}
    </ui-button>
  </div>

  <div
    *ngIf="!isSearching && emptyStateMessage && emptyStateMessage !== 'noSchoolsFound'"
    [ngClass]="{
      'mt-4': schools.length > 0
    }"
  >
    <ui-empty-state
      contentAlign="start"
      contentDirection="row"
      [message]="emptyStateMessage | transloco"
      img="/assets/img/color/search.svg"
      size="xs"
    ></ui-empty-state>
  </div>
  <div class="space-y-4" *ngIf="isSearching">
    <div *ngFor="let placeholder of loadingPlaceholders" class="flex items-center p-4 rounded-xl bg-mulberry-450">
      <div class="flex-shrink-0 w-6 h-6 mr-4 rounded-full bg-mulberry-500"></div>
      <div class="flex-1 w-full space-y-2">
        <div class="w-1/2 h-6 rounded-lg bg-mulberry-500 bg-opacity-70"></div>
        <div class="w-1/3 h-4 rounded-lg bg-mulberry-500 bg-opacity-70"></div>
      </div>
    </div>
  </div>
</div>

<ui-popup size="max-w-sm" *ngIf="isOtherSchoolPopupOpened" (close)="closeOtherSchoolPopup()">
  <ui-popup-title>
    <ui-typography> {{ "schoolPicker.otherSchoolPopupTitle" | transloco }} </ui-typography>
  </ui-popup-title>
  <ui-popup-body>
    <form (submit)="$event.preventDefault(); onCreateOther(otherSchoolPopupValue)">
      <div class="p-4 sm:p-6">
        <ui-text-input
          (textChanged)="changeOtherSchoolPopupValue($event)"
          classes="w-full"
          inputClasses="border border-mulberry-600"
          [placeholder]="'otherSchool' | transloco"
        ></ui-text-input>
      </div>
      <div class="p-4 px-10 border-t border-opacity-25 border-mulberry-550 bg-mulberry-450">
        <ui-button [disabled]="!isValidOtherSchool" [loading]="isSavingOtherSchool">
          {{ "schoolPicker.submit" | transloco }}
        </ui-button>
      </div>
    </form>
  </ui-popup-body>
</ui-popup>
