import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LoopBackConfig } from './app/shared/sdk';
import './app/shared/logging';

export let appInjector: Injector;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((componentRef) => {
    appInjector = componentRef.injector;
  })
  .catch((err) => console.error(err));

LoopBackConfig.setBaseURL(environment.apiUrl);
LoopBackConfig.setApiVersion(environment.apiVersion);
