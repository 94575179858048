<div class="relative" [class]="classes" [ngClass]="{ 'inline-block': inline }">
  <select
    #select
    class="w-full p-2 pr-10 text-lg font-light rounded-lg appearance-none text-mulberry-800 bg-mulberry-400 focus:outline-none"
    [ngClass]="{
      'text-opacity-50': !hasSelection && !multiple,
      'focus:ring-2 focus:ring-razberry-500': !error,
      'ring-2 ring-cherry-500': error
    }"
    [disabled]="disabled"
    (change)="onSelectionChange($event.target.selectedOptions)"
    [multiple]="multiple"
  >
    <option *ngIf="placeholder" value="" disabled [selected]="!value">{{ placeholder || "------" }}</option>

    <ng-container>
      <option *ngFor="let option of options" [selected]="isSelected(option.value)" [value]="option.value">
        <ng-container *ngIf="useTransloco">
          {{ option.label | transloco }}
        </ng-container>
        <ng-container *ngIf="!useTransloco"> {{ option.label || (option.name | transloco) }} </ng-container>
      </option>

      <ng-container *ngIf="multiple">
        <optgroup [label]="option.label | transloco" *ngIf="option.options">
          <option *ngFor="let opt of option.options" [selected]="isSelected(opt.value)" [value]="opt.value">
            <ng-container *ngIf="useTransloco">
              {{ opt.label | transloco }}
            </ng-container>
            <ng-container *ngIf="!useTransloco"> {{ opt.label || (opt.name | transloco) }} </ng-container>
          </option>
        </optgroup>
      </ng-container>
    </ng-container>
  </select>

  <svg
    *ngIf="!multiple"
    class="absolute right-0 w-4 mr-4 transform -translate-y-1/2 pointer-events-none text-mulberry-650 top-1/2"
    viewBox="0 0 320 512"
  >
    <path
      fill="currentColor"
      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
    ></path>
  </svg>

  <div
    *ngIf="loading"
    class="absolute inset-0 flex items-center justify-center w-full h-full rounded-lg bg-mulberry-400 bg-opacity-80"
  >
    <svg class="w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
</div>
<div *ngIf="error && error != ''" class="text-sm mt-0.5 text-right text-cherry-500">
  {{ error }}
</div>
<div
  *ngIf="multiple && multipleKey"
  [innerHTML]="'select-multiple-hint' | transloco: { key: multipleKey }"
  class="mt-0.5 text-sm text-mulberry-650"
></div>
