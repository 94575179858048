/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Diagnostic } from '../../models/Diagnostic';
import { SocketConnection } from '../../sockets/socket.connections';


/**
 * Api services for the `Diagnostic` model.
 */
@Injectable()
export class DiagnosticApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} type Type of diagnostic
   *
   * @param {number} minutes Last X number of minutes
   *
   * @param {boolean} onlyTopMethods Return only top level methods
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Diagnostic` object.)
   * </em>
   */
  public statistics(type: String, minutes: Number = new Number(), onlyTopMethods: Boolean = new Boolean(), customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Diagnostics/statistics";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    if (typeof minutes !== 'undefined' && minutes !== null) _urlParams.minutes = minutes;
    if (typeof onlyTopMethods !== 'undefined' && onlyTopMethods !== null) _urlParams.onlyTopMethods = onlyTopMethods;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} type Type of diagnostic
   *
   * @param {number} minutes Last X number of minutes
   *
   * @param {number} userId User Id
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Diagnostic` object.)
   * </em>
   */
  public userActivity(type: String, minutes: Number = new Number(), userId: Number, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Diagnostics/userActivity";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof type !== 'undefined' && type !== null) _urlParams.type = type;
    if (typeof minutes !== 'undefined' && minutes !== null) _urlParams.minutes = minutes;
    if (typeof userId !== 'undefined' && userId !== null) _urlParams.userId = userId;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {boolean} apiOnly fetch /api only or all requests
   *
   * @param {number} minutes Last X number of minutes
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Diagnostic` object.)
   * </em>
   */
  public apiStatistics(apiOnly: Boolean = new Boolean(), minutes: Number = new Number(), customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Diagnostics/statistics/api/analytics/";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof apiOnly !== 'undefined' && apiOnly !== null) _urlParams.apiOnly = apiOnly;
    if (typeof minutes !== 'undefined' && minutes !== null) _urlParams.minutes = minutes;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {boolean} apiOnly fetch /api only or all requests
   *
   * @param {number} userId Single user or array of users - Optional
   *
   * @param {string} method HTTP request methods - Optional
   *
   * @param {date} startDate Start date
   *
   * @param {date} endDate End date
   *
   * @param {string} format Return response in format
   *
   * @param {object} res Requests response object
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Diagnostic` object.)
   * </em>
   */
  public getApiRecoreds(apiOnly: Boolean = new Boolean(), userId: any[] = [], method: String = new String(), startDate: Date, endDate: Date, format: String, res: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Diagnostics/statistics/api/";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof apiOnly !== 'undefined' && apiOnly !== null) _urlParams.apiOnly = apiOnly;
    if (typeof userId !== 'undefined' && userId !== null) _urlParams.userId = userId;
    if (typeof method !== 'undefined' && method !== null) _urlParams.method = method;
    if (typeof startDate !== 'undefined' && startDate !== null) _urlParams.startDate = startDate;
    if (typeof endDate !== 'undefined' && endDate !== null) _urlParams.endDate = endDate;
    if (typeof format !== 'undefined' && format !== null) _urlParams.format = format;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} query read only query
   *
   * @param {string} params query params
   *
   * @param {string} format Return response in format
   *
   * @param {object} res Requests response object
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Diagnostic` object.)
   * </em>
   */
  public getQuery(query: String = new String(), params: any[] = [], format: String, res: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Diagnostics/query";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof query !== 'undefined' && query !== null) _urlParams.query = query;
    if (typeof params !== 'undefined' && params !== null) _urlParams.params = params;
    if (typeof format !== 'undefined' && format !== null) _urlParams.format = format;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Diagnostic`.
   */
  public getModelName() {
    return "Diagnostic";
  }
}
