/* tslint:disable */
import {
  Machine,
  UserAccount
} from '../index';

declare var Object: any;
export interface MachineRecommendationInterface {
  "machineId": number;
  "recommendedMachineId": number;
  "order"?: number;
  "active": boolean;
  "data"?: string;
  "sessionUuid"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  machine?: Machine;
  createdByUser?: UserAccount;
  updatedByUser?: UserAccount;
}

export class MachineRecommendation implements MachineRecommendationInterface {
  "machineId": number;
  "recommendedMachineId": number;
  "order": number;
  "active": boolean;
  "data": string;
  "sessionUuid": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  machine: Machine;
  createdByUser: UserAccount;
  updatedByUser: UserAccount;
  constructor(data?: MachineRecommendationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MachineRecommendation`.
   */
  public static getModelName() {
    return "MachineRecommendation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MachineRecommendation for dynamic purposes.
  **/
  public static factory(data: MachineRecommendationInterface): MachineRecommendation{
    return new MachineRecommendation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MachineRecommendation',
      plural: 'MachineRecommendations',
      path: 'MachineRecommendations',
      idName: 'id',
      properties: {
        "machineId": {
          name: 'machineId',
          type: 'number'
        },
        "recommendedMachineId": {
          name: 'recommendedMachineId',
          type: 'number'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "sessionUuid": {
          name: 'sessionUuid',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        machine: {
          name: 'machine',
          type: 'Machine',
          model: 'Machine',
          relationType: 'belongsTo',
                  keyFrom: 'machineId',
          keyTo: 'id'
        },
        createdByUser: {
          name: 'createdByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'createdBy',
          keyTo: 'id'
        },
        updatedByUser: {
          name: 'updatedByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'updatedBy',
          keyTo: 'id'
        },
      }
    }
  }
}
