import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstModComponent } from './first-mod.component';
import { MyDirectivesModule } from '../../pipes/MyDirectives.module';
import { UiModule } from '../ui/ui.module';
import { PortalModule } from '../portal/portal.module';
import { TranslocoRootModule } from 'src/app/transloco-root.module';

@NgModule({
    imports: [CommonModule, MyDirectivesModule, UiModule, PortalModule, TranslocoRootModule],
    declarations: [FirstModComponent],
    exports: [FirstModComponent],

    schemas: [NO_ERRORS_SCHEMA],
})
export class FirstModModule { }
