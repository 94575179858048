import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { AudioService } from '../../services/audio.service';
import { PlayerCommunicationsService } from '../../services/player-communications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ChallengeService } from 'src/app/dashboard/services/challenge.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-first-mod',
  templateUrl: './first-mod.component.html',
})
export class FirstModComponent implements OnInit {
  active = false;
  alreadyOpened: boolean = false;
  @ViewChild('goToButton', { static: false }) goToButton: ElementRef;
  shareId: string;
  lessonId: number;
  progress = 0;
  constructor(
    private $user: UserService,
    private $localStorage: LocalStorageService,
    private $audioService: AudioService,
    private $playerCommunicationsService: PlayerCommunicationsService,
    private $router: Router,
    private $route: ActivatedRoute,
    private $challenge: ChallengeService
  ) {}

  interval: number;
  get buttonWidth() {
    return `${this.progress}%`;
  }
  ngOnInit() {
    combineLatest([this.$challenge.machinesSubj, this.$user.userSubj]).subscribe(([challenge, user]) => {
      const returnedMachineObject = (machine, lesson) => ({
        shareId: machine.shareId,
        lessonId: lesson.id,
      });
      const getFirstMachine = (lessons: any[], untouched = true) => {
        if (!lessons) {
          return {};
        }
        for (let lesson of lessons) {
          for (let l of lesson.lessons) {
            if (l.lesson.machines.length) {
              if (untouched) {
                const machine = l.lesson.machines.find(
                  (machine) => machine.completionStatus === 1 || machine.completionStatus === 0
                );
                if (!machine) {
                  return getFirstMachine(lessons, false);
                } else {
                  return returnedMachineObject(machine, l.lesson);
                }
              } else {
                return returnedMachineObject(l.lesson.machines[0], l.lesson);
              }
            }
          }
        }
      };

      this.alreadyOpened = true;

      const { shareId, lessonId } = getFirstMachine(challenge.lessons);

      this.lessonId = lessonId;
      this.shareId = shareId;
      if (!this.shareId) {
        return;
      }

      if (!user.lastSeen && !this.$localStorage.get('onboardingMod')) {
        this.interval = window.setInterval(() => {
          this.progress++;
          if (this.progress >= 100) {
            clearInterval(this.interval);
            this.openMachine();
          }
        }, 50);

        this.active = true;
        this.$localStorage.set('onboardingMod', 'true');
      }
    });

    this.$route.queryParamMap.subscribe((paramMap) => {
      if (!this.active) {
        return;
      }
      if (paramMap.get('m')) {
        setTimeout(() => {
          this.close();
        }, 500);
      }
    });
  }

  close() {
    this.active = false;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  openMachine() {
    this.$audioService.play('/assets/sounds/select_block.mp3');

    let rect = this.goToButton.nativeElement.getBoundingClientRect();

    setTimeout(() => {
      this.$router.navigate([], {
        queryParams: { m: this.shareId, p: this.lessonId },
      });
    }, 200);

    this.$playerCommunicationsService.createAnimWipe(rect);
  }
}
