/* tslint:disable */
import {
  Announcement
} from '../index';

declare var Object: any;
export interface AnnouncementTranslationInterface {
  "title": string;
  "body": string;
  "languageCode": string;
  "announcementId": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  announcement?: Announcement;
}

export class AnnouncementTranslation implements AnnouncementTranslationInterface {
  "title": string;
  "body": string;
  "languageCode": string;
  "announcementId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  announcement: Announcement;
  constructor(data?: AnnouncementTranslationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AnnouncementTranslation`.
   */
  public static getModelName() {
    return "AnnouncementTranslation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AnnouncementTranslation for dynamic purposes.
  **/
  public static factory(data: AnnouncementTranslationInterface): AnnouncementTranslation{
    return new AnnouncementTranslation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AnnouncementTranslation',
      plural: 'AnnouncementTranslations',
      path: 'AnnouncementTranslations',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "body": {
          name: 'body',
          type: 'string'
        },
        "languageCode": {
          name: 'languageCode',
          type: 'string'
        },
        "announcementId": {
          name: 'announcementId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        announcement: {
          name: 'announcement',
          type: 'Announcement',
          model: 'Announcement',
          relationType: 'belongsTo',
                  keyFrom: 'announcementId',
          keyTo: 'id'
        },
      }
    }
  }
}
