import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

type Font = {
  url?: string;
  family: string;
};

type Language = {
  name: string;
  code: string;
  font?: Font;
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private $translocoService: TranslocoService) {
    window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      // the console.
      this.setActiveLanguage(window.localStorage.getItem('i18nextLng'));
    });
  }
  defaultFont: Font = {
    family: '',
  };
  get languages(): Language[] {
    return [
      {
        name: 'English',
        code: 'en',
      },
      {
        name: 'عربى',
        code: 'ar',
      },      
      {
        name: 'Azerbaijani',
        code: 'az',
        font: {
          url: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap',
          family: "'Montserrat', sans-serif",
        },
      },
      {
        name: 'Dari',
        code: 'dar',
      },
      {
        name: 'Español',
        code: 'es',
      },
    ];
  }

  static languageCode: string[] = ['en', 'ar', 'az', 'dar', 'es'];

  getLanguage(code: string) {
    return this.languages.find((language) => language.code === code);
  }

  setActiveLanguage(lang: string) {
    if (!LanguageService.languageCode.includes(lang)) {
      return;
    }
    this.$translocoService.setActiveLang(lang);
    localStorage.setItem('i18nextLng', lang);
  }

  getActiveLanguage() {
    return this.$translocoService.getActiveLang();
  }
}
