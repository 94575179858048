/* tslint:disable */
import {
  UserAccount
} from '../index';

declare var Object: any;
export interface UserAccountNotificationInterface {
  "userAccountId": number;
  "notificationId": number;
  "delivered"?: boolean;
  "deliveredAt"?: Date;
  "seen"?: boolean;
  "seenAt"?: Date;
  "data"?: any;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  user?: UserAccount;
}

export class UserAccountNotification implements UserAccountNotificationInterface {
  "userAccountId": number;
  "notificationId": number;
  "delivered": boolean;
  "deliveredAt": Date;
  "seen": boolean;
  "seenAt": Date;
  "data": any;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  user: UserAccount;
  constructor(data?: UserAccountNotificationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountNotification`.
   */
  public static getModelName() {
    return "UserAccountNotification";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountNotification for dynamic purposes.
  **/
  public static factory(data: UserAccountNotificationInterface): UserAccountNotification{
    return new UserAccountNotification(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountNotification',
      plural: 'UserAccountNotifications',
      path: 'UserAccountNotifications',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "notificationId": {
          name: 'notificationId',
          type: 'number'
        },
        "delivered": {
          name: 'delivered',
          type: 'boolean',
          default: false
        },
        "deliveredAt": {
          name: 'deliveredAt',
          type: 'Date'
        },
        "seen": {
          name: 'seen',
          type: 'boolean',
          default: false
        },
        "seenAt": {
          name: 'seenAt',
          type: 'Date'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
      }
    }
  }
}
