import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringService {
  constructor() {}

  trimNonPrintable(str: string) {
    if(!str || str === '' || typeof str !== 'string') {
      return str;
    }

    return str.replace(/(\r\n|\n|\r)/gm, '').trim();
  }
}
