import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  audio: HTMLAudioElement = new Audio();
  constructor() {
    this.audio.volume = 0.2;
  }
  play(file: string) {
    if (localStorage.getItem('polybrain') && JSON.parse(localStorage.getItem('polybrain'))['soundEffect'] == false)
      return;
    this.audio.src = file;
    this.audio.load();
    this.audio.play();
  }
}
