/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Announcement } from '../../models/Announcement';
import { SocketConnection } from '../../sockets/socket.connections';
import { AnnouncementGroup } from '../../models/AnnouncementGroup';
import { AnnouncementChallenge } from '../../models/AnnouncementChallenge';
import { AnnouncementTranslation } from '../../models/AnnouncementTranslation';


/**
 * Api services for the `Announcement` model.
 */
@Injectable()
export class AnnouncementApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * Find a related item by id for announcementGroup.
   *
   * @param {any} id PersistedModel id
   *
   * @param {any} fk Foreign key for announcementGroup
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public findByIdAnnouncementGroup(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementGroup/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for announcementGroup.
   *
   * @param {any} id PersistedModel id
   *
   * @param {any} fk Foreign key for announcementGroup
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdAnnouncementGroup(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementGroup/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for announcementGroup.
   *
   * @param {any} id PersistedModel id
   *
   * @param {any} fk Foreign key for announcementGroup
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public updateByIdAnnouncementGroup(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementGroup/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Find a related item by id for announcementChallenge.
   *
   * @param {any} id PersistedModel id
   *
   * @param {any} fk Foreign key for announcementChallenge
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public findByIdAnnouncementChallenge(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementChallenge/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for announcementChallenge.
   *
   * @param {any} id PersistedModel id
   *
   * @param {any} fk Foreign key for announcementChallenge
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdAnnouncementChallenge(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementChallenge/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for announcementChallenge.
   *
   * @param {any} id PersistedModel id
   *
   * @param {any} fk Foreign key for announcementChallenge
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public updateByIdAnnouncementChallenge(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementChallenge/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Find a related item by id for translation.
   *
   * @param {any} id PersistedModel id
   *
   * @param {any} fk Foreign key for translation
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public findByIdTranslation(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/translation/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Delete a related item by id for translation.
   *
   * @param {any} id PersistedModel id
   *
   * @param {any} fk Foreign key for translation
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public destroyByIdTranslation(id: any, fk: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/translation/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Update a related item by id for translation.
   *
   * @param {any} id PersistedModel id
   *
   * @param {any} fk Foreign key for translation
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public updateByIdTranslation(id: any, fk: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PUT";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/translation/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries announcementGroup of Announcement.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public getAnnouncementGroup(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementGroup";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in announcementGroup of this model.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public createAnnouncementGroup(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementGroup";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all announcementGroup of this model.
   *
   * @param {any} id PersistedModel id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteAnnouncementGroup(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementGroup";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts announcementGroup of Announcement.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countAnnouncementGroup(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementGroup/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries announcementChallenge of Announcement.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public getAnnouncementChallenge(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementChallenge";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in announcementChallenge of this model.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public createAnnouncementChallenge(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementChallenge";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all announcementChallenge of this model.
   *
   * @param {any} id PersistedModel id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteAnnouncementChallenge(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementChallenge";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts announcementChallenge of Announcement.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countAnnouncementChallenge(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementChallenge/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Queries translation of Announcement.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} filter 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public getTranslation(id: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/translation";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof filter !== 'undefined' && filter !== null) _urlParams.filter = filter;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in translation of this model.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public createTranslation(id: any, data: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/translation";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Deletes all translation of this model.
   *
   * @param {any} id PersistedModel id
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * This method returns no data.
   */
  public deleteTranslation(id: any, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/translation";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Counts translation of Announcement.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} where Criteria to match model instances
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * Data properties:
   *
   *  - `count` – `{number}` - 
   */
  public countTranslation(id: any, where: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/translation/count";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof where !== 'undefined' && where !== null) _urlParams.where = where;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch an existing model instance or insert a new one into the data source.
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - Model instance data
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public patchOrCreate(data: any = {}, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Patch attributes for a model instance and persist it into the data source.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - An object of model property name/value pairs
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public patchAttributes(id: any, data: any = {}, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} languageCode Translation language code
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public getMyAnnouncements(languageCode: String = new String(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/my";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof languageCode !== 'undefined' && languageCode !== null) _urlParams.languageCode = languageCode;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} skip How many entries to skip (Number, used in pagination)
   *
   * @param {number} limit How many entries to take (Number, used in pagination)
   *
   * @param {string} orderBy Field to sort with (String, default: 'id')
   *
   * @param {string} direction Direction to sort with (String, default: 'DESC')
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public getAnnouncementsForUser(skip: Number = new Number(), limit: Number = new Number(), orderBy: String = new String(), direction: String = new String(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/own";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof skip !== 'undefined' && skip !== null) _urlParams.skip = skip;
    if (typeof limit !== 'undefined' && limit !== null) _urlParams.limit = limit;
    if (typeof orderBy !== 'undefined' && orderBy !== null) _urlParams.orderBy = orderBy;
    if (typeof direction !== 'undefined' && direction !== null) _urlParams.direction = direction;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `title` – `{string}` - 
   *
   *  - `body` – `{string}` - 
   *
   *  - `status` – `{string}` - 
   *
   *  - `classroomIds` – `{any}` - 
   *
   *  - `targetGroupIds` – `{any}` - 
   *
   *  - `challengeIds` – `{any}` - 
   *
   *  - `startDate` – `{string}` - 
   *
   *  - `endDate` – `{string}` - 
   *
   *  - `language` – `{string}` - 
   *
   *  - `bodyImageUrl` – `{string}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public createAnnouncement(title: String = new String(), body: String = new String(), status: String, classroomIds: any[] = [], targetGroupIds: any[] = [], challengeIds: any[], startDate: String = new String(), endDate: String = new String(), language: String, bodyImageUrl: String = new String(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/create";
    let _routeParams: any = {};
    let _postBody: any = {
      data: {
        title: title,
        body: body,
        status: status,
        classroomIds: classroomIds,
        targetGroupIds: targetGroupIds,
        challengeIds: challengeIds,
        startDate: startDate,
        endDate: endDate,
        language: language,
        bodyImageUrl: bodyImageUrl
      }
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `title` – `{string}` - 
   *
   *  - `body` – `{string}` - 
   *
   *  - `status` – `{string}` - 
   *
   *  - `classroomIds` – `{any}` - 
   *
   *  - `targetGroupIds` – `{any}` - 
   *
   *  - `challengeIds` – `{any}` - 
   *
   *  - `startDate` – `{string}` - 
   *
   *  - `endDate` – `{string}` - 
   *
   *  - `bodyImageUrl` – `{string}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public editAnnouncement(id: Number, title: String = new String(), body: String = new String(), status: String = new String(), classroomIds: any[] = [], targetGroupIds: any[] = [], challengeIds: any[] = [], startDate: String = new String(), endDate: String = new String(), bodyImageUrl: String = new String(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/update";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: {
        title: title,
        body: body,
        status: status,
        classroomIds: classroomIds,
        targetGroupIds: targetGroupIds,
        challengeIds: challengeIds,
        startDate: startDate,
        endDate: endDate,
        bodyImageUrl: bodyImageUrl
      }
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} entityId 
   *
   * @param {string} search Search in title or body
   *
   * @param {string} status Announcement status, one of three (PUBLISHED, DRAFT, ARCHIVED)
   *
   * @param {boolean} onlyCurrentLevel Get announcements only for passed entityId
   *
   * @param {number} skip How many entries to skip (Number, used in pagination)
   *
   * @param {number} limit How many entries to take (Number, used in pagination)
   *
   * @param {string} orderBy Field to sort with (String, default: 'id')
   *
   * @param {string} direction Direction to sort with (String, default: 'DESC')
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public getAnnouncementsForSender(entityId: Number, search: String = new String(), status: String, onlyCurrentLevel: Boolean = new Boolean(), skip: Number = new Number(), limit: Number = new Number(), orderBy: String = new String(), direction: String = new String(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/sender";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof entityId !== 'undefined' && entityId !== null) _urlParams.entityId = entityId;
    if (typeof search !== 'undefined' && search !== null) _urlParams.search = search;
    if (typeof status !== 'undefined' && status !== null) _urlParams.status = status;
    if (typeof onlyCurrentLevel !== 'undefined' && onlyCurrentLevel !== null) _urlParams.onlyCurrentLevel = onlyCurrentLevel;
    if (typeof skip !== 'undefined' && skip !== null) _urlParams.skip = skip;
    if (typeof limit !== 'undefined' && limit !== null) _urlParams.limit = limit;
    if (typeof orderBy !== 'undefined' && orderBy !== null) _urlParams.orderBy = orderBy;
    if (typeof direction !== 'undefined' && direction !== null) _urlParams.direction = direction;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public getAnnouncementById(id: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/get/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} announcementId 
   *
   * @param {object} data Request data.
   *
   *  - `title` – `{string}` - 
   *
   *  - `body` – `{string}` - 
   *
   *  - `languageCode` – `{string}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public addTranslation(announcementId: Number, title: String, body: String, languageCode: String, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:announcementId/translation";
    let _routeParams: any = {
      announcementId: announcementId
    };
    let _postBody: any = {
      data: {
        title: title,
        body: body
      }
    };
    let _urlParams: any = {};
    if (typeof languageCode !== 'undefined' && languageCode !== null) _urlParams.languageCode = languageCode;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} translationId 
   *
   * @param {object} data Request data.
   *
   *  - `title` – `{string}` - 
   *
   *  - `body` – `{string}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public editTranslation(translationId: Number, title: String, body: String, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:translationId/translation";
    let _routeParams: any = {
      translationId: translationId
    };
    let _postBody: any = {
      data: {
        title: title,
        body: body
      }
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} translationId 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public removeTranslation(translationId: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:translationId/translation";
    let _routeParams: any = {
      translationId: translationId
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} announcementId 
   *
   * @param {object} data Request data.
   *
   *  - `title` – `{string}` - 
   *
   *  - `body` – `{string}` - 
   *
   *  - `languageCode` – `{string}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public upsertTranslation(announcementId: Number, title: String = new String(), body: String = new String(), languageCode: String, options: any = {}, customHeaders?: Function): Observable<Announcement> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:announcementId/translation/upsert";
    let _routeParams: any = {
      announcementId: announcementId
    };
    let _postBody: any = {
      data: {
        title: title,
        body: body,
        languageCode: languageCode
      }
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result.pipe(map((instance: Announcement) => new Announcement(instance)));
  }

  /**
   * Creates a new instance in announcementGroup of this model.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public createManyAnnouncementGroup(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementGroup";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in announcementChallenge of this model.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public createManyAnnouncementChallenge(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/announcementChallenge";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * Creates a new instance in translation of this model.
   *
   * @param {any} id PersistedModel id
   *
   * @param {object} data Request data.
   *
   * This method expects a subset of model properties as request parameters.
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `Announcement` object.)
   * </em>
   */
  public createManyTranslation(id: any, data: any[] = [], customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/Announcements/:id/translation";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Announcement`.
   */
  public getModelName() {
    return "Announcement";
  }
}
