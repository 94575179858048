/* tslint:disable */
import {
  Challenge,
  MilestoneType,
  UserAccountMilestone
} from '../index';

declare var Object: any;
export interface MilestoneInterface {
  "name"?: string;
  "milestoneTypeId": number;
  "challengeId"?: number;
  "timelineId"?: number;
  "displayName"?: string;
  "description"?: any;
  "code"?: string;
  "order"?: number;
  "data": any;
  "status"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  challengeMilestone?: Challenge;
  type?: MilestoneType;
  userMilestone?: UserAccountMilestone[];
}

export class Milestone implements MilestoneInterface {
  "name": string;
  "milestoneTypeId": number;
  "challengeId": number;
  "timelineId": number;
  "displayName": string;
  "description": any;
  "code": string;
  "order": number;
  "data": any;
  "status": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  challengeMilestone: Challenge;
  type: MilestoneType;
  userMilestone: UserAccountMilestone[];
  constructor(data?: MilestoneInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Milestone`.
   */
  public static getModelName() {
    return "Milestone";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Milestone for dynamic purposes.
  **/
  public static factory(data: MilestoneInterface): Milestone{
    return new Milestone(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Milestone',
      plural: 'Milestones',
      path: 'Milestones',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "milestoneTypeId": {
          name: 'milestoneTypeId',
          type: 'number'
        },
        "challengeId": {
          name: 'challengeId',
          type: 'number',
          default: 0
        },
        "timelineId": {
          name: 'timelineId',
          type: 'number',
          default: 0
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'any'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'string',
          default: 'DRAFT'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        challengeMilestone: {
          name: 'challengeMilestone',
          type: 'Challenge',
          model: 'Challenge',
          relationType: 'belongsTo',
                  keyFrom: 'challengeId',
          keyTo: 'id'
        },
        type: {
          name: 'type',
          type: 'MilestoneType',
          model: 'MilestoneType',
          relationType: 'belongsTo',
                  keyFrom: 'milestoneTypeId',
          keyTo: 'id'
        },
        userMilestone: {
          name: 'userMilestone',
          type: 'UserAccountMilestone[]',
          model: 'UserAccountMilestone',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'milestoneId'
        },
      }
    }
  }
}
