/* tslint:disable */
import {
  UserAccountItem,
  ItemType
} from '../index';

declare var Object: any;
export interface ItemInterface {
  "itemTypeId": number;
  "objectTypeId": number;
  "objectId": number;
  "name"?: string;
  "displayName"?: string;
  "description"?: string;
  "code"?: string;
  "active": boolean;
  "strings"?: string;
  "order"?: number;
  "cost"?: number;
  "currencyTypeId"?: number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  userItem?: UserAccountItem[];
  itemType?: ItemType;
  children?: Item[];
  parents?: Item[];
}

export class Item implements ItemInterface {
  "itemTypeId": number;
  "objectTypeId": number;
  "objectId": number;
  "name": string;
  "displayName": string;
  "description": string;
  "code": string;
  "active": boolean;
  "strings": string;
  "order": number;
  "cost": number;
  "currencyTypeId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  userItem: UserAccountItem[];
  itemType: ItemType;
  children: Item[];
  parents: Item[];
  constructor(data?: ItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Item`.
   */
  public static getModelName() {
    return "Item";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Item for dynamic purposes.
  **/
  public static factory(data: ItemInterface): Item{
    return new Item(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Item',
      plural: 'Items',
      path: 'Items',
      idName: 'id',
      properties: {
        "itemTypeId": {
          name: 'itemTypeId',
          type: 'number'
        },
        "objectTypeId": {
          name: 'objectTypeId',
          type: 'number'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "strings": {
          name: 'strings',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number',
          default: 0
        },
        "cost": {
          name: 'cost',
          type: 'number'
        },
        "currencyTypeId": {
          name: 'currencyTypeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        userItem: {
          name: 'userItem',
          type: 'UserAccountItem[]',
          model: 'UserAccountItem',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'itemId'
        },
        itemType: {
          name: 'itemType',
          type: 'ItemType',
          model: 'ItemType',
          relationType: 'belongsTo',
                  keyFrom: 'itemTypeId',
          keyTo: 'id'
        },
        children: {
          name: 'children',
          type: 'Item[]',
          model: 'Item',
          relationType: 'hasMany',
          modelThrough: 'ItemRelation',
          keyThrough: 'childItemId',
          keyFrom: 'id',
          keyTo: 'parentItemId'
        },
        parents: {
          name: 'parents',
          type: 'Item[]',
          model: 'Item',
          relationType: 'hasMany',
          modelThrough: 'ItemRelation',
          keyThrough: 'parentItemId',
          keyFrom: 'id',
          keyTo: 'childItemId'
        },
      }
    }
  }
}
