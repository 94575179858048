/* tslint:disable */
import {
  Lesson,
  Machine
} from '../index';

declare var Object: any;
export interface LessonMachineInterface {
  "lessonId": number;
  "machineId": number;
  "order"?: number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "valueMultiplier": number;
  "externalThumbnailUrl"?: string;
  "difficultyLevel"?: number;
  "disableSkip"?: boolean;
  "id"?: number;
  lesson?: Lesson;
  machine?: Machine;
}

export class LessonMachine implements LessonMachineInterface {
  "lessonId": number;
  "machineId": number;
  "order": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "valueMultiplier": number;
  "externalThumbnailUrl": string;
  "difficultyLevel": number;
  "disableSkip": boolean;
  "id": number;
  lesson: Lesson;
  machine: Machine;
  constructor(data?: LessonMachineInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `LessonMachine`.
   */
  public static getModelName() {
    return "LessonMachine";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of LessonMachine for dynamic purposes.
  **/
  public static factory(data: LessonMachineInterface): LessonMachine{
    return new LessonMachine(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LessonMachine',
      plural: 'LessonMachines',
      path: 'LessonMachines',
      idName: 'id',
      properties: {
        "lessonId": {
          name: 'lessonId',
          type: 'number'
        },
        "machineId": {
          name: 'machineId',
          type: 'number'
        },
        "order": {
          name: 'order',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "valueMultiplier": {
          name: 'valueMultiplier',
          type: 'number',
          default: 1
        },
        "externalThumbnailUrl": {
          name: 'externalThumbnailUrl',
          type: 'string'
        },
        "difficultyLevel": {
          name: 'difficultyLevel',
          type: 'number'
        },
        "disableSkip": {
          name: 'disableSkip',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        lesson: {
          name: 'lesson',
          type: 'Lesson',
          model: 'Lesson',
          relationType: 'belongsTo',
                  keyFrom: 'lessonId',
          keyTo: 'id'
        },
        machine: {
          name: 'machine',
          type: 'Machine',
          model: 'Machine',
          relationType: 'belongsTo',
                  keyFrom: 'machineId',
          keyTo: 'id'
        },
      }
    }
  }
}
