<button
  class="flex items-center justify-center w-full"
  [ngClass]="{
    'button-base': !color.includes('plain'),
    'text-mulberry-550': isDisabled,
    'bg-mulberry-400': isDisabled && color != 'plain',
    'mango-button': color == 'mango' && !isDisabled,
    'shadow-mango active:translate-y-0': color == 'mango' && elevate,
    'mulberry-button bg-white bg-opacity-50 hover:bg-opacity-100 hover:bg-white hover:text-mulberry-700 shadow-none hover:shadow-mulberry active:shadow-none':
      color == 'white' && !isDisabled,
    'button-mulberry': color == 'mulberry' && !isDisabled,
    'button-kiwi': color == 'kiwi' && !isDisabled,
    'bg-mulberry-500': color == 'mulberry-dark',
    'plum-button': color == 'plum' && !isDisabled,
    'bg-cherry-500': color == 'cherry' && !isDisabled,
    'text-base px-4 py-1.5': size == 'sm',
    'text-lg px-6 py-2': size == 'md',
    'text-xl py-3 px-8': size == 'lg',
    'text-white': color === 'mango-plain' || color === 'cherry',
    'bg-mango-500': color === 'mango-plain',
    'hover:bg-mango-550': !isDisabled && color === 'mango-plain',
    'text-opacity-20': isDisabled && color === 'mango-plain',
    'rounded-md': color === 'mango-plain',
    relative: color === 'mango-plain'
  }"
  [disabled]="isDisabled"
  [class]="classes"
  (click)="openLink(externalLink)"
>
  <div
    class="w-full whitespace-nowrap"
    [ngClass]="{
      'opacity-50': loading
    }"
  >
    <ng-content></ng-content>
  </div>

  <div *ngIf="loading" class="absolute inset-0 flex items-center justify-center text-mulberry-800">
    <svg class="w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
  <div *ngIf="success" class="absolute inset-0 flex items-center justify-center text-mulberry-800">
    <img class="w-16" src="assets/img/color/check.svg" />
  </div>
</button>
