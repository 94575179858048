import { Component, OnInit } from '@angular/core';
import { SITEMAP } from 'src/utilities/sitemap';

@Component({
  selector: 'lo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  numbers = Array(15).fill(0);
  SITEMAP = SITEMAP;

  year = new Date().getFullYear();
  links = [
    {
      name: 'About LetsMod',
      url: SITEMAP.ABOUT,
    },
    {
      name: 'Contact',
      url: SITEMAP.CONTACT,
    },
    {
      name: 'Privacy Policy',
      url: SITEMAP.PRIVACY,
    },
    {
      name: 'Terms of Service',
      url: SITEMAP.TERMS,
    },
    {
      name: 'Educators',
      url: SITEMAP.EDUCATORS_LANDING,
    },
  ];

  socials = [
    {
      name: 'Discord',
      favicon: 'fab fa-discord',
      url: 'https://discord.com/invite/9sWVaEUFk5',
    },
    // {
    //   name: 'YouTube',
    //   favicon: 'fab fa-youtube',
    //   url: 'https://www.youtube.com/channel/UCuLQYBetUCuE_9LcO880BXg',
    // },
    // {
    //   name: 'Twitter',
    //   favicon: 'fab fa-twitter',
    //   url: 'https://twitter.com/letsmodtogether',
    // },
    // {
    //   name: 'Facebook',
    //   favicon: 'fab fa-facebook',
    //   url: 'https://www.facebook.com/letsmodtogether',
    // },
    // {
    //   name: 'Instagram',
    //   favicon: 'fab fa-instagram',
    //   url: 'https://www.instagram.com/letsmodtogether',
    // },
  ];

  constructor() {}
  emailSubmitted: boolean = false;
  email: string = '';
  ngOnInit(): void {
    console.log('footer found');
  }
  submitEmail() {
    this.emailSubmitted = true;
  }
}
