import { Injectable } from '@angular/core';
import { BehaviorSubject, PartialObserver, Subject } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private subject = new BehaviorSubject([]);
  constructor(private $localStorage: LocalStorageService) {}

  subscribe(observer) {
    return this.subject.subscribe(observer);
  }

  add(banner: Banner, append = true) {
    if (this.isClosed(banner)) {
      return;
    }
    if (append) {
      this.subject.next([...this.subject.getValue(), banner]);
    } else {
      this.subject.next([banner, ...this.subject.getValue()]);
    }
  }

  addOnce(banner: Banner, append = true) {
    if (this.isClosed(banner)) {
      return;
    }
    this.remove(banner.key);

    if (append) {
      this.subject.next([...this.subject.getValue(), banner]);
    } else {
      this.subject.next([banner, ...this.subject.getValue()]);
    }
  }

  upsert(banner: Banner) {
    if (this.isClosed(banner)) {
      return;
    }
    if (this.has(banner.key)) {
      this.update(banner.key, () => banner);
    } else {
      this.add(banner);
    }
  }

  remove(key: string) {
    this.subject.next(this.subject.getValue().filter((banner) => banner.key !== key));
  }

  has(key: string) {
    return this.subject.getValue().some((banner) => banner.key === key);
  }

  update(key: string, callback: (banner: Banner) => Banner) {
    this.subject.next(
      this.subject.getValue().map((banner) => {
        if (banner.key === key) {
          return callback(banner);
        }
        return banner;
      })
    );
  }

  close(key: string) {
    this.remove(key);
    this.$localStorage.setToObject('banners', key, {
      closed: true,
      time: Date.now(),
    });
  }

  isClosed(banner: Banner) {
    const fromStorage = this.$localStorage.getFromObject('banners', banner.key);

    if (!fromStorage) {
      return false;
    }

    if (fromStorage.closed && fromStorage.time) {
      const deletedSinceInMinutes = (Date.now() - fromStorage.time) / 1000 / 60;

      const closeTimeout = banner.closeTimeout === undefined ? 1 : banner.closeTimeout;
      
      return deletedSinceInMinutes < closeTimeout;
    }

    return false;
  }
}
