/* tslint:disable */
import {
  ActivityType
} from '../index';

declare var Object: any;
export interface ActivityLogInterface {
  "createdAt": Date;
  "createdAtTs"?: number;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "data"?: string;
  "sourceId"?: number;
  "userAccountId"?: number;
  "eventId"?: number;
  "deviceId"?: string;
  "appSessionId"?: string;
  "machineId"?: number;
  "machineBoardId"?: number;
  "boardChipId"?: number;
  "id"?: number;
  "activityTypeId"?: number;
  activityType?: ActivityType;
}

export class ActivityLog implements ActivityLogInterface {
  "createdAt": Date;
  "createdAtTs": number;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "data": string;
  "sourceId": number;
  "userAccountId": number;
  "eventId": number;
  "deviceId": string;
  "appSessionId": string;
  "machineId": number;
  "machineBoardId": number;
  "boardChipId": number;
  "id": number;
  "activityTypeId": number;
  activityType: ActivityType;
  constructor(data?: ActivityLogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ActivityLog`.
   */
  public static getModelName() {
    return "ActivityLog";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ActivityLog for dynamic purposes.
  **/
  public static factory(data: ActivityLogInterface): ActivityLog{
    return new ActivityLog(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ActivityLog',
      plural: 'ActivityLogs',
      path: 'ActivityLogs',
      idName: 'id',
      properties: {
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdAtTs": {
          name: 'createdAtTs',
          type: 'number'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "sourceId": {
          name: 'sourceId',
          type: 'number'
        },
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "eventId": {
          name: 'eventId',
          type: 'number'
        },
        "deviceId": {
          name: 'deviceId',
          type: 'string'
        },
        "appSessionId": {
          name: 'appSessionId',
          type: 'string'
        },
        "machineId": {
          name: 'machineId',
          type: 'number'
        },
        "machineBoardId": {
          name: 'machineBoardId',
          type: 'number'
        },
        "boardChipId": {
          name: 'boardChipId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "activityTypeId": {
          name: 'activityTypeId',
          type: 'number'
        },
      },
      relations: {
        activityType: {
          name: 'activityType',
          type: 'ActivityType',
          model: 'ActivityType',
          relationType: 'belongsTo',
                  keyFrom: 'activityTypeId',
          keyTo: 'id'
        },
      }
    }
  }
}
