import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ui-text-input',
  templateUrl: './text-input.component.html',
})
export class TextInputComponent implements OnInit {
  constructor() {}

  @Input() value: string | number = '';
  @Input() color: string;
  @Input() error: boolean | string;
  @Input() success: boolean | string;
  @Input() warning: boolean | string;
  @Input() placeholder: string = '';
  @Input() classes: string;
  @Input() disabled: boolean;
  @Input() showSuccessIcon: boolean = true;
  @Input() type: string = 'text';
  @Input() id: string;
  @Input() blacklist: string[];
  @Input() autocomplete: string = '';
  @Input() loading: boolean;
  @Input() readonly: boolean = false;
  @Input() inputClasses: string = '';
  @Input() maxLength: string | number;
  @Input() max: string | number;
  @Input() min: string | number;
  @Input() multiline: boolean;

  @Output() textChanged: EventEmitter<any> = new EventEmitter();
  @Output() enter: EventEmitter<any> = new EventEmitter();
  @Output() blur: EventEmitter<any> = new EventEmitter();

  @Input() setFocus?: Observable<void>;
  @Input() size?: 'md' | 'lg' = 'md';

  @ViewChild('input') input;

  ngOnInit(): void {
    if (this.setFocus) {
      this.setFocus.subscribe(() => {
        setTimeout(() => this.input.nativeElement.focus(), 0);
      });
    }
  }

  get isError(): boolean {
    return Boolean(this.error);
  }

  get isWarning(): boolean {
    return Boolean(this.warning);
  }

  get isSuccess(): boolean {
    return Boolean(this.success);
  }

  get errorMessage(): string {
    if (typeof this.error !== 'string') {
      return '';
    }
    return this.error;
  }

  get successMessage(): string {
    if (typeof this.success !== 'string') {
      return '';
    }
    return this.success;
  }

  get warningMessage(): string {
    if (typeof this.warning !== 'string') {
      return '';
    }
    return this.warning;
  }

  onTextChanged(value: string) {
    this.textChanged.emit(value);
  }

  keyDown($event) {
    if (this.blacklist && this.blacklist.includes($event.key)) {
      $event.preventDefault();
    }
    if ($event.keyCode == 13) {
      this.enter.emit();
    }
  }

  get showIcon() {
    return (this.isSuccess && this.showSuccessIcon) || this.loading;
  }

  get inputValue() {
    return this.value != null && this.value !== undefined ? this.value : '';
  }
}
