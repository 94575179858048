<div
  class="flex"
  [class.items-start]="contentAlign == alignEnum.start && isColumn"
  [class.items-center]="contentAlign == alignEnum.center"
  [class.text-center]="contentAlign == alignEnum.center && isColumn"
  [class.items-end]="contentAlign == alignEnum.end && isColumn"
  [class.justify-center]="isColumn"
  [class.flex-col]="isColumn"
  [class.justify-start]="contentAlign == alignEnum.start && !isColumn"
  [class.justify-end]="contentAlign == alignEnum.end && !isColumn"
>
  <img
    [src]="img"
    [ngClass]="{
      'w-14': size == sizeEnum.xs,
      'max-w-xs': size == sizeEnum.md,
      'mr-4': contentDirection === directionEnum.row,
      'mb-4': contentDirection === directionEnum.column
    }"
    *ngIf="img"
    class="max-w-xs"
  />
  <div>
    <div class="text-2xl font-light text-grape-500">
      {{ message }}
    </div>
    <div *ngIf="subMessage" class="font-light text-mulberry-600">
      {{ subMessage }}
    </div>
  </div>

  <div class="flex flex-wrap items-center mt-4" *ngIf="actions">
    <ui-button
      *ngFor="let action of actions"
      (click)="action.onClick()"
      [color]="action.color || 'mango'"
      [classes]="action.classes || ''"
      class="mr-2"
    >
      {{ action.title | transloco }}
    </ui-button>
  </div>
</div>
