import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoopBackAuth } from '../sdk';
import { of } from 'rxjs';

import { SITEMAP } from 'src/utilities/sitemap';

import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class MyPermissionsGuard implements CanActivate, CanActivateChild {
  constructor(
    protected $lbauth: LoopBackAuth,
    private $router: Router,
    private $ngxPermissionsService: NgxPermissionsService,
    private $myuser: UserService
  ) {}
  SITEMAP = SITEMAP;
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.can(next, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.can(childRoute, state);
  }

  can(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!route.data?.permissions?.only) return of(true);
    return from(this.$ngxPermissionsService.hasPermission(route.data?.permissions.only)).pipe(
      map((hasPermission) => {
        if (hasPermission) {
          return true;
        } else {
          this.$router.navigate([SITEMAP.HOME], { queryParams: { m: route.queryParams.m } });
        }
      })
    );
  }
}
