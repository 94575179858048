import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoopBackAuth } from '../sdk';
import { of } from 'rxjs';
import { SITEMAP } from 'src/utilities/sitemap';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class TeamJoinGuard implements CanActivate, CanActivateChild {
  constructor(
    protected $lbauth: LoopBackAuth,
    private $router: Router,
    private $ngxPermissionsService: NgxPermissionsService,
    private $myuser: UserService
  ) {}
  SITEMAP = SITEMAP;
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.can(next);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.can(childRoute);
  }

  can(next: ActivatedRouteSnapshot): boolean {
    if (this.$myuser.loggedIn()) {
      this.$router.navigate([SITEMAP.TEAMS], { queryParams: { teamInviteCode: next.paramMap.get('id') } });
    } else {
      this.$router.navigate(['/about'], {
        queryParams: { mode: 'signup', teamInviteCode: next.paramMap.get('id') },
      });
    }
    return true;
  }
}
