import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { simpleFade } from '../../../../../../assets/animations';

import { DropdownItem } from 'src/app/shared/types/dropdown-item';
import { windowCount } from 'rxjs/operators';
import { AUTO_STYLE } from '@angular/animations';
import { TranslocoService } from '@ngneat/transloco';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    simpleFade,
  ],
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input() items: DropdownItem[] = [];
  @Input() showTopPortion: boolean;
  @Input() coinCount: number;
  @Input() name?: string;
  @Input() positioning;
  @ViewChild(CdkPortal) portal: ElementRef;
  private nativeElement;
  @Output()
  close: EventEmitter<any> = new EventEmitter();
  left: string;
  top: string;
  right: string;
  bottom: string;
  myStyle: any = {};
  overlayRef: OverlayRef;

  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private eRef: ElementRef,
    private $translocoService: TranslocoService,
    private overlay: Overlay
  ) {}
  handleClick(event: MouseEvent) {
    this.closeDropdown();
  }

  clickOut(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.close.emit();
  }

  itemClicked($event: MouseEvent, item: string, close: boolean) {
    this.action.emit(item);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.nativeElement = this.eRef.nativeElement.parentElement;

    let positions;
    if (this.positioning == 'top-right-outer') {
      console.log('positining is outer');
      positions = [
        { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'top', offsetX: 10 },
        { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'top', offsetX: -10 },
        { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -20 },
        { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 10 },
      ];
    } else if (this.positioning == 'nav') {
      if (window.innerWidth >= 800) {
        if (
          document.getElementById('logged-in-container') &&
          document.getElementById('logged-in-container').classList.contains('rtl')
        ) {
          positions = [{ originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom', offsetY: -20 }];
        } else {
          positions = [{ originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom', offsetY: -20 }];
        }
      } else {
        if (
          document.getElementById('logged-in-container') &&
          document.getElementById('logged-in-container').classList.contains('rtl')
        ) {
          positions = [{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetX: 10 }];
        } else {
          positions = [{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetX: -10 }];
        }
      }
    } else {
      positions = [
        { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top', offsetX: 0 },
        { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'top', offsetX: -10 },
        { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -20 },
        { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 10 },
      ];
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.nativeElement)
      .withPositions(positions)
      .withGrowAfterOpen();
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: null,
    });
    this.overlayRef = overlayRef;
    overlayRef.attach(this.portal);
    overlayRef.backdropClick().subscribe(() => {
      this.closeDropdown();
    });
  }

  closeDropdown() {
    if (this.overlayRef && this.overlayRef.hasAttached) {
      this.overlayRef.detach();
    }
    this.overlayRef.dispose();
    this.close.emit();
  }

  ngOnDestroy() {
    this.closeDropdown();
  }
}
