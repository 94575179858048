/* tslint:disable */
import {
  Challenge,
  Entity
} from '../index';

declare var Object: any;
export interface EntityChallengeInterface {
  "entityId": number;
  "challengeId": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "inherited"?: boolean;
  "id"?: number;
  challenge?: Challenge;
  entity?: Entity;
}

export class EntityChallenge implements EntityChallengeInterface {
  "entityId": number;
  "challengeId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "inherited": boolean;
  "id": number;
  challenge: Challenge;
  entity: Entity;
  constructor(data?: EntityChallengeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EntityChallenge`.
   */
  public static getModelName() {
    return "EntityChallenge";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EntityChallenge for dynamic purposes.
  **/
  public static factory(data: EntityChallengeInterface): EntityChallenge{
    return new EntityChallenge(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EntityChallenge',
      plural: 'EntityChallenges',
      path: 'EntityChallenges',
      idName: 'id',
      properties: {
        "entityId": {
          name: 'entityId',
          type: 'number'
        },
        "challengeId": {
          name: 'challengeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "inherited": {
          name: 'inherited',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        challenge: {
          name: 'challenge',
          type: 'Challenge',
          model: 'Challenge',
          relationType: 'belongsTo',
                  keyFrom: 'challengeId',
          keyTo: 'id'
        },
        entity: {
          name: 'entity',
          type: 'Entity',
          model: 'Entity',
          relationType: 'belongsTo',
                  keyFrom: 'entityId',
          keyTo: 'id'
        },
      }
    }
  }
}
