/* tslint:disable */
import {
  UserAccountAssessment
} from '../index';

declare var Object: any;
export interface UserAccountAssessmentAnswerInterface {
  "userAccountAssessmentId": number;
  "questionCode": string;
  "questionVersion": number;
  "answerType": string;
  "answerValue": string;
  "savedTs"?: number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  user?: UserAccountAssessment;
}

export class UserAccountAssessmentAnswer implements UserAccountAssessmentAnswerInterface {
  "userAccountAssessmentId": number;
  "questionCode": string;
  "questionVersion": number;
  "answerType": string;
  "answerValue": string;
  "savedTs": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  user: UserAccountAssessment;
  constructor(data?: UserAccountAssessmentAnswerInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountAssessmentAnswer`.
   */
  public static getModelName() {
    return "UserAccountAssessmentAnswer";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountAssessmentAnswer for dynamic purposes.
  **/
  public static factory(data: UserAccountAssessmentAnswerInterface): UserAccountAssessmentAnswer{
    return new UserAccountAssessmentAnswer(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountAssessmentAnswer',
      plural: 'UserAccountAssessmentAnswers',
      path: 'UserAccountAssessmentAnswers',
      idName: 'id',
      properties: {
        "userAccountAssessmentId": {
          name: 'userAccountAssessmentId',
          type: 'number'
        },
        "questionCode": {
          name: 'questionCode',
          type: 'string'
        },
        "questionVersion": {
          name: 'questionVersion',
          type: 'number'
        },
        "answerType": {
          name: 'answerType',
          type: 'string'
        },
        "answerValue": {
          name: 'answerValue',
          type: 'string'
        },
        "savedTs": {
          name: 'savedTs',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserAccountAssessment',
          model: 'UserAccountAssessment',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountAssessmentId',
          keyTo: 'id'
        },
      }
    }
  }
}
