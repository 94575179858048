/* tslint:disable */
import {
  Board,
  UserAccount
} from '../index';

declare var Object: any;
export interface BoardProgressInterface {
  "userAccountId": number;
  "boardId": number;
  "data"?: string;
  "sessionUuid"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "completed"?: boolean;
  "moddingData"?: any;
  "id"?: number;
  board?: Board;
  user?: UserAccount;
}

export class BoardProgress implements BoardProgressInterface {
  "userAccountId": number;
  "boardId": number;
  "data": string;
  "sessionUuid": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "completed": boolean;
  "moddingData": any;
  "id": number;
  board: Board;
  user: UserAccount;
  constructor(data?: BoardProgressInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BoardProgress`.
   */
  public static getModelName() {
    return "BoardProgress";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BoardProgress for dynamic purposes.
  **/
  public static factory(data: BoardProgressInterface): BoardProgress{
    return new BoardProgress(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BoardProgress',
      plural: 'BoardProgresses',
      path: 'BoardProgresses',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "boardId": {
          name: 'boardId',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "sessionUuid": {
          name: 'sessionUuid',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "completed": {
          name: 'completed',
          type: 'boolean',
          default: false
        },
        "moddingData": {
          name: 'moddingData',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        board: {
          name: 'board',
          type: 'Board',
          model: 'Board',
          relationType: 'belongsTo',
                  keyFrom: 'boardId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
      }
    }
  }
}
