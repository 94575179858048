<form [disabled]="forgotPasswordEmail.length === 0" *ngIf="forgotPass; else loginBlock" (submit)="resetPassword()">
  <div class="py-2 panel-bottom-stripe">
    <div class="signup-back" (click)="forgotPass = false"><i class="fas fa-arrow-left"></i></div>
    <img class="mx-auto icon lg hidden" src="assets/img/color/user-player.svg" />
    <div class="text-center header mar-md-bottom">{{ "forgotPassword" | transloco }}</div>
  </div>

  <div class="flex flex-col justify-center p-7 sm:px-20">
    <div class="mb-4 text-center">{{ "forgotPassword.description" | transloco }}</div>

    <ui-alert classes="mb-4" type="error" *ngIf="resetError" (click)="signupInstead()">
      {{ resetError | transloco }}
    </ui-alert>

    <ui-alert classes="mb-4" *ngIf="resetSucceeded" type="success">{{ "forgotPassword.success" | transloco }}</ui-alert>

    <ui-text-input (textChanged)="forgotPasswordEmail = $event" [value]="forgotPasswordEmail"
      [placeholder]="'emailPlaceholder' | transloco" type="email">
    </ui-text-input>

    <ui-button [loading]="isSendingPasswordInstructions" classes="mt-4" [disabled]="forgotPasswordEmail.length === 0"
      (click)="resetPassword()">
      {{ "submit" | transloco }}
    </ui-button>
  </div>
</form>

<ng-template #loginBlock>
  <div class="signup-back" (click)="goHome()"><i class="fas fa-arrow-left"></i></div>
  <img class="mx-auto icon lg hidden" src="assets/img/color/user-player.svg" />
  <div class="header mar-md-bottom" *ngIf="this.challengeCode" [innerHTML]="this.challengeInviteString"></div>
  <div class="text-center header mar-md-bottom" *ngIf="!this.challengeCode">{{ "logIn" | transloco }}</div>
  <div class="separator light"></div>
  <form (submit)="login($event)" class="flex flex-col justify-center p-7 sm:px-20">
    <ui-text-input type="text" (textChanged)="email = $event" [placeholder]="'emailUsernamePlaceholder' | transloco"
      classes="mb-2" [error]="errors.email | transloco"></ui-text-input>

    <ui-text-input type="password" (textChanged)="password = $event" [placeholder]="'passwordPlaceholder' | transloco"
      classes="mb-2" [error]="errors.password | transloco"></ui-text-input>

    <div class="mar-md-bottom">
      <a class="light right-align" (click)="forgotPass = true">{{ "forgotPasswordQuestion" | transloco }} </a>
    </div>

    <ui-alert classes="mb-4" type="error" *ngIf="errorText">
      {{ errorText | transloco }}
    </ui-alert>

    <div *ngIf="resendVerificationButton" class="flex-center pad-md-bottom">
      <a *ngIf="!resendVerifyMsg" (click)="resendVerification()">{{ "login.resendVerification" | transloco }}</a>
      <div>{{ resendVerifyMsg | transloco }}</div>
    </div>
    <ui-button [loading]="isLogging" (click)="login($event)">{{ "logIn" | transloco }}</ui-button>
    <div class="hidden">
      <span class="inline-block no-account">&nbsp;{{ "dontHaveAccount" | transloco }}&nbsp;</span>
      <a class="inline-block" (click)="signupInstead()">{{ "signUp" | transloco }}</a>
    </div>
  </form>
  <div class="flex flex-col items-center justify-center py-10 border-t border-mulberry-550 sm:px-20">
    <div class="pad-md-bottom subtle px-6">{{ "signInExternal" | transloco }}</div>
    <button class="
        flex
        items-center
        py-1.5
        px-3
        font-medium
        rounded-lg
        transition
        duration-150
        ease-in
        ring-0 ring-inset
        hover:-translate-y-2
        transform
        focus:outline-none
        active:translate-y-0
        bg-mulberry-450
        text-mulberry-800
        hover:shadow-mulberry
        focus:ring-2 focus:ring-mulberry-600
        active:shadow-none
        shadow-mulberry-light
      " (click)="signInWithGoogle()">
      <div *ngIf="googleLoading" class="icon sm mar-sm-right">
        <mat-spinner [diameter]="30"></mat-spinner>
      </div>
      <img *ngIf="!googleLoading" class="icon sm mar-sm-right" src="assets/img/icons8-google.svg" />
      <div class="subtle">{{ "signInWithGoogle" | transloco }}</div>
    </button>

    <ui-alert classes="mt-4" type="error" *ngIf="googleError">
      {{ googleError | transloco }}
    </ui-alert>
  </div>
</ng-template>