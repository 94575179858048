import { Injectable } from '@angular/core';

import {
  ChallengeApi,
  ChallengeTimeline,
  Lesson,
  V3Api,
  UserAccountChallengeApi,
  UserAccountChallenge,
  Challenge,
  Machine,
} from '../../shared/sdk';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { UserService } from '../../shared/services/user.service';
import { PlayerCommunicationsService, PlayerEvents } from 'src/app/shared/services/player-communications.service';

const LESSON_ORDER = ['xp.cs', 'xp.robotics', 'xp.math', 'xp.fun', null];
interface LessonInTrack extends Lesson {
  nextLesson?: Lesson;
  previousLesson?: LessonInTrack[];
  machines: UserMachine[];
}
export interface ChallengeBrandingDeadline {
  displayName: string;
  displayText: string;

  startAt: string;
  endAt: string;

  defaultScholarshipCode: string;
}

type PlayerIncomingEvent = {
  type: PlayerEvents;
  machineId: number;
  lessonId: number;
  xpModifier?: string;
};

interface Tracks {
  [key: string]: LessonInTrack[];
}

interface LessonSlides {
  current: number;
  isBeginning: boolean;
  isEnd: boolean;
  total: number;

  start: boolean;
  started: boolean;
  machines: boolean;
  completed: boolean;
  ended: boolean;
}
export interface LessonWithMeta {
  lesson: LessonInTrack;
  meta: {
    canStart: boolean;
    requireUpgrade: boolean;
    isCurrent: boolean;
    isNext: boolean;
    isPrevious: boolean;
    requireIntroFinish: boolean;
    isLoading: boolean;
    hasMachines: boolean;
    isLast: boolean;
    hasNext: boolean;
    isCompleted: boolean;
    numOfCompleted: number;
    numOfSkipped: number;
    isResolved: boolean;
    isStarting: boolean;
    slides: LessonSlides;
  };
}

interface TracksMetaTable {
  [xpModifier: string]: TrackMeta;
}

interface LessonsTable {
  [key: number]: LessonWithMeta;
}
export interface TrackMeta {
  currentLessonIndex: number;
  selectedLessonIndex: number;
  xpModifier: string;
}
export interface LessonsWithMeta {
  lessons: LessonWithMeta[];
  meta: TrackMeta;
}
interface UserMachine extends Machine {
  completionStatus: number;
  isCurrent: 0 | 1;
}
@Injectable({
  providedIn: 'root',
})
export class ChallengeService {
  challenge: any;
  machinesByTimeline: any;
  machinesSubj: ReplaySubject<any> = new ReplaySubject<any>(1);
  metadata: any;
  challengeId: any;
  metadataSubj: ReplaySubject<any> = new ReplaySubject<any>(1);
  xpSubj: ReplaySubject<any> = new ReplaySubject<any>(1);

  activeTimeline: ReplaySubject<any> = new ReplaySubject<any>(1);
  timelines: BehaviorSubject<any> = new BehaviorSubject<any[]>([]);

  activeUserChallenge$: Observable<UserAccountChallenge>;
  //teamCompletionSubj: ReplaySubject<any> = new ReplaySubject<any>(1);
  userChallenge: UserAccountChallenge;
  teamCompletion: any;

  currId: number;
  currGrade: number;

  public canAccess: boolean;
  lessonsTable: LessonsTable = {};

  lastGameActivity: any;

  recievedEvents: PlayerIncomingEvent[] = [];

  allTracks: Tracks = {};

  tracksMetaTable: TracksMetaTable = {};

  machines: {
    [key: number]: UserMachine;
  } = {};

  constructor(
    private $v3: V3Api,
    private $myuser: UserService,
    private $challenges: ChallengeApi,
    private $player: PlayerCommunicationsService,
    private $userChallenge: UserAccountChallengeApi
  ) {
    this.onPlayerEvent = this.onPlayerEvent.bind(this);
    this.$player.subscribe(this.onPlayerEvent);
  }

  onPlayerEvent(event: PlayerIncomingEvent) {
    this.recievedEvents.push(event);
    const mark = (lessonId: number, machineId: number, status: number) => {
      if (!this.challenge) {
        return;
      }

      this.machines[machineId].completionStatus = status;

      this.refreshTracks();
    };

    const goEndIndex = (lessonId: number) => {
      this.lessonsTable[lessonId] = {
        ...this.lessonsTable[lessonId],
        meta: {
          ...this.lessonsTable[lessonId].meta,
          slides: {
            ...this.lessonsTable[lessonId].meta.slides,
            current: this.lessonsTable[lessonId].meta.slides.total,
          },
        },
      };
      this.refreshTracks();
    };

    const switchMachine = (lessonId: number, machineId: number) => {
      if (!this.lessonsTable[lessonId]) {
        return;
      }
      const toIndex = this.lessonsTable[lessonId].lesson.machines.findIndex((machine) => machineId === machine.id);

      this.lessonsTable[lessonId] = {
        ...this.lessonsTable[lessonId],
        meta: {
          ...this.lessonsTable[lessonId].meta,
          slides: {
            ...this.lessonsTable[lessonId].meta.slides,
            current: toIndex + 1,
          },
        },
      };

      this.refreshTracks();
    };

    switch (event.type) {
      case PlayerEvents.MACHINE_COMPLETED:
        this.setLastActivity(() => mark(event.lessonId, event.machineId, 2));
        break;

      case PlayerEvents.MACHINE_SKIPPED:
        this.setLastActivity(() => mark(event.lessonId, event.machineId, 3));
        break;

      case PlayerEvents.LESSON_END:
        this.setLastActivity(() => goEndIndex(event.lessonId));
        break;

      case PlayerEvents.LESSON_SWITCH_MACHINE:
      case PlayerEvents.MACHINE_SWITCHED:
        if (event.lessonId) {
          this.setLastActivity(() => switchMachine(event.lessonId, event.machineId));
        }
        break;

      case PlayerEvents.LESSON_SWITCH:
        if (this.lastGameActivity) {
          this.lastGameActivity();
        }

        this.fetchChallengeTracks();

        break;

      case PlayerEvents.MACHINE_CLOSE:
        if (this.recievedEvents.find((event) => event.type === PlayerEvents.MACHINE_COMPLETED)) {
          this.fetchChallengeTracks(true);
        } else if (this.lastGameActivity) {
          setTimeout(() => {
            this.lastGameActivity();
          }, 400);
        }
        this.recievedEvents = [];
        break;

      default:
        console.log('unkown message');
    }
  }
  setLastActivity(lastActivity: () => void) {
    if (this.lastGameActivity) {
      this.lastGameActivity();
    }
    this.lastGameActivity = lastActivity;
  }

  getFullChallenge(id: number) {
    this.checkReload(id);
    return this.machinesSubj.asObservable();
  }

  getActiveTimeline() {
    return this.activeTimeline.asObservable();
  }

  getChallengeWeeks(id: number) {
    this.checkReload(id);
    return this.machinesSubj.asObservable().pipe(
      map((res) => {
        return res.map((week) => {
          var total = 0;
          var completed = 0;
          for (var lesson of week.lessons) {
            for (var machine of lesson.machines) {
              if (machine.shareId != 'new' && machine.userAccountId !== this.$myuser.id()) {
                total++;
                if (machine.completionStatus == 2) {
                  completed++;
                }
              }
            }
          }
          return {
            name: week.displayName,
            id: week.id,
            machinesSolved: completed,
            totalMachines: total,
            startTs: week.startTs,
            endTs: week.endTs,
          };
        });
      })
    );
  }

  checkReload(id: number) {
    if (id != this.currId || this.$myuser.getGrade() != this.currGrade) {
      this.loadFullChallenge(id);
      this.currId = id;
      this.currGrade = this.$myuser.getGrade();
    }
  }

  getTeamCompletion(challengeId: number, teamId: number) {
    if (!this.$myuser.isTeacher()) return;

    return this.$v3.getTeamChallengeMachines(teamId, challengeId).pipe(
      map((progressWeeks) => {
        if (Object.keys(progressWeeks).length === 0 && progressWeeks.constructor === Object) {
          return null;
        }
        return this.challenge.map((timeline) => {
          let machineTeamCompletions = [];
          let index = {};
          for (let lesson of timeline.lessons) {
            for (let machine of lesson.machines) {
              if (!index[machine.id]) {
                let a = { ...machine, teamCompletion: {} };
                machineTeamCompletions.push(a);
                index[a.id] = a;
              }
            }
          }
          for (var progressWeek of progressWeeks) {
            if (progressWeek.id == timeline.id) {
              for (var progress of progressWeek.details) {
                if (index[progress.machineId]) {
                  index[progress.machineId].teamCompletion[progress.userId] = {
                    status: progress.completionStatus,
                    time: progress.timeToComplete,
                  };
                }
              }
            }
          }

          return { ...timeline, lessons: undefined, machines: machineTeamCompletions };
        });
      })
    );
  }

  getXPs() {
    return this.xpSubj.asObservable();
  }

  loadFullChallenge(id: number) {
    this.$challenges
      .findById(id, {
        include: {
          relation: 'entity',
          scope: {
            limit: 1,
            order: 'createdAt DESC',
            where: {
              parentId: null,
            },
          },
        },
      })
      .subscribe((res: { [key: string]: any }) => {
        res.entity = res.entity[0];
        this.metadata = res;
        this.metadataSubj.next(this.metadata);
      });

    if (this.$myuser.isStudent()) {
      this.$v3.getChallengeTimelines(id).subscribe((timelines: ChallengeTimeline[]) => {
        let currDate = Date.now() / 1000;

        const ordered = [...timelines].sort((a, b) => {
          return a.startTs - b.startTs;
        });

        this.timelines.next(ordered);

        for (let timeline of timelines) {
          if (timeline.startTs < currDate && timeline.endTs >= currDate) {
            this.activeTimeline.next(timeline);
          }
        }
      });
    }

    this.fetchChallengeTracks();
  }

  fetchChallengeTracks(clearEverything = false) {
    this.tracksMetaTable = {};
    this.lessonsTable = {};
    this.challenge = {};

    forkJoin([this.getActiveUserChallenge(), this.$v3.getUserChallengeMachinesByLessonProgress(null, true)]).subscribe(
      ([userChallenge, tracks]: [any, any]) => {
        this.allTracks = tracks;
        this.tracksMetaTable = {};
        if (clearEverything) {
          this.lessonsTable = {};
          this.challenge = {};
        }
        this.refreshTracks();
      }
    );
  }

  refreshTracks(tracks = this.allTracks) {
    this.challenge = {
      ...(this.challenge || {}),
      lessons: this.makeLessons(tracks),
    };
    this.machinesSubj.next(this.challenge);
  }

  makeLessons(tracks: Tracks) {
    const lessons = LESSON_ORDER.reduce((lessons, xpModifier) => {
      const lesson = tracks[xpModifier]?.[0];

      if (!lesson || lesson.code.includes('CREATIVITY')) {
        return lessons;
      }

      lessons.push(lesson);

      return lessons;
    }, []);

    const hasIntros = this.checkIfHasIntros(lessons);
    const allIntrosComplete = this.checkIfAllIntrosCompleted(lessons, true);
    const trackLessons = lessons.filter((lesson) => {
      if (lesson.code.includes('CREATIVITY')) {
        return false;
      }
      if (this.$myuser.isStudent()) {
        if (!hasIntros) {
          return true;
        }

        if (!this.isIntroLesson(lesson)) {
          return false;
        }

        if (!allIntrosComplete && lesson.accessLevel !== this.userChallenge.accessLevel) {
          return false;
        }
      }
      return true;
    });

    lessons.sort((a, b) => (a.introLesson === b.introLesson ? 0 : a.introLesson ? -1 : 1));
    lessons.sort((a, b) => a.accessLevel - b.accessLevel);

    const tracksEnhanced = trackLessons.reduce((acc, lesson) => {
      const withMeta = this.makeLessonsWithMeta(lesson, hasIntros, allIntrosComplete);

      return [...acc, withMeta];
    }, []);

    const makeWeight = (lesson: LessonWithMeta) => {
      let weight = 0;

      if (!lesson.meta.requireIntroFinish && !lesson.meta.requireUpgrade) {
        weight += 2;
      }

      return weight;
    };

    tracksEnhanced.sort(
      (a: LessonsWithMeta, b: LessonsWithMeta) =>
        makeWeight(b.lessons[b.meta.currentLessonIndex]) - makeWeight(a.lessons[a.meta.currentLessonIndex])
    );

    return tracksEnhanced;
  }

  makeLessonsWithMeta(lesson: LessonInTrack, hasIntros: boolean, allIntrosComplete: boolean): LessonsWithMeta {
    const lessons = [];
    if (lesson.previousLesson) {
      lessons.push(...lesson.previousLesson);
    }
    const currentLessonIndex = lessons.length;

    lessons.push(lesson);

    if (lesson.nextLesson) {
      lessons.push(lesson.nextLesson);
    }

    const withMeta: LessonsWithMeta = {
      lessons: lessons.map((lesson, index) => {
        const cachedLesson = this.lessonsTable[lesson.id];

        lesson = {
          ...lesson,
          ...(cachedLesson?.lesson || {}),
        };

        lesson.machines = (lesson.machines || []).map((machine) => {
          machine = this.getMachineFromCache(machine);
          this.machines[machine.id] = machine;
          return machine;
        });

        const hasMachines = lesson.machines.length > 0;

        const requireIntroFinish = !this.isIntroLesson(lesson) && hasIntros && !allIntrosComplete;
        const requireUpgrade = lesson.accessLevel !== this.userChallenge.accessLevel;

        const result: LessonWithMeta = {
          lesson,
          meta: {
            canStart: !hasMachines && !requireIntroFinish && !requireUpgrade,
            requireUpgrade,
            isCurrent: index === currentLessonIndex,
            isNext: index > currentLessonIndex,
            isPrevious: index < currentLessonIndex,
            requireIntroFinish,
            isLoading: false,
            hasMachines,
            isLast: index === lessons.length - 1,
            hasNext: Boolean(lesson.nextLesson),
            isStarting: false,
            isCompleted: false,
            isResolved: false,
            numOfCompleted: 0,
            numOfSkipped: 0,
            slides: {
              current: null,
              start: false,
              started: false,
              machines: false,
              completed: false,
              ended: false,
              total: 0,
              isBeginning: false,
              isEnd: false,
            },
          },
        };

        result.meta = {
          ...result.meta,
          ...this.makeMakeLessonMachineStats(result),
        };

        const currentIndex = cachedLesson?.meta?.slides.current || null;

        result.meta.slides = this.makeMakeLessonSlides(result, currentIndex);

        this.lessonsTable[result.lesson.id] = result;

        return result;
      }),
      meta: {
        currentLessonIndex,
        xpModifier: lesson.xpModifier,
        selectedLessonIndex: currentLessonIndex,
        ...(this.tracksMetaTable[lesson.xpModifier] || {}),
      },
    };

    this.tracksMetaTable[lesson.xpModifier] = withMeta.meta;

    return withMeta;
  }

  makeMakeLessonMachineStats(lesson: LessonWithMeta) {
    const matchineStats = (lesson.lesson.machines || []).reduce(
      (acc, machine) => {
        // @ts-ignore
        if (machine.completionStatus === 2) {
          acc.numOfCompleted++;
          // @ts-ignore
        } else if (machine.completionStatus === 3) {
          acc.numOfSkipped++;
        }
        return acc;
      },
      {
        numOfCompleted: 0,
        numOfSkipped: 0,
      }
    );

    const machinesLength = lesson.lesson.machines?.length || 0;

    const hasMachines = machinesLength > 0;

    return {
      hasMachines,
      isCompleted: hasMachines ? matchineStats.numOfCompleted + matchineStats.numOfSkipped === machinesLength : false,
      isResolved: hasMachines ? matchineStats.numOfCompleted === machinesLength : false,
      numOfCompleted: matchineStats.numOfCompleted,
      numOfSkipped: matchineStats.numOfSkipped,
    };
  }
  getMetadata(id: number) {
    this.checkReload(id);
    return this.metadataSubj.asObservable();
  }

  getActiveUserChallenge(userId?: number) {
    if (!this.activeUserChallenge$) {
      userId = userId ? userId : this.$myuser.id();
      this.activeUserChallenge$ = this.$userChallenge
        .find({
          where: {
            userAccountId: userId,
          },
          order: 'createdAt DESC',
        })
        .pipe(
          map((res) => res[0]),
          shareReplay(1),
          tap((uac: UserAccountChallenge) => {
            this.challengeId = uac.challengeId;
            this.userChallenge = uac;
          })
        );
    }

    return this.activeUserChallenge$;
  }

  getChallengeBrandingDeadline(challenge: Challenge): ChallengeBrandingDeadline {
    const deadlines: ChallengeBrandingDeadline[] = challenge?.branding?.deadlines;

    if (!deadlines || !Array.isArray(deadlines)) {
      return;
    }

    return deadlines.find((deadline) => {
      const d1 = new Date(deadline.startAt);
      const d2 = new Date(deadline.endAt);

      return d2.getTime() > Date.now() && d1.getTime() < Date.now();
    });
  }

  isUserChallengeLocked(userChallenge: UserAccountChallenge) {
    return userChallenge && userChallenge.accessLevel === 0;
  }

  getUserChallenges(userId?: number) {
    userId = userId ? userId : this.$myuser.id();
    return this.$userChallenge.find({
      where: {
        userAccountId: userId,
      },
      include: {
        relation: 'challenge',
      },
    });
  }

  nextLesson(nextLesson: LessonWithMeta) {
    this.challenge = {
      ...this.challenge,
      lessons: this.challenge.lessons.map((lessons) => {
        if (lessons.meta.xpModifier !== nextLesson.lesson.xpModifier) {
          return lessons;
        }

        return {
          ...lessons,
          lessons: lessons.lessons.map((lesson) => {
            if (lesson.lesson.id !== nextLesson.lesson.id) {
              return lesson;
            }
            return {
              ...lesson,
              meta: {
                ...lesson.meta,
                isStarting: true,
              },
            };
          }),
        };
      }),
    };

    this.machinesSubj.next(this.challenge);

    this.$v3
      .getUserChallengeMachinesByLessonProgress(
        this.intros.length && this.isAllIntrosCompleted ? null : nextLesson.lesson.xpModifier,
        true
      )
      .subscribe((nextLessons) => {
        const xpModifiers = Object.keys(nextLessons) as string[];
        for (let xpModifier of xpModifiers) {
          for (let track of this.challenge.lessons) {
            if (track.meta.xpModifier !== xpModifier) {
              continue;
            }
            this.tracksMetaTable[xpModifier] = undefined;
            for (let lesson of track.lessons) {
              this.lessonsTable[lesson.lesson.id] = undefined;
            }
          }
        }

        this.allTracks = {
          ...this.allTracks,
          ...nextLessons,
        };
        this.refreshTracks();
      });
  }

  get intros() {
    return this.getIntros(this.challenge.lessons);
  }

  getIntros(lessons: LessonInTrack[], availableOnly = false) {
    return lessons.filter(
      (lesson) =>
        this.isIntroLesson(lesson) &&
        (!availableOnly || (availableOnly && lesson.accessLevel === this.userChallenge.accessLevel))
    );
  }

  checkIfAllIntrosCompleted(lessons: LessonInTrack[], availableOnly = false) {
    const intros = this.getIntros(lessons, availableOnly);
    return intros.every((lesson) =>
      lesson.machines.every((machine) => {
        return [2, 3].includes(this.getMachineFromCache(machine).completionStatus);
      })
    );
  }

  getMachineFromCache(machine: UserMachine) {
    return this.machines[machine.id] ? this.machines[machine.id] : machine;
  }

  get isAllIntrosCompleted() {
    return this.checkIfAllIntrosCompleted(this.challenge.lessons);
  }

  isIntroLesson(lesson: Lesson) {
    return Boolean(lesson.introLesson);
  }
  checkIfHasIntros(lessons: Lesson[]) {
    return lessons.some((lesson) => this.isIntroLesson(lesson));
  }

  get hasIntros() {
    return this.checkIfHasIntros(this.challenge.lessons);
  }

  get hasIncompletedIntros() {
    return Boolean(this.firstIntro);
  }

  get firstIntro() {
    for (let lessons of this.challenge.lessons) {
      const lesson = lessons.lessons[lessons.meta.currentLessonIndex];

      if (!lesson || !lesson.lesson.machines) {
        continue;
      }

      const inComplete =
        Boolean(lesson.lesson.introLesson) &&
        lesson.lesson.machines.some((machine) => {
          return ![2, 3].includes(this.getMachineFromCache(machine).completionStatus);
        });

      if (
        inComplete ||
        (lesson.lesson.nextLesson &&
          lesson.lesson.nextLesson.introLesson &&
          lesson.lesson.accessLevel === lesson.lesson.nextLesson.accessLevel)
      ) {
        return lesson;
      }
    }
  }

  onLessonIndexChange(lesson: LessonsWithMeta, index: number) {
    let thatLesson: LessonWithMeta;

    this.challenge = {
      ...this.challenge,
      lessons: this.challenge.lessons.map((lessons) => {
        if (lessons.meta.xpModifier !== lesson.meta.xpModifier) {
          return lessons;
        }

        return {
          ...lessons,
          lessons: lessons.lessons.map((lesson, i) => {
            if (i !== index) {
              return lesson;
            }

            thatLesson = {
              ...lesson,
              meta: {
                ...lesson.meta,
                isLoading: (!lesson.meta.hasMachines && lesson.meta.isPrevious) || lesson.meta.isLoading,
              },
            };

            return thatLesson;
          }),
          meta: {
            ...lessons.meta,
            selectedLessonIndex: index,
          },
        };
      }),
    };

    if (thatLesson && thatLesson.meta.isLoading) {
      this.$v3.getLessonWithLessonProgressById(thatLesson.lesson.id).subscribe((response) => {
        if (!this.lessonsTable[response.id]) {
          this.lessonsTable[response.id] = thatLesson;
        }

        this.lessonsTable[response.id] = {
          ...this.lessonsTable[response.id],
          lesson: {
            ...this.lessonsTable[response.id].lesson,
            ...response,
          },
          meta: {
            ...this.lessonsTable[response.id].meta,
            isLoading: false,
          },
        };

        this.lessonsTable[response.id].meta = {
          ...this.lessonsTable[response.id].meta,
          ...this.makeMakeLessonMachineStats(this.lessonsTable[response.id]),
        };

        this.lessonsTable[response.id].meta.slides = this.makeMakeLessonSlides(this.lessonsTable[response.id], null);

        this.challenge = {
          ...this.challenge,
          lessons: this.challenge.lessons.map((lessons) => {
            if (lessons.meta.xpModifier !== this.lessonsTable[response.id].lesson.xpModifier) {
              return lessons;
            }

            return {
              ...lessons,
              lessons: lessons.lessons.map((lesson) => {
                if (lesson.lesson.id !== this.lessonsTable[response.id].lesson.id) {
                  return lesson;
                }

                return this.lessonsTable[this.lessonsTable[response.id].lesson.id];
              }),
            };
          }),
        };

        this.machinesSubj.next(this.challenge);
      });
    }
    this.machinesSubj.next(this.challenge);
  }

  onLessonSlideChange(lessons: LessonsWithMeta, index: number) {
    this.challenge = {
      ...this.challenge,
      lessons: this.challenge.lessons.map((lesson: LessonsWithMeta) => {
        if (lesson.meta.xpModifier !== lessons.meta.xpModifier) {
          return lesson;
        }

        return {
          ...lesson,
          lessons: lesson.lessons.map((lesson, i) => {
            if (i !== lessons.meta.selectedLessonIndex) {
              return lesson;
            }
            let lastIndex = lesson.meta.slides.total - 1;
            if (index > lesson.meta.slides.current) {
              // next
              if (lesson.meta.slides.ended && index >= lastIndex - 2) {
                index = lastIndex;
              }
            } else {
              //back
              if (lesson.meta.slides.ended && index >= lastIndex - 2) {
                index = lastIndex - 3;
              }
            }
            return {
              ...lesson,
              meta: {
                ...lesson.meta,
                slides: this.makeMakeLessonSlides(lesson, index),
              },
            };
          }),
        };
      }),
    };

    this.machinesSubj.next(this.challenge);
  }

  makeMakeLessonSlides(lesson: LessonWithMeta, current: number): LessonSlides {
    const start = lesson.meta.isPrevious
      ? false
      : lesson.meta.isNext || (lesson.meta.isCurrent && (!lesson.meta.hasMachines || lesson.meta.requireIntroFinish));
    const slides: LessonSlides = {
      ...lesson.meta.slides,
      start,
      started:
        !start &&
        ((!lesson.meta.isPrevious && !lesson.meta.requireIntroFinish && lesson.meta.hasMachines) ||
          lesson.meta.isPrevious),
      machines: !start && lesson.meta.hasMachines,
      completed: !start && lesson.meta.isCompleted,
      ended: !start && lesson.meta.isCompleted && lesson.meta.hasNext,
      current,
    };

    slides.total = 0;

    if (slides.start) {
      slides.total = 1;
    } else {
      if (slides.started) {
        slides.total += 1;
      }
      if (slides.machines) {
        slides.total += lesson.lesson.machines.length;
      }
      if (slides.completed) {
        slides.total += 1;
      }
      if (slides.ended) {
        slides.total += 1;
      }
    }

    const diff = 2;

    if (lesson.meta.isCompleted && current === null) {
      slides.current = slides.total - diff;
    } else if (current === null) {
      if (lesson.meta.hasMachines) {
        const index = lesson.lesson.machines.findIndex((machine) => machine.isCurrent);
        if (index > 0) {
          slides.current = Math.min(index + 1, slides.total - 2);
        }
      }
    }

    if (slides.current === null) {
      slides.current = 0;
    }
    slides.isBeginning = slides.current === 0;
    slides.isEnd = slides.current >= slides.total - diff;

    return slides;
  }
}
