import { Component, OnInit, Input } from '@angular/core';
import { SITEMAP } from 'src/utilities/sitemap';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from 'src/app/shared/services/user.service';
import { fadeInOut, slideToSide } from 'src/assets/animations';
import { BannerService } from 'src/app/shared/modules/banner/banner.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'lo-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],

  animations: [slideToSide(), fadeInOut()],
})
export class NavComponent implements OnInit {
  SITEMAP = SITEMAP;
  @Input() whiteText: boolean = false;
  loginPopupActive: boolean = false;
  user: any = null;
  menuOpen: boolean = false;
  signingUp: boolean;
  userDropdownActive: boolean = false;
  userDropdownActivePhone: boolean = false;

  routes = [
    // {
    //   name: 'Home',
    //   url: SITEMAP.HOME_LANDING,
    // },
    // {
    //   name: 'Mod Market',
    //   url: SITEMAP.CHALLENGES_LANDING,
    // },

    {
      name: 'About',
      url: SITEMAP.ABOUT,
    },
  ];
  userDropdownOptions = [
    {
      name: 'Go to dashboard',
      id: 'dashboard',
    },
    {
      name: 'Log out',
      id: 'logout',
    },
  ];
  constructor(
    private $router: Router,
    private $ngxPermissionsService: NgxPermissionsService,
    public $myuser: UserService,
    private $route: ActivatedRoute,
    private $banner: BannerService,
    private $http: HttpClient
  ) {}

  activateSignUp() {
    console.log('going to sign up');
    this.$router.navigate([], { queryParams: { mode: 'signup' }, relativeTo: this.$route });
  }

  activateLogin() {
    this.$router.navigate([], { queryParams: { mode: 'login' }, relativeTo: this.$route });
  }

  onHomePage(): boolean {
    return this.$router.url.split('/')[this.$router.url.split('/').length - 1] == 'about';
  }

  handleAction(event) {
    switch (event) {
      case 'dashboard':
        this.$router.navigate(['']);
        break;
      case 'logout':
        this.$myuser.logout();
        break;
    }
  }

  ngOnInit(): void {
    this.$router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuOpen = false;
      }
    });

    this.$http.get('https://static.polyup.com/wrapper/banners.json').subscribe((banners: Banner[]) => {
      for (let banner of banners) {
        this.$banner.addOnce(banner);
      }
    });
  }

  get userFirstChar() {
    return String(this.$myuser.getName()).substring(0, 1);
  }

  get homeUrl() {
    return SITEMAP.HOME_LANDING;
  } 
}
