/* tslint:disable */
import {
  Milestone
} from '../index';

declare var Object: any;
export interface MilestoneTypeInterface {
  "name"?: string;
  "displayName"?: string;
  "description"?: string;
  "code"?: string;
  "order"?: number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  type?: Milestone[];
}

export class MilestoneType implements MilestoneTypeInterface {
  "name": string;
  "displayName": string;
  "description": string;
  "code": string;
  "order": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  type: Milestone[];
  constructor(data?: MilestoneTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MilestoneType`.
   */
  public static getModelName() {
    return "MilestoneType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MilestoneType for dynamic purposes.
  **/
  public static factory(data: MilestoneTypeInterface): MilestoneType{
    return new MilestoneType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MilestoneType',
      plural: 'MilestoneTypes',
      path: 'MilestoneTypes',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        type: {
          name: 'type',
          type: 'Milestone[]',
          model: 'Milestone',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'milestoneTypeId'
        },
      }
    }
  }
}
