/* tslint:disable */
import {
  UserMachineEntry
} from '../index';

declare var Object: any;
export interface ChallengeTimelineInterface {
  "challengeId": number;
  "name": string;
  "code": string;
  "displayName": string;
  "active"?: boolean;
  "description"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "startTs"?: number;
  "endTs"?: number;
  "order"?: number;
  "id"?: number;
  userMachineEntries?: UserMachineEntry[];
}

export class ChallengeTimeline implements ChallengeTimelineInterface {
  "challengeId": number;
  "name": string;
  "code": string;
  "displayName": string;
  "active": boolean;
  "description": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "startTs": number;
  "endTs": number;
  "order": number;
  "id": number;
  userMachineEntries: UserMachineEntry[];
  constructor(data?: ChallengeTimelineInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ChallengeTimeline`.
   */
  public static getModelName() {
    return "ChallengeTimeline";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ChallengeTimeline for dynamic purposes.
  **/
  public static factory(data: ChallengeTimelineInterface): ChallengeTimeline{
    return new ChallengeTimeline(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ChallengeTimeline',
      plural: 'ChallengeTimelines',
      path: 'ChallengeTimelines',
      idName: 'id',
      properties: {
        "challengeId": {
          name: 'challengeId',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: false
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "startTs": {
          name: 'startTs',
          type: 'number'
        },
        "endTs": {
          name: 'endTs',
          type: 'number'
        },
        "order": {
          name: 'order',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        userMachineEntries: {
          name: 'userMachineEntries',
          type: 'UserMachineEntry[]',
          model: 'UserMachineEntry',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'challengeTimelineId'
        },
      }
    }
  }
}
