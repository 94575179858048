/* tslint:disable */
import {
  Board,
  Machine
} from '../index';

declare var Object: any;
export interface MachineBoardInterface {
  "machineId"?: number;
  "boardId"?: number;
  "parentMachineBoardId"?: number;
  "data"?: string;
  "position"?: any;
  "isRoot"?: boolean;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  board?: Board;
  machine?: Machine;
}

export class MachineBoard implements MachineBoardInterface {
  "machineId": number;
  "boardId": number;
  "parentMachineBoardId": number;
  "data": string;
  "position": any;
  "isRoot": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  board: Board;
  machine: Machine;
  constructor(data?: MachineBoardInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MachineBoard`.
   */
  public static getModelName() {
    return "MachineBoard";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MachineBoard for dynamic purposes.
  **/
  public static factory(data: MachineBoardInterface): MachineBoard{
    return new MachineBoard(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MachineBoard',
      plural: 'MachineBoards',
      path: 'MachineBoards',
      idName: 'id',
      properties: {
        "machineId": {
          name: 'machineId',
          type: 'number'
        },
        "boardId": {
          name: 'boardId',
          type: 'number'
        },
        "parentMachineBoardId": {
          name: 'parentMachineBoardId',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "position": {
          name: 'position',
          type: 'any'
        },
        "isRoot": {
          name: 'isRoot',
          type: 'boolean'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        board: {
          name: 'board',
          type: 'Board',
          model: 'Board',
          relationType: 'belongsTo',
                  keyFrom: 'boardId',
          keyTo: 'id'
        },
        machine: {
          name: 'machine',
          type: 'Machine',
          model: 'Machine',
          relationType: 'belongsTo',
                  keyFrom: 'machineId',
          keyTo: 'id'
        },
      }
    }
  }
}
