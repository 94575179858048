<div class="bg-[#F3F5FA] py-6 px-4 lg:py-12 lg:px-20">
  <div class="w-full flex items-center justify-between flex-col gap-y-6 sm:flex-row">
    <ul class="flex items-center gap-x-8 lg:gap-x-14">
      <li class="text-[#4E5374] text-base leading-5">
        <a href="https://app.startinfinity.com/form/d73d152e-3e34-4e2c-b3c9-59465427cda5" target="_blank"
          rel="noopener noreferrer">
          Contact
        </a>
      </li>
      <li class="text-[#4E5374] text-base leading-5">
        <a href="http://letsmod.link/discord" target="_blank" rel="noopener noreferrer"
          class="flex items-center gap-x-2">
          <i class="fab fa-discord"></i> Discord
        </a>
      </li>
    </ul>
    <ul class="flex items-center justify-center gap-x-8 gap-y-6 flex-wrap-reverse lg:justify-start lg:gap-x-14">
      <li class="text-[#4E5374] text-sm text-center basis-full lg:text-start lg:basis-auto">
        Copyright LetsMod Inc. {{year}}
      </li>
      <li class="text-[#4E5374] text-sm">
        <a class="underline" href="http://letsmod.com/company/about/privacy-policy/index.html" target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </a>
      </li>
      <li class="text-[#4E5374] text-sm">
        <a class="underline" href="http://letsmod.com/company/about/terms-of-service/index.html" target="_blank"
          rel="noopener noreferrer">
          Terms of Service
        </a>
      </li>
    </ul>
  </div>
</div>