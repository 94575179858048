/* tslint:disable */
import {
  Currency
} from '../index';

declare var Object: any;
export interface CurrencyTypeInterface {
  "name"?: string;
  "description"?: string;
  "xpModifierCode"?: string;
  "active"?: boolean;
  "defaultValue": number;
  "authorMultiplier": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  currencies?: Currency[];
}

export class CurrencyType implements CurrencyTypeInterface {
  "name": string;
  "description": string;
  "xpModifierCode": string;
  "active": boolean;
  "defaultValue": number;
  "authorMultiplier": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  currencies: Currency[];
  constructor(data?: CurrencyTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CurrencyType`.
   */
  public static getModelName() {
    return "CurrencyType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CurrencyType for dynamic purposes.
  **/
  public static factory(data: CurrencyTypeInterface): CurrencyType{
    return new CurrencyType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CurrencyType',
      plural: 'CurrencyTypes',
      path: 'CurrencyTypes',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "xpModifierCode": {
          name: 'xpModifierCode',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "defaultValue": {
          name: 'defaultValue',
          type: 'number'
        },
        "authorMultiplier": {
          name: 'authorMultiplier',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        currencies: {
          name: 'currencies',
          type: 'Currency[]',
          model: 'Currency',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'currencyTypeId'
        },
      }
    }
  }
}
