/* tslint:disable */
import {
  Announcement,
  Challenge
} from '../index';

declare var Object: any;
export interface AnnouncementChallengeInterface {
  "announcementId": number;
  "challengeId": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "order"?: number;
  "id"?: number;
  announcement?: Announcement;
  challenge?: Challenge;
}

export class AnnouncementChallenge implements AnnouncementChallengeInterface {
  "announcementId": number;
  "challengeId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "order": number;
  "id": number;
  announcement: Announcement;
  challenge: Challenge;
  constructor(data?: AnnouncementChallengeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AnnouncementChallenge`.
   */
  public static getModelName() {
    return "AnnouncementChallenge";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AnnouncementChallenge for dynamic purposes.
  **/
  public static factory(data: AnnouncementChallengeInterface): AnnouncementChallenge{
    return new AnnouncementChallenge(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AnnouncementChallenge',
      plural: 'AnnouncementChallenges',
      path: 'AnnouncementChallenges',
      idName: 'id',
      properties: {
        "announcementId": {
          name: 'announcementId',
          type: 'number'
        },
        "challengeId": {
          name: 'challengeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        announcement: {
          name: 'announcement',
          type: 'Announcement',
          model: 'Announcement',
          relationType: 'belongsTo',
                  keyFrom: 'announcementId',
          keyTo: 'id'
        },
        challenge: {
          name: 'challenge',
          type: 'Challenge',
          model: 'Challenge',
          relationType: 'belongsTo',
                  keyFrom: 'challengeId',
          keyTo: 'id'
        },
      }
    }
  }
}
