/* tslint:disable */
import {
  ChipProgress,
  BoardChip,
  ChipHistory,
  UserAccount
} from '../index';

declare var Object: any;
export interface ChipInterface {
  "userAccountId": number;
  "uuid"?: string;
  "shareId"?: string;
  "name": string;
  "data"?: any;
  "script"?: string;
  "goals"?: string;
  "tags"?: string;
  "systemTags"?: string;
  "active": boolean;
  "status": number;
  "originalChipHistoryId"?: number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "isEditable"?: boolean;
  "bucketId"?: number;
  "strings"?: any;
  "description"?: string;
  "localId"?: number;
  "id"?: number;
  userProgress?: ChipProgress[];
  boards?: BoardChip[];
  history?: ChipHistory[];
  bucket?: any;
  userAccount?: UserAccount;
  createdByUser?: UserAccount;
  updatedByUser?: UserAccount;
}

export class Chip implements ChipInterface {
  "userAccountId": number;
  "uuid": string;
  "shareId": string;
  "name": string;
  "data": any;
  "script": string;
  "goals": string;
  "tags": string;
  "systemTags": string;
  "active": boolean;
  "status": number;
  "originalChipHistoryId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "isEditable": boolean;
  "bucketId": number;
  "strings": any;
  "description": string;
  "localId": number;
  "id": number;
  userProgress: ChipProgress[];
  boards: BoardChip[];
  history: ChipHistory[];
  bucket: any;
  userAccount: UserAccount;
  createdByUser: UserAccount;
  updatedByUser: UserAccount;
  constructor(data?: ChipInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Chip`.
   */
  public static getModelName() {
    return "Chip";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Chip for dynamic purposes.
  **/
  public static factory(data: ChipInterface): Chip{
    return new Chip(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Chip',
      plural: 'Chips',
      path: 'Chips',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "uuid": {
          name: 'uuid',
          type: 'string'
        },
        "shareId": {
          name: 'shareId',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "script": {
          name: 'script',
          type: 'string'
        },
        "goals": {
          name: 'goals',
          type: 'string'
        },
        "tags": {
          name: 'tags',
          type: 'string'
        },
        "systemTags": {
          name: 'systemTags',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "originalChipHistoryId": {
          name: 'originalChipHistoryId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "isEditable": {
          name: 'isEditable',
          type: 'boolean',
          default: true
        },
        "bucketId": {
          name: 'bucketId',
          type: 'number',
          default: 0
        },
        "strings": {
          name: 'strings',
          type: 'any'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "localId": {
          name: 'localId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        userProgress: {
          name: 'userProgress',
          type: 'ChipProgress[]',
          model: 'ChipProgress',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'chipId'
        },
        boards: {
          name: 'boards',
          type: 'BoardChip[]',
          model: 'BoardChip',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'chipId'
        },
        history: {
          name: 'history',
          type: 'ChipHistory[]',
          model: 'ChipHistory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'chipId'
        },
        bucket: {
          name: 'bucket',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'bucketId',
          keyTo: 'id'
        },
        userAccount: {
          name: 'userAccount',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
        createdByUser: {
          name: 'createdByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'createdBy',
          keyTo: 'id'
        },
        updatedByUser: {
          name: 'updatedByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'updatedBy',
          keyTo: 'id'
        },
      }
    }
  }
}
