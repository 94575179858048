/* tslint:disable */
import {
  UserAccountNotification,
  NotificationRule,
  NotificationTranslation
} from '../index';

declare var Object: any;
export interface NotificationInterface {
  "notificationTypeId": number;
  "name"?: string;
  "code"?: string;
  "active"?: boolean;
  "pinned"?: boolean;
  "pinnedExpiryDate"?: Date;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  userNotification?: UserAccountNotification[];
  rule?: NotificationRule[];
  translation?: NotificationTranslation[];
}

export class Notification implements NotificationInterface {
  "notificationTypeId": number;
  "name": string;
  "code": string;
  "active": boolean;
  "pinned": boolean;
  "pinnedExpiryDate": Date;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  userNotification: UserAccountNotification[];
  rule: NotificationRule[];
  translation: NotificationTranslation[];
  constructor(data?: NotificationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Notification`.
   */
  public static getModelName() {
    return "Notification";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Notification for dynamic purposes.
  **/
  public static factory(data: NotificationInterface): Notification{
    return new Notification(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Notification',
      plural: 'Notifications',
      path: 'Notifications',
      idName: 'id',
      properties: {
        "notificationTypeId": {
          name: 'notificationTypeId',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "pinned": {
          name: 'pinned',
          type: 'boolean',
          default: false
        },
        "pinnedExpiryDate": {
          name: 'pinnedExpiryDate',
          type: 'Date'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        userNotification: {
          name: 'userNotification',
          type: 'UserAccountNotification[]',
          model: 'UserAccountNotification',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'notificationId'
        },
        rule: {
          name: 'rule',
          type: 'NotificationRule[]',
          model: 'NotificationRule',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'notificationId'
        },
        translation: {
          name: 'translation',
          type: 'NotificationTranslation[]',
          model: 'NotificationTranslation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'notificationId'
        },
      }
    }
  }
}
