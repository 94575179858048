/* tslint:disable */
import {
  Machine
} from '../index';

declare var Object: any;
export interface MachineHistoryInterface {
  "machineId"?: number;
  "userAccountId": number;
  "uuid"?: string;
  "shareId"?: string;
  "name": string;
  "data"?: string;
  "rootBoardId"?: number;
  "tags"?: string;
  "systemTags"?: string;
  "active": boolean;
  "status": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "isDefault"?: boolean;
  "isEditable"?: boolean;
  "version"?: number;
  "bucketId"?: number;
  "thumbnailId"?: number;
  "strings"?: any;
  "description"?: string;
  "inappropriate"?: boolean;
  "isPublic"?: boolean;
  "id"?: number;
  machine?: Machine;
}

export class MachineHistory implements MachineHistoryInterface {
  "machineId": number;
  "userAccountId": number;
  "uuid": string;
  "shareId": string;
  "name": string;
  "data": string;
  "rootBoardId": number;
  "tags": string;
  "systemTags": string;
  "active": boolean;
  "status": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "isDefault": boolean;
  "isEditable": boolean;
  "version": number;
  "bucketId": number;
  "thumbnailId": number;
  "strings": any;
  "description": string;
  "inappropriate": boolean;
  "isPublic": boolean;
  "id": number;
  machine: Machine;
  constructor(data?: MachineHistoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MachineHistory`.
   */
  public static getModelName() {
    return "MachineHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MachineHistory for dynamic purposes.
  **/
  public static factory(data: MachineHistoryInterface): MachineHistory{
    return new MachineHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MachineHistory',
      plural: 'MachineHistories',
      path: 'MachineHistories',
      idName: 'id',
      properties: {
        "machineId": {
          name: 'machineId',
          type: 'number'
        },
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "uuid": {
          name: 'uuid',
          type: 'string'
        },
        "shareId": {
          name: 'shareId',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "rootBoardId": {
          name: 'rootBoardId',
          type: 'number'
        },
        "tags": {
          name: 'tags',
          type: 'string'
        },
        "systemTags": {
          name: 'systemTags',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "isDefault": {
          name: 'isDefault',
          type: 'boolean'
        },
        "isEditable": {
          name: 'isEditable',
          type: 'boolean',
          default: true
        },
        "version": {
          name: 'version',
          type: 'number'
        },
        "bucketId": {
          name: 'bucketId',
          type: 'number',
          default: 0
        },
        "thumbnailId": {
          name: 'thumbnailId',
          type: 'number'
        },
        "strings": {
          name: 'strings',
          type: 'any'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "inappropriate": {
          name: 'inappropriate',
          type: 'boolean',
          default: false
        },
        "isPublic": {
          name: 'isPublic',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        machine: {
          name: 'machine',
          type: 'Machine',
          model: 'Machine',
          relationType: 'belongsTo',
                  keyFrom: 'machineId',
          keyTo: 'id'
        },
      }
    }
  }
}
