/* tslint:disable */
import {
  Challenge,
  Lesson
} from '../index';

declare var Object: any;
export interface ChallengeLessonInterface {
  "lessonId": number;
  "challengeId": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  challenge?: Challenge;
  lesson?: Lesson;
}

export class ChallengeLesson implements ChallengeLessonInterface {
  "lessonId": number;
  "challengeId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  challenge: Challenge;
  lesson: Lesson;
  constructor(data?: ChallengeLessonInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ChallengeLesson`.
   */
  public static getModelName() {
    return "ChallengeLesson";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ChallengeLesson for dynamic purposes.
  **/
  public static factory(data: ChallengeLessonInterface): ChallengeLesson{
    return new ChallengeLesson(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ChallengeLesson',
      plural: 'ChallengeLessons',
      path: 'ChallengeLessons',
      idName: 'id',
      properties: {
        "lessonId": {
          name: 'lessonId',
          type: 'number'
        },
        "challengeId": {
          name: 'challengeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        challenge: {
          name: 'challenge',
          type: 'Challenge',
          model: 'Challenge',
          relationType: 'belongsTo',
                  keyFrom: 'challengeId',
          keyTo: 'id'
        },
        lesson: {
          name: 'lesson',
          type: 'Lesson',
          model: 'Lesson',
          relationType: 'belongsTo',
                  keyFrom: 'lessonId',
          keyTo: 'id'
        },
      }
    }
  }
}
