/* tslint:disable */

declare var Object: any;
export interface UserMigrationInterface {
  "userAccountId": number;
  "legacyUid": string;
  "migrated"?: boolean;
  "data": string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class UserMigration implements UserMigrationInterface {
  "userAccountId": number;
  "legacyUid": string;
  "migrated": boolean;
  "data": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: UserMigrationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserMigration`.
   */
  public static getModelName() {
    return "UserMigration";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserMigration for dynamic purposes.
  **/
  public static factory(data: UserMigrationInterface): UserMigration{
    return new UserMigration(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserMigration',
      plural: 'UserMigrations',
      path: 'UserMigrations',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "legacyUid": {
          name: 'legacyUid',
          type: 'string'
        },
        "migrated": {
          name: 'migrated',
          type: 'boolean',
          default: false
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
