/* tslint:disable */
import {
  Assessment
} from '../index';

declare var Object: any;
export interface AssessmentTemplateInterface {
  "name"?: string;
  "displayName"?: any;
  "code"?: string;
  "active"?: boolean;
  "version"?: number;
  "data"?: any;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  assessment?: Assessment[];
}

export class AssessmentTemplate implements AssessmentTemplateInterface {
  "name": string;
  "displayName": any;
  "code": string;
  "active": boolean;
  "version": number;
  "data": any;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  assessment: Assessment[];
  constructor(data?: AssessmentTemplateInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AssessmentTemplate`.
   */
  public static getModelName() {
    return "AssessmentTemplate";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AssessmentTemplate for dynamic purposes.
  **/
  public static factory(data: AssessmentTemplateInterface): AssessmentTemplate{
    return new AssessmentTemplate(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AssessmentTemplate',
      plural: 'AssessmentTemplates',
      path: 'AssessmentTemplates',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'any'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: false
        },
        "version": {
          name: 'version',
          type: 'number',
          default: 1
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        assessment: {
          name: 'assessment',
          type: 'Assessment[]',
          model: 'Assessment',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'templateId'
        },
      }
    }
  }
}
