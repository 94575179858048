import { environment } from 'src/environments/environment';

export const canReceive = (event: MessageEvent) => {
  if (!event.origin) {
    return false;
  }

  const origin = new URL(event.origin);

  const allowed = !!origin && environment.origins.find((o) => origin.host.includes(o));

  if (!allowed) {
    return false;
  }

  if (!event.data || typeof event.data !== 'object' || !String(event.data.type).startsWith('help:')) {
    return false;
  }

  return true;
};
