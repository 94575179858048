import { Component, Input, OnInit } from '@angular/core';

type Types = 'page-title' | 'headline';

@Component({
  selector: 'ui-typography',
  templateUrl: './typography.component.html',
  styles: [],
})
export class TypographyComponent implements OnInit {
  constructor() {}

  @Input() type?: Types;
  @Input() classes: string = '';

  ngOnInit() {}
}
