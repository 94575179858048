import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TranslocoService } from '@ngneat/transloco';
import List from './countries';

export interface CountryPickerModel {
  name: string;
  iso2: string;
  dialCode: string;
  areaCodes: string[];
  optionTitle?: string;
}

@Component({
  selector: 'ui-iso-picker',
  templateUrl: './iso-picker.component.html',
  styleUrls: ['./iso-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IsoPickerComponent implements OnInit, OnChanges {
  @Input() placeholder: string = 'Select country';
  @Input() showFlags: boolean = true;
  @Input() showDialCode: boolean = true;
  @Input() showName: boolean = true;
  @Input() only: string[];
  @Input() value: string;
  @Input() options: any;

  @Input() countries: any[];

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(private $transloco: TranslocoService) {}

  countryList: any;
  disabled: boolean;

  iso: string;
  selectedCountry: CountryPickerModel;
  searchKeyword: string = '';

  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.countries) {
      this.countryList = this.countries;
    } else if (this.only) {
      this.countryList = List.filter((country) => this.only.includes(country.iso2));
    } else {
      this.countryList = List;
    }

    if (this.value) {
      this.setIso(this.value);
    }
  }

  onValueChange(change: MatSelectChange) {
    this.setIso(change.value);
    this.selectionChanged.emit({ ...this.selectedCountry });
  }

  setIso(iso: string) {
    this.iso = iso;
    this.selectedCountry = this.countryList.find((country: CountryPickerModel) => country.iso2 === iso);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.value &&
      changes.value.previousValue &&
      changes.value.currentValue !== this.iso &&
      changes.value.previousValue !== changes.value.currentValue
    ) {
      this.setIso(changes.value.currentValue);
    }
  }

  get results(): CountryPickerModel[] {
    if (this.searchKeyword) {
      const searchKeyword = this.searchKeyword.toLowerCase().replace('(', '').replace(')', '');
      return this.countryList.filter((country: CountryPickerModel) => {
        return (
          (country.iso2 && country.iso2.toLowerCase().includes(searchKeyword)) ||
          (country.dialCode && ('+' + country.dialCode.toLowerCase()).includes(searchKeyword)) ||
          (country.name && country.name.toLowerCase().includes(searchKeyword))
        );
      });
    }

    return this.countryList;
  }

  onOpen(open: boolean) {
    if (open) {
      this.searchInput.nativeElement.focus();
    } else {
      this.searchKeyword = '';
    }
  }

  get direction() {
    return this.$transloco.translate('_direction')
  }
}
