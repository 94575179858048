import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HelpService } from './help.service';
import { canReceive } from './utils/canRecieve';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit, OnDestroy {
  constructor(private $help: HelpService) {
    this.onEvent = this.onEvent.bind(this);
  }

  active = false;
  isLoaded = false;
  onOpenData = {};

  @ViewChild('iframe') iframe;

  onEvent(event: MessageEvent) {
    if (!canReceive(event)) {
      return;
    }

    const data = event.data;
    console.log('daaata', data);

    switch (data.type) {
      case 'help:close':
        this.$help.close();
        break;

      case 'help:ready':
        this.dispatchEvent('open', this.onOpenData || {});
        break;

      case 'help:comments.open':
        break;
    }
  }

  ngOnInit() {
    this.$help.openSubject.subscribe((onOpenData) => {
      this.onOpenData = onOpenData;

      this.active = Boolean(onOpenData);
    });

    window.addEventListener('message', this.onEvent);
  }

  onLoad() {
    this.isLoaded = true;
  }
  get url() {
    return environment.helpUrl;
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.onEvent);
  }

  dispatchEvent(type: string, data = {}) {
    if (!this.iframe.nativeElement?.contentWindow) {
      console.log('unable to send messages to help system.');
      return;
    }

    this.iframe.nativeElement.contentWindow.postMessage(
      {
        ...data,
        type: `help:${type}`,
      },
      '*'
    );
  }
}
