import { OnInit, ElementRef, Input, Directive } from '@angular/core';
import tippy from 'tippy.js';

@Directive({
  selector: '[popover]',
})
export class PopoverComponent implements OnInit {
  constructor(private element: ElementRef) {}
  @Input() content: string;
  @Input() delay: number;
  ngOnInit() {
    tippy(this.element.nativeElement, {
      content: this.content,
      delay: this.delay,
    });
  }
}
