import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from '../../../environments/environment';

export class SentryLogger {
  static init() {
    Sentry.init({
      dsn: 'https://687c16b3ff3f4542bf65df2d9be550c3@o358071.ingest.sentry.io/5479192',
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
          tracingOrigins: ['localhost', environment.apiUrl],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      tracesSampleRate: 1.0,
      environment: environment.name,
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /youtube\.com/i,
        /google-analytics\.com/i,
        /mqtt\.dev\.polyup\.com/i,
      ],
    });
  }
}
