/* tslint:disable */
import {
  Item
} from '../index';

declare var Object: any;
export interface UserAccountItemInterface {
  "userAccountId": number;
  "itemId": number;
  "objectTypeId": number;
  "objectId": number;
  "amountPaid"?: number;
  "currencyTypeId"?: number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  item?: Item;
}

export class UserAccountItem implements UserAccountItemInterface {
  "userAccountId": number;
  "itemId": number;
  "objectTypeId": number;
  "objectId": number;
  "amountPaid": number;
  "currencyTypeId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  item: Item;
  constructor(data?: UserAccountItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountItem`.
   */
  public static getModelName() {
    return "UserAccountItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountItem for dynamic purposes.
  **/
  public static factory(data: UserAccountItemInterface): UserAccountItem{
    return new UserAccountItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountItem',
      plural: 'UserAccountItems',
      path: 'UserAccountItems',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "itemId": {
          name: 'itemId',
          type: 'number'
        },
        "objectTypeId": {
          name: 'objectTypeId',
          type: 'number'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "amountPaid": {
          name: 'amountPaid',
          type: 'number'
        },
        "currencyTypeId": {
          name: 'currencyTypeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        item: {
          name: 'item',
          type: 'Item',
          model: 'Item',
          relationType: 'belongsTo',
                  keyFrom: 'itemId',
          keyTo: 'id'
        },
      }
    }
  }
}
