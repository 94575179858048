/* tslint:disable */
import {
  Classroom,
  Lesson
} from '../index';

declare var Object: any;
export interface ClassroomMachineSetInterface {
  "classroomId": number;
  "lessonId": number;
  "active": boolean;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  classroom?: Classroom;
  lesson?: Lesson;
}

export class ClassroomMachineSet implements ClassroomMachineSetInterface {
  "classroomId": number;
  "lessonId": number;
  "active": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  classroom: Classroom;
  lesson: Lesson;
  constructor(data?: ClassroomMachineSetInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ClassroomMachineSet`.
   */
  public static getModelName() {
    return "ClassroomMachineSet";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ClassroomMachineSet for dynamic purposes.
  **/
  public static factory(data: ClassroomMachineSetInterface): ClassroomMachineSet{
    return new ClassroomMachineSet(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ClassroomMachineSet',
      plural: 'ClassroomMachineSets',
      path: 'ClassroomMachineSets',
      idName: 'id',
      properties: {
        "classroomId": {
          name: 'classroomId',
          type: 'number'
        },
        "lessonId": {
          name: 'lessonId',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        classroom: {
          name: 'classroom',
          type: 'Classroom',
          model: 'Classroom',
          relationType: 'belongsTo',
                  keyFrom: 'classroomId',
          keyTo: 'id'
        },
        lesson: {
          name: 'lesson',
          type: 'Lesson',
          model: 'Lesson',
          relationType: 'belongsTo',
                  keyFrom: 'lessonId',
          keyTo: 'id'
        },
      }
    }
  }
}
