/* tslint:disable */

declare var Object: any;
export interface DiagnosticInterface {
  "type"?: string;
  "userId"?: number;
  "uid"?: string;
  "sequence"?: number;
  "methodName"?: string;
  "start"?: number;
  "finish"?: number;
  "duration"?: number;
  "data"?: string;
  "createdAt"?: number;
  "id"?: number;
}

export class Diagnostic implements DiagnosticInterface {
  "type": string;
  "userId": number;
  "uid": string;
  "sequence": number;
  "methodName": string;
  "start": number;
  "finish": number;
  "duration": number;
  "data": string;
  "createdAt": number;
  "id": number;
  constructor(data?: DiagnosticInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Diagnostic`.
   */
  public static getModelName() {
    return "Diagnostic";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Diagnostic for dynamic purposes.
  **/
  public static factory(data: DiagnosticInterface): Diagnostic{
    return new Diagnostic(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Diagnostic',
      plural: 'Diagnostics',
      path: 'Diagnostics',
      idName: 'id',
      properties: {
        "type": {
          name: 'type',
          type: 'string'
        },
        "userId": {
          name: 'userId',
          type: 'number'
        },
        "uid": {
          name: 'uid',
          type: 'string'
        },
        "sequence": {
          name: 'sequence',
          type: 'number'
        },
        "methodName": {
          name: 'methodName',
          type: 'string'
        },
        "start": {
          name: 'start',
          type: 'number'
        },
        "finish": {
          name: 'finish',
          type: 'number'
        },
        "duration": {
          name: 'duration',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
