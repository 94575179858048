/* tslint:disable */
import {
  Entity
} from '../index';

declare var Object: any;
export interface EntityTypeInterface {
  "name"?: string;
  "description"?: string;
  "active"?: boolean;
  "level"?: number;
  "parentId"?: number;
  "survey"?: any;
  "childrenAllowed"?: Array<any>;
  "membersAllowed"?: boolean;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  entities?: Entity[];
}

export class EntityType implements EntityTypeInterface {
  "name": string;
  "description": string;
  "active": boolean;
  "level": number;
  "parentId": number;
  "survey": any;
  "childrenAllowed": Array<any>;
  "membersAllowed": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  entities: Entity[];
  constructor(data?: EntityTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EntityType`.
   */
  public static getModelName() {
    return "EntityType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EntityType for dynamic purposes.
  **/
  public static factory(data: EntityTypeInterface): EntityType{
    return new EntityType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EntityType',
      plural: 'EntityTypes',
      path: 'EntityTypes',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "level": {
          name: 'level',
          type: 'number'
        },
        "parentId": {
          name: 'parentId',
          type: 'number'
        },
        "survey": {
          name: 'survey',
          type: 'any'
        },
        "childrenAllowed": {
          name: 'childrenAllowed',
          type: 'Array&lt;any&gt;'
        },
        "membersAllowed": {
          name: 'membersAllowed',
          type: 'boolean'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        entities: {
          name: 'entities',
          type: 'Entity[]',
          model: 'Entity',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'entityTypeId'
        },
      }
    }
  }
}
