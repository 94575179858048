import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { EntityApi } from 'src/app/shared/sdk';
interface School {
  id: number;
  path: string;
}

@Injectable({
  providedIn: 'root',
})
export class SchoolPickerService {
  keywordSubject: BehaviorSubject<string> = new BehaviorSubject('');
  isSearching: Subject<boolean> = new Subject();
  errorMessage: BehaviorSubject<string> = new BehaviorSubject('schoolSearchKeywordLimit');

  minKeywordLength = 4;
  language: string = null;
  entityId: number;

  constructor(private $entity: EntityApi) {}

  private searchObservable: Observable<any[]>;
  private searchSubscriptions: Subscription[] = [];

  subscribe(callback) {
    if (!this.searchObservable) {
      this.searchObservable = this.createSearchObservable();
    }

    const subscription = this.searchObservable.subscribe(callback);

    this.searchSubscriptions.push(subscription);

    return subscription;
  }

  search(keyword: string, entityId = 1, language = null) {
    this.entityId = entityId;
    this.language = language;
    this.keywordSubject.next(keyword);
  }

  clear() {
    this.searchSubscriptions.forEach((subscription) => subscription.unsubscribe());
    this.errorMessage.next('schoolSearchKeywordLimit');
    this.keywordSubject.next(undefined);
  }

  createSearchObservable() {
    return this.keywordSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((keyword: string) => {
        this.errorMessage.next(undefined);
        if (!this.isValidKeyword(keyword)) {
          this.isSearching.next(false);
          this.errorMessage.next('schoolSearchKeywordLimit');
          return new Observable();
        }
        this.isSearching.next(true);
        return this.$entity.searchCountrySchools(this.entityId, keyword, this.language || null, 10);
      }),
      map((result) => {
        if (result && result.length === 0) {
          this.errorMessage.next('noSchoolsFound');
        }
        this.isSearching.next(false);

        return result;
      })
    );
  }

  isValidKeyword(keyword) {
    return keyword && String(keyword).trim().length >= this.minKeywordLength;
  }
}
