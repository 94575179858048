/* tslint:disable */
import {
  AssessmentTemplate
} from '../index';

declare var Object: any;
export interface AssessmentInterface {
  "templateId": number;
  "active"?: boolean;
  "description"?: any;
  "displayNameOverride"?: any;
  "assignedToObjectType"?: string;
  "assignedToObjectId"?: number;
  "startTs"?: number;
  "endTs"?: number;
  "showOnFirstMatch"?: boolean;
  "showCriteria"?: any;
  "forceCompletion"?: boolean;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  template?: AssessmentTemplate;
}

export class Assessment implements AssessmentInterface {
  "templateId": number;
  "active": boolean;
  "description": any;
  "displayNameOverride": any;
  "assignedToObjectType": string;
  "assignedToObjectId": number;
  "startTs": number;
  "endTs": number;
  "showOnFirstMatch": boolean;
  "showCriteria": any;
  "forceCompletion": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  template: AssessmentTemplate;
  constructor(data?: AssessmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Assessment`.
   */
  public static getModelName() {
    return "Assessment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Assessment for dynamic purposes.
  **/
  public static factory(data: AssessmentInterface): Assessment{
    return new Assessment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Assessment',
      plural: 'Assessments',
      path: 'Assessments',
      idName: 'id',
      properties: {
        "templateId": {
          name: 'templateId',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: false
        },
        "description": {
          name: 'description',
          type: 'any'
        },
        "displayNameOverride": {
          name: 'displayNameOverride',
          type: 'any'
        },
        "assignedToObjectType": {
          name: 'assignedToObjectType',
          type: 'string'
        },
        "assignedToObjectId": {
          name: 'assignedToObjectId',
          type: 'number'
        },
        "startTs": {
          name: 'startTs',
          type: 'number'
        },
        "endTs": {
          name: 'endTs',
          type: 'number'
        },
        "showOnFirstMatch": {
          name: 'showOnFirstMatch',
          type: 'boolean',
          default: false
        },
        "showCriteria": {
          name: 'showCriteria',
          type: 'any'
        },
        "forceCompletion": {
          name: 'forceCompletion',
          type: 'boolean',
          default: false
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        template: {
          name: 'template',
          type: 'AssessmentTemplate',
          model: 'AssessmentTemplate',
          relationType: 'belongsTo',
                  keyFrom: 'templateId',
          keyTo: 'id'
        },
      }
    }
  }
}
