/* tslint:disable */
import {
  CurrencyType,
  UserAccount
} from '../index';

declare var Object: any;
export interface CurrencyInterface {
  "balance"?: number;
  "currencyTypeId"?: number;
  "userAccountId"?: number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  currencyType?: CurrencyType;
  user?: UserAccount;
}

export class Currency implements CurrencyInterface {
  "balance": number;
  "currencyTypeId": number;
  "userAccountId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  currencyType: CurrencyType;
  user: UserAccount;
  constructor(data?: CurrencyInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Currency`.
   */
  public static getModelName() {
    return "Currency";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Currency for dynamic purposes.
  **/
  public static factory(data: CurrencyInterface): Currency{
    return new Currency(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Currency',
      plural: 'Currencies',
      path: 'Currencies',
      idName: 'id',
      properties: {
        "balance": {
          name: 'balance',
          type: 'number'
        },
        "currencyTypeId": {
          name: 'currencyTypeId',
          type: 'number'
        },
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        currencyType: {
          name: 'currencyType',
          type: 'CurrencyType',
          model: 'CurrencyType',
          relationType: 'belongsTo',
                  keyFrom: 'currencyTypeId',
          keyTo: 'id'
        },
        user: {
          name: 'user',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
      }
    }
  }
}
