/* tslint:disable */

declare var Object: any;
export interface LibraryObjectInterface {
  "libraryId": number;
  "objectId": number;
  "objectTypeId": number;
  "active"?: boolean;
  "isPro"?: number;
  "maxFreeUsage"?: number;
  "name"?: string;
  "strings"?: any;
  "image"?: string;
  "order"?: number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class LibraryObject implements LibraryObjectInterface {
  "libraryId": number;
  "objectId": number;
  "objectTypeId": number;
  "active": boolean;
  "isPro": number;
  "maxFreeUsage": number;
  "name": string;
  "strings": any;
  "image": string;
  "order": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: LibraryObjectInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `LibraryObject`.
   */
  public static getModelName() {
    return "LibraryObject";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of LibraryObject for dynamic purposes.
  **/
  public static factory(data: LibraryObjectInterface): LibraryObject{
    return new LibraryObject(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LibraryObject',
      plural: 'LibraryObjects',
      path: 'LibraryObjects',
      idName: 'id',
      properties: {
        "libraryId": {
          name: 'libraryId',
          type: 'number'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "objectTypeId": {
          name: 'objectTypeId',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "isPro": {
          name: 'isPro',
          type: 'number'
        },
        "maxFreeUsage": {
          name: 'maxFreeUsage',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "strings": {
          name: 'strings',
          type: 'any'
        },
        "image": {
          name: 'image',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
