import { Injectable, EventEmitter, Renderer2, RendererFactory2 } from '@angular/core';
import { MachineComponent } from '../modules/machine/components/machine/machine.component';

export enum PlayerEvents {
  MACHINE_CLOSE = 'player:machine.close',
  MACHINE_COMPLETED = 'player:machine.completed',
  MACHINE_SWITCHED = 'player:machine.switch',
  MACHINE_SKIPPED = 'player:machine.skip',

  LESSON_SWITCH = 'player:lesson.switch',
  LESSON_END = 'player:lesson.end',
  LESSON_SWITCH_MACHINE = 'player:lesson.switch_machine',
}

export type EventData = {
  type: string;
  [key: string]: any;
};

type Observer = (data: EventData) => void;

@Injectable({
  providedIn: 'root',
})
export class PlayerCommunicationsService {
  private activeMachineThumbnail: MachineComponent;
  private animCircle: HTMLElement;
  private $renderer: Renderer2;

  private observers: Observer[] = [];

  constructor(private $rendererFact: RendererFactory2) {
    this.$renderer = this.$rendererFact.createRenderer(null, null);

    this.messageListener = this.messageListener.bind(this);

    window.addEventListener('message', this.messageListener);
  }

  messageListener(event: MessageEvent) {
    if (!event.data || typeof event.data !== 'object' || !String(event.data.type).startsWith('player:')) {
      return;
    }
    this.notifyObservers(event.data);
  }

  notifyObservers(data: EventData) {
    for (let i = 0; i < this.observers.length; i++) {
      const observer = this.observers[i];
      observer(data);
    }
  }

  sendMessageToObservers() {}

  setActiveMachineThumbnail(thumbnail: MachineComponent) {
    this.activeMachineThumbnail = thumbnail;
  }

  setActiveMachineCompleted() {
    if (this.activeMachineThumbnail?.data) {
      this.activeMachineThumbnail.data.completed = true;
    }
  }

  createAnimWipe(rect?: any) {
    if (this.animCircle) return;
    let c = this.$renderer.createElement('div');
    if (rect) {
      this.$renderer.addClass(c, 'square-wipe');
      this.$renderer.setStyle(c, 'display', 'block');
      this.$renderer.setStyle(c, 'left', Math.round(rect.left) + 'px');
      this.$renderer.setStyle(c, 'top', Math.round(rect.top) + 'px');
      this.$renderer.setStyle(c, 'width', Math.round(rect.width) + 'px');
      this.$renderer.setStyle(c, 'height', Math.round(rect.height) + 'px');
      setTimeout(() => {
        this.$renderer.setStyle(this.animCircle, 'left', 0);
        this.$renderer.setStyle(this.animCircle, 'top', 0);
        this.$renderer.setStyle(this.animCircle, 'width', window.innerWidth + 'px');
        this.$renderer.setStyle(this.animCircle, 'height', window.innerHeight + 'px');
        this.$renderer.setStyle(c, 'border-radius', '0px');
      }, 10);
    } else {
      this.$renderer.setStyle(c, 'left', '0px');
      this.$renderer.setStyle(c, 'top', '0px');
      this.$renderer.setStyle(c, 'bottom', '0px');
      this.$renderer.setStyle(c, 'right', '0px');
    }
    this.$renderer.appendChild(document.body, c);
    this.animCircle = c;
  }

  hideAnimCircle() {
    if (this.animCircle) {
      this.animCircle.remove();
      this.animCircle = null;
    }
  }

  subscribe(callback: Observer) {
    this.observers.push(callback);

    return this;
  }

  unsubscribe(callback: Observer) {
    this.observers = this.observers.filter((observer) => observer !== callback);

    return this;
  }

  sendMessage(name: string, params: { [key: string]: any } = {}) {
    const iframe = document.getElementById('player-iframe') as HTMLIFrameElement;
    if (iframe) {
      iframe.contentWindow.postMessage({ ...params, type: `player:${name}` }, '*');
    }
  }
}
