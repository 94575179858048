/* tslint:disable */
import {
  UserAccount,
  Library
} from '../index';

declare var Object: any;
export interface UserAccountTrayFavoriteInterface {
  "userAccountId": number;
  "trayId": number;
  "objectId": number;
  "objectTypeId": number;
  "active"?: boolean;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  user?: UserAccount;
  library?: Library;
}

export class UserAccountTrayFavorite implements UserAccountTrayFavoriteInterface {
  "userAccountId": number;
  "trayId": number;
  "objectId": number;
  "objectTypeId": number;
  "active": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  user: UserAccount;
  library: Library;
  constructor(data?: UserAccountTrayFavoriteInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountTrayFavorite`.
   */
  public static getModelName() {
    return "UserAccountTrayFavorite";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountTrayFavorite for dynamic purposes.
  **/
  public static factory(data: UserAccountTrayFavoriteInterface): UserAccountTrayFavorite{
    return new UserAccountTrayFavorite(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountTrayFavorite',
      plural: 'UserAccountTrayFavorites',
      path: 'UserAccountTrayFavorites',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "trayId": {
          name: 'trayId',
          type: 'number'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "objectTypeId": {
          name: 'objectTypeId',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
        library: {
          name: 'library',
          type: 'Library',
          model: 'Library',
          relationType: 'belongsTo',
                  keyFrom: 'objectId',
          keyTo: 'id'
        },
      }
    }
  }
}
