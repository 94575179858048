import {
  trigger,
  state,
  style,
  group,
  animate,
  transition,
  query,
  animateChild,
  AnimationMetadata,
  keyframes,
  // ...
} from '@angular/animations';
import { getTranslationDeclStmts } from '@angular/compiler/src/render3/view/template';
import { access } from 'fs';
import { Z_FIXED } from 'zlib';

export const fadeDown = trigger('fadeDown', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ opacity: 1 })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [style({ opacity: 0, transform: 'translate(0, 20px)' }), animate(300)]),

  // fade out when destroyed. this could also be written as transition('void => *')
  transition(':leave', animate(100, style({ opacity: 0, transform: 'translate(0, 10px)' }))),
]);

export const fader = trigger('routeAnimations', [
  // transition('Challenges => Challenge', [
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       left: 0,
  //       top: 0,
  //       width: '100%',
  //       opacity: 0,
  //     }),
  //   ]),
  //   // Animate the new page in
  //   group([
  //     query(':leave', [animate('1000ms ease', style({ opacity: 1 }))]),
  //     query(':enter', [
  //       style({ transform: 'translateY(100vh)' }),
  //       animate(
  //         '1000ms ease',
  //         style({ transform: 'translateY(0px)', opacity: 1 })
  //       ),
  //     ]),
  //   ]),
  // ]),
  transition('* <=> *', [
    // Set a default  style for enter and leave
    query(':enter, :leave', [
      style({
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        opacity: 0,
      }),
    ]),
    // Animate the new page in
    group([
      query(':leave', [animate('300ms ease', style({ opacity: 0 }))], { optional: true }),
      query(':enter', [animate('300ms ease', style({ opacity: 1 }))]),
    ]),
  ]),
]);

export const swipeableViews = trigger('swipeableViews', [
  transition(
    'Leaderboards=>Machines, Machines=>Overview, Leaderboards=>Overview, Community=>Machines, Community=>Leaderboards, Community=>Overview',
    [
      // Set a default  style for enter and leave
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 'auto',
          width: '100%',
        }),
      ]),
      query(':enter', [
        style({
          transform: 'translateX(-100vw)',
        }),
      ]),
      // Animate the new page in
      group([
        query(':leave', [animate('300ms ease', style({ transform: 'translateX(100vw)' }))], {
          optional: true,
        }),
        query(':enter', [animate('300ms ease', style({ transform: 'translateX(0vw)' }))]),
      ]),
    ]
  ),
  transition(
    'Machines=>Leaderboards, Overview=>Machines, Overview=>Leaderboards, Machines=>Community, Leaderboards=>Community, Overview=>Community',
    [
      // Set a default  style for enter and leave
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 'auto',
          width: '100%',
        }),
      ]),
      query(':enter', [
        style({
          transform: 'translateX(100vw)',
        }),
      ]),
      // Animate the new page in
      group([
        query(':leave', [animate('300ms ease', style({ transform: 'translateX(-100vw)' }))], {
          optional: true,
        }),
        query(':enter', [animate('300ms ease', style({ transform: 'translateX(0vw)' }))]),
      ]),
    ]
  ),
]);

export const simpleFade = trigger('simpleFadeAnimation', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ opacity: 1 })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [style({ opacity: 0 }), animate(100)]),

  // fade out when destroyed. this could also be written as transition('void => *')
  transition(
    ':leave',
    animate(
      50,
      style({
        opacity: 0,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      })
    )
  ),
]);

export const scaleUp = trigger('scaleUp', [
  // the "in" style determines the "resting" state of the element when it is visible.
  state('in', style({ opacity: 0.5, transform: 'translateY(-20px)' })),

  // fade in when created. this could also be written as transition('void => *')
  transition(':enter', [style({ opacity: 0, transform: 'scale(0.2)' }), animate(1000)]),
]);

export const slideToSide = () => {
  const closeTiming = '0.1s';
  const openTiming = '0.2s';

  const directions = [
    [null, '-110%'],
    ['ltr', '-110%'],
    ['rtl', '110%'],
  ];

  const metaData = directions.reduce((acc: AnimationMetadata[], direction) => {
    const directionName = `${direction[0] ? '-' : ''}${direction[0] || ''}`;

    return [
      ...acc,
      state(
        `open${directionName}`,
        style({
          transform: 'translateX(0%)',
        })
      ),
      state(
        `close${directionName}`,
        style({
          transform: `translateX(${direction[1]})`,
        })
      ),
      transition(`open${directionName} => close${directionName}`, [animate(closeTiming)]),
      transition(`close${directionName} => open${directionName}`, [animate(openTiming)]),
    ];
  }, []);

  return trigger('slideToSide', metaData);
};

export const fadeInOut = () =>
  trigger('fadeInOut', [
    state(
      'open',
      style({
        opacity: 1,
        pointerEvents: 'auto',
      })
    ),
    state(
      'close',
      style({
        opacity: 0,
        pointerEvents: 'none',
      })
    ),
    transition('open => close', [animate('0.1s')]),
    transition('close => open', [animate('0.2s')]),
  ]);

export const playlistMachine = () => {
  const directions = [
    [null, '-100%'],
    ['ltr', '-100%'],
    ['rtl', '100%'],

    [null, '-200%', 'double'],
    ['ltr', '-200%', 'double'],
    ['rtl', '200%', 'double'],
  ];

  const metaData = directions.reduce((acc: AnimationMetadata[], direction) => {
    let directionName = [direction[0], direction[2]].filter(Boolean).join('-');
    directionName = directionName ? `-${directionName}` : '';
    return [
      ...acc,
      state(
        `slide${directionName}`,
        style({
          transform: `translateX(calc(${direction[1]} - 1rem))`,
        })
      ),
      transition(`* => slide${directionName}`, [animate('0.8s ease-in-out')]),
    ];
  }, []);

  return trigger('playlistMachine', metaData);
};

export const zoomIn = () => {
  return trigger('zoomIn', [
    transition(':enter', [style({ transform: 'scale(0)' }), animate('0.4s linear', style({ transform: 'scale(1)' }))]),
  ]);
};
