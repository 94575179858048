/* tslint:disable */

declare var Object: any;
export interface DiagnosticLogInterface {
  "start"?: number;
  "finish"?: number;
  "duration"?: number;
  "numberOfUsers"?: number;
  "arrivalTime"?: number;
  "description"?: string;
  "status"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class DiagnosticLog implements DiagnosticLogInterface {
  "start": number;
  "finish": number;
  "duration": number;
  "numberOfUsers": number;
  "arrivalTime": number;
  "description": string;
  "status": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: DiagnosticLogInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DiagnosticLog`.
   */
  public static getModelName() {
    return "DiagnosticLog";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of DiagnosticLog for dynamic purposes.
  **/
  public static factory(data: DiagnosticLogInterface): DiagnosticLog{
    return new DiagnosticLog(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DiagnosticLog',
      plural: 'DiagnosticLogs',
      path: 'DiagnosticLogs',
      idName: 'id',
      properties: {
        "start": {
          name: 'start',
          type: 'number'
        },
        "finish": {
          name: 'finish',
          type: 'number'
        },
        "duration": {
          name: 'duration',
          type: 'number'
        },
        "numberOfUsers": {
          name: 'numberOfUsers',
          type: 'number'
        },
        "arrivalTime": {
          name: 'arrivalTime',
          type: 'number'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
