/* tslint:disable */
import {
  Challenge,
  Machine
} from '../index';

declare var Object: any;
export interface MachinePrizeInterface {
  "machineId": number;
  "prizeId": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  board?: Challenge;
  machine?: Machine;
}

export class MachinePrize implements MachinePrizeInterface {
  "machineId": number;
  "prizeId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  board: Challenge;
  machine: Machine;
  constructor(data?: MachinePrizeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MachinePrize`.
   */
  public static getModelName() {
    return "MachinePrize";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MachinePrize for dynamic purposes.
  **/
  public static factory(data: MachinePrizeInterface): MachinePrize{
    return new MachinePrize(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MachinePrize',
      plural: 'MachinePrizes',
      path: 'MachinePrizes',
      idName: 'id',
      properties: {
        "machineId": {
          name: 'machineId',
          type: 'number'
        },
        "prizeId": {
          name: 'prizeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        board: {
          name: 'board',
          type: 'Challenge',
          model: 'Challenge',
          relationType: 'belongsTo',
                  keyFrom: 'prizeId',
          keyTo: 'id'
        },
        machine: {
          name: 'machine',
          type: 'Machine',
          model: 'Machine',
          relationType: 'belongsTo',
                  keyFrom: 'machineId',
          keyTo: 'id'
        },
      }
    }
  }
}
