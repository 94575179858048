/* tslint:disable */
import {
  Board,
  Chip
} from '../index';

declare var Object: any;
export interface BoardChipInterface {
  "boardId"?: number;
  "chipId"?: number;
  "data"?: string;
  "position"?: any;
  "isRoot"?: boolean;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "sortOrder"?: number;
  "boardGridCode"?: string;
  "id"?: number;
  board?: Board;
  chip?: Chip;
}

export class BoardChip implements BoardChipInterface {
  "boardId": number;
  "chipId": number;
  "data": string;
  "position": any;
  "isRoot": boolean;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "sortOrder": number;
  "boardGridCode": string;
  "id": number;
  board: Board;
  chip: Chip;
  constructor(data?: BoardChipInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `BoardChip`.
   */
  public static getModelName() {
    return "BoardChip";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of BoardChip for dynamic purposes.
  **/
  public static factory(data: BoardChipInterface): BoardChip{
    return new BoardChip(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'BoardChip',
      plural: 'BoardChips',
      path: 'BoardChips',
      idName: 'id',
      properties: {
        "boardId": {
          name: 'boardId',
          type: 'number'
        },
        "chipId": {
          name: 'chipId',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "position": {
          name: 'position',
          type: 'any'
        },
        "isRoot": {
          name: 'isRoot',
          type: 'boolean'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "sortOrder": {
          name: 'sortOrder',
          type: 'number'
        },
        "boardGridCode": {
          name: 'boardGridCode',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        board: {
          name: 'board',
          type: 'Board',
          model: 'Board',
          relationType: 'belongsTo',
                  keyFrom: 'boardId',
          keyTo: 'id'
        },
        chip: {
          name: 'chip',
          type: 'Chip',
          model: 'Chip',
          relationType: 'belongsTo',
                  keyFrom: 'chipId',
          keyTo: 'id'
        },
      }
    }
  }
}
