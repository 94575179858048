<div
  class="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center w-screen h-screen p-4 pb-5 antialiased  popup__root bg-grape-500 text-grape-500"
  [style]="{ zIndex: 999 }" (click)="handleOverlayClose($event)" #overlay [ngClass]="{
    'locked-backdrop': locked,
    'bg-opacity-50': !dark,
    'bg-opacity-90': dark
  }">
  <div class="locked-background" *ngIf="locked && !closable"></div>
  <div class="flex flex-col w-full overflow-hidden max-h-popup rounded-2xl"
    [ngClass]="[sizeClassName, styled ?'bg-white' : '', bodyClass]">
    <div *ngIf="showHeader" class="relative p-4 border-0 border-b border-solid border-mulberry-500">
      <div class="text-lg font-medium text-center">
        <ng-content select="ui-popup-title"></ng-content>
      </div>
      <button *ngIf="closable" (click)="close.emit($event)"
        class="absolute p-0 rounded-lg shadow-none hover:bg-mulberry-300 focus:ring-2 focus:ring-mulberry-550 focus:outline-none text-mulberry-600 top-2 right-3">
        <svg class="w-5" viewBox="0 0 32 32">
          <path d="M21 23.5c-.5 0-1-.2-1.4-.6l-11-11c-.8-.8-.8-2 0-2.8s2-.8 2.8 0l11 11c.8.8.8 2 0 2.8-.4.4-.9.6-1.4.6z"
            fill="currentColor"></path>
          <path
            d="M10 23.5c-.5 0-1-.2-1.4-.6-.8-.8-.8-2 0-2.8l11-11c.8-.8 2-.8 2.8 0s.8 2 0 2.8l-11 11c-.4.4-.9.6-1.4.6z"
            fill="currentColor"></path>
        </svg>
      </button>
    </div>

    <div class="flex-1 overflow-y-auto">
      <ng-content select="ui-popup-body"></ng-content>
    </div>

    <ng-content select="ui-popup-footer"></ng-content>
  </div>
</div>