<div class="toggle-outer" [class.on]="on">
  <div class="toggle" (click)="flip()">
    <div class="toggle-overlay">
      <div class="flex-row">
        <div class="toggle-text">On</div>
        <div class="circle"></div>
        <div class="toggle-text">Off</div>
      </div>
    </div>
  </div>
</div>
