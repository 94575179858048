// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.dev.polyup.com',
  apiVersion: 'api',
  meetPolyId: 954,
  gaTrackingId: 'UA-78464974-10',
  googleClientId: '882294190473-540f162sd51tcqpmi0o2ak4e91ur6up9.apps.googleusercontent.com',
  name: 'dev',

  mqttTopic: 'dev/polyup/webapp/',
  mqttURL: 'mqtt.dev.polyup.com',
  mqttPort: 443,
  helpUrl: 'https://dev.polyup.com/help/index.html',

  origins: [
    'stage.moddingapp.com',
    'moddingapp.com',
    'letsmod.com',
    'prod.letsmod.com',
    'app.letsmod.com',
    'www.letsmod.com',
    'dev.polyup.com',
    'stage.polyup.com',
    'polyup.com',
    'letsmod.com',
    '0.0.0.0',
    '127.0.0.1',
    'localhost',
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
