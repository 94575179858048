import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg';

import { SDKBrowserModule, InternalStorage } from './shared/sdk';

import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslocoRootModule } from './transloco-root.module';
import { DelayedHoverDirective } from './shared/pipes/DelayedHoverDirective';
import { ToastrModule } from 'ngx-toastr';

import { registerLocaleData } from '@angular/common';
import localAr from '@angular/common/locales/ar';

import { HTTPInterceptor } from './shared/guards/interceptor';
import { HelpModule } from './shared/modules/help/help.module';

import { environment } from 'src/environments/environment';

import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { ModalModule } from './shared/modules/modal/modal.module';
import { MyDirectivesModule } from './shared/pipes/MyDirectives.module';

import { LocalStorageService } from './shared/services/local-storage.service';
import { SocialLoginModule, GoogleLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { DropdownModule } from './shared/modules/dropdown/dropdown.module';
import { FirstModModule } from './shared/modules/first-mod/first-mod.module';

registerLocaleData(localAr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ModalModule,
    MyDirectivesModule,
    FormsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    HttpClientModule,
    SDKBrowserModule.forRoot({
      provide: InternalStorage,
      useClass: LocalStorageService,
    }),
    InlineSVGModule,
    NgxPermissionsModule.forRoot(),
    SocialLoginModule,
    TranslocoRootModule,
    ToastrModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.gaTrackingId),
    DropdownModule,
    HelpModule,
    FirstModModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HTTPInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId, { prompt: 'select_account' }),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
