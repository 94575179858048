/* tslint:disable */
import {
  Chip
} from '../index';

declare var Object: any;
export interface ChipHistoryInterface {
  "chipId"?: number;
  "userAccountId": number;
  "uuid"?: string;
  "shareId"?: string;
  "name": string;
  "data"?: string;
  "script"?: string;
  "goals"?: string;
  "tags"?: string;
  "active": boolean;
  "status": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  chip?: Chip;
}

export class ChipHistory implements ChipHistoryInterface {
  "chipId": number;
  "userAccountId": number;
  "uuid": string;
  "shareId": string;
  "name": string;
  "data": string;
  "script": string;
  "goals": string;
  "tags": string;
  "active": boolean;
  "status": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  chip: Chip;
  constructor(data?: ChipHistoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ChipHistory`.
   */
  public static getModelName() {
    return "ChipHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ChipHistory for dynamic purposes.
  **/
  public static factory(data: ChipHistoryInterface): ChipHistory{
    return new ChipHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ChipHistory',
      plural: 'ChipHistories',
      path: 'ChipHistories',
      idName: 'id',
      properties: {
        "chipId": {
          name: 'chipId',
          type: 'number'
        },
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "uuid": {
          name: 'uuid',
          type: 'string'
        },
        "shareId": {
          name: 'shareId',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "script": {
          name: 'script',
          type: 'string'
        },
        "goals": {
          name: 'goals',
          type: 'string'
        },
        "tags": {
          name: 'tags',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        chip: {
          name: 'chip',
          type: 'Chip',
          model: 'Chip',
          relationType: 'belongsTo',
                  keyFrom: 'chipId',
          keyTo: 'id'
        },
      }
    }
  }
}
