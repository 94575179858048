<div class="sticky top-0 z-50 hidden print:hidden md:block">
  <app-banner-priority></app-banner-priority>

  <div class="items-center justify-between p-4 bg-white nav-outer flex">
    <div class="flex-row-center">
      <a class="ml-4 mr-8" [routerLink]="homeUrl">
        <img src="/assets/img/letsmod_yellow.svg" class="w-20" />
      </a>
      <ng-container *ngFor="let route of routes">
        <a class="after:w-full after:absolute after:h-1 after:opacity-0 group hover:after:opacity-100 relative after:bg-[#2F97C9] after:-bottom-1.5 after:left-0 after:rounded-full hover:!text-[#2F97C9] px-1 mt-2"
          [routerLink]="route.url" routerLinkActive="orange-underline after:!bg-[#2F97C9]"
          [routerLinkActiveOptions]="{ exact: true }">{{ route.name }}</a>
      </ng-container>
    </div>

    <div class="relative" *ngIf="$myuser.loggedIn(); else loginPlay">
      <button class="px-4 py-2 transition rounded-lg rounde hover:bg-white hover:bg-opacity-25"
        (click)="userDropdownActive = true">
        Logged in as {{ $myuser.getName() }}
      </button>
      <app-dropdown *ngIf="userDropdownActive" positioning="bottom-right" [items]="userDropdownOptions"
        (close)="userDropdownActive = false" (action)="handleAction($event)"></app-dropdown>
    </div>
    <ng-template #loginPlay>
      <div class="flex-row space-x-2">
        <button class="bg-[#2F97C9] hover:bg-[#1F7BA7] text-white rounded-sm p-2 text-sm font-light"
          (click)="activateLogin()">
          Login
        </button>
      </div>
    </ng-template>
  </div>
  <app-banner></app-banner>
</div>

<div class="sticky top-0 z-50 print:hidden md:hidden">
  <app-banner-priority></app-banner-priority>

  <div class="container relative flex items-center justify-center py-4 space-x-4 nav-outer">
    <button class="absolute p-2 transform -translate-y-1/2 left-2 top-1/2" (click)="this.menuOpen = !this.menuOpen">
      <i class="fas fa-bars"></i>
    </button>

    <a [routerLink]="homeUrl">
      <img src="/assets/img/letsmod_yellow.svg" class="w-20" />
    </a>

    <div *ngIf="$myuser.loggedIn(); else loginPlayPhone" class="absolute right-2 transform -translate-y-1/2 top-1/2">
      <button
        class="flex items-center justify-center w-8 h-8 text-sm font-medium uppercase bg-white border-2 rounded-full text-grape-500 border-mulberry-550"
        (click)="userDropdownActivePhone = true">
        {{ userFirstChar }}
      </button>

      <app-dropdown *ngIf="userDropdownActivePhone" positioning="bottom-right" [items]="userDropdownOptions"
        (close)="userDropdownActivePhone = false" (action)="handleAction($event)"></app-dropdown>
    </div>
    <ng-template #loginPlayPhone>
      <div></div>
    </ng-template>
  </div>

  <app-banner></app-banner>
</div>

<div (click)="menuOpen = false" [@fadeInOut]="menuOpen ? 'open' : 'close'"
  class="fixed inset-0 z-50 h-screen p-4 pt-10 space-y-2 bg-opacity-25 bg-grape-400"></div>

<div [@slideToSide]="menuOpen ? 'open-' + ('_direction' | transloco) : 'close-' + ('_direction' | transloco)"
  class="fixed top-0 left-0 z-50 h-screen p-4 pt-10 space-y-2 bg-white shadow-lg w-60" (click)="menuOpen = false">
  <div (click)="$event.stopPropagation()">
    <ng-container *ngFor="let route of routes">
      <div
        class="mb-2 lg:mb-0 relative flex items-center justify-start w-full p-2 space-x-2 text-sm font-light rounded-lg lg:pl-0.5 lg:py-0.5 text-mulberry-650 group focus:outline-none focus:ring-2 focus:ring-mulberry-500 hover:bg-opacity-20 focus:bg-mulberry-100 cursor-pointer"
        [routerLink]="route.url" routerLinkActive="bg-mulberry-500" (click)="this.menuOpen = false"
        [routerLinkActiveOptions]="{ exact: true }">
        {{ route.name }}
      </div>
    </ng-container>

    <div *ngIf="!$myuser.loggedIn()" class="flex flex-col items-start space-y-2 mt-2">
      <div class="h-1 bg-mulberry-100 w-full"></div>
      <button class="bg-[#2F97C9] hover:bg-[#1F7BA7] text-white rounded-sm p-2 text-sm font-light"
        (click)="activateLogin()">
        Log in
      </button>
    </div>
  </div>
</div>