/* tslint:disable */
import {
  BoardProgress,
  BoardPortal,
  BoardChip,
  BoardHistory,
  MachineBoard,
  UserAccount
} from '../index';

declare var Object: any;
export interface BoardInterface {
  "userAccountId": number;
  "uuid"?: string;
  "shareId"?: string;
  "name": string;
  "data"?: string;
  "grid"?: string;
  "tags"?: string;
  "systemTags"?: string;
  "active": boolean;
  "status": number;
  "originalBoardHistoryId"?: number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "isEditable"?: boolean;
  "arData"?: string;
  "modelId"?: string;
  "bucketId"?: number;
  "strings"?: any;
  "description"?: string;
  "type"?: number;
  "memory"?: string;
  "localId"?: number;
  "id"?: number;
  userProgress?: BoardProgress[];
  parentBoard?: BoardPortal;
  portals?: BoardPortal[];
  chips?: BoardChip[];
  history?: BoardHistory[];
  bucket?: any;
  machines?: MachineBoard[];
  userAccount?: UserAccount;
  createdByUser?: UserAccount;
  updatedByUser?: UserAccount;
}

export class Board implements BoardInterface {
  "userAccountId": number;
  "uuid": string;
  "shareId": string;
  "name": string;
  "data": string;
  "grid": string;
  "tags": string;
  "systemTags": string;
  "active": boolean;
  "status": number;
  "originalBoardHistoryId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "isEditable": boolean;
  "arData": string;
  "modelId": string;
  "bucketId": number;
  "strings": any;
  "description": string;
  "type": number;
  "memory": string;
  "localId": number;
  "id": number;
  userProgress: BoardProgress[];
  parentBoard: BoardPortal;
  portals: BoardPortal[];
  chips: BoardChip[];
  history: BoardHistory[];
  bucket: any;
  machines: MachineBoard[];
  userAccount: UserAccount;
  createdByUser: UserAccount;
  updatedByUser: UserAccount;
  constructor(data?: BoardInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Board`.
   */
  public static getModelName() {
    return "Board";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Board for dynamic purposes.
  **/
  public static factory(data: BoardInterface): Board{
    return new Board(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Board',
      plural: 'Boards',
      path: 'Boards',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "uuid": {
          name: 'uuid',
          type: 'string'
        },
        "shareId": {
          name: 'shareId',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "grid": {
          name: 'grid',
          type: 'string'
        },
        "tags": {
          name: 'tags',
          type: 'string'
        },
        "systemTags": {
          name: 'systemTags',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "originalBoardHistoryId": {
          name: 'originalBoardHistoryId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "isEditable": {
          name: 'isEditable',
          type: 'boolean',
          default: true
        },
        "arData": {
          name: 'arData',
          type: 'string'
        },
        "modelId": {
          name: 'modelId',
          type: 'string'
        },
        "bucketId": {
          name: 'bucketId',
          type: 'number',
          default: 0
        },
        "strings": {
          name: 'strings',
          type: 'any'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'number'
        },
        "memory": {
          name: 'memory',
          type: 'string'
        },
        "localId": {
          name: 'localId',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        userProgress: {
          name: 'userProgress',
          type: 'BoardProgress[]',
          model: 'BoardProgress',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'boardId'
        },
        parentBoard: {
          name: 'parentBoard',
          type: 'BoardPortal',
          model: 'BoardPortal',
          relationType: 'belongsTo',
                  keyFrom: 'id',
          keyTo: 'id'
        },
        portals: {
          name: 'portals',
          type: 'BoardPortal[]',
          model: 'BoardPortal',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'parentBoardId'
        },
        chips: {
          name: 'chips',
          type: 'BoardChip[]',
          model: 'BoardChip',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'boardId'
        },
        history: {
          name: 'history',
          type: 'BoardHistory[]',
          model: 'BoardHistory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'boardId'
        },
        bucket: {
          name: 'bucket',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'bucketId',
          keyTo: 'id'
        },
        machines: {
          name: 'machines',
          type: 'MachineBoard[]',
          model: 'MachineBoard',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'boardId'
        },
        userAccount: {
          name: 'userAccount',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
        createdByUser: {
          name: 'createdByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'createdBy',
          keyTo: 'id'
        },
        updatedByUser: {
          name: 'updatedByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'updatedBy',
          keyTo: 'id'
        },
      }
    }
  }
}
