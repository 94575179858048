<div
  class="print:hidden relative"
  [ngClass]="{
    'bg-pineapple-450 text-grape-500': banner.color == 'pineapple' || !banner.color,
    'bg-cherry-300 text-cherry-800': banner.color == 'cherry',
    'bg-kiwi-500 text-kiwi-800': banner.color == 'kiwi',
    'bg-blueberry-500 text-blueberry-100': banner.color == 'blueberry',
    'text-sm': banner.size === 'sm',
    'text-center': banner.align === 'center',
    'py-5 min-h-banner': banner.size != 'sm',
    'py-4 font-light': banner.size == 'sm',
    'rounded-lg': rounded
  }"
>
  <button
    *ngIf="banner.close"
    class="absolute right-4 top-1/2 transform -translate-y-1/2 opacity-70 hover:opacity-100 text-current"
    [setSVGAttributes]="{ class: 'w-3 h-3' }"
    inlineSVG="assets/img/black/times.svg"
    (click)="close()"
  ></button>
  <div
    [ngClass]="{
      'justify-center md:items-center md:justify-between': banner.align != 'center',
      'items-center justify-center': banner.align == 'center'
    }"
    class="container md:flex md: md:flex-row"
  >
    <div
      [ngClass]="{
        ' items-center flex-1': banner.align != 'center'
      }"
      class="flex"
    >
      <img *ngIf="banner.image" [src]="banner.image" class="object-contain w-12 h-12 mr-4 flex-0" />
      <div>
        <div class="text-xl font-bold" *ngIf="banner.title">{{ banner.title | transloco }}</div>
        <div class="break-words" [innerHTML]="banner.content | transloco: banner.translocoParams"></div>
      </div>
    </div>
    <div class="flex flex-col items-center mt-4 md:mt-0 md:pl-4 flex-0" *ngIf="banner.action">
      <ui-button
        color="white"
        [success]="banner.action.success"
        [disabled]="banner.action.success || banner.action.loading"
        [loading]="banner.action.loading"
        (click)="onClick($event, banner.action)"
        [size]="banner.size || 'md'"
        *ngIf="banner.action.style != 'link'"
        >{{ banner.action.label | transloco }}</ui-button
      >

      <button
        *ngIf="banner.action.style == 'link'"
        class="underline font-normal text-grape-800 flex items-center"
        (click)="onClick($event, banner.action)"
      >
        {{ banner.action.label | transloco }}
        <img *ngIf="banner.action.icon" [src]="banner.action.icon" class="object-contain w-3 h-3 ml-2 flex-0" />
      </button>

      <button
        (click)="onClick($event, banner.secondaryAction)"
        *ngIf="banner.secondaryAction"
        class="block px-4 mx-auto mt-1 text-sm leading-tight text-center md:w-64 focus:outline-none hover:text-mango-600"
      >
        {{ banner.secondaryAction.label | transloco }}
      </button>
    </div>
  </div>
</div>
