<div class="flex flex-col p-4 sm:p-6 max-h-72 sm:max-h-96">
  <div class="mt-4 overflow-y-auto">
    <app-schools-list
      [keyword]="keyword"
      [entityId]="countryId"
      [language]="language"
      [school]="school?.id"
      [searchBox]="true"
      (onSelect)="onSelect.emit($event)"
      (createOther)="selectOtherSchool.emit($event)"
      [prependItem]="school"
      [otherSchool]="otherSchool"
    ></app-schools-list>
  </div>

  <ui-button size="lg" classes="mt-4" [disabled]="!school" (click)="submit.emit()">
    {{ "next" | transloco }}
  </ui-button>
</div>
