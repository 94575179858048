/* tslint:disable */
import {
  MachineProgress,
  MachineBoard,
  MachineHistory,
  MachineRecommendation,
  MachinePresentation,
  UserAccount
} from '../index';

declare var Object: any;
export interface MachineInterface {
  "userAccountId": number;
  "uuid"?: string;
  "shareId"?: string;
  "name": string;
  "data"?: string;
  "grid"?: string;
  "rootBoardId"?: number;
  "active": boolean;
  "status": number;
  "originalMachineHistoryId"?: number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "isDefault"?: boolean;
  "isEditable"?: boolean;
  "version": number;
  "bucketId"?: number;
  "thumbnailId"?: number;
  "strings"?: any;
  "description"?: string;
  "inappropriate"?: boolean;
  "isPublic"?: boolean;
  "hasLeaderboard"?: boolean;
  "leaderboardType"?: number;
  "leaderboardRankType"?: number;
  "requireModeration"?: boolean;
  "commentsEnabled"?: boolean;
  "featuredRank"?: number;
  "notCloneable"?: boolean;
  "autorun"?: string;
  "enableRunHelp"?: boolean;
  "id"?: number;
  userProgress?: MachineProgress[];
  boards?: MachineBoard[];
  history?: MachineHistory[];
  recommended?: MachineRecommendation[];
  presentation?: MachinePresentation;
  bucket?: any;
  thumbnail?: any;
  userAccount?: UserAccount;
  createdByUser?: UserAccount;
  updatedByUser?: UserAccount;
}

export class Machine implements MachineInterface {
  "userAccountId": number;
  "uuid": string;
  "shareId": string;
  "name": string;
  "data": string;
  "grid": string;
  "rootBoardId": number;
  "active": boolean;
  "status": number;
  "originalMachineHistoryId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "isDefault": boolean;
  "isEditable": boolean;
  "version": number;
  "bucketId": number;
  "thumbnailId": number;
  "strings": any;
  "description": string;
  "inappropriate": boolean;
  "isPublic": boolean;
  "hasLeaderboard": boolean;
  "leaderboardType": number;
  "leaderboardRankType": number;
  "requireModeration": boolean;
  "commentsEnabled": boolean;
  "featuredRank": number;
  "notCloneable": boolean;
  "autorun": string;
  "enableRunHelp": boolean;
  "id": number;
  userProgress: MachineProgress[];
  boards: MachineBoard[];
  history: MachineHistory[];
  recommended: MachineRecommendation[];
  presentation: MachinePresentation;
  bucket: any;
  thumbnail: any;
  userAccount: UserAccount;
  createdByUser: UserAccount;
  updatedByUser: UserAccount;
  constructor(data?: MachineInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Machine`.
   */
  public static getModelName() {
    return "Machine";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Machine for dynamic purposes.
  **/
  public static factory(data: MachineInterface): Machine{
    return new Machine(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Machine',
      plural: 'Machines',
      path: 'Machines',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "uuid": {
          name: 'uuid',
          type: 'string'
        },
        "shareId": {
          name: 'shareId',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'string'
        },
        "grid": {
          name: 'grid',
          type: 'string'
        },
        "rootBoardId": {
          name: 'rootBoardId',
          type: 'number'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "originalMachineHistoryId": {
          name: 'originalMachineHistoryId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "isDefault": {
          name: 'isDefault',
          type: 'boolean'
        },
        "isEditable": {
          name: 'isEditable',
          type: 'boolean',
          default: true
        },
        "version": {
          name: 'version',
          type: 'number'
        },
        "bucketId": {
          name: 'bucketId',
          type: 'number',
          default: 0
        },
        "thumbnailId": {
          name: 'thumbnailId',
          type: 'number'
        },
        "strings": {
          name: 'strings',
          type: 'any'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "inappropriate": {
          name: 'inappropriate',
          type: 'boolean',
          default: false
        },
        "isPublic": {
          name: 'isPublic',
          type: 'boolean',
          default: false
        },
        "hasLeaderboard": {
          name: 'hasLeaderboard',
          type: 'boolean',
          default: false
        },
        "leaderboardType": {
          name: 'leaderboardType',
          type: 'number',
          default: 1
        },
        "leaderboardRankType": {
          name: 'leaderboardRankType',
          type: 'number',
          default: 1
        },
        "requireModeration": {
          name: 'requireModeration',
          type: 'boolean',
          default: false
        },
        "commentsEnabled": {
          name: 'commentsEnabled',
          type: 'boolean',
          default: true
        },
        "featuredRank": {
          name: 'featuredRank',
          type: 'number',
          default: 0
        },
        "notCloneable": {
          name: 'notCloneable',
          type: 'boolean',
          default: false
        },
        "autorun": {
          name: 'autorun',
          type: 'string',
          default: 'on'
        },
        "enableRunHelp": {
          name: 'enableRunHelp',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        userProgress: {
          name: 'userProgress',
          type: 'MachineProgress[]',
          model: 'MachineProgress',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'machineId'
        },
        boards: {
          name: 'boards',
          type: 'MachineBoard[]',
          model: 'MachineBoard',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'machineId'
        },
        history: {
          name: 'history',
          type: 'MachineHistory[]',
          model: 'MachineHistory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'machineId'
        },
        recommended: {
          name: 'recommended',
          type: 'MachineRecommendation[]',
          model: 'MachineRecommendation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'recommendedMachineId'
        },
        presentation: {
          name: 'presentation',
          type: 'MachinePresentation',
          model: 'MachinePresentation',
          relationType: 'belongsTo',
                  keyFrom: 'shareId',
          keyTo: 'shareId'
        },
        bucket: {
          name: 'bucket',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'bucketId',
          keyTo: 'id'
        },
        thumbnail: {
          name: 'thumbnail',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'thumbnailId',
          keyTo: 'id'
        },
        userAccount: {
          name: 'userAccount',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
        createdByUser: {
          name: 'createdByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'createdBy',
          keyTo: 'id'
        },
        updatedByUser: {
          name: 'updatedByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'updatedBy',
          keyTo: 'id'
        },
      }
    }
  }
}
