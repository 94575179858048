/* tslint:disable */
import {
  Entity
} from '../index';

declare var Object: any;
export interface EntityTranslationInterface {
  "entityId"?: number;
  "languageCode"?: string;
  "name": string;
  "description"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "data"?: any;
  "address1"?: string;
  "address2"?: string;
  "city"?: string;
  "state"?: string;
  "zip"?: string;
  "country"?: string;
  "branding"?: any;
  "onboarding"?: any;
  "fromMessage"?: string;
  "fromSms"?: string;
  "fromEmail"?: string;
  "tags"?: string;
  "systemTags"?: string;
  "strings"?: any;
  "id"?: number;
  entity?: Entity;
}

export class EntityTranslation implements EntityTranslationInterface {
  "entityId": number;
  "languageCode": string;
  "name": string;
  "description": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "data": any;
  "address1": string;
  "address2": string;
  "city": string;
  "state": string;
  "zip": string;
  "country": string;
  "branding": any;
  "onboarding": any;
  "fromMessage": string;
  "fromSms": string;
  "fromEmail": string;
  "tags": string;
  "systemTags": string;
  "strings": any;
  "id": number;
  entity: Entity;
  constructor(data?: EntityTranslationInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `EntityTranslation`.
   */
  public static getModelName() {
    return "EntityTranslation";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of EntityTranslation for dynamic purposes.
  **/
  public static factory(data: EntityTranslationInterface): EntityTranslation{
    return new EntityTranslation(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'EntityTranslation',
      plural: 'EntityTranslations',
      path: 'EntityTranslations',
      idName: 'id',
      properties: {
        "entityId": {
          name: 'entityId',
          type: 'number'
        },
        "languageCode": {
          name: 'languageCode',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "zip": {
          name: 'zip',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "branding": {
          name: 'branding',
          type: 'any'
        },
        "onboarding": {
          name: 'onboarding',
          type: 'any'
        },
        "fromMessage": {
          name: 'fromMessage',
          type: 'string'
        },
        "fromSms": {
          name: 'fromSms',
          type: 'string'
        },
        "fromEmail": {
          name: 'fromEmail',
          type: 'string'
        },
        "tags": {
          name: 'tags',
          type: 'string'
        },
        "systemTags": {
          name: 'systemTags',
          type: 'string'
        },
        "strings": {
          name: 'strings',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        entity: {
          name: 'entity',
          type: 'Entity',
          model: 'Entity',
          relationType: 'belongsTo',
                  keyFrom: 'entityId',
          keyTo: 'id'
        },
      }
    }
  }
}
