import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
  Inject,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { simpleFade } from '../../../../../assets/animations';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],

  animations: [simpleFade],
  host: { '[@simpleFadeAnimation]': 'true' },
})
export class ModalComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Input() closeable: boolean = true;
  @Input() hideX: boolean = false;
  @Input() size: string;

  constructor(@Inject(DOCUMENT) private document: Document, private elRef: ElementRef) {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode == 27) {
      this.closeModal();
    }
  }
  ngOnInit(): void {
    this.document.body.classList.add('modal-open');
    if (this.size == 'fullScreen') {
      this.elRef.nativeElement.style.zIndex = 9999999;
    }
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('modal-open');
  }

  closeModal() {
    if (this.closeable) {
      this.close.emit();
    }
  }
}
