<ui-popup
  bodyClass="animate-popupUpIn drop-shadow-2xl"
  *ngIf="active"
  [styled]="false"
  [locked]="true"
  [dark]="true"
  [showHeader]="false"
  size="max-w-md"
>
  <ui-popup-body>
    <div class="pb-3 bg-[#5236EB]">
      <div
        style="background: linear-gradient(180deg, #ac58ff 0%, #5236eb 100%)"
        class="w-full bg-mulberry-400 relative border-opacity-5 rounded-xl"
      >
        <div class="absolute rounded-xl bottom-0 left-0 wi-full h-4 border-b border-white opacity-10 w-full"></div>
        <div
          class="w-36 bg-cover absolute right-0 top-0 h-full opacity-5"
          [style]="{
            backgroundImage: 'url(/assets/img/color/strip-pattern.svg)'
          }"
        ></div>

        <div
          class="w-36 bg-cover absolute left-0 top-0 h-full transform -rotate-180 opacity-5"
          [style]="{
            backgroundImage: 'url(/assets/img/color/strip-pattern.svg)'
          }"
        ></div>

        <div class="relative flex flex-col items-center text-white">
          <h1 class="px-6 py-6 m-0 text-3xl font-bold bg-white bg-opacity-10 rounded-b-xl">
            {{ "onboarding.popup.title" | transloco }}
            <div class="h-1 bg-white rounded"></div>
          </h1>

          <p class="my-8 px-4 text-center text-2xl font-medium px-8">{{ "onboarding.popup.text" | transloco }}</p>

          <button
            (click)="openMachine()"
            #goToButton
            class="mb-6 bg-pineapple-500 px-8 relative py-4 text-4xl group font-bold rounded-md overflow-hidden text-grape-800"
          >
            <span
              [ngStyle]="{
                width: buttonWidth
              }"
              class="absolute left-0 top-0  bg-pineapple-450  h-full"
            ></span>
            <span class="relative">{{ "onboarding.popup.cta" | transloco }}</span>
          </button>

          <button (click)="close()" class="absolute right-2 bottom-2 text-white opacity-70 hover:opacity-100">
            {{ "onboarding.popup.hyperlink" | transloco }}
          </button>
        </div>
      </div>
    </div>
  </ui-popup-body>
</ui-popup>
