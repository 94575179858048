import { Component, OnInit, NgZone, ViewChild, Input } from '@angular/core';
import { BannerService } from './banner.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
})
export class BannerComponent implements OnInit {
  constructor(private banner: BannerService, private zone: NgZone, private $translocoService: TranslocoService) {}

  banners: Banner[] = [];

  activeIndex: number = 0;
  swiperEnabled = true;
  @Input() contained = false;

  toggleButtonColor: string = '';
  @ViewChild('swiper') swiper;

  ngOnInit() {
    this.banner.subscribe((banners: Banner[]) => {
      if (!banners[this.activeIndex]) {
        this.activeIndex = 0;
      }
      const bannersList = banners.filter((banner) => !banner.priority);
      if (bannersList.length === banners.length) {
        this.banners = bannersList;
      } else {
        // don't show low priority banners if we got a priority banner
        this.banners = [];
      }
      this.updateToggleButtonColor();
    });
    this.$translocoService.langChanges$.subscribe((languageCode: string) => {
      if (this.swiper && this.swiper.swiperRef) {
        this.swiper.swiperRef.update();
      }
    });
  }

  onSlideChange(slide: number) {
    this.zone.run(() => {
      this.activeIndex = slide;
      this.updateToggleButtonColor();
    });
  }

  toggleSwiper() {
    this.swiperEnabled = !this.swiperEnabled;
    this.updateToggleButtonColor();
  }

  updateToggleButtonColor() {
    if (!this.banners || this.banners.length === 0) {
      return;
    }

    let color = '';
    if (this.swiperEnabled) {
      color = this.banners[this.activeIndex].color;
    } else {
      color = this.banners[this.banners.length - 1].color;
    }

    color = color || 'pineapple';

    switch (color) {
      case 'pineapple':
        color =
          'bg-pineapple-500 text-pineapple-700 bg-gradient-to-t hover:from-pineapple-450  hover:via-pineapple-450   hover:to-pineapple-500';
        break;
      case 'cherry':
        color =
          'bg-cherry-300 text-cherry-700 bg-gradient-to-t hover:from-cherry-200  hover:via-cherry-200   hover:to-cherry-300';
        break;
      case 'kiwi':
        color =
          'bg-kiwi-500 text-kiwi-700 bg-gradient-to-t hover:from-kiwi-450  hover:via-kiwi-450   hover:to-kiwi-500';
        break;
      case 'blueberry':
        color =
          'text-white bg-blueberry-500 text-blueberry-white bg-gradient-to-t hover:from-blueberry-400  hover:via-blueberry-400   hover:to-blueberry-500';
        break;
    }

    this.toggleButtonColor = color;
  }
}
