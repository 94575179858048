export enum SITEMAP {
  LANDING = 'about',
  ROOT = '',
  SEARCH = '/search',
  MACHINE = '/machine',
  TEAMS = 'teams',
  CHALLENGES = '/challenges',
  EDUCATORS = '/educators',
  LAB = 'lab',
  MACHINEPLAYER = 'machine',
  LOGGED_IN = '',
  TOS = 'terms-of-service',
  PRIVACY = '/about/privacy',
  HOME = '/home',
  PLAYLIST = '/playlist',
  MY_MACHINES = '/playlist?id=my',
  SETTINGS = '/settings',
  EDUCATORS_LANDING = '/about/educators',
  PARENTS_LANDING = '/about/parents',
  CHALLENGES_LANDING = '/about/mod-market',
  HOME_LANDING = '/about',
  TERMS = '/about/terms',
  ABOUT = '/about/story',
  CONTACT = '/about/contact',
  TEAM = '/about/team',
  PASSWORD_RESET = 'reset',
  VERIFY_EMAIL = 'verify',
  LEARN = '/educators/learn',
  MACHINES_IFRAME = 'machines',
  ADMIN = 'admin',
  CHALLENGE = 'challenge',
  DASHBOARD = 'dashboard',
  ACTIVITIES = '/activities',
  COMMUNITY = '/community',
  BADGES = 'badges',
  PUBLIC_MODS = "/about/mods"
}
