/* tslint:disable */

declare var Object: any;
export interface GlobalSettingsInterface {
  "keyName"?: string;
  "keyValue"?: string;
  "description"?: string;
  "displayName"?: string;
  "label"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class GlobalSettings implements GlobalSettingsInterface {
  "keyName": string;
  "keyValue": string;
  "description": string;
  "displayName": string;
  "label": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: GlobalSettingsInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GlobalSettings`.
   */
  public static getModelName() {
    return "GlobalSettings";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GlobalSettings for dynamic purposes.
  **/
  public static factory(data: GlobalSettingsInterface): GlobalSettings{
    return new GlobalSettings(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GlobalSettings',
      plural: 'GlobalSettings',
      path: 'GlobalSettings',
      idName: 'id',
      properties: {
        "keyName": {
          name: 'keyName',
          type: 'string'
        },
        "keyValue": {
          name: 'keyValue',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "label": {
          name: 'label',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
