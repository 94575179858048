import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserAccountApi, LoopBackAuth, V3Api } from 'src/app/shared/sdk';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NgxPermissionsService } from 'ngx-permissions';
import { SITEMAP } from 'src/utilities/sitemap';
import { UserService } from 'src/app/shared/services/user.service';
//import { AuthService, GoogleLoginProvider } from 'ng-social-login';

import { SocialAuthService } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  @Output() signup: EventEmitter<any> = new EventEmitter();
  SITEMAP = SITEMAP;
  email: string = '';
  password: string = '';
  errorText: string;
  forgotPass: boolean = false;
  forgotPasswordEmail: string = '';
  resetError: string;
  resetSucceeded: boolean = false;
  resendVerificationButton: boolean = false;
  resendVerifyMsg: string;
  authStateListener: Subscription;
  googleError: string;

  challengeInviteString: string;

  swgPopupOpened: boolean;
  googleLoading: boolean;

  challengeCode: string;
  isLogging: boolean;

  isSendingPasswordInstructions: boolean = false;

  errors: {
    email?: string;
    password?: string;
  } = {};

  constructor(
    private $auth: UserAccountApi,
    private $lbauth: LoopBackAuth,
    private $router: Router,
    private $route: ActivatedRoute,
    private $v3: V3Api,
    private $ngxPermissionsService: NgxPermissionsService,
    private $myuser: UserService,
    private $authService: SocialAuthService,
    private $translocoService: TranslocoService,
    private $toastr: ToastrService
  ) {
    this.challengeCode = this.$route.snapshot.queryParams.challengeInviteCode;
  }

  ngOnInit(): void {
    if (this.$myuser.loggedIn()) {
      this.$router.navigate(['', SITEMAP.ROOT]);
    }
    this.authStateListener = this.$authService.authState.subscribe((user) => {
      if (!this.swgPopupOpened) return;
      this.googleLoading = true;
      this.$v3.postSigninGoogle({ idToken: user.idToken }).subscribe(
        (user) => {
          console.log('setting user to', user);
          this.$lbauth.setToken({
            ...user,
            user: { ...user.userAccount, roles: user.roles, permissions: user.permissions },
          });
          this.redirect();
        },
        (error) => {
          this.googleLoading = false;
          switch (error.message) {
            case 'GOOGLE_ACCOUNT_NOT_EXISTS':
              this.googleError = 'swg.noAccount';
              break;

            case 'GOOGLE_ACCOUNT_EXISTS':
              this.googleError = 'swg.accountNotLinkedWithGoogle';
              break;
            default:
              this.googleError = 'swg.failed';
              break;
          }

          if (this.googleError == 'swg.noAccount') {
            this.$toastr.success(this.$translocoService.translate('swg.noAccount'));
            this.signup.emit();
          }
        }
      );
    });

    if (this.challengeCode) {
      this.$v3.getChallengeByCode(this.challengeCode, true).subscribe((res) => {
        this.challengeInviteString = this.$translocoService.translate('login.joinChallenge', {
          challenge: '<span class="text-orange thicc">' + res.name + '</span>',
        });
      });
    }
  }

  redirect() {
    if (this.$route.snapshot.queryParams.teamInviteCode) {
      window.location.href = `?teamInviteCode=${this.$route.snapshot.queryParams.teamInviteCode}`; // reload to keep invite code and apply it
    } else if (this.challengeCode) {
      window.location.href = '/teams' + window.location.search; //go to team page but apply code
    } else {
      window.location.href = '/'; // go to root
    }
  }

  login($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }

    this.errors = {};

    if (!this.email || String(this.email) === '') {
      this.errors.email = 'Emails is required';
      return;
    }

    if (!this.password || String(this.password) === '') {
      this.errors.password = 'Password is required';
      return;
    }

    this.errorText = undefined;
    this.resendVerificationButton = false;
    this.resendVerifyMsg = undefined;
    this.isLogging = true;
    this.$auth.login({ username: this.email, password: this.password }, null, true).subscribe(
      (user) => {
        this.$lbauth.setToken({
          ...user,
          user: { ...user.userAccount, roles: user.roles, permissions: user.permissions },
        });
        this.isLogging = false;
        this.redirect();
      },
      (error) => {
        this.isLogging = false;
        switch (error.code) {
          case 'LOGIN_FAILED': // wrong user pass
            this.errorText = 'login.failedUserPass';
            return;
          case 'LOGIN_WITH_GOOGLE_EXIST':
            this.errorText = 'login.useGoogleInstead';
            return;
          case 'EMAIL_NOT_VERIFIED':
            this.errorText = 'login.unverified';
            this.resendVerificationButton = true;
            return;
          case 'LOGIN_ERROR': // no account exists
            if (this.email.includes('@')) {
              this.errorText = 'login.error'; // student filled in email instead of username
            } else {
              this.errorText = 'login.failedUserPass'; //
            }
            return;
          default:
            this.errorText = 'login.error';
            return;
        }
      }
    );
  }

  signInWithGoogle(): void {
    this.swgPopupOpened = true;
    this.googleError = undefined;
    this.$authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  resetPassword() {
    this.isSendingPasswordInstructions = true;
    this.$v3.postRequestReset({ username: this.forgotPasswordEmail }).subscribe(
      (response) => {
        this.isSendingPasswordInstructions = false;
        this.resetSucceeded = true;
        this.resetError = undefined;
      },
      (error) => {
        this.isSendingPasswordInstructions = false;
        this.resetError = error.message == 'Invalid reset request' ? 'login.useGoogleInstead' : 'forgotPassword.error';
        this.resetSucceeded = false;
      }
    );
  }

  validEmail(str: string) {
    return (
      str &&
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        str
      )
    );
  }

  resendVerification() {
    this.$v3.postResendVerify({ username: this.email }).subscribe(
      (response) => {
        this.resendVerifyMsg = 'login.resendSuccess';
      },
      (error) => {
        this.resendVerifyMsg = 'login.resendFailed';
      }
    );
  }

  checkSubmit(event: KeyboardEvent) {
    if (event.keyCode == 13 && this.email.length > 0 && this.password.length > 0) {
      this.login();
    }
  }

  signupInstead() {
    if (this.forgotPass && this.resetError == 'login.useGoogleInstead') {
      this.forgotPass = false;
    } else {
      this.signup.emit();
    }
  }

  goHome() {
    this.$router.navigate([SITEMAP.LANDING]);
  }

  ngOnDestroy() {
    this.authStateListener.unsubscribe();
  }
}
