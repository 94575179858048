/* tslint:disable */
import {
  UserMachineEntry,
  UserAccount
} from '../index';

declare var Object: any;
export interface UserMachineEntryScoreInterface {
  "userMachineEntryId": number;
  "userAccountId": number;
  "score"?: number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  userMachineEntry?: UserMachineEntry;
  userAccount?: UserAccount;
}

export class UserMachineEntryScore implements UserMachineEntryScoreInterface {
  "userMachineEntryId": number;
  "userAccountId": number;
  "score": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  userMachineEntry: UserMachineEntry;
  userAccount: UserAccount;
  constructor(data?: UserMachineEntryScoreInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserMachineEntryScore`.
   */
  public static getModelName() {
    return "UserMachineEntryScore";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserMachineEntryScore for dynamic purposes.
  **/
  public static factory(data: UserMachineEntryScoreInterface): UserMachineEntryScore{
    return new UserMachineEntryScore(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserMachineEntryScore',
      plural: 'UserMachineEntryScores',
      path: 'UserMachineEntryScores',
      idName: 'id',
      properties: {
        "userMachineEntryId": {
          name: 'userMachineEntryId',
          type: 'number'
        },
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "score": {
          name: 'score',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        userMachineEntry: {
          name: 'userMachineEntry',
          type: 'UserMachineEntry',
          model: 'UserMachineEntry',
          relationType: 'belongsTo',
                  keyFrom: 'userMachineEntryId',
          keyTo: 'id'
        },
        userAccount: {
          name: 'userAccount',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
      }
    }
  }
}
