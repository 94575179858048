/* tslint:disable */
import {
  Challenge,
  UserAccount
} from '../index';

declare var Object: any;
export interface UserAccountChallengeInterface {
  "userAccountId": number;
  "challengeId": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "accessLevel"?: number;
  "paymentStatus"?: number;
  "paymentUpdatedAt"?: Date;
  "paymentData"?: any;
  "scholarshipCode"?: string;
  "finalPrice"?: number;
  "finalCurrency"?: string;
  "introCompleted"?: boolean;
  "id"?: number;
  challenge?: Challenge;
  userAccount?: UserAccount;
}

export class UserAccountChallenge implements UserAccountChallengeInterface {
  "userAccountId": number;
  "challengeId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "accessLevel": number;
  "paymentStatus": number;
  "paymentUpdatedAt": Date;
  "paymentData": any;
  "scholarshipCode": string;
  "finalPrice": number;
  "finalCurrency": string;
  "introCompleted": boolean;
  "id": number;
  challenge: Challenge;
  userAccount: UserAccount;
  constructor(data?: UserAccountChallengeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountChallenge`.
   */
  public static getModelName() {
    return "UserAccountChallenge";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountChallenge for dynamic purposes.
  **/
  public static factory(data: UserAccountChallengeInterface): UserAccountChallenge{
    return new UserAccountChallenge(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountChallenge',
      plural: 'UserAccountChallenges',
      path: 'UserAccountChallenges',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "challengeId": {
          name: 'challengeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "accessLevel": {
          name: 'accessLevel',
          type: 'number',
          default: 0
        },
        "paymentStatus": {
          name: 'paymentStatus',
          type: 'number',
          default: 0
        },
        "paymentUpdatedAt": {
          name: 'paymentUpdatedAt',
          type: 'Date'
        },
        "paymentData": {
          name: 'paymentData',
          type: 'any'
        },
        "scholarshipCode": {
          name: 'scholarshipCode',
          type: 'string',
          default: 'null'
        },
        "finalPrice": {
          name: 'finalPrice',
          type: 'number'
        },
        "finalCurrency": {
          name: 'finalCurrency',
          type: 'string'
        },
        "introCompleted": {
          name: 'introCompleted',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        challenge: {
          name: 'challenge',
          type: 'Challenge',
          model: 'Challenge',
          relationType: 'belongsTo',
                  keyFrom: 'challengeId',
          keyTo: 'id'
        },
        userAccount: {
          name: 'userAccount',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
      }
    }
  }
}
