import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BannerService } from '../banner.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-banner-priority',
  templateUrl: './banner-priority.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerPriorityComponent implements OnInit {
  constructor(private banner: BannerService, private cdr: ChangeDetectorRef) {}

  banners: Banner[];

  ngOnInit() {
    this.banner.subscribe((banners: Banner[]) => {
      banners = banners.filter((banner) => banner.priority);
      banners.sort((a, b) => {
        const getOrder = (item) => (item.priority === true ? 1 : item.priority);
        return getOrder(a) - getOrder(b);
      });

      if (banners.length) {
        this.banners = [banners[0]];
      } else {
        this.banners = [];
      }
      this.cdr.markForCheck();
    });
  }
}
