/* tslint:disable */
import {
  Classroom,
  Challenge
} from '../index';

declare var Object: any;
export interface ClassroomChallengeInterface {
  "classroomId": number;
  "challengeId": number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  classroom?: Classroom;
  challenge?: Challenge;
}

export class ClassroomChallenge implements ClassroomChallengeInterface {
  "classroomId": number;
  "challengeId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  classroom: Classroom;
  challenge: Challenge;
  constructor(data?: ClassroomChallengeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ClassroomChallenge`.
   */
  public static getModelName() {
    return "ClassroomChallenge";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ClassroomChallenge for dynamic purposes.
  **/
  public static factory(data: ClassroomChallengeInterface): ClassroomChallenge{
    return new ClassroomChallenge(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ClassroomChallenge',
      plural: 'ClassroomChallenges',
      path: 'ClassroomChallenges',
      idName: 'id',
      properties: {
        "classroomId": {
          name: 'classroomId',
          type: 'number'
        },
        "challengeId": {
          name: 'challengeId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        classroom: {
          name: 'classroom',
          type: 'Classroom',
          model: 'Classroom',
          relationType: 'belongsTo',
                  keyFrom: 'classroomId',
          keyTo: 'id'
        },
        challenge: {
          name: 'challenge',
          type: 'Challenge',
          model: 'Challenge',
          relationType: 'belongsTo',
                  keyFrom: 'challengeId',
          keyTo: 'id'
        },
      }
    }
  }
}
