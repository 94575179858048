/* tslint:disable */
import {
  UserAccount
} from '../index';

declare var Object: any;
export interface GoogleClassroomInterface {
  "userAccountId": number;
  "accessToken"?: string;
  "accessTokenCreatedAt": Date;
  "accessTokenTtl"?: number;
  "accessTokenExpiryTs"?: number;
  "refreshToken"?: string;
  "idToken"?: string;
  "scope"?: string;
  "credentials"?: any;
  "secret"?: any;
  "authUrl"?: string;
  "role"?: string;
  "googleAccount"?: string;
  "authUrlCreatedAt"?: Date;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  user?: UserAccount;
}

export class GoogleClassroom implements GoogleClassroomInterface {
  "userAccountId": number;
  "accessToken": string;
  "accessTokenCreatedAt": Date;
  "accessTokenTtl": number;
  "accessTokenExpiryTs": number;
  "refreshToken": string;
  "idToken": string;
  "scope": string;
  "credentials": any;
  "secret": any;
  "authUrl": string;
  "role": string;
  "googleAccount": string;
  "authUrlCreatedAt": Date;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  user: UserAccount;
  constructor(data?: GoogleClassroomInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GoogleClassroom`.
   */
  public static getModelName() {
    return "GoogleClassroom";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GoogleClassroom for dynamic purposes.
  **/
  public static factory(data: GoogleClassroomInterface): GoogleClassroom{
    return new GoogleClassroom(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GoogleClassroom',
      plural: 'GoogleClassrooms',
      path: 'GoogleClassrooms',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "accessToken": {
          name: 'accessToken',
          type: 'string'
        },
        "accessTokenCreatedAt": {
          name: 'accessTokenCreatedAt',
          type: 'Date'
        },
        "accessTokenTtl": {
          name: 'accessTokenTtl',
          type: 'number'
        },
        "accessTokenExpiryTs": {
          name: 'accessTokenExpiryTs',
          type: 'number'
        },
        "refreshToken": {
          name: 'refreshToken',
          type: 'string'
        },
        "idToken": {
          name: 'idToken',
          type: 'string'
        },
        "scope": {
          name: 'scope',
          type: 'string'
        },
        "credentials": {
          name: 'credentials',
          type: 'any'
        },
        "secret": {
          name: 'secret',
          type: 'any'
        },
        "authUrl": {
          name: 'authUrl',
          type: 'string'
        },
        "role": {
          name: 'role',
          type: 'string'
        },
        "googleAccount": {
          name: 'googleAccount',
          type: 'string'
        },
        "authUrlCreatedAt": {
          name: 'authUrlCreatedAt',
          type: 'Date'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
      }
    }
  }
}
