/* tslint:disable */
import {
  UserAccount,
  Challenge,
  Milestone
} from '../index';

declare var Object: any;
export interface UserAccountMilestoneInterface {
  "userAccountId": number;
  "milestoneId": number;
  "challengeId"?: number;
  "order"?: number;
  "exclude"?: number;
  "comments"?: string;
  "data"?: any;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  user?: UserAccount[];
  challenge?: Challenge;
  milestone?: Milestone;
}

export class UserAccountMilestone implements UserAccountMilestoneInterface {
  "userAccountId": number;
  "milestoneId": number;
  "challengeId": number;
  "order": number;
  "exclude": number;
  "comments": string;
  "data": any;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  user: UserAccount[];
  challenge: Challenge;
  milestone: Milestone;
  constructor(data?: UserAccountMilestoneInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountMilestone`.
   */
  public static getModelName() {
    return "UserAccountMilestone";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountMilestone for dynamic purposes.
  **/
  public static factory(data: UserAccountMilestoneInterface): UserAccountMilestone{
    return new UserAccountMilestone(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountMilestone',
      plural: 'UserAccountMilestones',
      path: 'UserAccountMilestones',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "milestoneId": {
          name: 'milestoneId',
          type: 'number'
        },
        "challengeId": {
          name: 'challengeId',
          type: 'number'
        },
        "order": {
          name: 'order',
          type: 'number',
          default: 0
        },
        "exclude": {
          name: 'exclude',
          type: 'number',
          default: 0
        },
        "comments": {
          name: 'comments',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'UserAccount[]',
          model: 'UserAccount',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'id'
        },
        challenge: {
          name: 'challenge',
          type: 'Challenge',
          model: 'Challenge',
          relationType: 'belongsTo',
                  keyFrom: 'challengeId',
          keyTo: 'id'
        },
        milestone: {
          name: 'milestone',
          type: 'Milestone',
          model: 'Milestone',
          relationType: 'belongsTo',
                  keyFrom: 'milestoneId',
          keyTo: 'id'
        },
      }
    }
  }
}
