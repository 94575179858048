/* tslint:disable */
import {
  Announcement,
  Group
} from '../index';

declare var Object: any;
export interface AnnouncementGroupInterface {
  "announcementId": number;
  "groupId": number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  announcement?: Announcement;
  group?: Group;
}

export class AnnouncementGroup implements AnnouncementGroupInterface {
  "announcementId": number;
  "groupId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  announcement: Announcement;
  group: Group;
  constructor(data?: AnnouncementGroupInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AnnouncementGroup`.
   */
  public static getModelName() {
    return "AnnouncementGroup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AnnouncementGroup for dynamic purposes.
  **/
  public static factory(data: AnnouncementGroupInterface): AnnouncementGroup{
    return new AnnouncementGroup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AnnouncementGroup',
      plural: 'AnnouncementGroups',
      path: 'AnnouncementGroups',
      idName: 'id',
      properties: {
        "announcementId": {
          name: 'announcementId',
          type: 'number'
        },
        "groupId": {
          name: 'groupId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        announcement: {
          name: 'announcement',
          type: 'Announcement',
          model: 'Announcement',
          relationType: 'belongsTo',
                  keyFrom: 'announcementId',
          keyTo: 'id'
        },
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'groupId',
          keyTo: 'id'
        },
      }
    }
  }
}
