import { NgModule } from '@angular/core';

import { AutofocusDirective } from './AutofocusDirective';
import { PopperDirective } from './PopperDirective';
import { UpgradeAccountDirective } from './UpgradeAccountDirective';

import { SafePipe } from './SafePipe';
import { YouTubeURLPipe } from './YouTubeURLPipe';
import { UserDisplayNamePipe } from './UserDisplayNamePipe';
import { LocalizedTextPipe } from './LocalizedText.pipe';
import { HumanTimePipe } from './HumanTime.pipe';
import { ViewportVisibiltyDirective } from './ViewportVisibiltyDirective';

@NgModule({
  declarations: [
    AutofocusDirective,
    PopperDirective,
    YouTubeURLPipe,
    SafePipe,
    UserDisplayNamePipe,
    LocalizedTextPipe,
    UpgradeAccountDirective,
    HumanTimePipe,
    ViewportVisibiltyDirective
  ],
  exports: [
    AutofocusDirective,
    PopperDirective,
    SafePipe,
    YouTubeURLPipe,
    UserDisplayNamePipe,
    LocalizedTextPipe,
    UpgradeAccountDirective,
    HumanTimePipe,
    ViewportVisibiltyDirective
  ],
})
export class MyDirectivesModule {}
