import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

const Filter = require('bad-words');

@Injectable({
  providedIn: 'root',
})
export class AuthUtils {
  public user: any;
  public role: any;

  constructor(private $translocoService: TranslocoService) {}

  passwordErrors(password: string): string {
    let req = '';
    if (password === '' || password === null) return;

    if (password.length < 6) {
      req += '\n' + this.$translocoService.translate('login.passwordRequirements1');
    }

    const lowerCheck = new RegExp('^(?=.*[a-z])');
    const hasLower = lowerCheck.test(password); //&& !reSpec.test(p);

    if (!hasLower) {
      req += '\n' + this.$translocoService.translate('login.passwordRequirements2');
    }

    const upperCheck = new RegExp('^(?=.*[A-Z])');
    const hasUpper = upperCheck.test(password); //&& !reSpec.test(p);

    if (!hasUpper) {
      req += '\n' + this.$translocoService.translate('login.passwordRequirements3');
    }

    const numCheck = new RegExp('^(?=.*[0-9])');
    const hasNum = numCheck.test(password); //&& !reSpec.test(p);

    if (!hasNum) {
      req += '\n' + this.$translocoService.translate('login.passwordRequirements4');
    }
    if (req.length > 0) {
      req = this.$translocoService.translate('login.passwordRequirementsInit') + req;
    }
    return req.length > 0 ? req : undefined;
  }

  validPassword(password: string) {
    var re = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})');
    return password && re.test(password);
  }

  validEmail(email: string) {
    return (
      email &&
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email
      )
    );
  }

  usernameErrors(username: string) {
    if (username.length < 5) {
      return 'signup.usernameTooShort';
    } else if (!/^([a-zA-Z0-9_.-]{5,20})$/.test(username)) {
      return 'signup.usernameRestrictions';
    } else {
      return 'signup.invalidUsername';
    }
  }

  validUsername(str: string) {
    if (!/^([a-zA-Z0-9_.-]{5,20})$/.test(str)) return false; // alphanumeric only
    return this.containsNoBadWords(str);
  }

  containsNoBadWords(str: string) {
    const filter = new Filter();
    for (let word of str.split(/\W+/)) {
      if (filter.isProfane(word)) {
        return false;
      }
    }
    return true;
  }

  validTeamName(str: string) {
    return /^\#{0,1}([a-zA-Z0-9_.-]{5,20})$/.test(str);
  }

  replaceBadWords(word: string, placeHolder = '*') {
    if (!word) {
      return word;
    }
    const filter = new Filter({ placeHolder });

    return filter.clean(word);
  }
}
