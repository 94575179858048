import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { SITEMAP } from 'src/utilities/sitemap';

import { IsLoggedGuard } from './shared/guards/is-logged.guard';
import { TeamJoinGuard } from './shared/guards/team-join.guard';
import { ChallengeJoinGuard } from './shared/guards/challenge-join.guard';
import { AppComponent } from './app.component';
import { MyPermissionsGuard } from './shared/guards/my-permissions.guard';
import { FromPortalComponent } from './from-portal/from-portal.component';
import { LoggedOutComponent } from './logged-out/components/logged-out/logged-out.component';

const routes: Routes = [
  {
    path: SITEMAP.PASSWORD_RESET,
    loadChildren: () => import('./password-reset/password-reset.module').then((m) => m.PasswordResetModule),
    data: {
      title: 'Reset Password',
    },
  },
  {
    path: SITEMAP.VERIFY_EMAIL,
    loadChildren: () => import('./verify-email/verify-email.module').then((m) => m.VerifyEmailModule),
    data: {
      title: 'Verify Email',
    },
  },
  {
    path: SITEMAP.LANDING,
    loadChildren: () => import('./logged-out/logged-out.module').then((m) => m.LoggedOutModule),
    data: {
      title: '',
    },
  },
  {
    path: SITEMAP.ROOT,
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('./logged-in/logged-in.module').then((m) => m.LoggedInModule),
  },
  {
    path: 'payment',
    canActivate: [MyPermissionsGuard],
    outlet: 'modal-router',
    loadChildren: () => import('./payment/payment.module').then((m) => m.PaymentModule),
    data: {
      animation: 'Payment',
      title: 'Payment',
      permissions: {
        only: ['payment'],
        // redirectTo: ['', { outlets: { 'modal-router': null } }],
      },
    },
  },
  {
    path: 't/:id',
    canActivate: [TeamJoinGuard],
    children: [],
  },
  {
    path: 'from-portal',
    loadChildren: () => import('./from-portal/from-portal.module').then((m) => m.FromPortalModule),
  },
  {
    path: 'certificate/:id',
    component: LoggedOutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/certificate/certificate.module').then((m) => m.CertificateModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
