/* tslint:disable */
import {
  Entity,
  UserAccount
} from '../index';

declare var Object: any;
export interface UserEntityInterface {
  "userAccountId": number;
  "entityId": number;
  "bucketId"?: number;
  "permissions"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  entity?: Entity;
  userAccount?: UserAccount;
  bucket?: any;
  history?: any[];
}

export class UserEntity implements UserEntityInterface {
  "userAccountId": number;
  "entityId": number;
  "bucketId": number;
  "permissions": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  entity: Entity;
  userAccount: UserAccount;
  bucket: any;
  history: any[];
  constructor(data?: UserEntityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserEntity`.
   */
  public static getModelName() {
    return "UserEntity";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserEntity for dynamic purposes.
  **/
  public static factory(data: UserEntityInterface): UserEntity{
    return new UserEntity(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserEntity',
      plural: 'UserEntities',
      path: 'UserEntities',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "entityId": {
          name: 'entityId',
          type: 'number'
        },
        "bucketId": {
          name: 'bucketId',
          type: 'number'
        },
        "permissions": {
          name: 'permissions',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        entity: {
          name: 'entity',
          type: 'Entity',
          model: 'Entity',
          relationType: 'belongsTo',
                  keyFrom: 'entityId',
          keyTo: 'id'
        },
        userAccount: {
          name: 'userAccount',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'userAccountId',
          keyTo: 'id'
        },
        bucket: {
          name: 'bucket',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'bucketId',
          keyTo: 'id'
        },
        history: {
          name: 'history',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'userEntityId'
        },
      }
    }
  }
}
