/* tslint:disable */
/**
* @module SDKModule
* @author Jonathan Casarrubias <t:@johncasarrubias> <gh:jonathan-casarrubias>
* @license MIT 2016 Jonathan Casarrubias
* @version 2.1.0
* @description
* The SDKModule is a generated Software Development Kit automatically built by
* the LoopBack SDK Builder open source module.
*
* The SDKModule provides Angular 2 >= RC.5 support, which means that NgModules
* can import this Software Development Kit as follows:
*
*
* APP Route Module Context
* ============================================================================
* import { NgModule }       from '@angular/core';
* import { BrowserModule }  from '@angular/platform-browser';
* // App Root 
* import { AppComponent }   from './app.component';
* // Feature Modules
* import { SDK[Browser|Node|Native]Module } from './shared/sdk/sdk.module';
* // Import Routing
* import { routing }        from './app.routing';
* @NgModule({
*  imports: [
*    BrowserModule,
*    routing,
*    SDK[Browser|Node|Native]Module.forRoot()
*  ],
*  declarations: [ AppComponent ],
*  bootstrap:    [ AppComponent ]
* })
* export class AppModule { }
*
**/
import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SocketBrowser } from './sockets/socket.browser';
import { SocketDriver } from './sockets/socket.driver';
import { SocketConnection } from './sockets/socket.connections';
import { RealTime } from './services/core/real.time';
import { ActivityLogApi } from './services/custom/ActivityLog';
import { ActivityTypeApi } from './services/custom/ActivityType';
import { BoardChipApi } from './services/custom/BoardChip';
import { BoardHistoryApi } from './services/custom/BoardHistory';
import { BoardPortalApi } from './services/custom/BoardPortal';
import { BoardProgressApi } from './services/custom/BoardProgress';
import { BoardApi } from './services/custom/Board';
import { ChallengeApi } from './services/custom/Challenge';
import { ChallengeLessonApi } from './services/custom/ChallengeLesson';
import { ChallengeTimelineApi } from './services/custom/ChallengeTimeline';
import { ChipHistoryApi } from './services/custom/ChipHistory';
import { ChipProgressApi } from './services/custom/ChipProgress';
import { ChipApi } from './services/custom/Chip';
import { ClassroomApi } from './services/custom/Classroom';
import { ContainerApi } from './services/custom/Container';
import { CurrencyApi } from './services/custom/Currency';
import { CurrencyTypeApi } from './services/custom/CurrencyType';
import { DiagnosticApi } from './services/custom/Diagnostic';
import { DiagnosticLogApi } from './services/custom/DiagnosticLog';
import { EmailApi } from './services/custom/Email';
import { EntityChallengeApi } from './services/custom/EntityChallenge';
import { EntityApi } from './services/custom/Entity';
import { EntityTranslationApi } from './services/custom/EntityTranslation';
import { EntityTypeApi } from './services/custom/EntityType';
import { ExportApi } from './services/custom/Export';
import { GlobalSettingsApi } from './services/custom/GlobalSettings';
import { GoogleClassroomApi } from './services/custom/GoogleClassroom';
import { GoogleClassroomAssignmentApi } from './services/custom/GoogleClassroomAssignment';
import { GroupApi } from './services/custom/Group';
import { GroupPermissionApi } from './services/custom/GroupPermission';
import { GroupRoleApi } from './services/custom/GroupRole';
import { IntegrationApi } from './services/custom/Integration';
import { LessonApi } from './services/custom/Lesson';
import { LessonChallengeTimelineApi } from './services/custom/LessonChallengeTimeline';
import { LessonMachineApi } from './services/custom/LessonMachine';
import { MachineBoardApi } from './services/custom/MachineBoard';
import { MachineHistoryApi } from './services/custom/MachineHistory';
import { MachinePresentationApi } from './services/custom/MachinePresentation';
import { MachinePrizeApi } from './services/custom/MachinePrize';
import { MachineProgressApi } from './services/custom/MachineProgress';
import { MachineRecommendationApi } from './services/custom/MachineRecommendation';
import { MachineApi } from './services/custom/Machine';
import { ObjectTypeApi } from './services/custom/ObjectType';
import { PermissionApi } from './services/custom/Permission';
import { PromoCodeApi } from './services/custom/PromoCode';
import { ReportApi } from './services/custom/Report';
import { TagApi } from './services/custom/Tag';
import { TagTypeApi } from './services/custom/TagType';
import { TimeZoneApi } from './services/custom/TimeZone';
import { TokenApi } from './services/custom/Token';
import { UIDiagnosticApi } from './services/custom/UIDiagnostic';
import { UIAppApi } from './services/custom/UIApp';
import { UICacheApi } from './services/custom/UICache';
import { UIPortalClientApi } from './services/custom/UIPortalClient';
import { UIPublicApi } from './services/custom/UIPublic';
import { UIUserAccountApi } from './services/custom/UIUserAccount';
import { UserAccountApi } from './services/custom/UserAccount';
import { UserAccountGroupApi } from './services/custom/UserAccountGroup';
import { UserAccountChallengeApi } from './services/custom/UserAccountChallenge';
import { UserEntityApi } from './services/custom/UserEntity';
import { UserMigrationApi } from './services/custom/UserMigration';
import { UserMachineEntryApi } from './services/custom/UserMachineEntry';
import { UserMachineEntryScoreApi } from './services/custom/UserMachineEntryScore';
import { V3Api } from './services/custom/V3';
import { PaymentApi } from './services/custom/Payment';
import { AnnouncementApi } from './services/custom/Announcement';
import { AnnouncementGroupApi } from './services/custom/AnnouncementGroup';
import { AnnouncementChallengeApi } from './services/custom/AnnouncementChallenge';
import { AnnouncementTranslationApi } from './services/custom/AnnouncementTranslation';
import { ClassroomTranslationApi } from './services/custom/ClassroomTranslation';
import { ClassroomTeacherApi } from './services/custom/ClassroomTeacher';
import { ClassroomStudentApi } from './services/custom/ClassroomStudent';
import { ClassroomChallengeApi } from './services/custom/ClassroomChallenge';
import { UserAccountEntityApi } from './services/custom/UserAccountEntity';
import { MilestoneApi } from './services/custom/Milestone';
import { MilestoneTypeApi } from './services/custom/MilestoneType';
import { UserAccountMilestoneApi } from './services/custom/UserAccountMilestone';
import { UserAccountItemApi } from './services/custom/UserAccountItem';
import { ItemApi } from './services/custom/Item';
import { ItemTypeApi } from './services/custom/ItemType';
import { AssessmentApi } from './services/custom/Assessment';
import { AssessmentTemplateApi } from './services/custom/AssessmentTemplate';
import { UserAccountAssessmentApi } from './services/custom/UserAccountAssessment';
import { UserAccountAssessmentAnswerApi } from './services/custom/UserAccountAssessmentAnswer';
import { ItemRelationApi } from './services/custom/ItemRelation';
import { LessonProgressApi } from './services/custom/LessonProgress';
import { TopicApi } from './services/custom/Topic';
import { TopicCategoryApi } from './services/custom/TopicCategory';
import { TopicCommentApi } from './services/custom/TopicComment';
import { TopicCommentTypeApi } from './services/custom/TopicCommentType';
import { TopicCommentReportApi } from './services/custom/TopicCommentReport';
import { TopicCommentReportTypeApi } from './services/custom/TopicCommentReportType';
import { HelpCardTypeApi } from './services/custom/HelpCardType';
import { ObjectHelpCardApi } from './services/custom/ObjectHelpCard';
import { ObjectTypeHelpCardApi } from './services/custom/ObjectTypeHelpCard';
import { TutorialApi } from './services/custom/Tutorial';
import { TutorialStepApi } from './services/custom/TutorialStep';
import { TutorialStepTranslationApi } from './services/custom/TutorialStepTranslation';
import { TutorialProgressApi } from './services/custom/TutorialProgress';
import { ClassroomMachineSetApi } from './services/custom/ClassroomMachineSet';
import { CampaignApi } from './services/custom/Campaign';
import { CampaignSettingApi } from './services/custom/CampaignSetting';
import { LibraryApi } from './services/custom/Library';
import { LibraryObjectApi } from './services/custom/LibraryObject';
import { TrayApi } from './services/custom/Tray';
import { TrayObjectApi } from './services/custom/TrayObject';
import { UserAccountObjectHistoryApi } from './services/custom/UserAccountObjectHistory';
import { UserAccountTrayFavoriteApi } from './services/custom/UserAccountTrayFavorite';
import { UserAccountNotificationApi } from './services/custom/UserAccountNotification';
import { NotificationApi } from './services/custom/Notification';
import { NotificationRuleApi } from './services/custom/NotificationRule';
import { NotificationTypeApi } from './services/custom/NotificationType';
import { NotificationTranslationApi } from './services/custom/NotificationTranslation';
/**
* @module SDKBrowserModule
* @description
* This module should be imported when building a Web Application in the following scenarios:
*
*  1.- Regular web application
*  2.- Angular universal application (Browser Portion)
*  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
**/
@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [ ],
  exports:      [ ],
  providers:    [
    ErrorHandler,
    SocketConnection
  ]
})
export class SDKBrowserModule {
  static forRoot(internalStorageProvider: any = {
    provide: InternalStorage,
    useClass: CookieBrowser
  }): ModuleWithProviders<any> {
    return {
      ngModule  : SDKBrowserModule,
      providers : [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        RealTime,
        ActivityLogApi,
        ActivityTypeApi,
        BoardChipApi,
        BoardHistoryApi,
        BoardPortalApi,
        BoardProgressApi,
        BoardApi,
        ChallengeApi,
        ChallengeLessonApi,
        ChallengeTimelineApi,
        ChipHistoryApi,
        ChipProgressApi,
        ChipApi,
        ClassroomApi,
        ContainerApi,
        CurrencyApi,
        CurrencyTypeApi,
        DiagnosticApi,
        DiagnosticLogApi,
        EmailApi,
        EntityChallengeApi,
        EntityApi,
        EntityTranslationApi,
        EntityTypeApi,
        ExportApi,
        GlobalSettingsApi,
        GoogleClassroomApi,
        GoogleClassroomAssignmentApi,
        GroupApi,
        GroupPermissionApi,
        GroupRoleApi,
        IntegrationApi,
        LessonApi,
        LessonChallengeTimelineApi,
        LessonMachineApi,
        MachineBoardApi,
        MachineHistoryApi,
        MachinePresentationApi,
        MachinePrizeApi,
        MachineProgressApi,
        MachineRecommendationApi,
        MachineApi,
        ObjectTypeApi,
        PermissionApi,
        PromoCodeApi,
        ReportApi,
        TagApi,
        TagTypeApi,
        TimeZoneApi,
        TokenApi,
        UIDiagnosticApi,
        UIAppApi,
        UICacheApi,
        UIPortalClientApi,
        UIPublicApi,
        UIUserAccountApi,
        UserAccountApi,
        UserAccountGroupApi,
        UserAccountChallengeApi,
        UserEntityApi,
        UserMigrationApi,
        UserMachineEntryApi,
        UserMachineEntryScoreApi,
        V3Api,
        PaymentApi,
        AnnouncementApi,
        AnnouncementGroupApi,
        AnnouncementChallengeApi,
        AnnouncementTranslationApi,
        ClassroomTranslationApi,
        ClassroomTeacherApi,
        ClassroomStudentApi,
        ClassroomChallengeApi,
        UserAccountEntityApi,
        MilestoneApi,
        MilestoneTypeApi,
        UserAccountMilestoneApi,
        UserAccountItemApi,
        ItemApi,
        ItemTypeApi,
        AssessmentApi,
        AssessmentTemplateApi,
        UserAccountAssessmentApi,
        UserAccountAssessmentAnswerApi,
        ItemRelationApi,
        LessonProgressApi,
        TopicApi,
        TopicCategoryApi,
        TopicCommentApi,
        TopicCommentTypeApi,
        TopicCommentReportApi,
        TopicCommentReportTypeApi,
        HelpCardTypeApi,
        ObjectHelpCardApi,
        ObjectTypeHelpCardApi,
        TutorialApi,
        TutorialStepApi,
        TutorialStepTranslationApi,
        TutorialProgressApi,
        ClassroomMachineSetApi,
        CampaignApi,
        CampaignSettingApi,
        LibraryApi,
        LibraryObjectApi,
        TrayApi,
        TrayObjectApi,
        UserAccountObjectHistoryApi,
        UserAccountTrayFavoriteApi,
        UserAccountNotificationApi,
        NotificationApi,
        NotificationRuleApi,
        NotificationTypeApi,
        NotificationTranslationApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
        { provide: SocketDriver, useClass: SocketBrowser }
      ]
    };
  }
}
/**
* Have Fun!!!
* - Jon
**/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';

