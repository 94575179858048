import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setToObject(name: string, key: string, val: string | object): void {
    const object = this.get(name) || {};
    object[key] = val;
    this.set(name, object);
  }
  getFromObject(name: string, key: string) {
    const object = this.get(name) || {};
    return object[key];
  }
  set(key: string, val: string | object): void {
    localStorage.setItem(key, typeof val === 'object' ? JSON.stringify(val) : val);
  }
  get(key: string): any {
    const data: string = localStorage.getItem(key);
    return this.parse(data);
  }
  remove(key: string): any {
    if (localStorage[key]) {
      localStorage.removeItem(key);
    } else {
      console.log('Trying to remove unexisting key: ', key);
    }
  }
  private parse(value: any) {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
}
