<mat-form-field #formField appearance="fill" floatLabel="never" [dir]="direction">
  <mat-select
    (openedChange)="onOpen($event)"
    (selectionChange)="onValueChange($event)"
    [value]="iso"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [disableOptionCentering]="true"
    panelClass="iso-picker-wrapper"
  >
    <input
      #searchInput
      class="w-full p-2 text-base font-light appearance-none  ring-inset panel-bottom-stripe focus:outline-none focus:ring-2 focus-ring-razeberry-500"
      [placeholder]="'searchForCountry' | transloco"
      (input)="searchKeyword = $event.target.value"
      (keydown)="$event.stopPropagation()"
    />
    <mat-select-trigger>
      <div class="flex items-center" *ngIf="selectedCountry">
        <ui-country-flag class="flex-none" *ngIf="showFlags" [iso]="selectedCountry.iso2"></ui-country-flag>
        <span *ngIf="showName" [ngClass]="{ 'ml-2': showFlags }" class="text-mulberry-800">{{
          selectedCountry.name
        }}</span>
        <span
          *ngIf="showDialCode"
          [ngClass]="{ 'ml-2': !showName, 'ml-0.5': showName }"
          class="text-sm text-mulberry-700"
          >(+{{ selectedCountry.dialCode }})</span
        >
      </div>
    </mat-select-trigger>
    <mat-option *ngFor="let item of results" [value]="item.iso2">
      <div class="flex items-center text-base">
        <ui-country-flag *ngIf="showFlags" [iso]="item.iso2"></ui-country-flag>
        <span [ngClass]="{ 'ml-2': showFlags }" style="margin: 0 7px" class="value">{{ item.name }}</span>
        <span
          *ngIf="showDialCode"
          [ngClass]="{ 'ml-2': !showName, 'ml-0.5': showName }"
          class="text-sm text-mulberry-700"
          >+({{ item.dialCode }})</span
        >
      </div>
    </mat-option>
  </mat-select>

  <svg
    [class.force:left-0]="direction === 'rtl'"
    [class.force:right-0]="direction === 'ltr'"
    class="absolute w-4 mr-4 transform -translate-y-1/2 pointer-events-none text-mulberry-650 top-1/2"
    viewBox="0 0 320 512"
  >
    <path
      fill="currentColor"
      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
    ></path>
  </svg>
</mat-form-field>
