import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ToggleModule } from '../toggle/toggle.module';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [DropdownComponent],
  imports: [CommonModule, ToggleModule, TranslocoRootModule, PortalModule, OverlayModule],
  exports: [DropdownComponent],
})
export class DropdownModule {}
