/* tslint:disable */
import {
  ChallengeLesson,
  Entity,
  Classroom
} from '../index';

declare var Object: any;
export interface ChallengeInterface {
  "name": string;
  "description"?: string;
  "displayName"?: string;
  "code"?: string;
  "active"?: boolean;
  "dateStart": Date;
  "dateEnd": Date;
  "q1Text"?: string;
  "q1AnswerType"?: string;
  "q1AnswerOptions"?: string;
  "q2Text"?: string;
  "q2AnswerType"?: string;
  "q2AnswerOptions"?: string;
  "q3Text"?: string;
  "q3AnswerType"?: string;
  "q3AnswerOptions"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "tags"?: string;
  "systemTags"?: string;
  "strings"?: any;
  "resultFrequency"?: string;
  "timeZone"?: string;
  "resultReset"?: boolean;
  "public"?: boolean;
  "thumbnailUrl"?: string;
  "targetId": number;
  "startTs"?: number;
  "endTs"?: number;
  "branding"?: any;
  "lessonPlanBranding"?: any;
  "userLimit"?: any;
  "payment"?: any;
  "onboarding"?: any;
  "unlockDeadline"?: Date;
  "certificateLogo"?: string;
  "certificateSponsorLogo"?: Array<any>;
  "autoUpgrade"?: boolean;
  "id"?: number;
  lessons?: ChallengeLesson[];
  entity?: Entity[];
  classrooms?: Classroom[];
}

export class Challenge implements ChallengeInterface {
  "name": string;
  "description": string;
  "displayName": string;
  "code": string;
  "active": boolean;
  "dateStart": Date;
  "dateEnd": Date;
  "q1Text": string;
  "q1AnswerType": string;
  "q1AnswerOptions": string;
  "q2Text": string;
  "q2AnswerType": string;
  "q2AnswerOptions": string;
  "q3Text": string;
  "q3AnswerType": string;
  "q3AnswerOptions": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "tags": string;
  "systemTags": string;
  "strings": any;
  "resultFrequency": string;
  "timeZone": string;
  "resultReset": boolean;
  "public": boolean;
  "thumbnailUrl": string;
  "targetId": number;
  "startTs": number;
  "endTs": number;
  "branding": any;
  "lessonPlanBranding": any;
  "userLimit": any;
  "payment": any;
  "onboarding": any;
  "unlockDeadline": Date;
  "certificateLogo": string;
  "certificateSponsorLogo": Array<any>;
  "autoUpgrade": boolean;
  "id": number;
  lessons: ChallengeLesson[];
  entity: Entity[];
  classrooms: Classroom[];
  constructor(data?: ChallengeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Challenge`.
   */
  public static getModelName() {
    return "Challenge";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Challenge for dynamic purposes.
  **/
  public static factory(data: ChallengeInterface): Challenge{
    return new Challenge(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Challenge',
      plural: 'Challenges',
      path: 'Challenges',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: false
        },
        "dateStart": {
          name: 'dateStart',
          type: 'Date'
        },
        "dateEnd": {
          name: 'dateEnd',
          type: 'Date'
        },
        "q1Text": {
          name: 'q1Text',
          type: 'string',
          default: 'Did this machine theme match the prize?'
        },
        "q1AnswerType": {
          name: 'q1AnswerType',
          type: 'string',
          default: 'Number'
        },
        "q1AnswerOptions": {
          name: 'q1AnswerOptions',
          type: 'string',
          default: 'thumbs'
        },
        "q2Text": {
          name: 'q2Text',
          type: 'string',
          default: 'How difficult was this machine to complete?'
        },
        "q2AnswerType": {
          name: 'q2AnswerType',
          type: 'string',
          default: 'Number'
        },
        "q2AnswerOptions": {
          name: 'q2AnswerOptions',
          type: 'string',
          default: 'easy-right-hard'
        },
        "q3Text": {
          name: 'q3Text',
          type: 'string',
          default: 'Was this machine fun?'
        },
        "q3AnswerType": {
          name: 'q3AnswerType',
          type: 'string',
          default: 'Number'
        },
        "q3AnswerOptions": {
          name: 'q3AnswerOptions',
          type: 'string',
          default: 'thumbs'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "tags": {
          name: 'tags',
          type: 'string'
        },
        "systemTags": {
          name: 'systemTags',
          type: 'string'
        },
        "strings": {
          name: 'strings',
          type: 'any'
        },
        "resultFrequency": {
          name: 'resultFrequency',
          type: 'string'
        },
        "timeZone": {
          name: 'timeZone',
          type: 'string'
        },
        "resultReset": {
          name: 'resultReset',
          type: 'boolean'
        },
        "public": {
          name: 'public',
          type: 'boolean'
        },
        "thumbnailUrl": {
          name: 'thumbnailUrl',
          type: 'string'
        },
        "targetId": {
          name: 'targetId',
          type: 'number'
        },
        "startTs": {
          name: 'startTs',
          type: 'number'
        },
        "endTs": {
          name: 'endTs',
          type: 'number'
        },
        "branding": {
          name: 'branding',
          type: 'any'
        },
        "lessonPlanBranding": {
          name: 'lessonPlanBranding',
          type: 'any'
        },
        "userLimit": {
          name: 'userLimit',
          type: 'any'
        },
        "payment": {
          name: 'payment',
          type: 'any'
        },
        "onboarding": {
          name: 'onboarding',
          type: 'any'
        },
        "unlockDeadline": {
          name: 'unlockDeadline',
          type: 'Date'
        },
        "certificateLogo": {
          name: 'certificateLogo',
          type: 'string'
        },
        "certificateSponsorLogo": {
          name: 'certificateSponsorLogo',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "autoUpgrade": {
          name: 'autoUpgrade',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        lessons: {
          name: 'lessons',
          type: 'ChallengeLesson[]',
          model: 'ChallengeLesson',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'challengeId'
        },
        entity: {
          name: 'entity',
          type: 'Entity[]',
          model: 'Entity',
          relationType: 'hasMany',
          modelThrough: 'EntityChallenge',
          keyThrough: 'entityId',
          keyFrom: 'id',
          keyTo: 'challengeId'
        },
        classrooms: {
          name: 'classrooms',
          type: 'Classroom[]',
          model: 'Classroom',
          relationType: 'hasMany',
          modelThrough: 'ClassroomChallenge',
          keyThrough: 'classroomId',
          keyFrom: 'id',
          keyTo: 'challengeId'
        },
      }
    }
  }
}
