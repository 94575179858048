import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UserAccountApi, LoopBackAuth } from '../../../shared/sdk';
import { SITEMAP } from 'src/utilities/sitemap';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Option } from 'src/app/shared/modules/ui/select/types';
import { TranslocoService } from '@ngneat/transloco';
// import { tap } from 'rxjs/operators';

//import { simpleFade } from '../animations';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  SITEMAP = SITEMAP;
  @Input() mode: string = 'login';

  @Output() close: EventEmitter<any> = new EventEmitter();

  languages: Option[] = this.$language.languages.map((option) => ({ label: option.name, value: option.code }));

  constructor(private $auth: UserAccountApi, private $lbauth: LoopBackAuth, public $language: LanguageService) {}

  reset() {}

  loginInstead() {
    this.mode = 'login';
    this.reset();
  }

  signupInstead() {
    this.mode = 'signup';
    this.reset();
  }

  swithMode(mode: string) {
    this.mode = mode;
  }

  ngOnInit(): void {}
}
