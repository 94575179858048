<div
  class="p-4 rounded-lg"
  [class]="classes"
  [ngClass]="{
    'bg-kiwi-500 text-white': type == 'success',
    'bg-cherry-100 text-cherry-500': type == 'error'
  }"
>
  <ng-content></ng-content>
</div>
