import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';

@Component({
  selector: 'ui-popup',
  styleUrls: ['./style.css'],
  templateUrl: './popup.component.html',
})
export class PopupComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor() {
    this.setOverlaySize = this.setOverlaySize.bind(this);
  }

  static activeInstances = 0;

  @ViewChild('overlay') overlayRef;

  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input() showHeader = true;

  @Input() styled = true;

  @Input() size: string = 'max-w-2xl';
  @Input() closable: boolean = true;
  @Input() locked: boolean = false;
  @Input() dark: boolean = false
  @Input() bodyClass = ""

  ngOnInit(): void {
    if (PopupComponent.activeInstances === 0) {
      document.body.style['margin-right'] = `${this.getScrollbarWidth()}px`;

      document.body.style.overflow = 'hidden';
    }

    PopupComponent.activeInstances++;
  }

  ngOnDestroy() {
    PopupComponent.activeInstances--;

    if (PopupComponent.activeInstances === 0) {
      document.body.style['margin-right'] = null;
      document.body.style.overflow = '';
    }

    window.removeEventListener('resize', this.setOverlaySize);
  }

  ngAfterViewInit() {
    window.addEventListener('resize', this.setOverlaySize);
    this.setOverlaySize();
  }

  setOverlaySize() {
    const vh = window.innerHeight * 0.01;
    this.overlayRef.nativeElement.style.setProperty('--vh', `${vh}px`);
  }

  handleOverlayClose(event: MouseEvent) {
    if (this.closable && event.target === this.overlayRef.nativeElement) {
      this.close.emit(event);
    }
  }

  get sizeClassName() {
    switch (this.size) {
      case 'sm':
        return 'max-w-md';

      default:
        return this.size || 'max-w-2xl';
    }
  }

  getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }
}
