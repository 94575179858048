import * as Sentry from '@sentry/angular';

export {};
declare global {
  interface Window {
    SessionStack: any;
  }
}

export class SessionStackLogger {
  static init() {
    const SessionStack = window.SessionStack;
    if (!SessionStack) {
      console.log('no session stack setup.');
      return ;
    }
    SessionStack.getSessionId(function (sessionId) {
      Sentry.configureScope((scope) => {
        scope.addEventProcessor(async (event) => {
          event.contexts = {
            sessionstack: {
              session_id: sessionId,
              timestamp: new Date().getTime(),
            },
          };
          return event;
        });
      });
    });
  }
}
