import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import List from './../iso-picker/countries';

const FLAGS_SPRITE = 'assets/img/flags-sprite.png';
@Component({
  selector: 'ui-country-flag',
  template: `<img
    [src]="flagImage"
    (error)="onImageError()"
    [alt]="isoLocal"
    [style.objectPosition]="objectPosition"
  />`,
  styleUrls: ['./country-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryFlagComponent {
  isoLocal: string;
  objectPosition: string = '0px 0px';
  isoCodes: string[] = List.map((c) => c.iso2).sort();
  flagImageWidth: number = 16; // px
  flagImage = FLAGS_SPRITE;

  loadingRetries = 0;
  loadingMaxRetries = 1;

  @Input() set iso(iso: string) {
    this.isoLocal = iso;
    const flagIndex = this.getFlagIndex(iso);
    this.objectPosition = this.calcObjectPosition(flagIndex);
    if (flagIndex === -1) {
      this.flagImage = `https://static.polyup.com/wrapper/assets/flags/${iso}.png`;
    } else {
      this.flagImage = FLAGS_SPRITE;
    }
  }

  private getFlagIndex(iso: string) {
    return this.isoCodes.findIndex((code) => code === iso);
  }
  private calcObjectPosition(flagIndex: number): string {
    const xShiftPx: number = flagIndex === -1 ? 0 : this.flagImageWidth * flagIndex;
    return `-${xShiftPx}px 0px`;
  }

  onImageError() {
    if (this.loadingRetries >= this.loadingMaxRetries) {
      return;
    }
    this.flagImage = `https://static.polyup.com/wrapper/assets/flags/aa.png`;
    this.loadingRetries++;
  }
}
