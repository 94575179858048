/* tslint:disable */
import {
  Group,
  Permission
} from '../index';

declare var Object: any;
export interface GroupPermissionInterface {
  "groupId"?: number;
  "permissionId"?: number;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  group?: Group;
  permission?: Permission;
}

export class GroupPermission implements GroupPermissionInterface {
  "groupId": number;
  "permissionId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  group: Group;
  permission: Permission;
  constructor(data?: GroupPermissionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GroupPermission`.
   */
  public static getModelName() {
    return "GroupPermission";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GroupPermission for dynamic purposes.
  **/
  public static factory(data: GroupPermissionInterface): GroupPermission{
    return new GroupPermission(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GroupPermission',
      plural: 'GroupPermissions',
      path: 'GroupPermissions',
      idName: 'id',
      properties: {
        "groupId": {
          name: 'groupId',
          type: 'number'
        },
        "permissionId": {
          name: 'permissionId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        group: {
          name: 'group',
          type: 'Group',
          model: 'Group',
          relationType: 'belongsTo',
                  keyFrom: 'groupId',
          keyTo: 'id'
        },
        permission: {
          name: 'permission',
          type: 'Permission',
          model: 'Permission',
          relationType: 'belongsTo',
                  keyFrom: 'permissionId',
          keyTo: 'id'
        },
      }
    }
  }
}
