<div [class]="classes">
  <div class="relative">

    <input
      *ngIf="!multiline"
      #input
      class="w-full px-4 text-lg font-light placeholder-opacity-50 rounded-lg appearance-none focus:outline-none placeholder-mulberry-800"
      [ngClass]="{
        'ring-2 ring-inset': isError || isSuccess || isWarning,
        'ring-cherry-500': isError,
        'bg-mulberry-500  bg-opacity-50': color != 'white',
        'ring-pineapple-500': isWarning,
        'ring-kiwi-500': !isWarning && !isError && isSuccess,
        'focus:ring-2 focus:ring-razberry-500': !isSuccess && !isError && !isWarning,
        'pr-10': showIcon,
        'py-2': size == 'md',
        'py-3': size == 'lg'
      }"
      [class]="inputClasses"
      [type]="type"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [value]="inputValue"
      (input)="onTextChanged($event.target.value)"
      (keydown)="keyDown($event)"
      [maxLength]="maxLength || 32676"
      [max]="max"
      [min]="min"
      [autocomplete]="autocomplete"
      (blur)="blur.emit($event)"
      [readonly]="readonly"
    />
    <textarea
      *ngIf="multiline"
      #input
      class="w-full px-4 text-lg font-light placeholder-opacity-50 rounded-lg appearance-none focus:outline-none placeholder-mulberry-800"
      [ngClass]="{
        'ring-2 ring-inset': isError || isSuccess || isWarning,
        'ring-cherry-500': isError,
        'bg-mulberry-500  bg-opacity-50': color != 'white',
        'ring-pineapple-500': isWarning,
        'ring-kiwi-500': !isWarning && !isError && isSuccess,
        'focus:ring-2 focus:ring-razberry-500': !isSuccess && !isError && !isWarning,
        'pr-10': showIcon,
        'py-2': size == 'md',
        'py-3': size == 'lg'
      }"
      [class]="inputClasses"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [value]="value || ''"
      (input)="onTextChanged($event.target.value)"
      (keydown)="keyDown($event)"
      [maxLength]="maxLength || 32676"
      [max]="max"
      [min]="min"
      [autocomplete]="autocomplete"
      (blur)="blur.emit($event)"
      [readonly]="readonly"
      [rows]="6"
    ></textarea>

    <div
      *ngIf="showIcon"
      class="absolute right-0 flex items-center justify-center w-6 mr-3 transform -translate-y-1/2 text-mulberry-650 top-1/2"
    >
      <svg *ngIf="loading" class="w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>

      <svg *ngIf="!loading && !isWarning && !isError && isSuccess && showSuccessIcon" viewBox="0 0 32 32" class="w-6">
        <path
          d="M13 24c-.5 0-1-.2-1.4-.6l-5-5c-.8-.8-.8-2 0-2.8s2-.8 2.8 0l3.6 3.6 9.6-9.6c.8-.8 2-.8 2.8 0s.8 2 0 2.8l-11 11c-.4.4-.9.6-1.4.6z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  </div>

  <div *ngIf="isError && errorMessage !== ''" class="text-sm pl-1 mt-0.5 text-right text-cherry-500">
    {{ errorMessage }}
  </div>
  <div *ngIf="isWarning && warningMessage !== ''" class="mt-0.5 text-right text-pineapple-600">
    {{ warningMessage }}
  </div>
  <div *ngIf="isSuccess && successMessage !== ''" class="mt-0.5 text-right text-kiwi-500">
    {{ successMessage }}
  </div>
</div>
