/* tslint:disable */
import {
  GoogleClassroom
} from '../index';

declare var Object: any;
export interface GoogleClassroomAssignmentInterface {
  "googleClassroomId": number;
  "courseId"?: string;
  "machineId": number;
  "courseWorkId"?: string;
  "googleUserId"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  user?: GoogleClassroom;
}

export class GoogleClassroomAssignment implements GoogleClassroomAssignmentInterface {
  "googleClassroomId": number;
  "courseId": string;
  "machineId": number;
  "courseWorkId": string;
  "googleUserId": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  user: GoogleClassroom;
  constructor(data?: GoogleClassroomAssignmentInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `GoogleClassroomAssignment`.
   */
  public static getModelName() {
    return "GoogleClassroomAssignment";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of GoogleClassroomAssignment for dynamic purposes.
  **/
  public static factory(data: GoogleClassroomAssignmentInterface): GoogleClassroomAssignment{
    return new GoogleClassroomAssignment(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'GoogleClassroomAssignment',
      plural: 'GoogleClassroomAssignments',
      path: 'GoogleClassroomAssignments',
      idName: 'id',
      properties: {
        "googleClassroomId": {
          name: 'googleClassroomId',
          type: 'number'
        },
        "courseId": {
          name: 'courseId',
          type: 'string'
        },
        "machineId": {
          name: 'machineId',
          type: 'number'
        },
        "courseWorkId": {
          name: 'courseWorkId',
          type: 'string'
        },
        "googleUserId": {
          name: 'googleUserId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'GoogleClassroom',
          model: 'GoogleClassroom',
          relationType: 'belongsTo',
                  keyFrom: 'googleClassroomId',
          keyTo: 'id'
        },
      }
    }
  }
}
