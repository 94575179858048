/* tslint:disable */
import {
  AnnouncementGroup,
  AnnouncementChallenge,
  AnnouncementTranslation
} from '../index';

declare var Object: any;
export interface AnnouncementInterface {
  "title"?: string;
  "body"?: string;
  "status": string;
  "startDate"?: Date;
  "endDate"?: Date;
  "publishedAt"?: Date;
  "archivedAt"?: Date;
  "language"?: string;
  "bodyImageUrl"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "grades"?: Array<any>;
  "id"?: number;
  announcementGroup?: AnnouncementGroup[];
  announcementChallenge?: AnnouncementChallenge[];
  translation?: AnnouncementTranslation[];
}

export class Announcement implements AnnouncementInterface {
  "title": string;
  "body": string;
  "status": string;
  "startDate": Date;
  "endDate": Date;
  "publishedAt": Date;
  "archivedAt": Date;
  "language": string;
  "bodyImageUrl": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "grades": Array<any>;
  "id": number;
  announcementGroup: AnnouncementGroup[];
  announcementChallenge: AnnouncementChallenge[];
  translation: AnnouncementTranslation[];
  constructor(data?: AnnouncementInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Announcement`.
   */
  public static getModelName() {
    return "Announcement";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Announcement for dynamic purposes.
  **/
  public static factory(data: AnnouncementInterface): Announcement{
    return new Announcement(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Announcement',
      plural: 'Announcements',
      path: 'Announcements',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "body": {
          name: 'body',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "publishedAt": {
          name: 'publishedAt',
          type: 'Date'
        },
        "archivedAt": {
          name: 'archivedAt',
          type: 'Date'
        },
        "language": {
          name: 'language',
          type: 'string'
        },
        "bodyImageUrl": {
          name: 'bodyImageUrl',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "grades": {
          name: 'grades',
          type: 'Array&lt;any&gt;'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        announcementGroup: {
          name: 'announcementGroup',
          type: 'AnnouncementGroup[]',
          model: 'AnnouncementGroup',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'announcementId'
        },
        announcementChallenge: {
          name: 'announcementChallenge',
          type: 'AnnouncementChallenge[]',
          model: 'AnnouncementChallenge',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'announcementId'
        },
        translation: {
          name: 'translation',
          type: 'AnnouncementTranslation[]',
          model: 'AnnouncementTranslation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'announcementId'
        },
      }
    }
  }
}
