/* tslint:disable */
import {
  ChallengeLesson,
  Machine
} from '../index';

declare var Object: any;
export interface LessonInterface {
  "name": string;
  "code": string;
  "displayName": string;
  "active"?: boolean;
  "description"?: string;
  "entityId"?: number;
  "overview"?: string;
  "targetGrades": string;
  "standards"?: string;
  "wrapup"?: string;
  "resources"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "tags"?: string;
  "systemTags"?: string;
  "strings"?: any;
  "publishedAt": Date;
  "startDate": Date;
  "endDate": Date;
  "target"?: number;
  "programCode"?: string;
  "order"?: number;
  "xpModifier"?: string;
  "authenticationProviderId"?: number;
  "challengeTimelineId"?: number;
  "alwaysShowCompleted": boolean;
  "accessLevel"?: number;
  "thumbnailUrl"?: string;
  "introLesson"?: boolean;
  "detailsIconUrl"?: string;
  "detailsDescription"?: string;
  "detailsUnlockWithPlayText"?: string;
  "detailsUnlockWithPayText"?: string;
  "detailsVideoUrl"?: string;
  "detailsMachineUrl"?: string;
  "detailsCustomUrl"?: string;
  "startContent"?: string;
  "setStartedDescription"?: string;
  "upgradeSetDescription"?: string;
  "id"?: number;
  challenges?: ChallengeLesson[];
  machines?: Machine[];
}

export class Lesson implements LessonInterface {
  "name": string;
  "code": string;
  "displayName": string;
  "active": boolean;
  "description": string;
  "entityId": number;
  "overview": string;
  "targetGrades": string;
  "standards": string;
  "wrapup": string;
  "resources": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "tags": string;
  "systemTags": string;
  "strings": any;
  "publishedAt": Date;
  "startDate": Date;
  "endDate": Date;
  "target": number;
  "programCode": string;
  "order": number;
  "xpModifier": string;
  "authenticationProviderId": number;
  "challengeTimelineId": number;
  "alwaysShowCompleted": boolean;
  "accessLevel": number;
  "thumbnailUrl": string;
  "introLesson": boolean;
  "detailsIconUrl": string;
  "detailsDescription": string;
  "detailsUnlockWithPlayText": string;
  "detailsUnlockWithPayText": string;
  "detailsVideoUrl": string;
  "detailsMachineUrl": string;
  "detailsCustomUrl": string;
  "startContent": string;
  "setStartedDescription": string;
  "upgradeSetDescription": string;
  "id": number;
  challenges: ChallengeLesson[];
  machines: Machine[];
  constructor(data?: LessonInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Lesson`.
   */
  public static getModelName() {
    return "Lesson";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Lesson for dynamic purposes.
  **/
  public static factory(data: LessonInterface): Lesson{
    return new Lesson(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Lesson',
      plural: 'Lessons',
      path: 'Lessons',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: false
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "entityId": {
          name: 'entityId',
          type: 'number'
        },
        "overview": {
          name: 'overview',
          type: 'string'
        },
        "targetGrades": {
          name: 'targetGrades',
          type: 'string'
        },
        "standards": {
          name: 'standards',
          type: 'string'
        },
        "wrapup": {
          name: 'wrapup',
          type: 'string'
        },
        "resources": {
          name: 'resources',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "tags": {
          name: 'tags',
          type: 'string'
        },
        "systemTags": {
          name: 'systemTags',
          type: 'string'
        },
        "strings": {
          name: 'strings',
          type: 'any'
        },
        "publishedAt": {
          name: 'publishedAt',
          type: 'Date'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date'
        },
        "target": {
          name: 'target',
          type: 'number'
        },
        "programCode": {
          name: 'programCode',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number',
          default: 0
        },
        "xpModifier": {
          name: 'xpModifier',
          type: 'string'
        },
        "authenticationProviderId": {
          name: 'authenticationProviderId',
          type: 'number',
          default: 0
        },
        "challengeTimelineId": {
          name: 'challengeTimelineId',
          type: 'number'
        },
        "alwaysShowCompleted": {
          name: 'alwaysShowCompleted',
          type: 'boolean',
          default: false
        },
        "accessLevel": {
          name: 'accessLevel',
          type: 'number',
          default: 0
        },
        "thumbnailUrl": {
          name: 'thumbnailUrl',
          type: 'string'
        },
        "introLesson": {
          name: 'introLesson',
          type: 'boolean',
          default: true
        },
        "detailsIconUrl": {
          name: 'detailsIconUrl',
          type: 'string'
        },
        "detailsDescription": {
          name: 'detailsDescription',
          type: 'string'
        },
        "detailsUnlockWithPlayText": {
          name: 'detailsUnlockWithPlayText',
          type: 'string'
        },
        "detailsUnlockWithPayText": {
          name: 'detailsUnlockWithPayText',
          type: 'string'
        },
        "detailsVideoUrl": {
          name: 'detailsVideoUrl',
          type: 'string'
        },
        "detailsMachineUrl": {
          name: 'detailsMachineUrl',
          type: 'string'
        },
        "detailsCustomUrl": {
          name: 'detailsCustomUrl',
          type: 'string'
        },
        "startContent": {
          name: 'startContent',
          type: 'string'
        },
        "setStartedDescription": {
          name: 'setStartedDescription',
          type: 'string'
        },
        "upgradeSetDescription": {
          name: 'upgradeSetDescription',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        challenges: {
          name: 'challenges',
          type: 'ChallengeLesson[]',
          model: 'ChallengeLesson',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'lessonId'
        },
        machines: {
          name: 'machines',
          type: 'Machine[]',
          model: 'Machine',
          relationType: 'hasMany',
          modelThrough: 'LessonMachine',
          keyThrough: 'machineId',
          keyFrom: 'id',
          keyTo: 'lessonId'
        },
      }
    }
  }
}
