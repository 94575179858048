/* tslint:disable */
import {
  ActivityLog
} from '../index';

declare var Object: any;
export interface ActivityTypeInterface {
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "name"?: string;
  "description"?: string;
  "active"?: boolean;
  "id"?: number;
  activities?: ActivityLog[];
}

export class ActivityType implements ActivityTypeInterface {
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "name": string;
  "description": string;
  "active": boolean;
  "id": number;
  activities: ActivityLog[];
  constructor(data?: ActivityTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ActivityType`.
   */
  public static getModelName() {
    return "ActivityType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ActivityType for dynamic purposes.
  **/
  public static factory(data: ActivityTypeInterface): ActivityType{
    return new ActivityType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ActivityType',
      plural: 'ActivityTypes',
      path: 'ActivityTypes',
      idName: 'id',
      properties: {
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        activities: {
          name: 'activities',
          type: 'ActivityLog[]',
          model: 'ActivityLog',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'activityTypeId'
        },
      }
    }
  }
}
