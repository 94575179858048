/* tslint:disable */

declare var Object: any;
export interface UserAccountObjectHistoryInterface {
  "userAccountId": number;
  "objectId": number;
  "objectTypeId": number;
  "machineId": number;
  "status": string;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class UserAccountObjectHistory implements UserAccountObjectHistoryInterface {
  "userAccountId": number;
  "objectId": number;
  "objectTypeId": number;
  "machineId": number;
  "status": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: UserAccountObjectHistoryInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UserAccountObjectHistory`.
   */
  public static getModelName() {
    return "UserAccountObjectHistory";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UserAccountObjectHistory for dynamic purposes.
  **/
  public static factory(data: UserAccountObjectHistoryInterface): UserAccountObjectHistory{
    return new UserAccountObjectHistory(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UserAccountObjectHistory',
      plural: 'UserAccountObjectHistories',
      path: 'UserAccountObjectHistories',
      idName: 'id',
      properties: {
        "userAccountId": {
          name: 'userAccountId',
          type: 'number'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "objectTypeId": {
          name: 'objectTypeId',
          type: 'number'
        },
        "machineId": {
          name: 'machineId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'string',
          default: 'ADD'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
