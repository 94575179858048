import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BannerService } from '../banner.service';

@Component({
  selector: 'app-banner-item',
  templateUrl: './banner-item.component.html',
  styles: [],
})
export class BannerItemComponent implements OnInit {
  constructor(private $router: Router, private $banner: BannerService) {}

  @Input() banner: Banner;
  @Input() rounded = false;
  ngOnInit() {}

  onClick(event, action: Action) {
    if (action.onClick) {
      action.onClick(event);
    } else if (action.href) {
      const port = window.location.port ? `:${window.location.port}` : '';
      const hostWithPort = `${window.location.hostname}${port}`;
      const baseUrl = `${window.location.protocol}//${hostWithPort}`;

      const url = new URL(action.href, baseUrl);

      console.log(hostWithPort, url);
      if (hostWithPort !== url.host) {
        window.open(action.href, '_blank');
      } else {
        const queryParams = {};
        url.searchParams.forEach((value, key) => {
          queryParams[key] = value;
        });

        this.$router.navigate([url.pathname], {
          queryParams,
        });
      }
    }
  }

  close() {
    this.$banner.close(this.banner.key);
  }
}
