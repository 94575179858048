import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  audio: HTMLAudioElement = new Audio();
  constructor(private $translocoService: TranslocoService) {
    this.audio.volume = 0.2;
  }

  getLocalizedValue(value: any, translocoKey?) {
    if (value && typeof value === 'object') {
      const currentLanguage = this.$translocoService.getActiveLang();

      if (value[currentLanguage]) {
        return value[currentLanguage];
      }

      if (value.en) {
        return value.en;
      }

      return Object.values(value)[0];
    }

    if (!value && translocoKey) {
      return this.$translocoService.translate(translocoKey);
    }

    return value;
  }

  getLocalizedName(data: any) {
    if (!data) {
      return null;
    }
    if (data.strings) {
      let str;
      if (typeof data.strings == 'object') {
        str = data.strings;
      } else {
        try {
          str = JSON.parse(data.strings);
        } catch (e) {}
      }

      if (str) {
        if (str[this.$translocoService.getActiveLang()] && str[this.$translocoService.getActiveLang()].name) {
          return str[this.$translocoService.getActiveLang()].name;
        } else if (str['en'] && str['en'].name) {
          return str['en'].name;
        } else {
          return data.name;
        }
      } else {
        return data.name;
      }
    } else {
      return data.name;
    }
  }

  isValidShareId(str: string) {
    return /^M[A-Z0-9]{6}$|^[0-9]{6}$/.test(str);
  }

  tryParse(json: string, fallback = {}) {
    try {
      return JSON.parse(json);
    } catch (e) {
      return fallback;
    }
  }
  leadingZero(number: number) {
    return number < 10 ? `0${number}` : number;
  }

  linkify(str: string) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = str.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  }

  delinkify(str: string) {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<a href=".*" target="_blank">)/gi, '').replace(/(<\/a>)/gi, '');
  }

  smartSplit(str: string, isNumerical: boolean = false): (number | string)[] {
    try {
      return str
        .replace(' ', '')
        .split(',')
        .map((item) => (isNumerical ? parseInt(item) : item));
    } catch {
      return [];
    }
  }
}
