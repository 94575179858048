import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { EntityApi } from 'src/app/shared/sdk';
import { SchoolPickerService } from 'src/app/shared/modules/school-picker/school-picker.service';

interface School {
  id: number;
  path: string;
}

@Component({
  selector: 'app-schools-list',
  templateUrl: './schools-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

  providers: [SchoolPickerService],
})
export class SchoolsListComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  constructor(private cdr: ChangeDetectorRef, private $schoolPicker: SchoolPickerService, private $entity: EntityApi) {}

  @Input() searchBox: boolean;
  @Input() keyword: string;
  @Input() entityId: number;
  @Input() language: string;
  @Input() school: number;
  @Input() isSavingOtherSchool: boolean;
  @Input() prependItem: School;
  @Input() otherSchool: string;

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() createOther: EventEmitter<string> = new EventEmitter();
  @Output() searching: EventEmitter<any> = new EventEmitter();

  @ViewChild('input') input;

  submitting = false;

  keywordSubject: Subject<any> = new Subject();

  schools: School[] = [];

  minKeywordLength = 4;

  isSearching: boolean;
  loadingPlaceholders = [1, 2, 3];

  emptyStateMessage: string;

  otherSchoolPopupValue: string = '';
  isOtherSchoolPopupOpened = false;

  ngOnInit() {
    this.$schoolPicker.subscribe((schools) => {
      this.schools = schools;

      this.cdr.markForCheck();
    });

    if (this.prependItem) {
      this.schools = [this.prependItem];
    }
    this.$schoolPicker.isSearching.subscribe((isSearching: boolean) => {
      this.isSearching = isSearching;
      this.cdr.markForCheck();
      this.searching.emit(isSearching);
    });

    this.$schoolPicker.errorMessage.subscribe((errorMessage: string) => {
      this.emptyStateMessage = errorMessage;
      this.cdr.markForCheck();
    });
  }

  selectSchool(school) {
    this.onSelect.emit(school);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.keyword && changes.keyword.currentValue !== changes.keyword.previousValue) {
      this.$schoolPicker.search(changes.keyword.currentValue, this.entityId, this.language || null);
    }
  }

  ngOnDestroy() {
    this.$schoolPicker.clear();
  }

  onCreateOther(value?: string) {
    this.createOther.emit(value || this.$schoolPicker.keywordSubject.getValue());
  }
  get selectedSchool() {
    return this.school;
  }

  search(keyword) {
    this.$schoolPicker.search(keyword, this.entityId, this.language);
  }

  ngAfterViewInit() {
    if (this.input && this.input.input) {
      this.input.input.nativeElement.focus();
    }
  }

  get isValidKeyword() {
    return this.$schoolPicker.isValidKeyword(this.$schoolPicker.keywordSubject.getValue());
  }

  openOtherSchoolPopup() {
    this.isOtherSchoolPopupOpened = true;
    this.cdr.markForCheck();
  }

  closeOtherSchoolPopup() {
    this.otherSchoolPopupValue = '';
    this.isOtherSchoolPopupOpened = false;
    this.cdr.markForCheck();
  }

  changeOtherSchoolPopupValue(value: string) {
    this.otherSchoolPopupValue = value;
    this.cdr.markForCheck();
  }

  get isValidOtherSchool() {
    return this.$schoolPicker.isValidKeyword(this.otherSchoolPopupValue);
  }

}
