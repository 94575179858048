/* tslint:disable */
import {
  Permission,
  UserAccount
} from '../index';

declare var Object: any;
export interface GroupInterface {
  "active"?: boolean;
  "globalAdminOnly"?: boolean;
  "name"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "id"?: number;
  permissions?: Permission[];
  roles?: any[];
  userAccounts?: UserAccount[];
}

export class Group implements GroupInterface {
  "active": boolean;
  "globalAdminOnly": boolean;
  "name": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  permissions: Permission[];
  roles: any[];
  userAccounts: UserAccount[];
  constructor(data?: GroupInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Group`.
   */
  public static getModelName() {
    return "Group";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Group for dynamic purposes.
  **/
  public static factory(data: GroupInterface): Group{
    return new Group(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Group',
      plural: 'Groups',
      path: 'Groups',
      idName: 'id',
      properties: {
        "active": {
          name: 'active',
          type: 'boolean'
        },
        "globalAdminOnly": {
          name: 'globalAdminOnly',
          type: 'boolean'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        permissions: {
          name: 'permissions',
          type: 'Permission[]',
          model: 'Permission',
          relationType: 'hasMany',
          modelThrough: 'GroupPermission',
          keyThrough: 'permissionId',
          keyFrom: 'id',
          keyTo: 'groupId'
        },
        roles: {
          name: 'roles',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          modelThrough: 'GroupRole',
          keyThrough: 'roleId',
          keyFrom: 'id',
          keyTo: 'groupId'
        },
        userAccounts: {
          name: 'userAccounts',
          type: 'UserAccount[]',
          model: 'UserAccount',
          relationType: 'hasMany',
          modelThrough: 'UserAccountGroup',
          keyThrough: 'userAccountId',
          keyFrom: 'id',
          keyTo: 'groupId'
        },
      }
    }
  }
}
