/* tslint:disable */
import {
  EntityTranslation,
  EntityType,
  TimeZone,
  UserAccount
} from '../index';

declare var Object: any;
export interface EntityInterface {
  "entityTypeId": number;
  "code"?: string;
  "name": string;
  "parentId"?: number;
  "active"?: boolean;
  "description"?: string;
  "parentPath"?: string;
  "createdAt": Date;
  "createdBy"?: number;
  "updatedAt": Date;
  "updatedBy"?: number;
  "timeZoneId"?: string;
  "observeDst"?: boolean;
  "topEntityId"?: number;
  "data"?: any;
  "position"?: string;
  "address1"?: string;
  "address2"?: string;
  "city"?: string;
  "state"?: string;
  "zip"?: string;
  "country"?: string;
  "ownerUserAccountId"?: number;
  "joiningEnabled"?: boolean;
  "branding"?: any;
  "onboarding"?: any;
  "minorAge"?: number;
  "fromMessage"?: string;
  "fromSms"?: string;
  "fromEmail"?: string;
  "tags"?: string;
  "systemTags"?: string;
  "strings"?: any;
  "defaultLanguage"?: string;
  "id"?: number;
  "bucketId"?: number;
  parentEntity?: Entity;
  childEntities?: Entity[];
  history?: any[];
  translations?: EntityTranslation[];
  entityType?: EntityType;
  timeZone?: TimeZone;
  createdByUser?: UserAccount;
  updatedByUser?: UserAccount;
  userAccounts?: UserAccount[];
}

export class Entity implements EntityInterface {
  "entityTypeId": number;
  "code": string;
  "name": string;
  "parentId": number;
  "active": boolean;
  "description": string;
  "parentPath": string;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "timeZoneId": string;
  "observeDst": boolean;
  "topEntityId": number;
  "data": any;
  "position": string;
  "address1": string;
  "address2": string;
  "city": string;
  "state": string;
  "zip": string;
  "country": string;
  "ownerUserAccountId": number;
  "joiningEnabled": boolean;
  "branding": any;
  "onboarding": any;
  "minorAge": number;
  "fromMessage": string;
  "fromSms": string;
  "fromEmail": string;
  "tags": string;
  "systemTags": string;
  "strings": any;
  "defaultLanguage": string;
  "id": number;
  "bucketId": number;
  parentEntity: Entity;
  childEntities: Entity[];
  history: any[];
  translations: EntityTranslation[];
  entityType: EntityType;
  timeZone: TimeZone;
  createdByUser: UserAccount;
  updatedByUser: UserAccount;
  userAccounts: UserAccount[];
  constructor(data?: EntityInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Entity`.
   */
  public static getModelName() {
    return "Entity";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Entity for dynamic purposes.
  **/
  public static factory(data: EntityInterface): Entity{
    return new Entity(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Entity',
      plural: 'Entities',
      path: 'Entities',
      idName: 'id',
      properties: {
        "entityTypeId": {
          name: 'entityTypeId',
          type: 'number'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "parentId": {
          name: 'parentId',
          type: 'number',
          default: 0
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: false
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "parentPath": {
          name: 'parentPath',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "timeZoneId": {
          name: 'timeZoneId',
          type: 'string'
        },
        "observeDst": {
          name: 'observeDst',
          type: 'boolean',
          default: true
        },
        "topEntityId": {
          name: 'topEntityId',
          type: 'number'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "position": {
          name: 'position',
          type: 'string'
        },
        "address1": {
          name: 'address1',
          type: 'string'
        },
        "address2": {
          name: 'address2',
          type: 'string'
        },
        "city": {
          name: 'city',
          type: 'string'
        },
        "state": {
          name: 'state',
          type: 'string'
        },
        "zip": {
          name: 'zip',
          type: 'string'
        },
        "country": {
          name: 'country',
          type: 'string'
        },
        "ownerUserAccountId": {
          name: 'ownerUserAccountId',
          type: 'number'
        },
        "joiningEnabled": {
          name: 'joiningEnabled',
          type: 'boolean',
          default: true
        },
        "branding": {
          name: 'branding',
          type: 'any'
        },
        "onboarding": {
          name: 'onboarding',
          type: 'any'
        },
        "minorAge": {
          name: 'minorAge',
          type: 'number'
        },
        "fromMessage": {
          name: 'fromMessage',
          type: 'string'
        },
        "fromSms": {
          name: 'fromSms',
          type: 'string'
        },
        "fromEmail": {
          name: 'fromEmail',
          type: 'string'
        },
        "tags": {
          name: 'tags',
          type: 'string'
        },
        "systemTags": {
          name: 'systemTags',
          type: 'string'
        },
        "strings": {
          name: 'strings',
          type: 'any'
        },
        "defaultLanguage": {
          name: 'defaultLanguage',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "bucketId": {
          name: 'bucketId',
          type: 'number'
        },
      },
      relations: {
        parentEntity: {
          name: 'parentEntity',
          type: 'Entity',
          model: 'Entity',
          relationType: 'belongsTo',
                  keyFrom: 'parentId',
          keyTo: 'id'
        },
        childEntities: {
          name: 'childEntities',
          type: 'Entity[]',
          model: 'Entity',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'parentId'
        },
        history: {
          name: 'history',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'entityId'
        },
        translations: {
          name: 'translations',
          type: 'EntityTranslation[]',
          model: 'EntityTranslation',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'entityId'
        },
        entityType: {
          name: 'entityType',
          type: 'EntityType',
          model: 'EntityType',
          relationType: 'belongsTo',
                  keyFrom: 'entityTypeId',
          keyTo: 'id'
        },
        timeZone: {
          name: 'timeZone',
          type: 'TimeZone',
          model: 'TimeZone',
          relationType: 'belongsTo',
                  keyFrom: 'timeZoneId',
          keyTo: 'id'
        },
        createdByUser: {
          name: 'createdByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'createdBy',
          keyTo: 'id'
        },
        updatedByUser: {
          name: 'updatedByUser',
          type: 'UserAccount',
          model: 'UserAccount',
          relationType: 'belongsTo',
                  keyFrom: 'updatedBy',
          keyTo: 'id'
        },
        userAccounts: {
          name: 'userAccounts',
          type: 'UserAccount[]',
          model: 'UserAccount',
          relationType: 'hasMany',
          modelThrough: 'UserEntity',
          keyThrough: 'userAccountId',
          keyFrom: 'id',
          keyTo: 'entityId'
        },
      }
    }
  }
}
