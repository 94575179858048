/* tslint:disable */
import {
  Tutorial
} from '../index';

declare var Object: any;
export interface TutorialStepInterface {
  "tutorialId": number;
  "tutorialElement": string;
  "order": number;
  "name"?: string;
  "displayName"?: string;
  "data": any;
  "active"?: boolean;
  "tutorialTranslationId"?: number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
  tutorial?: Tutorial;
}

export class TutorialStep implements TutorialStepInterface {
  "tutorialId": number;
  "tutorialElement": string;
  "order": number;
  "name": string;
  "displayName": string;
  "data": any;
  "active": boolean;
  "tutorialTranslationId": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  tutorial: Tutorial;
  constructor(data?: TutorialStepInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TutorialStep`.
   */
  public static getModelName() {
    return "TutorialStep";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TutorialStep for dynamic purposes.
  **/
  public static factory(data: TutorialStepInterface): TutorialStep{
    return new TutorialStep(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TutorialStep',
      plural: 'TutorialSteps',
      path: 'TutorialSteps',
      idName: 'id',
      properties: {
        "tutorialId": {
          name: 'tutorialId',
          type: 'number'
        },
        "tutorialElement": {
          name: 'tutorialElement',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "tutorialTranslationId": {
          name: 'tutorialTranslationId',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        tutorial: {
          name: 'tutorial',
          type: 'Tutorial',
          model: 'Tutorial',
          relationType: 'belongsTo',
                  keyFrom: 'tutorialId',
          keyTo: 'id'
        },
      }
    }
  }
}
