<div class="outer" [style.z-index]="1000" (click)="handleClick($event)" @simpleFadeAnimation *cdkPortal>
  <ng-content></ng-content>
  <div *ngFor="let item of items">
    <ng-container *ngIf="item.visible != false">
      <div *ngIf="item.separated" class="separator-lineonly light"></div>
      <div class="inner" [id]="'dropdown-' + item.id" (click)="itemClicked($event, item.id, true)">
        <div class="mar-md-right nowrap">{{ item.name | transloco }}</div>
        <img *ngIf="item.img" [src]="item.img" />
        <app-toggle
          *ngIf="item.toggle"
          (toggled)="itemClicked($event, item.id + '-' + ($event ? 'on' : 'off'), false)"
          (click)="$event.stopPropagation()"
          [on]="item.toggleDefault"
        ></app-toggle>
      </div>
    </ng-container>
  </div>
</div>

<!-- <div (click)="clickOut($event)" class="transparent-veil"></div> -->
