/* tslint:disable */

declare var Object: any;
export interface TopicCommentReportTypeInterface {
  "name"?: string;
  "displayName"?: string;
  "description"?: string;
  "code": string;
  "order"?: number;
  "createdAt"?: Date;
  "createdBy"?: number;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: number;
}

export class TopicCommentReportType implements TopicCommentReportTypeInterface {
  "name": string;
  "displayName": string;
  "description": string;
  "code": string;
  "order": number;
  "createdAt": Date;
  "createdBy": number;
  "updatedAt": Date;
  "updatedBy": number;
  "id": number;
  constructor(data?: TopicCommentReportTypeInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TopicCommentReportType`.
   */
  public static getModelName() {
    return "TopicCommentReportType";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TopicCommentReportType for dynamic purposes.
  **/
  public static factory(data: TopicCommentReportTypeInterface): TopicCommentReportType{
    return new TopicCommentReportType(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TopicCommentReportType',
      plural: 'TopicCommentReportTypes',
      path: 'TopicCommentReportTypes',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "order": {
          name: 'order',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'number'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
