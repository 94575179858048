<link href="https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap" rel="stylesheet">

<div class="min-h-screen flex flex-col">
  <lo-nav *ngIf="!hideNav"></lo-nav>
  <div class="relative z-40 main print:mt-0 flex-1" [class.mt-16]="paddingTop">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
  <lo-footer></lo-footer>
</div>

<app-modal *ngIf="loginPopupActive" (close)="closeAuth()">
  <app-auth [mode]="authMode"></app-auth>
</app-modal>