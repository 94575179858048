export type Action = {
  title: string;
  onClick: () => void;
  color?: string;
  classes?: string;
};

export enum Size {
  xs = 'xs',
  md = 'md',
}

export enum Align {
  start = 'start',
  center = 'center',
  end = 'end',
}

export enum Direction {
  row = 'row',
  column = 'column',
}
