/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SDKModels } from './SDKModels';
import { BaseLoopBackApi } from '../core/base.service';
import { LoopBackConfig } from '../../lb.config';
import { LoopBackAuth } from '../core/auth.service';
import { LoopBackFilter,  } from '../../models/BaseModels';
import { ErrorHandler } from '../core/error.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UIApp } from '../../models/UIApp';
import { SocketConnection } from '../../sockets/socket.connections';


/**
 * Api services for the `UIApp` model.
 */
@Injectable()
export class UIAppApi extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(SocketConnection) protected connection: SocketConnection,
    @Inject(SDKModels) protected models: SDKModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http,  connection,  models, auth, errorHandler);
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} machineShareId 
   *
   * @param {boolean} machineOnly 
   *
   * @param {boolean} rootOnly 
   *
   * @param {boolean} includeChips 
   *
   * @param {boolean} includeProgress 
   *
   * @param {number} machineVersion 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public findByShareId(machineShareId: String, machineOnly: Boolean = new Boolean(), rootOnly: Boolean = new Boolean(), includeChips: Boolean = new Boolean(), includeProgress: Boolean = new Boolean(), machineVersion: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<UIApp> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/findByShareId";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof machineShareId !== 'undefined' && machineShareId !== null) _urlParams.machineShareId = machineShareId;
    if (typeof machineOnly !== 'undefined' && machineOnly !== null) _urlParams.machineOnly = machineOnly;
    if (typeof rootOnly !== 'undefined' && rootOnly !== null) _urlParams.rootOnly = rootOnly;
    if (typeof includeChips !== 'undefined' && includeChips !== null) _urlParams.includeChips = includeChips;
    if (typeof includeProgress !== 'undefined' && includeProgress !== null) _urlParams.includeProgress = includeProgress;
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result.pipe(map((instance: UIApp) => new UIApp(instance)));
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {boolean} machineOnly 
   *
   * @param {boolean} rootOnly 
   *
   * @param {boolean} includeChips 
   *
   * @param {number} machineVersion 
   *
   * @param {number} totalCount 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public recommendations(id: Number, machineOnly: Boolean = new Boolean(), rootOnly: Boolean = new Boolean(), includeChips: Boolean = new Boolean(), machineVersion: Number = new Number(), totalCount: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/:id/recommendations";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof machineOnly !== 'undefined' && machineOnly !== null) _urlParams.machineOnly = machineOnly;
    if (typeof rootOnly !== 'undefined' && rootOnly !== null) _urlParams.rootOnly = rootOnly;
    if (typeof includeChips !== 'undefined' && includeChips !== null) _urlParams.includeChips = includeChips;
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof totalCount !== 'undefined' && totalCount !== null) _urlParams.totalCount = totalCount;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} machineVersion 
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public userMachines(machineVersion: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/userMachines";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} whatToLink 1 = default machines ONLY, 2 = NOT default machines, 3 = ALL machines
   *
   * @param {number} machineVersion 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public link(whatToLink: Number, machineVersion: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/link";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof whatToLink !== 'undefined' && whatToLink !== null) _urlParams.whatToLink = whatToLink;
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postMachine(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machine";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchMachine(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machine/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postComponent(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/component";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchComponent(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/component/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postChip(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/chip";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchChip(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/chip/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postMachineProgress(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machineProgress";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchMachineProgress(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machineProgress/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postComponentProgress(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/componentProgress";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchComponentProgress(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/componentProgress/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postChipProgress(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/chipProgress";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchChipProgress(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/chipProgress/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postComponentChip(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/componentChip";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchComponentChip(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/componentChip/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postMachineComponent(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machineComponent";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchMachineComponent(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machineComponent/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} machineShareId 
   *
   * @param {boolean} machineOnly 
   *
   * @param {boolean} rootOnly 
   *
   * @param {boolean} includeChips 
   *
   * @param {boolean} includeProgress 
   *
   * @param {number} machineVersion 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public machineFindByShareId(machineShareId: String, machineOnly: Boolean = new Boolean(), rootOnly: Boolean = new Boolean(), includeChips: Boolean = new Boolean(), includeProgress: Boolean = new Boolean(), machineVersion: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machines/findByShareId";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof machineShareId !== 'undefined' && machineShareId !== null) _urlParams.machineShareId = machineShareId;
    if (typeof machineOnly !== 'undefined' && machineOnly !== null) _urlParams.machineOnly = machineOnly;
    if (typeof rootOnly !== 'undefined' && rootOnly !== null) _urlParams.rootOnly = rootOnly;
    if (typeof includeChips !== 'undefined' && includeChips !== null) _urlParams.includeChips = includeChips;
    if (typeof includeProgress !== 'undefined' && includeProgress !== null) _urlParams.includeProgress = includeProgress;
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {boolean} machineOnly 
   *
   * @param {boolean} rootOnly 
   *
   * @param {boolean} includeChips 
   *
   * @param {number} machineVersion 
   *
   * @param {number} totalCount 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public machineRecommendations(id: Number, machineOnly: Boolean = new Boolean(), rootOnly: Boolean = new Boolean(), includeChips: Boolean = new Boolean(), machineVersion: Number = new Number(), totalCount: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machines/:id/recommendations";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof machineOnly !== 'undefined' && machineOnly !== null) _urlParams.machineOnly = machineOnly;
    if (typeof rootOnly !== 'undefined' && rootOnly !== null) _urlParams.rootOnly = rootOnly;
    if (typeof includeChips !== 'undefined' && includeChips !== null) _urlParams.includeChips = includeChips;
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof totalCount !== 'undefined' && totalCount !== null) _urlParams.totalCount = totalCount;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} machineVersion 
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public machinesUserMachines(machineVersion: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machines/userMachines";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} whatToLink 1 = default machines ONLY, 2 = NOT default machines, 3 = ALL machines
   *
   * @param {number} machineVersion 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public machinesLink(whatToLink: Number, machineVersion: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machines/link";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof whatToLink !== 'undefined' && whatToLink !== null) _urlParams.whatToLink = whatToLink;
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postMachines(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machines";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchMachines(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machines/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postComponents(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/components";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchComponents(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/components/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postChips(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/chips";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchChips(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/chips/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postMachineProgresses(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machineProgresses";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchMachineProgresses(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machineProgresses/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postComponentProgresses(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/componentProgresses";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchComponentProgresses(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/componentProgresses/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postChipProgresses(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/chipProgresses";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchChipProgresses(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/chipProgresses/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postComponentChips(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/componentChips";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchComponentChips(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/componentChips/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postMachineComponents(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machineComponents";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - {
         *               
         *           }
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchMachineComponents(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machineComponents/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postMachineThumbnail(id: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machines/:id/thumbnail";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getTeamMachines(id: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/teams/:id/machines";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} tag 
   *
   * @param {number} totalCount 
   *
   * @param {number} machineVersion 
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getMachinesByTag(tag: String, totalCount: Number = new Number(), machineVersion: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machinesByTag";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof tag !== 'undefined' && tag !== null) _urlParams.tag = tag;
    if (typeof totalCount !== 'undefined' && totalCount !== null) _urlParams.totalCount = totalCount;
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getLessonById(id: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/lessons/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} code 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getLessonByCode(code: String, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/lessons/byCode/:code";
    let _routeParams: any = {
      code: code
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} grade 
   *
   * @param {number} target 
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getLessons(grade: String = new String(), target: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/lessons";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof grade !== 'undefined' && grade !== null) _urlParams.grade = grade;
    if (typeof target !== 'undefined' && target !== null) _urlParams.target = target;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} searchTerm 
   *
   * @param {number} limit 
   *
   * @param {number} skip 
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getSearch(searchTerm: String, limit: Number = new Number(), skip: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/search";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof searchTerm !== 'undefined' && searchTerm !== null) _urlParams.searchTerm = searchTerm;
    if (typeof limit !== 'undefined' && limit !== null) _urlParams.limit = limit;
    if (typeof skip !== 'undefined' && skip !== null) _urlParams.skip = skip;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postMachineRating(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machineRatings";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postEntityPrize(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/EntityChallenges/AddPrize";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postClaimPendingCoin(data: any = {}, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/CurrencyTransaction/ClaimPendingCoin";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getPendingCoin(options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/CurrencyTransaction/PendingCoin";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postAddMachineTag(data: any = {}, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Machines/addTag";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public deleteRemoveMachineTag(data: any = {}, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Machines/RemoveTag";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postAddUserAccountTag(data: any = {}, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/UserAccounts/AddTag";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public deleteRemoveUserAccountTag(data: any = {}, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/UserAccounts/RemoveTag";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postAddTeamTag(data: any = {}, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Entities/AddTag";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public deleteRemoveTeamTag(data: any = {}, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "DELETE";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Entities/RemoveTag";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getEntityTags(id: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Entities/:id/tags";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postCheckLogin(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/UserAccounts/CheckLogin";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postAuthorize(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/UserAccountsOauth/Authorize";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getLab(id: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Labs/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getLabs(options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Labs";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getLabSurfaces(id: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Labs/:id/Surfaces";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postLab(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Labs";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchLab(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Labs/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getSurface(id: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Surfaces/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getSurfaces(options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Surfaces";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postSurface(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Surfaces";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchSurface(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Surfaces/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getPlaylist(id: Number, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Playlists/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getPlaylists(options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Playlists";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postPlaylist(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Playlists";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchPlaylist(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "PATCH";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Playlists/:id";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postPlaylistObject(id: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Playlists/:id/PlaylistObjects";
    let _routeParams: any = {
      id: id
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} id 
   *
   * @param {number} fk 
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public patchPlaylistObject(id: Number, fk: Number, data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Playlists/:id/PlaylistObjects/:fk";
    let _routeParams: any = {
      id: id,
      fk: fk
    };
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `data` – `{object}` - 
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postClone(data: any, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/Machines/Clone";
    let _routeParams: any = {};
    let _postBody: any = {
      data: data
    };
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {number} communityId 0 = All, 1 = Game Users Only, 2 = Oman Users Only, 3 = US Challenge Users Only
   *
   * @param {boolean} includeSurvey 
   *
   * @param {number} limit 
   *
   * @param {number} skip 
   *
   * @param {boolean} machineOnly 
   *
   * @param {boolean} rootOnly 
   *
   * @param {boolean} includeChips 
   *
   * @param {number} machineVersion 
   *
   * @param {object} options 
   *
   * @returns {object[]} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getCommunityMachineRecommendations(communityId: Number = new Number(), includeSurvey: Boolean = new Boolean(), limit: Number = new Number(), skip: Number = new Number(), machineOnly: Boolean = new Boolean(), rootOnly: Boolean = new Boolean(), includeChips: Boolean = new Boolean(), machineVersion: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/machines/communityrecommendations";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof communityId !== 'undefined' && communityId !== null) _urlParams.communityId = communityId;
    if (typeof includeSurvey !== 'undefined' && includeSurvey !== null) _urlParams.includeSurvey = includeSurvey;
    if (typeof limit !== 'undefined' && limit !== null) _urlParams.limit = limit;
    if (typeof skip !== 'undefined' && skip !== null) _urlParams.skip = skip;
    if (typeof machineOnly !== 'undefined' && machineOnly !== null) _urlParams.machineOnly = machineOnly;
    if (typeof rootOnly !== 'undefined' && rootOnly !== null) _urlParams.rootOnly = rootOnly;
    if (typeof includeChips !== 'undefined' && includeChips !== null) _urlParams.includeChips = includeChips;
    if (typeof machineVersion !== 'undefined' && machineVersion !== null) _urlParams.machineVersion = machineVersion;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getMyUserNameAsNonce(options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/myUserNameAsNonce";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {string} nonce 
   *
   * @param {number} authenticationProviderId 
   *
   * @param {object} options 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public getResolveRemoteNonce(nonce: String, authenticationProviderId: Number = new Number(), options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "GET";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/resolveRemoteNonce/:nonce/:authenticationProviderId";
    let _routeParams: any = {
      nonce: nonce,
      authenticationProviderId: authenticationProviderId
    };
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * <em>
         * (The remote method definition does not provide any description.)
         * </em>
   *
   * @param {object} data Request data.
   *
   *  - `ids` – `{number}` - Array of positive integers includes Board IDs
   *
   *  - `status` – `{boolean}` - Board locked status, could take True or False
   *
   *  - `options` – `{object}` - 
   *
   * @returns {object} An empty reference that will be
   *   populated with the actual data once the response is returned
   *   from the server.
   *
   * <em>
   * (The remote method definition does not provide any description.
   * This usually means the response is a `UIApp` object.)
   * </em>
   */
  public postSetLocked(ids: any[], status: Boolean, options: any = {}, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    "/UIApps/components/setLocked";
    let _routeParams: any = {};
    let _postBody: any = {};
    let _urlParams: any = {};
    if (typeof ids !== 'undefined' && ids !== null) _urlParams.ids = ids;
    if (typeof status !== 'undefined' && status !== null) _urlParams.status = status;
    if (typeof options !== 'undefined' && options !== null) _urlParams.options = options;
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `UIApp`.
   */
  public getModelName() {
    return "UIApp";
  }
}
